import Cookies from 'js-cookie';
import axios from "axios";
import { api } from './constant'

export const login = async (u, p, r) => {
    const options = { withCredentials: true };
    let data = { 'username': u, 'password': p };
    if (r) data = { ...data, 'selectedUser': r }
    const url = `${api}/login`;

    const response = await axios.post(url, data, options)
        .then(res => { return { status: true, code: res.status, roles: res.data } })
        .catch(e => { isLogin(); return { status: false, code: e.response?.status } })
    return response;
}

export const logout = async () => {
    const url = `${api}/login`;
    await axios.get(url)
    const cookies = ['isAuth', 'token', 'role'];
    cookies.map(item => Cookies.remove(item))
    return true
}

export const isLogin = () => {
    const isAuth = Cookies.get('isAuth') ? true : false;
    return isAuth;
}