import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { api } from '../../util/constant';
import { identifyRole, isArr } from '../../util/functions';
import { Dropdown } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { Notify } from 'notiflix';

const SupervisorsAndTaAndAdvisorForStudents = props => {
    const { isOFFP, isOFP, isAdminOrEidaad, isAdvisor, isAFP } = identifyRole(props.role)
    const [selectedSupervisors, setSelectedSupervisors] = useState(isArr(props.supervisors).map(item => item._id))
    const [selectedTA, setSelectedTA] = useState(isArr(props.technical_authorities).map(item => item._id))
    const [selectedAdvisor, setSelectedAdvisor] = useState(isArr(props.advisors).map(item => item._id))
    const [advisors, setAdvisors] = useState([])
    const [supervisors, setSupervisors] = useState([])
    const [TAs, setTAs] = useState([])
    const isAllowedToEditSuAndTa = isOFFP || isOFP || isAdminOrEidaad
    const isAllowedToEditAdviosor = isAdminOrEidaad || isAdvisor || isAFP
    const loader = status => props.loader(status)

    const getSupervisorsAndTaAndAdvisors = async () => {
        loader(true)
        let headers = {}
        headers = isAllowedToEditSuAndTa ? { ...headers, supervisorandta: true } : headers
        headers = isAllowedToEditAdviosor ? { ...headers, advisors: true } : headers
        await axios.get(`${api}/users`, { headers, withCredentials: true })
            .then(res => {
                const { supervisors, ta, advisors } = res.data
                setSupervisors(isArr(supervisors))
                setTAs(isArr(ta))
                setAdvisors(isArr(advisors))
                loader(false)
            })
            .catch(e => { console.log(e); loader(false) })
    }

    const updateSupervisorsAndTA = async () => {
        loader(true)
        let headers = {}
        headers = isAllowedToEditSuAndTa ? { ...headers, supervisorandta: true } : headers
        headers = isAllowedToEditAdviosor ? { ...headers, advisors: true } : headers
        await axios.put(`${api}/users`, { id: props?.studentInfo?._id, supervisors: selectedSupervisors, technical_authorities: selectedTA, advisors: selectedAdvisor }, { headers, withCredentials: true })
            .then(res => {
                props.toggle()
                props.refresh()
                loader(false)
                Notify.success('updated')
            })
            .catch(e => { Notify.failure(e.response); console.log(e); loader(false) })
    }

    useEffect(() => {
        if (!props.modal) {
            setSelectedSupervisors([])
            setSelectedTA([])
            setAdvisors([])
            setSupervisors([])
            setTAs([])
        }
    }, [props.modal])

    useEffect(() => {
        if (isAllowedToEditSuAndTa || isAllowedToEditAdviosor) getSupervisorsAndTaAndAdvisors()
    }, [props.modal])

    return <>
        <div className="alert alert-success mb-3 p-2">
            <h4 > Supervisors:[{isArr(props.supervisors).length}]</h4>
            {isAllowedToEditSuAndTa ?
                <Dropdown className="m-2"
                    placeholder='<Select supervisors>'
                    fluid
                    clearable
                    search
                    selection
                    multiple
                    options={isArr(supervisors).map((item, index) => { return { key: index, text: item?.fullname, value: item?._id } })}
                    onChange={(e, d) => { setSelectedSupervisors(d.value); }}
                    value={selectedSupervisors}
                /> :
                isArr(props.supervisors).map((item, index) => <div key={index}>{index + 1} - {item.fullname}</div>)}
        </div>
        <div className="alert alert-success mb-3 p-2">
            <h4 >Technical Authorities:[{isArr(props.technical_authorities).length}]</h4>
            {isAllowedToEditSuAndTa ?
                <Dropdown className="m-2"
                    placeholder='<Select technical authorities>'
                    fluid
                    clearable
                    search
                    selection
                    multiple
                    options={isArr(TAs).map((item, index) => { return { key: index, text: item?.fullname, value: item?._id } })}
                    onChange={(e, d) => { setSelectedTA(d.value); }}
                    value={selectedTA}
                /> :
                isArr(props.technical_authorities).map((item, index) => <div key={index}>{index + 1} - {item.fullname}</div>)}
        </div>

        <div className="alert alert-success mb-3 p-2">
            <h4 >Advisor:[{isArr(props.technical_authorities).length}]</h4>
            {isAllowedToEditAdviosor ?
                <Dropdown className="m-2"
                    placeholder='<Select Advisors>'
                    fluid
                    clearable
                    search
                    selection
                    multiple
                    options={isArr(advisors).map((item, index) => { return { key: index, text: item?.fullname, value: item?._id } })}
                    onChange={(e, d) => { setSelectedAdvisor(d.value); }}
                    value={selectedAdvisor}
                /> :
                isArr(props.technical_authorities).map((item, index) => <div key={index}>{index + 1} - {item.fullname}</div>)}
        </div>
        {isAllowedToEditSuAndTa || isAllowedToEditAdviosor ? <div className="text-center"><button className="btn btn-success" onClick={updateSupervisorsAndTA}>update</button></div> : null}
    </>
}

export default SupervisorsAndTaAndAdvisorForStudents