import React from 'react'
import Card from './card'
function Users({ numberOfOffp, numberOfOfp, numberOfStudents, numberOfSupervisors, numberOfTa, numberOfAFP }) {
    return (
        <>
            <div className="row m-2 p-2 shadow bg-white60">
                <h1 className='text-center mb-3'>Users</h1>
                <div className="p-2 col-md-4"><Card title={'organization function focal point'} number={numberOfOffp} /></div>
                <div className="p-2 col-md-4"><Card title={'organization focal point'} number={numberOfOfp} /></div>
                <div className="p-2 col-md-4"><Card title={'Students'} number={numberOfStudents} /></div>
                <div className="p-2 col-md-4"><Card title={'Supervisors'} number={numberOfSupervisors} /></div>
                <div className="p-2 col-md-4"><Card title={'Tachnical Authority'} number={numberOfTa} /></div>
                <div className="p-2 col-md-4"><Card title={'Acadamic Focal Point'} number={numberOfAFP} /></div>
            </div>
        </>
    )
}

export default Users