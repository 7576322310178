import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { api } from '../../../../util/constant'
import DisciplineModal from './disciplineModal'
import HistoryModal from '../historyModal'
import { isArr } from '../../../../util/functions'
import { IoFileTrayFullOutline } from "react-icons/io5";

function Disciplines(props) {
    const [disciplines, setDisciplines] = useState([])
    const [disciplinesForModal, setDisciplinesForModal] = useState([])
    const [selectedDisciplinesForModal, setSelectedDisciplinesForModal] = useState([])
    const [modal, setModal] = useState(false) //=>for Discipline Modal
    const loader = (status) => { props.loader(status) }
    const [modalHistory, setModalHistory] = useState(false)
    const [historyInfo, setHistoryInfo] = useState([])

    const getFunctionHistory = () => {
        loader(true)
        axios.get(`${api}/fdf/history`, { headers: { functions: props?.selectedFunction?.id }, withCredentials: true })
            .then((res) => { loader(false); setModalHistory(true); setHistoryInfo(res?.data) })
            .catch((e) => { loader(false); console.log(e) })
    }

    const getDisciplinesOfFunction = () => {
        loader(true)
        axios.get(`${api}/fdf/discipline`, { headers: { id: props?.selectedFunction?.id }, withCredentials: true })
            .then((res) => { loader(false); setDisciplines(isArr(res?.data)) })
            .catch((e) => { loader(false); console.log(e) })
    }

    const getDisciplinesForModal = () => {
        loader(true)
        axios.get(`${api}/fdf/discipline`, { headers: { id: props?.selectedFunction?.id, isModify: true }, withCredentials: true })
            .then((res) => {
                setModal(true);
                setDisciplinesForModal(isArr(res?.data?.disciplines));
                setSelectedDisciplinesForModal(isArr(res?.data?.selectedDisciplines));
                loader(false)
            })
            .catch((e) => { loader(false); console.log(e) })
    }

    useEffect(() => { getDisciplinesOfFunction() }, [])
    useEffect(() => { if (!modal) { setSelectedDisciplinesForModal([]); setDisciplinesForModal([]); } }, [modal])

    return (
        <>
            <HistoryModal
                modal={modalHistory}
                setModal={modal => setModalHistory(modal)}
                info={historyInfo}
            />
            <DisciplineModal
                disciplines={disciplinesForModal}
                selectedDisciplines={selectedDisciplinesForModal}
                setSelectedDisciplines={disciplines => setSelectedDisciplinesForModal(disciplines)}
                modal={modal}
                function={props?.selectedFunction}
                setModal={modal => setModal(modal)}
                loader={loader => props.loader(loader)}
                refresh={() => { getDisciplinesOfFunction() }}
            />
            <div className='p-2'>
                {props.AllowToAddNew ? <button className="btn btn-success float-end me-3" onClick={getDisciplinesForModal}>+</button> : null}
                <button className="btn btn-warning float-end me-1" onClick={getFunctionHistory}><IoFileTrayFullOutline size={17} /> History</button>

            </div>
            <div className='p-2'>
                {props.isBack ? <button className="btn btn-light border" style={{ fontSize: '10px' }} onClick={() => { props.setSelectedFunction({ name: '', id: '' }) }}>&larr; back | <span className="text-success">{`${props.selectedFunction.name}`}</span></button> : null}
            </div>
            <p className="h6  text-center p-2 shadow bg-white60"><span className='fw-bold'>Disciplines (</span>{props.selectedFunction.name}<span className='fw-bold'>)</span></p>
            {!disciplines.length ?
                <div className="div-center-container" ><div className=" p-3 h5 div-center alert alert-warning text-center">there is no disciplines for this function</div></div>
                : <>
                    <div className="fdf-main row p-4">
                        {disciplines.map((item, index) => {
                            return (
                                <div className="col-md-12 mb-3" onClick={() => { props.setSelectedDiscipline({ id: item?._id, name: item?.name }) }}>
                                    <div className="shadow pointer bg-white60 border p-3">
                                        <p className="h6 fw-bold">
                                            {index + 1}. {item?.name}
                                        </p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </>}
        </>
    )
}

export default Disciplines