import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Dropdown } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import axios from 'axios';
import { api } from '../../../../util/constant';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

function FdfModal(props) {
    const toggle = () => (props.setModal(!props.modal));
    const loader = (status) => { props.loader(status) };
    const refresh = () => { props.refresh() };

    const save = () => {
        const obj = retrunFdfObject();
        loader(true);
        axios.post(`${api}/fdf`, { obj }, { withCredentials: true })
            .then((res) => { toggle(); refresh(); Notify.success('fundimantal devlopment framework has been added successfully'); loader(false) })
            .catch((e) => { console.log(e); loader(false); Notify.failure(e.response.status == 403 ? e.response.data : 'somthing went wrong!'); })
    }

    const update = () => {
        const obj = retrunFdfObject()
        loader(true);
        axios.put(`${api}/fdf`, { obj, fdf_Id: props.fdfId }, { withCredentials: true })
            .then((res) => { toggle(); refresh(); Notify.success('fundimantal devlopment framework has been updated successfully'); loader(false) })
            .catch((e) => { console.log(e); loader(false); Notify.failure(e.response.status == 403 ? e.response.data : 'somthing went wrong!'); })
    }

    const retrunFdfObject = () => {
        return {
            title: props?.title,
            technicalAuths: props?.selectedTechnicalAuths,
            discipline: props?.discipline?.id,
            functions: props?.functions?.id,
            supervisors: props?.selectedSuperVisors,
            learning_need: props?.learningNeeds,
            competencies: props?.competencies
        }
    }


    return (
        <Modal size="lg" style={{ maxWidth: '700px', width: '100%' }} isOpen={props.modal} toggle={() => confirmAlert({
            title: 'Confirm to close',
            message: 'Do you want to close FDF popup ? ',
            buttons: [{ label: 'yes, close it', onClick: () => { { toggle(props) } } }, { label: 'no, continue editing', onClick: () => { } }]
        })}>

            <ModalHeader toggle={() => { toggle(props) }}>fundimantal devlopment framework</ModalHeader>
            <ModalBody>
                <div className="row">
                    <div className="col-md-12 ">
                        <p className='fw-bold'>Title :</p>
                        <input type="text" className="form-control" value={props.title} onChange={e => props.setTitle(e.target.value)} placeholder='Title' />
                    </div>
                    {/* <div className="col-md-12 ">
                        <p className='fw-bold'>Technical Authority :</p>
                        <Dropdown placeholder='<select technical authority>' fluid multiple search selection
                            options={props?.technicalAuths ? props?.technicalAuths.map(item => { return { key: item.key, text: item.fullname, value: item._id } }) : []}
                            onChange={(e, d) => { props.setSelectedTechnicalAuths(d.value); }}
                            value={props.selectedTechnicalAuths}
                        />
                    </div> */}
                    {/* <div className="col-md-12 ">
                        <p className='fw-bold'>Supervisors :</p>
                        <Dropdown placeholder='<select supervisors>' fluid multiple search selection
                            options={props?.superVisors ? props?.superVisors.map(item => { return { key: item.key, text: item.fullname, value: item._id } }) : []}
                            onChange={(e, d) => { props.setSelectedSuperVisors(d.value); }}
                            value={props.selectedSuperVisors}
                        />
                    </div> */}
                    <div className="col-md-12 border">
                        <p className='fw-bold'>Learning Needs :  </p>
                        {props?.courses?.length == 0 ? <div className="alert alert-warning text-center">
                            there is no courses for this discipline
                        </div> :
                            <Dropdown placeholder='<select courses>' fluid multiple search selection
                                options={props?.courses ? props?.courses.map(item => { return { key: item.key, text: `${item.name}(${item.id})`, value: item._id } }) : []}
                                onChange={(e, d) => { props.setLearningNeeds(d.value); }}
                                value={props.learningNeeds}
                            />}
                    </div>
                    <div className="col-md-12 border mt-1">
                        <p className='fw-bold'>competencies : ({props?.competencies?.length}) <button className="btn btn-success" onClick={() => props.handleAddNewCompetencies()}>+</button></p>
                        {props.competencies.map((competency_item, competency_index) => {
                            return (<div className="row border border-secondary" key={`competency-${competency_index}`}>
                                <div className='col-sm-2 text-center'>
                                    <button className="btn btn-info pb-0 pt-0 pe-1 ps-1" onClick={() => props.handleChangeCompetencySequence(competency_index, competency_index - 1)}>&#x2191;</button>
                                    <button disabled={true} className="btn btn-light">{competency_index + 1}</button>
                                    <button onClick={() => props.handleChangeCompetencySequence(competency_index, competency_index + 1)} className="btn btn-warning pb-0 pt-0 pe-1 ps-1">&#x2193;</button></div>
                                <div className='col-sm-5'><input name='title' type="text" placeholder='Title' value={competency_item.title} onChange={e => props.handleEditCompetencies(e, competency_index)} className="form-control" /></div>
                                <div className='col-sm-5'><select className='form-control' name="level" value={competency_item.level} onChange={e => props.handleEditCompetencies(e, competency_index)}><option value="">{'<select level>'}</option><option value="Awareness">Awareness</option><option value="Knowledge">knowledge</option><option value="Skilled">Skilled</option><option value="Practiced">Practiced</option></select></div>
                                <div className='col-sm-12'><textarea name="description" placeholder='Description' value={competency_item.description} onChange={e => props.handleEditCompetencies(e, competency_index)} className="form-control" rows="3"></textarea></div>

                                <div className='col-sm-12 p-2'>
                                    <div><b>Job Tasks: ({competency_item?.job_tasks?.length}) </b> <button className="btn btn-success " onClick={() => props.handleAddNewJobTask(competency_index)}>+</button></div>
                                    <div className="border p-2 rounded">
                                        {competency_item?.job_tasks.map((jobTask_item, jobTask_Index) => {
                                            return (
                                                <div className='p-2 bg-white-1 rounded mb-2 border' key={`jobTask-${jobTask_Index}`}>
                                                    <div className="row">
                                                        <div className="col-sm-6"><input name='job_task_id' type="text" placeholder='jobtask id' value={jobTask_item.job_task_id} onChange={e => props.handleEditJobTask(e, jobTask_Index, competency_index)} className="form-control" /></div>
                                                        <div className="col-sm-6"><input name='title' type="text" placeholder='Title' value={jobTask_item.title} onChange={e => props.handleEditJobTask(e, jobTask_Index, competency_index)} className="form-control" /></div>
                                                        <div className="col-sm-12">
                                                            <textarea name="description" placeholder='description' value={jobTask_item.description} onChange={e => props.handleEditJobTask(e, jobTask_Index, competency_index)} className="form-control" rows="3"></textarea>
                                                        </div>
                                                        <div className="col-sm-12"><b>activities : ({jobTask_item?.activities?.length}) </b><button className="btn btn-success" onClick={() => props.handleAddActivity(jobTask_Index, competency_index)}>+</button></div>
                                                        {jobTask_item?.activities?.map((activity_item, activity_index) => {
                                                            return (
                                                                <div className="pe-2" key={`activities-${activity_index}`}>
                                                                    <div className="position-relative pe-5"><input type="text" className="form-control" value={activity_item} onChange={e => props.handleEditActivitiy(e, jobTask_Index, competency_index, activity_index)} placeholder='activity description' /> <button className="btn btn-danger position-absolute top-0 end-0" onClick={() => props.handleRemoveActivitiy(jobTask_Index, competency_index, activity_index)}>-</button></div>
                                                                </div>
                                                            )
                                                        })}
                                                        <div className='col-sm-12 text-center'><button className="btn btn-danger" onClick={() => props.handleRemoveJobTask(jobTask_Index, competency_index)}>Remove Job Task</button></div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className='col-sm-12 text-center'><button className="btn btn-danger" onClick={() => props.handleRemoveCompetencies(competency_index)}>Remove Competency</button></div>
                            </div>)
                        })}
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <button className='btn btn-success' onClick={() => { props.fdfId == '' ? save() : update() }}>{props.fdfId == '' ? 'Save' : 'update'}</button>
                    </div>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default FdfModal