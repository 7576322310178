import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { IoLockClosedOutline, IoLockOpenOutline } from "react-icons/io5";
import axios from 'axios';
import '../components_style/yearModal.css'
import { api } from '../../util/constant'
function YearModal(props) {

    const [Year, setYear] = useState([])
    const toggle = () => (props.changePerentToggle(!props.modal));
    const loaderToggle = (loader) => (props.changeLoader(loader));


    const handleAddYear = () => { setYear([...Year, { year: nextYear(), enable: true }]) }
    const handleRemoveYear = (i) => { let newArr = [...Year]; newArr.splice(i, 1); setYear(newArr) }
    const handleOnChangeYear = (name, value, i) => { let newArr = [...Year]; newArr[i][name] = value; setYear(newArr) }

    const getYears = async () => {
        loaderToggle(true)
        axios.get(`${api}/years`, { withCredentials: true })
            .then((res) => { if (Array.isArray(res.data)) { setYear(res.data) }; loaderToggle(false) })
            .catch((e) => { console.log(e); loaderToggle(false) })
    }

    //next year when add new field
    const nextYear = () => {
        if (Year.length == 0) return new Date().getFullYear().toString()
        else return (parseInt(Year.pop().year) + 1).toString()
    }

    const Save = () => {
        loaderToggle(true)
        axios.post(`${api}/years`, Year, { withCredentials: true })
            .then(() => { loaderToggle(false); toggle() })
            .catch((e) => { loaderToggle(false); console.log(e); })

    }
    useEffect(() => {
        if (props.modal) getYears()

    }, [props.modal])

    return (
        <Modal size="lg" style={{ maxWidth: '700px', width: '100%' }} isOpen={props.modal} toggle={() => { toggle(props) }}>
            <ModalHeader toggle={() => { toggle(props) }}>Call Year</ModalHeader>
            <ModalBody>
                <div className="year-content">
                    <table className="table table-borderless" >
                        <thead>
                            <tr style={{ backgroundColor: 'white' }}>
                                <td>Year</td>
                                <td>Status</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>

                            {Year.map((item, i) => {
                                return (
                                    <tr key={i + 'year'}>
                                        <td>
                                            <input type="text" className="form-control" value={item.year} disabled />
                                        </td>
                                        <td>
                                            <input type="checkbox" checked={item.enable} name='enable' onChange={(e) => { handleOnChangeYear(e.target.name, e.target.checked, i); }} />
                                            {item.enable ? <span className="text-success"> <IoLockOpenOutline /></span> : <span className="text-danger"> <IoLockClosedOutline /></span>}
                                        </td>
                                        <td>
                                            {/* <button onClick={() => { handleRemoveYear(i) }} className="btn btn-danger">-</button> */}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <button onClick={handleAddYear} className="btn btn-success m-3">+</button>

                </div>
            </ModalBody>
            <ModalFooter>
                <div className="row">
                    <div className="col-sm-4 mb-2"><Button color="success" onClick={Save}>Save</Button></div>
                    <div className="col-sm-4 mb-2"></div>
                    <div className="col-sm-4 mb-2"><Button color="warning" onClick={toggle}>Cancel</Button></div>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default YearModal
