import React, { useState } from 'react'
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { handleEditObject } from '../../util/functions'
import { Dropdown } from 'semantic-ui-react';
import { api } from '../../util/constant';
import { Notify } from 'notiflix';
import { confirmAlert } from 'react-confirm-alert';
import ProjectSubmissionModal from './projectSubmissionModal'
import ProjectSubmissionsListModal from './projectSubmissionListModal';
function ProjectModal({ selectedProject, setSelectedProject, organizations,
    isAllowedToEditOrg, modalToggle, modal, setLoader, refresh, isAllowToDeleteProject,
    projectSubmission, setProjectSubmission, isAllowToEditProject, isAllowToAddProjectSubmission,
    isStudentAndHaveSubmissionForSelectedProject, isAllowedToViewProjectSubmissionsList }) {

    const [isProjectSubmissionModal, setIsProjectSubmissionModal] = useState(false)
    const [isProjectSubmissionsListModal, setIsProjectSubmissionsListModal] = useState(false)

    const save = () => {
        setLoader(true)
        axios.post(`${api}/projects`, selectedProject, { withCredentials: true })
            .then(res => {
                setLoader(false);
                if (res.status == 203) {
                    Notify.warning(res.data)
                } else {
                    modalToggle(); refresh();
                }
            })
            .catch(e => { console.error({ e }); setLoader(false) })
    }

    const deleteProject = () => {
        setLoader(true)
        axios.delete(`${api}/projects/project/${selectedProject?._id}`, { withCredentials: true })
            .then(res => {
                setLoader(false);
                Notify.success(res.data);
                modalToggle(); refresh();
            })
            .catch(e => { console.error({ e }); setLoader(false) })
    }

    return (<>
        <ProjectSubmissionModal
            projectSubmission={projectSubmission}
            setProjectSubmission={projectSubmission => setProjectSubmission(projectSubmission)}
            modal={isProjectSubmissionModal}
            modalToggle={modal => setIsProjectSubmissionModal(prev => !prev)}
            setLoader={loader => setLoader(loader)}
            selectedProject={selectedProject}
            refresh={() => refresh()}
        />

        <ProjectSubmissionsListModal
            modal={isProjectSubmissionsListModal}
            modalToggle={modal => setIsProjectSubmissionsListModal(prev => !prev)}
            selectedProject={selectedProject}
            setLoader={loader => setLoader(loader)}
            projectSubmission={projectSubmission}
            setProjectSubmission={projectSubmission => setProjectSubmission(projectSubmission)}
        />

        <Modal size="lg" style={{ maxWidth: '700px', width: '100%' }} isOpen={modal} toggle={modalToggle}>
            <ModalHeader className='w-100' onClick={() => console.log(selectedProject)}>Project
            </ModalHeader>
            <ModalBody>
                {isAllowedToViewProjectSubmissionsList && selectedProject?._id ? <><button className='btn btn-success float-end' onClick={() => setIsProjectSubmissionsListModal(true)}>Submissions [{selectedProject?.submissionsCount}]</button><br /> <br /></> : null}
                <Dropdown className="m-2"
                    placeholder='<Select institution>'
                    fluid
                    clearable
                    search
                    selection
                    disabled={!isAllowedToEditOrg}
                    value={selectedProject.organization}
                    options={organizations ? organizations.map(item => { return { key: item.key, text: item.name, value: item._id } }) : []}
                    onChange={(e, d) => { setSelectedProject(handleEditObject(d.value, 'organization', selectedProject)) }}
                />
                <p className="fw-bold">Title:</p>
                {isAllowToEditProject ? <input type='text' value={selectedProject?.title} onChange={e => setSelectedProject(handleEditObject(e?.target?.value, 'title', selectedProject))} className='form-control mb-2' /> : <div style={{ whiteSpace: 'pre-wrap' }} className='alert alert-light p-3 shadow'>{selectedProject?.title}</div>}
                <p className="fw-bold"> Challenge Description (Background and Current Practices):</p>
                {isAllowToEditProject ? <textarea type='text' rows={4} value={selectedProject?.challenge} onChange={e => setSelectedProject(handleEditObject(e?.target?.value, 'challenge', selectedProject))} className='form-control mb-2' > </textarea> : <div style={{ whiteSpace: 'pre-wrap' }} className='alert alert-light p-3 shadow'>{selectedProject?.challenge}</div>}
                <p className="fw-bold">Objectives:</p>
                {isAllowToEditProject ? <textarea type='text' rows={4} value={selectedProject?.objective} onChange={e => setSelectedProject(handleEditObject(e?.target?.value, 'objective', selectedProject))} className='form-control mb-2' > </textarea> : <div style={{ whiteSpace: 'pre-wrap' }} className='alert alert-light p-3 shadow'>{selectedProject?.objective}</div>}
                <p className="fw-bold">Scope of Work:</p>
                {isAllowToEditProject ? <textarea type='text' rows={4} value={selectedProject?.outcome} onChange={e => setSelectedProject(handleEditObject(e?.target?.value, 'outcome', selectedProject))} className='form-control mb-2' > </textarea> : <div style={{ whiteSpace: 'pre-wrap' }} className='alert alert-light p-3 shadow'>{selectedProject?.outcome}</div>}

                <span className="fw-bold">Submission status :</span>
                {isAllowToEditProject ?
                    <input type="checkbox" checked={selectedProject?.isOpen} onChange={(e) => setSelectedProject(handleEditObject(e.target.checked, 'isOpen', selectedProject))} />
                    : null} {selectedProject?.isOpen ? ' Open' : ' Closed'}

            </ModalBody>
            <ModalFooter>

                <div className="w-100 text-center">

                    {isAllowToEditProject ?
                        <> <Button color="success float-start" onClick={save}>Save</Button>
                            {isAllowToDeleteProject && selectedProject?._id ? <button className="btn btn-danger" onClick={() => confirmAlert({
                                title: 'Confirm to delete',
                                message: 'Are you sure you want to delete this project? Any submissions will also be deleted.',
                                buttons: [{ label: 'Delete', onClick: () => { deleteProject(); } }, { label: 'cancel', onClick: () => { } }]
                            })}>DELETE</button> : null}</>
                        : null}

                    {isAllowToAddProjectSubmission ? <button className='btn btn-success float-start'
                        onClick={() => {
                            setIsProjectSubmissionModal(true);
                        }}>{isStudentAndHaveSubmissionForSelectedProject ? 'View my Submission' : 'Apply'}</button> : null}



                    <Button color="warning float-end" onClick={modalToggle}>Cancel</Button>
                </div>
            </ModalFooter>
        </Modal>
    </>
    )
}

export default ProjectModal