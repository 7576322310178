import React, { useState, useEffect, useContext } from 'react';
import MUIDataTable from "mui-datatables";
import { TableFooter, TableCell, TableRow } from "@material-ui/core";
import { IoEllipsisVerticalCircle, IoExpandOutline } from "react-icons/io5";
import axios from 'axios';
import ViewAppModal from './viewAppModal';
import Allocation from './allocation';
import { Cxt } from '../../App';
import muiTable from '../../util/mui-table'
import { api } from '../../util/constant'
import { MuiThemeProvider } from "@material-ui/core/styles";
import { identifyRole, isArr } from '../../util/functions'
import { IoSearch } from "react-icons/io5";
import { Dropdown } from 'semantic-ui-react';

function OtherRoles(props) {

    const [list, setList] = useState([])
    const [info, setInfo] = useState({ app: {} })
    const [loader, setLoader] = useState(false)
    const [modal, setModal] = useState(false)
    const [page, setPage] = useState(0)
    const { role } = useContext(Cxt);
    const [Years, setYears] = useState([])
    const [workflows, setWorkflows] = useState([])
    const [orgs, setOrgs] = useState([])
    const [studentsList, setStudentsList] = useState([])

    const [theme, setTheme] = useState(false)
    const [isSearch, setIsSearch] = useState(false)
    const [tablePageNumber, setTablePageNumber] = useState(1)
    const [tableRowsLimit, setTableRowsLimit] = useState(10)
    const [tableTotalPages, setTotalPages] = useState(0)
    const [tableTotalRows, setTableTotalRows] = useState(0)
    const [tableSearchStudentName, setTableSearchStudentName] = useState('')
    const [tabelSearchSelectedYear, setTabelSearchSelectedYear] = useState('')
    const [tabelSearchSelectedWorkflow, setTabelSearchSelectedWorkflow] = useState('')
    const [tableSearchSelectedOrg, setTableSearchSelectedOrg] = useState('')
    const [appsForMyActionOnly, setAppsForMyActionOnly] = useState(true)
    const [tableSearchSelectedStudent, setTableSearchSelectedStudent] = useState('')

    const getApplicationsList = async () => {
        setLoader(true)
        const search = handleSearch()
        await axios.get(`${api}/app`, { headers: { search: JSON.stringify(search), page: tablePageNumber, limit: tableRowsLimit, formyaction: appsForMyActionOnly }, withCredentials: true })
            .then((res) => {
                const { apps, totalPages, totalApps, years, workflows, acadamicOrgs } = res?.data
                setList(isArr(apps));
                setTotalPages(totalPages);
                setTableTotalRows(totalApps)
                setYears(isArr(years))
                setWorkflows(isArr(workflows))
                setOrgs(isArr(acadamicOrgs))
                setLoader(false);
            })
            .catch((e) => { console.log(e); setLoader(false) })
    }

    const handleSearch = () => {
        let obj = {}
        if (!isSearch) return obj
        if (tableSearchSelectedStudent != '') obj = { ...obj, user_id: tableSearchSelectedStudent }
        if (tableSearchSelectedOrg != '') obj = { ...obj, orgnization: tableSearchSelectedOrg }
        if (tabelSearchSelectedWorkflow != '') obj = { ...obj, workflow_id: tabelSearchSelectedWorkflow }
        if (tabelSearchSelectedYear != '') obj = { ...obj, allocation_call: tabelSearchSelectedYear }
        return obj
    }

    const searchStudent = () => {
        setLoader(true)
        axios.get(`${api}/users`, { headers: { search: JSON.stringify({ fullname: { $regex: tableSearchStudentName, $options: "i" } }), page: 1, limit: 50 }, withCredentials: true })
            .then((res) => {
                const { users } = res?.data;
                setStudentsList(isArr(users));
                setLoader(false)
            })
            .catch((e) => { setLoader(false) })
    }

    const getSinglApp = async (id) => {
        setLoader(true)
        await axios.get(`${api}/app`, { headers: { id }, withCredentials: true })
            .then((res) => { setInfo(res.data); setLoader(false); setModal(true) })
            .catch((e) => { console.log(e); setLoader(false) })
    }

    let options = muiTable.muiOptions(theme)
    options = {
        ...options,
        rowsPerPage: 100,
        search: false,
        serverSide: true,
        customFooter: () => {
            return (
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={6}>
                            page {tablePageNumber} of {tableTotalPages} - total users {tableTotalRows}
                            <button className="btn btn-light" disabled={tablePageNumber == 1 ? true : false} onClick={() => setTablePageNumber(prev => prev - 1)}>{`<`}</button>
                            <button className="btn btn-light" disabled={tablePageNumber == tableTotalPages || tableTotalPages == 0 ? true : false} onClick={() => setTablePageNumber(prev => prev + 1)}>{`>`}</button>
                            jump to <select onChange={e => setTablePageNumber(+e.target.value)}>{[...Array(tableTotalPages)].map((item, i) => <option value={i + 1} key={i}>{i + 1}</option>)}</select>
                            per page <select onChange={e => setTableRowsLimit(+e.target.value)}>{[...Array(10)].map((item, i) => <option value={(i + 1) * 10} key={i}>{(i + 1) * 10}</option>)}</select>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            );
        }

    }
    const columns = [{ label: "Student Name", options: { filter: false } }, "call Year", "Workflow",
    {
        label: "Disciplines",
        options: {
            filter: false,
            customBodyRender: (value, tableMeta, updateValue,) => {
                return (
                    <ul key={`${tableMeta.rowIndex + 1}-disciName`}>
                        {tableMeta.rowData[3].map((item, index) => { return (<li key={`${tableMeta.rowIndex + 1}-${index}-li`}>{item.discipline_id.name}</li>) })}
                    </ul>
                )
            }
        }
    },
    {
        label: "",
        options: {
            filter: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                return (
                    <span className="btn" style={{ fontSize: "20px" }} onClick={() => getSinglApp(tableMeta.rowData[4])}><IoEllipsisVerticalCircle /></span>
                )
            }
        }
    }];

    const { isAdminOrEidaad } = identifyRole(role)

    useEffect(() => { getApplicationsList(); }, [])
    useEffect(() => { props.loaderToggle(loader); }, [loader])

    useEffect(() => { getApplicationsList(); }, [appsForMyActionOnly, tablePageNumber, tableRowsLimit, isSearch])
    useEffect(() => { setTablePageNumber(1) }, [tableRowsLimit])
    useEffect(() => {
        if (!isSearch) {
            setTableSearchStudentName('')
            setTabelSearchSelectedYear('')
            setTabelSearchSelectedWorkflow('')
            setTableSearchSelectedOrg('')
            setTableSearchSelectedStudent('')
        }
    }, [isSearch])
    useEffect(() => {
        if (tableSearchStudentName?.length > 2) { searchStudent() }
    }, [tableSearchStudentName])

    return (
        <div>

            {role < 3 ? <>
                <div className="row">
                    <div className="col-md-12 mb-2 p-0 shadow">
                        <button className={`sub-nav w50 left ${page == 0 ? 'active' : ''}`} onClick={() => { setPage(0) }}>Application</button>
                        <button className={`sub-nav w50 mid ${page == 1 ? 'active' : ''}`} onClick={() => { setPage(1) }}>Allocation</button>
                    </div>
                </div>
            </> : null}

            {page == 0 ? <>
                <button className={`btn btn-${appsForMyActionOnly ? 'success' : 'light'} me-2`} onClick={() => setAppsForMyActionOnly(true)}>For my action</button>
                <button className={`btn btn-${!appsForMyActionOnly ? 'success' : 'light'}`} onClick={() => setAppsForMyActionOnly(false)}>All</button>
                <ViewAppModal modal={modal} info={info} refreshApp={() => { getApplicationsList() }} loaderToggle={loader => setLoader(loader)} modalToggle={modal => setModal(modal)} />
                <div className="p-4">
                    {!isSearch ? <button className="btn btn-warning mb-1" onClick={() => setIsSearch(prev => !prev)}>search <IoSearch /></button>
                        :
                        <div className="row">
                            <div className="col-sm-4" style={{ zIndex: '110' }}>
                                <Dropdown
                                    placeholder='<student name>'
                                    clearable
                                    fluid
                                    search
                                    selection
                                    options={studentsList.map((item, index) => { return { key: index, text: item.fullname, value: item._id } })}
                                    onChange={(e, d) => { setTableSearchSelectedStudent(d.value) }}
                                    value={tableSearchSelectedStudent}
                                    onSearchChange={(e, d) => { setTableSearchStudentName(e.target.value) }}
                                    style={{ fontSize: '13px', zIndex: '101' }}
                                />
                            </div>
                            <div className="col-sm-4 ">
                                <select value={tabelSearchSelectedYear} className='w-100' onChange={e => { setTabelSearchSelectedYear(e.target.value); }}>
                                    <option value="">{`<select year>`}</option>
                                    {Years.map((item, index) => <option key={index} value={item._id}>{item.year}</option>)}
                                </select>
                            </div>
                            <div className="col-sm-4 ">
                                <select value={tabelSearchSelectedWorkflow} className='w-100' onChange={e => setTabelSearchSelectedWorkflow(e.target.value)}>
                                    <option value="">{`<select workflow>`}</option>
                                    {workflows.map((item, index) => <option key={index} value={item._id}>{item.name}</option>)}
                                </select>
                            </div>
                            <div className="col-sm-10">
                                {isAdminOrEidaad ?
                                    <Dropdown
                                        placeholder='<Select institution>'
                                        fluid
                                        clearable
                                        search
                                        selection
                                        options={orgs.map((item, index) => { return { key: index, text: item.name, value: item._id } })}
                                        onChange={(e, d) => { setTableSearchSelectedOrg(d.value) }}
                                        value={tableSearchSelectedOrg}
                                        style={{ fontSize: '13px', zIndex: '101' }}
                                    />
                                    : null}
                            </div>
                            <div className="col-sm-2">
                                <button className="btn btn-danger mb-1 float-end ms-1" onClick={() => setIsSearch(prev => !prev)}>X</button>
                                <button className="btn btn-warning mb-1 float-end" onClick={() => { getApplicationsList(); setTablePageNumber(1) }}><IoSearch /></button>
                            </div>
                        </div>
                    }
                    <MuiThemeProvider theme={theme ? muiTable.getMuiTheme() : muiTable.noTheme()}>
                        <MUIDataTable title={<>
                            <button className="btn btn-light shadow" disabled >Submitted Applications</button>
                            <button onClick={() => { setTheme(!theme); }} className="btn btn-light shadow"><IoExpandOutline /></button>
                        </>} columns={columns} options={options}
                            data={list ? list.map(item => { return [item.user_id.fullname, item.allocation_call?.year, item.workflow_id.name, item.options, item._id] }) : []} />
                    </MuiThemeProvider>
                </div>
            </>
                : <Allocation loaderToggle={loader => setLoader(loader)} />
            }

        </div>
    )
}

export default OtherRoles
