import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../style/reg.css';
import 'semantic-ui-css/semantic.min.css';
import { Dropdown } from 'semantic-ui-react';
import axios from 'axios';
import Loader from '../components/loader';
import { api, path } from '../util/constant'
import { validMail } from '../util/validateMail';
import { isArr } from '../util/functions'
import { Notify } from 'notiflix/build/notiflix-notify-aio';
const Reg = () => {

    const [FullName, setFullName] = useState('');
    const [Email, setEmail] = useState('');
    const [Department, setDepartment] = useState('');
    const [Role, setRole] = useState('');
    const [Gender, setGender] = useState('');
    const [Organization, setOrganization] = useState('');
    const [Qualification, setQualification] = useState('');
    const [saveStatus, setSaveStatus] = useState(false);
    const [loader, setLoader] = useState(false)
    const [colleges, setColleges] = useState([])
    const [industries, setIndustries] = useState([])
    const [isAcademic, setIsAcademic] = useState(true)


    const getOrgs = async () => {
        setLoader(true);
        const orgs = await axios.get(`${api}/org`);
        setColleges(isArr(orgs.data).filter(item => item.type == 1));
        setIndustries(isArr(orgs.data).filter(item => item.type == 2))
        setLoader(false);
    }

    useEffect(() => { getOrgs(); }, []);
    useEffect(() => { setOrganization(''); }, [isAcademic])

    const handleSubmit = () => {
        let Fields = [Email, Department, Gender, Role, Organization, FullName];
        console.log({ Fields });
        if (isAcademic) Fields = [...Fields, Qualification];
        if (!validation(Fields)) return Notify.failure('please Fill all fields')
        if (!validMail(Email)) return Notify.failure('Email is not valid')
        handleSave(Fields)
    }


    const validation = (Fields) => {
        let status = true;
        Fields.map(item => { if (item.trim() == '') { status = false; return } })
        return status;
    }

    const handleSave = () => {
        setLoader(true);
        let json = {
            "fullname": FullName,
            "email": Email,
            "department": Department,
            "gender": Gender,
            "role": parseInt(Role),
            orgnization_id: Organization,
            isAcademic
        }
        if (isAcademic) json = { ...json, qualification: Qualification }
        axios.post(`${api}/reg`, json)
            .then(() => { setLoader(false); setSaveStatus(true) })
            .catch((e) => { setLoader(false); Notify.failure(e.response.data) })
    }

    return (
        <div>
            {loader ? <Loader /> : null}
            <div className="reg-main container" style={{ maxWidth: "700px" }}>
                <div className='center-div pe-4'>
                    {saveStatus ? <SuccessAlert role={Role} /> : <>
                        <p className="title">Registraion</p>
                        <div className="row">
                            <div className="col-md-12 text-center mb-3">
                                <span onClick={() => { setIsAcademic(false) }} className={`btn p-2 m-2 rounded border-on-hover  ${!isAcademic ? 'text-success fw-bold shadow border' : ''}`}>industry</span> <input type="checkbox" checked={isAcademic} onChange={(e) => { setIsAcademic(e.target.checked) }} /> <span onClick={() => { setIsAcademic(true) }} className={`border-on-hover btn p-2 m-2 rounded ${isAcademic ? 'text-success fw-bold shadow border' : ''}`}>academia</span>
                            </div>
                            <div className="col-md-12">
                                <input type="text" value={FullName} onChange={(e) => { setFullName(e.target.value) }} placeholder="Full Name" className="form-control m-2" />
                            </div>
                            <div className="col-md-12">
                                <input type="email" value={Email} onChange={(e) => { setEmail(e.target.value) }} placeholder="Email" className="form-control m-2" />
                            </div>
                            <div className="col-md-6">
                                <select className="form-control m-2" onChange={(e) => { setGender(e.target.value) }} >
                                    <option value="0">{`<Select Gender>`}</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                            </div>

                            <div className="col-md-6">
                                <select className="form-control m-2" onChange={(e) => { setRole(e.target.value); setDepartment('') }}>
                                    <option value="0">{`<Select Role>`}</option>
                                    {isAcademic ?
                                        <>
                                            <option value="1">Student</option>
                                            <option value="2">Academic Focal Point</option>
                                            {/* <option value="7">Academic Advisor</option> */}
                                        </>
                                        :
                                        <>
                                            <option value="3">Organization Focal Point</option>
                                            <option value="4">Organization Functional Focal Point</option>
                                            <option value="5">Technical Authority</option>
                                            <option value="6">Supervisor</option>
                                        </>}
                                </select>
                            </div>
                            <div className="col-md-6">
                                <Dropdown className="m-2"
                                    placeholder='<Select institution>'
                                    fluid
                                    clearable
                                    search
                                    selection
                                    options={isAcademic ?
                                        colleges ? colleges.map(item => { return { key: item.key, text: item.name, value: item._id } }) : []
                                        :
                                        industries ? industries.map(item => { return { key: item.key, text: item.name, value: item._id } }) : []
                                    }
                                    onChange={(e, d) => { setOrganization(d.value) }}
                                    value={Organization}
                                />
                            </div>
                            <div className="col-md-6">
                                <input type="text" value={Department} onChange={(e) => { setDepartment(e.target.value) }} placeholder={Role == 4 ? 'Function' : 'Department'} className="form-control m-2" />
                            </div>
                            {isAcademic ? <div className="col-md-6">
                                <select className="form-control m-2" onChange={(e) => { setQualification(e.target.value) }}>
                                    <option value="1">{`<Select Qualification>`}</option>
                                    <option value="PHD">Phd</option>
                                    <option value="MASTER">Master</option>
                                    <option value="BACHELOR">Bachelor</option>
                                    <option value="DIPLOMA">Diploma</option>
                                </select>
                            </div> : null}
                        </div>
                        <div className="text-center">
                            <button type="button" className="btn btn-light border" onClick={handleSubmit}>Submit</button>
                        </div>
                        <hr />
                        already have an account ? <Link to={`${path}/`}> <button type="button" className="btn btn-link">login</button></Link>
                    </>}
                </div>
            </div>
        </div>
    )
}

const SuccessAlert = (props) => {
    return (<><div className="text-center h3 p-3">You registered successfully!</div>
        <div className="alert alert-success pb-0" role="alert"><ul>
            {
                props.role == 1 ? <>
                    <li>thank you for registering, verification email has been sent to you.</li>
                    <li><br /></li>
                    <li>*please note that email sometime take a while.</li>
                </>
                    :
                    <><li>thank you for registring ! please wait until eidaad team or your orgnization focal point accept your registration Request.</li>
                        <li>after acceptance you are going to receive an email to set a new password to login .</li></>
            }
        </ul>
        </div>
        <div className='text-center'><Link to={`${path}/`}><button className="btn btn-success shadow">Login</button></Link></div>
    </>)
}

export default Reg
