import React, { useContext, useState, useEffect, } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { logout } from '../util/login'
import { Cxt } from '../App'
import { identifyRole } from '../util/functions'
import "./components_style/nav.css";
import { IoLogOut, IoAppsOutline, IoNotificationsOutline, IoMenu } from "react-icons/io5";
import { path } from '../util/constant';

const Nav = () => {
    const history = useHistory()
    const { role, pageWidth, setCurrentPage } = useContext(Cxt);
    const [MobileView, setMobileView] = useState(false)
    const [isSideNavHidden, setIsSideNavHidden] = useState(true)
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const { isAFP, isOFP, isStudent, isAdminOrEidaad, isSV, isTA, isOFFP, isAdvisor } = identifyRole(role)

    const exit = () => {
        const status = logout()
        if (status) { history.push(`${path}/login`); }
    }

    const sideNavToggle = () => { MobileView ? setIsSideNavHidden(!isSideNavHidden) : setIsSideNavHidden(false) }
    useEffect(() => { pageWidth >= 700 ? setMobileView(false) : setMobileView(true); }, [pageWidth])
    useEffect(() => { !MobileView ? setIsSideNavHidden(false) : setIsSideNavHidden(true); }, [MobileView])

    //change currentpage state on App.js
    const location = useLocation().pathname
    useEffect(() => { setCurrentPage(location); }, [useLocation().pathname])




    return (
        <div>
            {/* <!-- top navigation --> */}
            <nav className="top">
                {MobileView ? <span className='p-1 btn' onClick={sideNavToggle}><IoAppsOutline size='30' /></span> : null}
                <span className="logo cos-font"><Link className="rm-text-decoration" to={`${path}/`}><img src="/images/logo.png" style={{ height: "30px" }} /></Link></span>
                <span className="cos-font">
                    {!MobileView ? <TimeAndDate /> : null}
                    <span className="mx-3"><IoNotificationsOutline /></span>
                    <IoMenu fontSize={25} color='lightblue' onClick={() => { setIsMenuOpen(!isMenuOpen) }} />
                    {isMenuOpen ? <Menu role={role} exit={() => { exit() }} /> : null}
                </span>
            </nav>
            {/* <!-- side navigation --> */}
            {!isSideNavHidden ?
                <nav className={`side side-${MobileView ? 'mobile' : 'desktop'}`}>
                    <Link className="rm-text-decoration" to={`${path}/`}><p className='shadow' onClick={sideNavToggle}> <img src="/images/dashboard.svg" style={{ width: '22px' }} /> <span>Dashboard</span></p></Link>
                    {isAdminOrEidaad || isOFP ? <Link className="rm-text-decoration" to={`${path}/registration`}><p className='shadow' onClick={sideNavToggle} ><img src="/images/reg.svg" style={{ width: '22px' }} /><span>Registraion</span></p></Link> : null}
                    {/* {isAdminOrEidaad || isOFP || isOFFP || isAFP || isStudent || isAdvisor ? <Link className="rm-text-decoration" to={`${path}/projects`}><p className='shadow' onClick={sideNavToggle} ><img src="/images/projects.svg" style={{ width: '22px' }} /><span>Projects</span></p></Link> : null} */}
                    {isAdminOrEidaad || isAFP || isStudent ? <Link className="rm-text-decoration" to={`${path}/application`}><p className='shadow' onClick={sideNavToggle} ><img src="/images/app.svg" style={{ width: '22px' }} /><span>Application</span></p></Link> : null}
                    {isAdminOrEidaad || isOFP ? <Link className="rm-text-decoration" to={`${path}/discipline`}><p className='shadow' onClick={sideNavToggle} ><img src="/images/major.svg" style={{ width: '22px' }} /><span>Majors and Discibline</span></p></Link> : null}
                    {isAdminOrEidaad || isOFP || isOFFP ? <Link className="rm-text-decoration" to={`${path}/orgnizations`}><p className='shadow' onClick={sideNavToggle} ><img src="/images/org.svg" style={{ width: '22px' }} /><span>Orgnizations</span></p></Link> : null}
                    <Link className="rm-text-decoration" to={`${path}/fdf`}><p className='shadow' onClick={sideNavToggle} ><img src="/images/fdf.svg" style={{ width: '22px' }} /><span>FDF</span></p></Link>
                    {isAdminOrEidaad || isOFP || isOFFP || isAFP || isAdvisor ? <Link className="rm-text-decoration" to={`${path}/users`}><p className='shadow' onClick={sideNavToggle} ><img src="/images/disk.svg" style={{ width: '22px' }} /><span>Users</span></p></Link> : null}
                    {isAdminOrEidaad ? <Link className="rm-text-decoration" to={`${path}/reports`}><p className='shadow' onClick={sideNavToggle} ><img src="/images/report.svg" style={{ width: '22px' }} /><span>Reports</span></p></Link> : null}
                    <a><p className='shadow' onClick={exit} ><img src="/images/door.svg" style={{ width: '22px' }} /><span>Logout</span></p></a>
                </nav>
                : null}
        </div >
    );
}


const TimeAndDate = () => {
    const [clock, setClock] = useState(new Date().toLocaleTimeString())
    const date = new Date().toDateString()
    useEffect(() => {
        const clockInterval = setInterval(() => {
            setClock(new Date().toLocaleTimeString());
        }, 1000);
        return () => clearInterval(clockInterval);
    }, [])
    return (<span style={{ fontSize: '0.8em' }}>{`${clock}  ${date}`}</span>)
}

const Menu = (props) => {
    return (<>
        <div className='nav-dropmenu'>
            <p><Link className="rm-text-decoration" to={`${path}/profile`}><img src="/images/profile.svg" /> Profile</Link></p>
            {props.role <= 2 ? <><p><Link className="rm-text-decoration" to={`${path}/setting`}><img src="/images/setting.svg" /> Setting</Link></p></> : null}
            <hr />
            <p onClick={() => { props.exit() }}><IoLogOut size="17px" /> Logout</p>
        </div>
    </>)
}


export default Nav;
