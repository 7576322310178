import React, { useState } from 'react'
import { isArr } from '../../../../util/functions'
import { IoCheckmarkCircleOutline, IoTimeOutline, IoPencil } from "react-icons/io5";
import ExtraWorkModal from './extraworkModal'
function ExtraWork(props) {
    const [extraWorkModal, setExtraWorkModal] = useState(false)
    const [extraWorkSubmission, setExtraWorkSubmission] = useState({})
    const [isStudentAlloedToEditExtraWork, setIsStudentAlloedToEditExtraWork] = useState(false)
    const [isStudentSubmittedExtraWork, setIsStudentSubmittedExtraWork] = useState(false)
    const [isSupervisorSubmitted, setIsSupervisorSubmitted] = useState(false)
    const [isExtraWorkComplete, setIsExtraWorkComplete] = useState(false)

    const openExtraWorkModal = ({ isExtraWorkComplete, isAllowStudentUpdateComment, isStudentSubmitted, extraWorkSubmitied, isSupervisorSubmitted }) => {
        setExtraWorkSubmission(extraWorkSubmitied)
        setIsStudentSubmittedExtraWork(isStudentSubmitted)
        setIsStudentAlloedToEditExtraWork(isAllowStudentUpdateComment)
        setIsSupervisorSubmitted(isSupervisorSubmitted)
        setIsExtraWorkComplete(isExtraWorkComplete)
        setExtraWorkModal(true)
    }

    const checkIfExtraWorksSubmitted = extrawork_id => {
        let extraWorkSubmitied = props.submission?.extra_works?.filter(item => item._id == extrawork_id)
        extraWorkSubmitied = isArr(extraWorkSubmitied).length != 0 ? extraWorkSubmitied[0] : {};
        const isSupervisorSubmitted = extraWorkSubmitied?.comment_by_supervisor?.text ? true : false
        const isStudentSubmitted = true
        const isExtraWorkComplete = isSupervisorSubmitted && isStudentSubmitted
        const isAllowStudentUpdateComment = extraWorkSubmitied?.is_allow_student_edit ? true : false
        return { isExtraWorkComplete, isAllowStudentUpdateComment, isStudentSubmitted, isSupervisorSubmitted, extraWorkSubmitied }
    }

    const createNewExtraWork = () => {
        setExtraWorkSubmission({})
        setIsStudentSubmittedExtraWork(false)
        setIsStudentAlloedToEditExtraWork(true)
        setIsSupervisorSubmitted(false)
        setIsExtraWorkComplete(false)
        setExtraWorkModal(true)
    }

    return (
        <>
            <ExtraWorkModal
                refresh={() => props.refresh()}
                loader={status => props.loader(status)}
                extraWorkSubmission={extraWorkSubmission}
                setModal={modal => setExtraWorkModal(modal)}
                modal={extraWorkModal}
                isStudentSubmittedExtraWork={isStudentSubmittedExtraWork}
                isStudentAlloedToEditExtraWork={isStudentAlloedToEditExtraWork}
                isSupervisorSubmitted={isSupervisorSubmitted}
                isExtraWorkComplete={isExtraWorkComplete}
                fdf_id={props.fdf_id}
                submission_id={props?.submission?._id}
                isFdfSubmissionCompleted={props?.submission?.is_complete == true}

            />
            <div className="col-sm-12 mt-3 fw-bold">Extra Works: {props.isStudent && !props.isSubmissionCompleted ? <button className="btn btn-success" onClick={createNewExtraWork}>+</button> : null}</div>
            {isArr(props.submission?.extra_works).length == 0 ? <div className="ps-3 pe-3 mt-2"><div className="alert alert-success mb-0">There is no extra work</div></div>
                :
                isArr(props.submission?.extra_works).map((item, extraWorkIndex) => {
                    const { isExtraWorkComplete, isAllowStudentUpdateComment, isStudentSubmitted, isSupervisorSubmitted, extraWorkSubmitied } = checkIfExtraWorksSubmitted(item?._id, extraWorkIndex)
                    return (<div className="col-md-3 p-2 btn " onClick={() => { openExtraWorkModal({ isExtraWorkComplete, extraWorkIndex, isAllowStudentUpdateComment, isStudentSubmitted, extraWorkSubmitied, isSupervisorSubmitted }) }}>
                        <div className={`card-info smooth-box-shadow bg-white60 rounded p-3 ${isExtraWorkComplete ? 'bg-success' : ''}`}>
                            <p className="h6 w-100 text-start ">
                                <div className="p-1 text-center fw-bold mb-1">work {extraWorkIndex + 1} ({item?.title})</div>
                                <div className='m-1'><p className={isStudentSubmitted ? `bg-${isAllowStudentUpdateComment ? 'warning' : 'success'} text-light p-1 rounded border` : ''}> {isStudentSubmitted ? isAllowStudentUpdateComment ? <IoPencil /> : <IoCheckmarkCircleOutline /> : <IoTimeOutline />} student</p></div>
                                <div className="m-1"><p className={isSupervisorSubmitted ? 'bg-success p-1 rounded border' : ''}>{isSupervisorSubmitted ? <IoCheckmarkCircleOutline /> : <IoTimeOutline />} supervisor </p></div>
                            </p>
                        </div>
                    </div>)
                }
                )}

        </>

    )
}

export default ExtraWork