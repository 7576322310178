import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Notify } from 'notiflix'
import { api } from '../../util/constant';
import { isArr } from '../../util/functions';

// Muitable Requerments
import { TableFooter, TableCell, TableRow } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import muiTable from '../../util/mui-table'
import MUIDataTable from "mui-datatables";
import { IoExpandOutline, IoRepeat, IoSearch, IoExpand } from "react-icons/io5";

function ProjectSubmissions({ setLoader }) {

  const [submissions, setSubmissions] = useState([])

  const [theme, setTheme] = useState(false)
  const [isSearch, setIsSearch] = useState(false)
  const [tablePageNumber, setTablePageNumber] = useState(1)
  const [tableRowsLimit, setTableRowsLimit] = useState(10)
  const [tableTotalPages, setTotalPages] = useState(0)
  const [tableTotalRows, setTableTotalRows] = useState(0)
  const [tableSearch, setTableSearch] = useState('')
  const [tableSearchType, setTableSearchType] = useState('')

  let options = muiTable.muiOptions(theme)
  options = {
    ...options,
    rowsPerPage: 100,
    search: false,
    serverSide: true,
    customFooter: () => {
      return (
        <TableFooter>
          <TableRow>
            <TableCell colSpan={6}>
              page {tablePageNumber} of {tableTotalPages} - total projects {tableTotalRows}
              <button className="btn btn-light" disabled={tablePageNumber == 1 ? true : false} onClick={() => setTablePageNumber(prev => prev - 1)}>{`<`}</button>
              <button className="btn btn-light" disabled={tablePageNumber == tableTotalPages || tableTotalPages == 0 ? true : false} onClick={() => setTablePageNumber(prev => prev + 1)}>{`>`}</button>
              jump to <select onChange={e => setTablePageNumber(+e.target.value)}>{[...Array(tableTotalPages)].map((item, i) => <option value={i + 1} key={i}>{i + 1}</option>)}</select>
              per page <select onChange={e => setTableRowsLimit(+e.target.value)}>{[...Array(10)].map((item, i) => <option value={(i + 1) * 10} key={i}>{(i + 1) * 10}</option>)}</select>
            </TableCell>
          </TableRow>
        </TableFooter>
      );
    }
  }

  const columns = ["Student", "Project", "Orgnization", {
    label: "",
    options: {
      filter: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (<span style={{ fontSize: "20px" }} className="btn pt-0 p-1 shadow border" onClick={() => {
          // getProject(tableMeta.rowData[tableMeta.columnIndex]);
        }}><IoExpand /></span>)
      }
    }
  }];

  const getProjectSubmissions = () => {
    setLoader(true)
    axios.get(`${api}/projects/submission`, { withCredentials: true })
      .then(res => {
        if (res.status == 203) {
          Notify.warning(res.data);
        } else {
          const { submissions } = res.data;
          setSubmissions(isArr(submissions));

        }
        setLoader(false)
      })
      .catch(e => { console.error({ e }); setLoader(false); })
  }



  return (
    <div>
      <button className='btn btn-success shadow float-end' onClick={() => getProjectSubmissions()}><IoRepeat size={24} /></button>
      <MuiThemeProvider theme={muiTable.getMuiTheme()}>
        <MUIDataTable
          title={<>
            <button className="btn btn-light shadow" disabled >All Users</button>
            <button onClick={() => { setTheme(!theme); }} className="btn btn-light shadow"><IoExpandOutline /></button>
          </>}
          data={[].map(item => { return [item?.title, item?.organization?.name, item?.submissionsCount, item._id] })}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
    </div>
  )
}

export default ProjectSubmissions