import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Nav from '../components/nav'
import Location from '../components/location'
import Loader from '../components/loader'
import { api, defaultProjectObj } from '../util/constant'
import { isArr } from '../util/functions'
import ProjectModal from '../components/projects/projectModal'
import ProjectSubmissions from '../components/projects/ProjectSubmissions'
import { Notify } from 'notiflix'

// Muitable Requerments
import { TableFooter, TableCell, TableRow } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import muiTable from '../util/mui-table'
import MUIDataTable from "mui-datatables";
import { IoExpandOutline, IoRepeat, IoSearch, IoExpand } from "react-icons/io5";
function Projects() {
    const [loader, setLoader] = useState(false);
    const [page, setPage] = useState(1)
    const [projects, setProjects] = useState([]);
    const [isProjectModal, setIsProjectModal] = useState(false)
    const [selectedproject, setSelectedProject] = useState(defaultProjectObj)
    const [projectSubmission, setProjectSubmission] = useState({})
    const [organizations, setOrganizations] = useState('')
    const [isAllowToAddProject, setIsAllowToAddProject] = useState(false)
    const [isAllowedToEditOrg, setIsAllowedToEditOrg] = useState(false)
    const [isAllowToDeleteProject, setIsAllowToDeleteProject] = useState(false)
    const [isAllowToEditProject, setIsAllowToEditProject] = useState(false)
    const [isAllowToAddProjectSubmission, setIsAllowToAddProjectSubmission] = useState(false)
    const [isAllowedToViewProjectSubmissionsList, setIsAllowedToViewProjectSubmissionsList] = useState(false)
    const [isStudentAndHaveSubmissionForSelectedProject, setIsStudentAndHaveSubmissionForSelectedProject] = useState(false)

    const [theme, setTheme] = useState(false)
    const [isSearch, setIsSearch] = useState(false)
    const [tablePageNumber, setTablePageNumber] = useState(1)
    const [tableRowsLimit, setTableRowsLimit] = useState(10)
    const [tableTotalPages, setTotalPages] = useState(0)
    const [tableTotalRows, setTableTotalRows] = useState(0)
    const [tableSearch, setTableSearch] = useState('')
    const [tableSearchType, setTableSearchType] = useState('')

    let options = muiTable.muiOptions(theme)
    options = {
        ...options,
        rowsPerPage: 100,
        search: false,
        serverSide: true,
        customFooter: () => {
            return (
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={6}>
                            page {tablePageNumber} of {tableTotalPages} - total projects {tableTotalRows}
                            <button className="btn btn-light" disabled={tablePageNumber == 1 ? true : false} onClick={() => setTablePageNumber(prev => prev - 1)}>{`<`}</button>
                            <button className="btn btn-light" disabled={tablePageNumber == tableTotalPages || tableTotalPages == 0 ? true : false} onClick={() => setTablePageNumber(prev => prev + 1)}>{`>`}</button>
                            jump to <select onChange={e => setTablePageNumber(+e.target.value)}>{[...Array(tableTotalPages)].map((item, i) => <option value={i + 1} key={i}>{i + 1}</option>)}</select>
                            per page <select onChange={e => setTableRowsLimit(+e.target.value)}>{[...Array(10)].map((item, i) => <option value={(i + 1) * 10} key={i}>{(i + 1) * 10}</option>)}</select>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            );
        }
    }

    const columns = [{ label: "Project Title", options: { filter: false } }, "Orgnization", "Submissions", {
        label: "",
        options: {
            filter: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                return (<span style={{ fontSize: "20px" }} className="btn pt-0 p-1 shadow border" onClick={() => {
                    getProject(tableMeta.rowData[tableMeta.columnIndex]);
                }}><IoExpand /></span>)
            }
        }
    }];

    const getProjects = () => {
        setLoader(true)
        const search = handleSearch()
        axios.get(`${api}/projects`, { headers: { search: JSON.stringify(search), page: tablePageNumber, limit: tableRowsLimit }, withCredentials: true })
            .then((res) => {
                const { projects, totalPages, totalProjects, isAllowToAddProject, organizations, isAllowedToEditOrg,
                    isAllowToDeleteProject, isAllowToEditProject, isAllowToAddProjectSubmission, isAllowedToViewProjectSubmissionsList } = res?.data
                setProjects(isArr(projects));
                setTotalPages(totalPages);
                setTableTotalRows(totalProjects)
                setIsAllowToAddProject(isAllowToAddProject)
                setIsAllowedToEditOrg(isAllowedToEditOrg)
                setIsAllowToDeleteProject(isAllowToDeleteProject)
                setIsAllowToEditProject(isAllowToEditProject)
                setIsAllowToAddProjectSubmission(isAllowToAddProjectSubmission)
                setIsAllowedToViewProjectSubmissionsList(isAllowedToViewProjectSubmissionsList)
                setOrganizations(organizations)
                setLoader(false)
            })
            .catch((e) => { setLoader(false) })
    }

    const getProject = (id) => {
        setLoader(true)
        axios.get(`${api}/projects/project/${id}`, { withCredentials: true })
            .then((res) => {
                const { project, isStudentAndHaveSubmissionForSelectedProject } = res?.data
                setSelectedProject(project)
                setIsStudentAndHaveSubmissionForSelectedProject(isStudentAndHaveSubmissionForSelectedProject)
                setIsProjectModal(true)
                setLoader(false)
            })
            .catch((e) => { setLoader(false); Notify.failure(JSON.stringify(e)) })
    }

    const handleSearch = () => {
        if (isSearch && tableSearchType != '') return { [tableSearchType]: { $regex: tableSearch, $options: "i" } }
        else return {}
    }

    useEffect(() => {
        if (!isProjectModal) setSelectedProject(defaultProjectObj)
    }, [isProjectModal])

    useEffect(() => { getProjects() }, [tablePageNumber, tableRowsLimit, isSearch])
    useEffect(() => { setTablePageNumber(1) }, [tableRowsLimit])

    return (<>
        <Nav />
        <Location />
        {loader ? <Loader /> : null}
        <ProjectModal
            modal={isProjectModal}
            modalToggle={() => setIsProjectModal(prev => !prev)}
            refresh={() => getProjects()}
            selectedProject={selectedproject}
            projectSubmission={projectSubmission}
            setProjectSubmission={projectSubmission => setProjectSubmission(projectSubmission)}
            setSelectedProject={selectedproject => setSelectedProject(selectedproject)}
            organizations={organizations}
            isAllowedToEditOrg={isAllowedToEditOrg}
            setLoader={loader => setLoader(loader)}
            isAllowToDeleteProject={isAllowToDeleteProject}
            isAllowToEditProject={isAllowToEditProject}
            isAllowToAddProjectSubmission={isAllowToAddProjectSubmission}
            isStudentAndHaveSubmissionForSelectedProject={isStudentAndHaveSubmissionForSelectedProject}
            isAllowedToViewProjectSubmissionsList={isAllowedToViewProjectSubmissionsList}
        />
        <div className="row">
            <div className="col-md-12 mb-2 p-0 shadow">
                <button className={`sub-nav w50 left ${page == 1 ? 'active' : ''}`} onClick={() => { setPage(1) }}>Projects</button>
                <button className={`sub-nav w50 mid ${page == 2 ? 'active' : ''}`} onClick={() => { setPage(2) }}>Submissions</button>
            </div>
        </div>
        {page == 1 ? <>
            <div className="p-4">
                {isAllowToAddProject ? <button className='btn btn-light me-2 shadow' onClick={() => {
                    setSelectedProject(defaultProjectObj);
                    setIsProjectModal(true);
                }}>New</button> : null}
                {!isSearch ? <button className="btn btn-warning mb-1" onClick={() => setIsSearch(prev => !prev)}>search <IoSearch /></button>
                    :
                    <div className="row">
                        <div className="col-sm-6"><input className='form-control' placeholder='search' onChange={e => setTableSearch(e.target.value)} /></div>
                        <div className="col-sm-3"><select value={tableSearchType} className='w-100' onChange={e => setTableSearchType(e.target.value)}>
                            <option value="">{`<select>`}</option>
                            <option value="title">Project Title</option>
                        </select></div>
                        <div className="col-sm-1"><button className="btn btn-warning mb-1" onClick={() => { getProjects(); setTablePageNumber(1) }}><IoSearch /></button></div>
                        <div className="col-sm-1"><button className="btn btn-danger mb-1" onClick={() => setIsSearch(prev => !prev)}>X</button></div>
                    </div>
                }
                <button className='btn btn-success shadow float-end' onClick={() => getProjects()}><IoRepeat size={24} /></button>
                <MuiThemeProvider theme={muiTable.getMuiTheme()}>
                    <MUIDataTable
                        title={<>
                            <button className="btn btn-light shadow" disabled >All Users</button>
                            <button onClick={() => { setTheme(!theme); }} className="btn btn-light shadow"><IoExpandOutline /></button>
                        </>}
                        data={projects.map(item => { return [item?.title, item?.organization?.name, item?.submissionsCount, item._id] })}
                        columns={columns}
                        options={options}
                    />
                </MuiThemeProvider>
            </div>
        </> :
            <ProjectSubmissions setLoader={loader => setLoader(loader)} />}
    </>
    )
}

export default Projects