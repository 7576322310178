import React, { useState, useEffect, useContext } from 'react'
import { Dropdown } from 'semantic-ui-react';
import Nav from '../components/nav';
import Location from '../components/location';
import axios from 'axios';
import '../style/profile.css';
import Loader from '../components/loader';
import { countryList } from '../util/countries'
import { IoCloudDownloadOutline } from 'react-icons/io5';
import { Cxt } from '../App'
import { api } from '../util/constant'
import { FileDownload, identifyRole, isArr } from '../util/functions'
import Cookies from 'js-cookie';
import { Notify } from 'notiflix/build/notiflix-notify-aio';

function Profile() {

    // states
    const [FullName, setFullName] = useState('');
    const [Email, setEmail] = useState('');
    const [Department, setDepartment] = useState('');
    const [Role, setRole] = useState('');
    const [Gender, setGender] = useState('');
    const [Organization, setOrganization] = useState('');
    const [Qualification, setQualification] = useState('');
    const [colleges, setColleges] = useState([]); // get all colleges from db
    const [Address, setAddress] = useState('');
    const [Agreement, setAgreement] = useState(false);
    const [GPA, setGPA] = useState('');
    const [Nationality, setNationality] = useState('');
    const [CV, setCV] = useState('');
    const [TransScript, setTransScript] = useState('');
    const [Major, setMajor] = useState('')
    const [Phone, setPhone] = useState('')
    const [Urls, setUrls] = useState({});
    const { role } = useContext(Cxt);
    const [isStudent, setIsStudent] = useState(false)

    const [loader, setLoader] = useState(false);
    const [CountryList, setCountryList] = useState([])
    const [MajorList, setMajorList] = useState([])
    const [Warning, setWarning] = useState(false);
    const [Messege, setMessege] = useState(['', []]);

    //get user profile
    const getProfile = async () => {
        setLoader(true);
        axios.get(`${api}/users`, { headers: { profile: true }, withCredentials: true })
            .then((res) => {
                const { user, majors, orgs } = res.data
                const { fullname = '', email = '', department = '', gender = '', orgnization_id: orgnization, role = '', qualification = '', gpa = '', address = '', nationality = 'Oman', cv_url = '', tr_url = '', major = '', profileupdate = false, phone = '' } = user
                setFullName(fullname);
                setEmail(email);
                setDepartment(department);
                setRole(role);
                setGender(gender);
                setOrganization(orgnization._id);
                setQualification(qualification);
                setAgreement(profileupdate);
                setPhone(phone);
                setUrls({ cv_url, tr_url });
                if (gpa) setGPA(gpa);
                if (address) setAddress(address);
                if (nationality) setNationality(nationality);
                if (major) setMajor(major._id);
                setMajorList(isArr(majors))
                setColleges(isArr(orgs));
                setLoader(false);
            })
            .catch((e) => { console.log(e); setLoader(false); })
    };

    //validation
    const validate = () => {
        setWarning(false); setWarning(['', []]);
        const limit = 5000000; //5MB
        let isEmpty = false;
        let Messeges = [];
        let Fields = [FullName, Gender, Address, Nationality, Phone]
        if (isStudent) { Fields = [...Fields, Major, Qualification, Department] }
        if (CV) {
            if (CV.size > limit) {
                Messeges.push('CV max file size is 5MB')
                isEmpty = true;
            }
        }

        if (TransScript) {
            if (TransScript.size > limit) {
                Messeges.push('Transcript max file size is 5MB')
                isEmpty = true;
            }
        }

        //1-if any field is empty
        Fields.map((item) => {
            if (!isEmpty) {
                try { item = item.trim(); if (item == '') { isEmpty = true; Messeges.push('Please Fill All Fields.'); } }
                catch (e) { isEmpty = true; Messeges.push('Please Fill All Fields.'); return }
            }
        })

        //2-if accept Agreement
        if (!Agreement) { Messeges.push('Accept all terms and Conditions.'); isEmpty = true; }

        if (role == 3) {//if student check gpa and urls
            //3-if GPA number > if its not zero and less than 4
            if (!isNaN(parseFloat(GPA))) {
                if (GPA == 0) { Messeges.push('GPA cannot be 0 .'); isEmpty = true; }
                else if (parseFloat(GPA) > 4.0) { Messeges.push('GPA must be out of 4 .'); isEmpty = true; }
            } else { Messeges.push('GPA Accept numbers only .'); isEmpty = true; }

        }



        if (isEmpty) { Alert(0, Messeges) } else { Alert(0, [''], true) }
        return isEmpty
    }

    //alert messege
    const Alert = (type, messege, AlertOff) => {
        if (AlertOff) {
            setWarning(false)
        } else {
            const types = ['alert alert-warning', 'alert alert-success'];
            setMessege([types[type], messege]);
            setWarning(true)
        }
    }

    //update
    const update = async () => {
        setLoader(true);
        const isEmpty = validate()
        const { isAdminOrEidaad } = identifyRole(role)

        if (!isEmpty) {
            let json = {
                fullname: FullName,
                gender: Gender,
                address: Address,
                nationality: Nationality,
                phone: Phone
            }

            //if eidaad team or admin allow him change org
            if (isAdminOrEidaad) { json = { ...json, orgnization_id: Organization, } }
            if (isStudent) { json = { ...json, gpa: GPA, qualification: Qualification, major: Major, department: Department } }
            const data = new FormData();
            if (CV != '') data.append('cv', CV);
            if (TransScript != '') data.append('tr', TransScript);
            data.append('data', JSON.stringify(json))
            await axios.post(`${api}/users`, data, { withCredentials: true })
                .then(res => { Alert(1, ['Profile updated succefuly !']) })
                .catch((e) => { Alert(0, ['Somthing went wrong !']); })
        }
        setLoader(false)
    }

    useEffect(() => { getProfile(); setCountryList(countryList); setIsStudent(role == 3 ? true : false); }, []);

    return (
        <>
            <Nav />
            <Location />
            {loader ? <Loader /> : null}
            <div>
                <div className="profile-main">
                    <div className='center-div'>
                        <img src="/images/profile.svg" className='profile-pic' />
                        <div className="row">
                            <div className="col-md-10">{Warning ? <div className={`${Messege[0]} mt-2 pr-0 pl-0 pb-0 pt-1`} role="alert"><ul>{Messege[1].length > 0 ? Messege[1].map((item, i) => <li key={i}>{item}</li>) : null}</ul></div> : null}</div>
                            <div className="col-md-6">
                                Full Name
                                <input type="text" value={FullName} onChange={(e) => { setFullName(e.target.value) }} placeholder="Full name" className="form-control m-2" />
                            </div>
                            <div className="col-md-6">
                                Email
                                <input type="email" disabled value={Email} placeholder="Email" className="form-control m-2" />
                            </div>
                            <div className="col-md-6">
                                mobile Number
                                <input type="text" value={Phone} onChange={(e) => { setPhone(e.target.value) }} placeholder="mobile Number" className="form-control m-2" />
                            </div>

                            <div className="col-md-6">
                                Gender
                                <select value={Gender} className="form-control m-2" onChange={(e) => { setGender(e.target.value) }} >
                                    <option value="">--Select Gender--</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                            </div>

                            <div className="col-md-6">
                                Role
                                <input type="text" value={Role} className="form-control m-2" disabled />
                            </div>
                            <div className="col-md-6">
                                Nationality
                                <select value={Nationality} onChange={(e) => { setNationality(e.target.value) }} className="form-control m-2">
                                    <option value=""></option>
                                    {CountryList.map((item, i) => { return (<option key={i} value={item.name}>{item.name}</option>) })}
                                </select>
                            </div>
                            <div className="col-md-6">
                                Institution
                                <Dropdown className="m-2"
                                    placeholder='--Select institution--'
                                    fluid
                                    search
                                    clearable
                                    selection
                                    options={colleges ? colleges.map(item => { return { key: item.key, text: item.name, value: item._id } }) : []}
                                    onChange={(e, d) => { setOrganization(d.value); }}
                                    value={Organization}
                                    disabled
                                />
                            </div>
                            {isStudent ? <>
                                <div className="col-md-6">
                                    Department
                                    <input type="text" value={Department} onChange={(e) => { setDepartment(e.target.value) }} placeholder="Department" className="form-control m-2" />
                                </div>
                                <div className="col-md-6" onClick={() => console.log(Major)}>
                                    Major
                                    <select value={Major} onChange={(e) => { setMajor(e.target.value) }} className="form-control m-2">
                                        <option value=""></option>
                                        {MajorList.map((item, i) => { return (<option key={i} value={item._id}>{item.name}</option>) })}
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    Qualification
                                    <select className="form-control m-2" value={Qualification} onChange={(e) => { setQualification(e.target.value) }}>
                                        <option value="1">--Select Qualification--</option>
                                        <option value="PHD">Phd</option>
                                        <option value="MASTER">Master</option>
                                        <option value="BACHELOR">Bachelor</option>
                                        <option value="DIPLOMA">Diploma</option>
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    GPA
                                    <input type="text" value={GPA} onChange={(e) => { setGPA(e.target.value) }} placeholder="GPA" className="form-control m-2" />
                                </div>
                            </>
                                : null}
                            <div className="col-md-6">
                                Address
                                <input type="text" value={Address} onChange={(e) => { setAddress(e.target.value) }} placeholder="Address" className="form-control m-2" />
                            </div>
                            {isStudent ? <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-6 ">
                                        CV (Optinal)<br />
                                        <input type="file" className="mt-4 mb-4" onChange={(e) => { setCV(e.target.files[0]) }} /> <br />
                                        {Urls?.cv_url && Urls?.cv_url != '' ? <button className="btn btn-light shadow" onClick={() => { FileDownload(`${api}/download/?token=${Cookies.get('token')}&file_name=${Urls?.cv_url}&type=cv`) }}><IoCloudDownloadOutline />  CV</button> : null}
                                    </div>
                                    <div className="col-md-6">
                                        Transcript (Optinal)<br />
                                        <input type="file" className="mt-4 mb-4" onChange={(e) => { setTransScript(e.target.files[0]) }} /> <br />
                                        {Urls?.tr_url && Urls?.tr_url != '' ? <button className="btn btn-light shadow" onClick={() => { FileDownload(`${api}/download/?token=${Cookies.get('token')}&file_name=${Urls?.tr_url}&type=transcript`) }}><IoCloudDownloadOutline />  Transcript</button> : null}
                                    </div>
                                </div>
                            </div> : null}
                            <div className="col-md-12 p-2">
                                <input type="checkbox" className="form-check-input" checked={Agreement} onChange={() => { setAgreement(!Agreement) }} /> I Accept all terms and Conditions of Eidaad Program .
                            </div>
                        </div>

                        <div className="text-center">
                            <button type="button" className="btn btn-light border update" onClick={update} >update</button>
                        </div>

                    </div>
                    <ChangePasswordComponent loader={loader} setLoader={loader => setLoader(loader)} userid={null} />
                </div>
            </div>
        </>
    )
}

export function ChangePasswordComponent({ setLoader, userid }) {
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');

    const updatePassword = () => {

        if (password != rePassword) return Notify.failure("password doesn't match");
        if (password == '' || password.trim().length < 8) return Notify.failure("minimum length is 8 characters");

        setLoader(true)

        let body = { password }
        if (userid) body = { ...body, id: userid }
        axios.post(`${api}/users`, body, { withCredentials: true })
            .then(res => { setLoader(false); Notify.success("password updated") })
            .catch((e) => { setLoader(false); Notify.failure("somthing went worng") })
    }

    return <div className="mt-5 ">
        <div className='center-div pt-1 shadow p-3'>
            <div className="row">
                <div className=" h3">
                    Change Password
                </div>
                <div className="col-md-6">
                    password
                    <input type="password" value={password} className='form-control' onChange={(e) => setPassword(e.target.value)} />
                </div>
                <div className="col-md-6">
                    rewrite password
                    <input type="password" value={rePassword} className='form-control' onChange={(e) => setRePassword(e.target.value)} />
                </div>
                <div className="col-md-12" onClick={updatePassword}>
                    <button className="btn btn-success m-3 float-end">update password</button>
                </div>
            </div>
        </div>
    </div>
}

export default Profile
