import React from 'react'

function Statistics({ isEng }) {
    return (
        <section className="counter">
            <div className="content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="count-item decoration-bottom">
                                {/* <strong>126</strong> */}
                                {/* <span>Seats</span> */}
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            {/* <div className="count-item decoration-top"> */}
                            {/* <strong>63</strong> */}
                            {/* <span>Displents</span> */}
                            {/* </div> */}
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="count-item decoration-bottom">
                                {/* <strong>18</strong> */}
                                {/* <span>Companies</span> */}
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="count-item">
                                {/* <strong>27</strong> */}
                                {/* <span>Students applied</span> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Statistics