import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { api } from '../../../util/constant'
import News from '../components/news'
import { isArr } from '../../../util/functions'
import ProfileCard from '../components/profileCard'
import ForMyAction from '../components/fdfForMyAction'
import DashboardMessege from '../components/dashboardMessege'
function Supervisor(props) {

    const [newsList, setNewsList] = useState([]);
    const [students, setStudents] = useState(0);
    const [submissions, setSubmissions] = useState(0)
    const [allUserInfo, setAllUserInfo] = useState({});
    const [forMyAction, setForMyAction] = useState({});
    const [dashboard_messege, setDashboard_messege] = useState({ is_show: false, text: '', color: 'success' })
    const loader = (status) => { props.loderToggle(status) };

    const getDashboard = () => {
        loader(true)
        axios.get(`${api}/dashboard`, { withCredentials: true })
            .then((res) => {
                const { news = [], user = {}, submissionCount = 0, studentsCount = 0, forMyAction, dashboard_messege } = res?.data;
                setNewsList(isArr(news));
                setAllUserInfo(user)
                setStudents(studentsCount);
                setSubmissions(submissionCount);
                setForMyAction(forMyAction);
                setDashboard_messege(dashboard_messege)
                loader(false);
            })
            .catch((e) => { console.log(e); loader(false) })
    }

    useEffect(() => { getDashboard(); }, []);



    return (
        <>
            <div className="dashboard-main row p-4">
                <DashboardMessege dashboard_messege={dashboard_messege} />
                <div className="col-md-4 p-2">
                    <div className=" card-info smooth-box-shadow">
                        <div className="title">
                            Students
                        </div>
                        <div className="icon"><img src="/images/paper.svg" /></div>
                        <div className="val">{students}</div>
                    </div>
                </div>
                <div className="col-md-4 p-2"><ProfileCard info={allUserInfo} /></div>
                <div className="col-md-4 p-2">
                    <div className=" card-info smooth-box-shadow">
                        <div className="title">
                            Submissions
                        </div>
                        <div className="icon"><img src="/images/demand.svg" /></div>
                        <div className="val">{submissions}</div>
                    </div>
                </div>
                <div className="col-md-6 p-2"><News news={newsList} /></div>
                <div className="col-md-6 p-2"><ForMyAction fdf={forMyAction} /></div>
            </div>
        </>
    )
}

export default Supervisor