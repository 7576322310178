import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { isArr } from '../../util/functions';

function userSelectLoginRoleModal(props) {
  const toggle = () => (props.setModal(!props.modal));
  return (
    <Modal size="lg" style={{ maxWidth: '700px', width: '100%' }} isOpen={props.modal} toggle={() => { toggle(props) }}>
      <ModalHeader toggle={() => { toggle() }}>Select Role</ModalHeader>
      <ModalBody>
        <div>
          {isArr(props.roles).map((item, index) => <button key={index} onClick={() => props.login(item.user)} className="btn btn-light w-100 text-center mb-2 shadow">{item.org_name} <br /> <b>{item.role}</b> </button>)}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="row">
          <div className="col-md-12">
            <button className='btn btn-warning float-end' onClick={() => { toggle() }}>Close</button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default userSelectLoginRoleModal