import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { isArr } from '../../../util/functions'
import { api } from '../../../util/constant'

function Orgs(props) {
    const [orgs, setOrgs] = useState([])
    const loader = (status) => { props.loader(status) }

    const getOrgs = () => {
        loader(true);
        axios.get(`${api}/org`, { headers: { industry: true }, withCredentials: true })
            .then((response) => { loader(false); setOrgs(isArr(response.data)) })
            .catch((e) => { console.log(e); loader(false); })
    }

    useEffect(() => { getOrgs(); }, [])

    return (
        <>
            <p className="h4 text-center p-2 mt-5"><span className='shadow bg-white60 p-2 rounded'>Orgnizations</span></p>
            {!orgs.length ?
                <div className="div-center-container" >
                    <div className="p-3 h3 div-center alert alert-warning text-center">
                        there is no orgnizations</div></div>
                : <>
                    <div className="fdf-main row p-4">
                        {orgs.map((item, index) => {
                            return (
                                <div className="col-md-12 mb-3" onClick={() => { props.setSelectedOrg({ id: item?._id, name: item?.name }) }} key={`org-${index}`}>
                                    <div className="shadow pointer bg-white60 border p-3">
                                        <p className="h6 fw-bold">
                                            {index + 1}. {item?.name}
                                        </p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </>}
        </>
    )
}

export default Orgs