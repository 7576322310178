import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { api } from '../../../util/constant'
import Fdfs from '../components/fdfs/fdfs'
import Fdf from '../components/fdf/fdf'
import FdfSubmission from '../components/fdfSubmission/fdfSubmission'
import { useLocation } from 'react-router-dom'
import ViewUserModal from '../../users/viewUserModal'
import FdfFullSubmissionList from '../components/fdfFullSubmissionList'
function Supervisor(props) {

    const [isModal, setIsModal] = useState(false)
    const [userInfo, setUserInfo] = useState({})

    const getSvOrTaInfo = async () => {
        props.loader(true)
        await axios.get(`${api}/users`, { headers: { profile: true }, withCredentials: true })
            .then((res) => { setUserInfo(res?.data?.user); setIsModal(true); props.loader(false); })
            .catch((e) => { props.loader(false); console.log(e); })
    }

    return (
        <>
            <ViewUserModal modal={isModal} defualtPage={2} isAdminOrEidaad={false} refreshApp={() => { }} info={userInfo} loaderToggle={loader => props.loader(loader)} modalToggle={modal => setIsModal(modal)} />
            <button className="btn btn-success" onClick={getSvOrTaInfo}>My Students</button>
            <FdfFullSubmissionList loader={loader => props.loader(loader)} />
        </>
    )
}

export default Supervisor