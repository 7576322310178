import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Dropdown } from 'semantic-ui-react';
import axios from 'axios';
import { api } from '../../util/constant'


function ModalNewApp(props) {
    //status

    const [disciplinesList, setDisciplinesList] = useState([]);
    const [discipline, setDiscipline] = useState([{ discipline_id: '', discipline_seq: 1 }, { discipline_id: '', discipline_seq: 2 }, { discipline_id: '', discipline_seq: 3 }]);
    const [disciplineByYear, setDisciplineByYear] = useState([]);
    const [disciplineDescription, setDisciplineDescription] = useState([])
    const [warning, setWarning] = useState(false);
    const [messege, setMessege] = useState('');
    const [comment, setComment] = useState('');
    const [Year, setYear] = useState('');
    const [runOneTime, setRunOneTime] = useState(false);
    const [EnabledYears, setEnabledYears] = useState([]);
    const [resetWhenModify, setResetWhenModify] = useState(false);
    const [autoSecondAllocation, setAutoSecondAllocation] = useState(false)

    //toggles
    const toggle = () => (props.modalToggle(!props.modal));
    const loader = (status) => { props.loaderToggle(status) }
    const refresh = () => { props.refreshApp() }

    //get disciplines based on his major
    const getDiscipline = async () => {
        loader(true);
        if (!props.modify) { handleYearChange('') }
        const response = await axios.get(`${api}/discipline`, { withCredentials: true, headers: { modify: props.modify } });
        console.log(response);
        //if major has disciplines push them on state
        if (response.data?.discipline?.disciplines) {
            if (response.data.year) setEnabledYears(response.data.year)
            setDisciplinesList(response.data.discipline.disciplines);
            loader(false);
        } else { setDisciplinesList([]) }
    }

    //handle discipline change
    const handleDisciplineChange = (i, value) => {
        let arr = [...discipline]
        //if discipline length less than disciplineByYear add to the array one object
        if ((arr.length) < i + 1) {
            for (let j = 1; j < i; j++)arr.push({ discipline_id: '', discipline_seq: (j + 2).toString() })
        }
        arr[i].discipline_id = value
        setDiscipline(arr)
    }

    //display discipline based on year
    const handleYearChange = (year) => {
        // reset disciplines (dont reset if we open modal first time)
        if (!props.modify || resetWhenModify) resetDesciplines()
        else setResetWhenModify(true);

        setYear(year)
        let arr = []

        //map over all disciplines and push them on DisciplineByYear
        disciplinesList.map(item => {
            let push = false
            item.discipline.demand.map(item => {
                if (item.year._id === year) { push = true; }
            })
            if (push) { arr.push(item) }
        })
        setDisciplineByYear(arr)

        //check if year options less than current selectd year than remove from the end of the array
        if (arr.length < discipline.length) {
            let temp = [...discipline];
            while (arr.length < temp.length) temp.pop()
            setDiscipline(temp)
        }


    }

    //reset selected disciplines
    const resetDesciplines = () => {
        let arr = [];
        for (let i = 0; i < 3; i++) { if (arr.length < 3) { arr.push({ discipline_id: '', discipline_seq: (i + 1) }) } }
        setDiscipline(arr);
    }

    //send new app to database
    const save = async (next) => {

        const pass = validation();
        if (pass) {
            loader(true)
            //remove empty discipline
            const options = discipline.filter((item) => item.discipline_id != '');

            //save
            await axios.post(`${api}/app`, { allocation_call: Year, options, next, auto_second_allocation: autoSecondAllocation }, { withCredentials: true })
                .then((res) => { loader(false); toggle(); refresh(); })
                .catch(e => { setMessege('please updete your profile first'); setWarning(true); loader(false) })

        }
    }

    //validation
    const validation = () => {
        //1-check if year not selected

        if (disciplineByYear == '' && Year == '') {
            setMessege('please select a year'); setWarning(true);
            return false
        } else {

            //2-check at least have one option
            const NewArr = discipline.filter(item => item.discipline_id == '')
            if (NewArr.length == discipline.length) {
                setWarning(true);
                setMessege("You must at least have one option !")
                return false
            }

            //3-check if repeate (only on new app)
            let isNotRepeate = true
            if (!props.modify) {
                discipline.map(item => {
                    const arr = discipline.filter(_item => {
                        if (_item.discipline_id == item.discipline_id && _item.discipline_id != '') {
                            return _item
                        }
                    })
                    if (isNotRepeate) {
                        if (arr.length > 1) { setMessege('Discipline Cannot Repeate !'); setWarning(true); isNotRepeate = false } else { setWarning(false) }
                    }
                })
            }
            return isNotRepeate
        }
    }

    //update app
    const updateApp = async (id) => {
        const pass = validation()
        if (pass) {
            //1-remove empty options
            const options = discipline.filter((item) => item.discipline_id != '');

            //2-if year has options less than selected year
            while (disciplineByYear.length < options.length) options.pop()

            //3-if selected discipline not available in this year remove it.
            options.map((option, index) => {
                const select = disciplineByYear.filter((item) => item.discipline._id == option.discipline_id)
                if (select.length == 0) options.splice(index, 1)
            })

            //4-orgnize sequnce priority if not all options selected, ex({seq:1},{seq:3}) to ({seq:1},{seq:2}) 
            if (options.lenth != 3) {
                options.map((item, index) => {
                    if (item.discipline_seq != (index + 1)) { item.discipline_seq = (index + 1).toString() }
                })
            }

            //5-check if option repeate
            let isNotRepeate = true;
            options.map(item => {
                const arr = options.filter(_item => {
                    if (_item.discipline_id == item.discipline_id && _item.discipline_id != '') { return _item }
                })
                if (arr.length > 1) { setMessege('Discipline Cannot Repeate !'); setWarning(true); isNotRepeate = false } else { setWarning(false) }
            })

            //6-if no repeate => update
            if (isNotRepeate) {
                loader(true)
                await axios.put(`${api}/app`, { allocation_call: Year, next: id, options: options, comment, auto_second_allocation: autoSecondAllocation }, { withCredentials: true })
                    .then((res) => { toggle(); loader(false); refresh() })
                    .catch((e) => { loader(false) })
            }
        }
    }

    //remove selected option
    const removeSingleDiscipline = (index) => { const arr = [...discipline]; arr[index - 1].discipline_id = ''; setDiscipline(arr); }

    const handleDiscriptionChange = () => {
        const disciplineWithDiscription = []
        discipline.map(item => {
            try {
                if (item.discipline_id.trim() != '') {
                    if (disciplinesList.filter(disciplineItem => disciplineItem.discipline._id == item.discipline_id).length > 0) {
                        disciplineWithDiscription.push(disciplinesList.filter(disciplineItem => disciplineItem.discipline._id == item.discipline_id)[0])
                    }
                }
            } catch (e) { console.log(e); }
        })
        setDisciplineDescription(disciplineWithDiscription);
    }

    const retrivePreviouseChoiceToModal = () => {
        //Get Desciplines from APP
        if (props.modal) getDiscipline(); setWarning(false);
        // set previouse selection
        if (props.modify) {
            let year = props.info?.app?.allocation_call._id;
            let options = props.info?.app?.options;
            setAutoSecondAllocation(props.info?.app?.auto_second_allocation == null ? false : props.info?.app?.auto_second_allocation)
            setYear(year);

            //put previous desciplines on modal
            let arr = [];
            options.map(item => { arr = [...arr, { discipline_id: item.discipline_id._id, discipline_seq: item.discipline_seq }] })
            setDiscipline(arr);
            handleYearChange(year);
            setComment('')

            //when we close modal than open it dont allow reset disciplines for one time
            if (props.modal == false) { setResetWhenModify(false); }
        }
    }

    // get discipline based on his major once page loaded, run one time only
    useEffect(() => { if (!runOneTime) { getDiscipline(); setRunOneTime(true); } }, [disciplinesList])

    //reset previouse selected Disciplines when modal open 
    useEffect(() => { retrivePreviouseChoiceToModal(); }, [props.modal])

    useEffect(() => { handleDiscriptionChange(); }, [discipline])

    return (
        <div>
            <Modal size="lg" style={{ maxWidth: '700px', width: '100%' }} isOpen={props.modal} toggle={() => { toggle(props) }}>

                <ModalHeader toggle={() => { toggle(props) }} onClick={() => { console.log(autoSecondAllocation) }}>Application</ModalHeader>
                <ModalBody>
                    <div className="row">
                        {props.step >= 8 ? null : <> <div className="col-md-12">
                            <div className="alert alert-success">
                                <ul>
                                    <li>please Note that <b>option 1</b> is your most priority</li>
                                </ul>
                            </div>
                        </div>
                            {props.hasApp ? <div className="col-md-12"><div className="alert alert-warning">
                                <b>Comment :</b> {props.info?.app?.comment}</div></div> : null}
                            <div className="col-md-12">
                                <select onChange={(e) => { handleYearChange(e.target.value) }} value={Year} className="form-control">
                                    <option value="">Select Call Year</option>
                                    {EnabledYears.map((item, i) => {
                                        return <option key={`${i}year`} value={item._id}>{item.year}</option>
                                    })}
                                </select>
                            </div>
                            {disciplineByYear.length == 0 ?
                                <div className="p-3">
                                    {Year != '' ? <>there is no disciplines on this year related to your major.</> :
                                        <>Please Select a year</>
                                    }</div> : <>
                                    <div className="col-md-4">
                                        <button className="btn btn-warning" onClick={() => { removeSingleDiscipline(1) }}>option 1 </button>
                                        <Dropdown className="m-1"
                                            placeholder='--Select Discipline--'
                                            fluid
                                            search
                                            clearable
                                            selection
                                            value={discipline[0]?.discipline_id}
                                            options={disciplineByYear ? disciplineByYear.map((item, i) => { return { key: i, text: item.discipline.name, value: item.discipline._id } }) : []}
                                            onChange={(e, d) => { handleDisciplineChange(0, d.value); }}
                                        /></div>

                                    {disciplineByYear.length >= 2 ? <div className="col-md-4">
                                        <button className="btn btn-warning" onClick={() => { removeSingleDiscipline(2) }}>option 2 :</button>
                                        <Dropdown className="m-1"
                                            placeholder='--Select Discipline--'
                                            fluid
                                            search
                                            clearable
                                            selection
                                            value={discipline[1]?.discipline_id}
                                            options={disciplineByYear ? disciplineByYear.map((item, i) => { return { key: i, text: item.discipline.name, value: item.discipline._id } }) : []}
                                            onChange={(e, d) => { handleDisciplineChange(1, d.value); }}
                                        /></div> : null}
                                    {disciplineByYear.length >= 3 ? <div className="col-md-4">
                                        <button className="btn btn-warning" onClick={() => { removeSingleDiscipline(3) }}>option 3 :</button>
                                        <Dropdown className="m-1"
                                            placeholder='--Select Discipline--'
                                            fluid
                                            search
                                            clearable
                                            selection
                                            value={discipline[2]?.discipline_id}
                                            options={disciplineByYear ? disciplineByYear.map((item, i) => { return { key: i, text: item.discipline.name, value: item.discipline._id } }) : []}
                                            onChange={(e, d) => { handleDisciplineChange(2, d.value); }}
                                        /></div> : null}
                                </>}
                        </>}
                        {props.modify ? <div className="col-md-12">
                            <textarea className="form-control w-100" value={comment} placeholder={"comment ... (optional)"} onChange={(e) => { setComment(e.target.value) }} rows="5"></textarea></div> : null}

                        {props.step >= 8 ? null : <> <div className="col-md-12">
                            <div className="alert warning">
                                <input type="checkbox" checked={autoSecondAllocation} onChange={() => { setAutoSecondAllocation(!autoSecondAllocation) }} /> if i didn't pass in first allocation, automatically sign me in next allocation.
                            </div>
                        </div>

                            {disciplineDescription.length > 0 ? <div className='alert alert-info' style={{ fontSize: '11px' }}>
                                <ul>
                                    {disciplineDescription.map((item, index) => { return <li key={`Description-${index}`}><b>{item.discipline.name}</b ><br />{item.discipline.Description}{index < 2 ? <hr /> : null}</li> })}
                                </ul>
                            </div> : null}

                            {warning ? <div className="col-md-12 alert alert-warning ">
                                {messege}</div> : null}
                        </>}
                    </div>
                </ModalBody>
                <ModalFooter> <div className="row">
                    {!props.modify ? <>
                        <div className="col-sm-4 p-2">
                            <Button color="success" onClick={() => { save(2) }}>Send for Review by AFP</Button>
                        </div>
                        <div className="col-sm-4 p-2">
                        </div>
                        <div className="col-sm-4 p-2">
                            <Button color="warning" onClick={() => { toggle(props) }}>Cancel</Button>
                        </div></>
                        :
                        props.info?.app.workflow_id?.next_workflow?.map((item, i) => {
                            return (
                                <div key={i} className="col-md-4">
                                    <button className={`btn btn-${item.id.color}`} onClick={() => { updateApp(item.id._id) }}>{item.id.name}</button>
                                </div>
                            )
                        })}

                </div>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default ModalNewApp
