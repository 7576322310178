import React from 'react'
import './components_style/loader.css';
function Loader() {
    return (
        <div className="loader-container">
            <div className="loader-wrapper">
                <div className="loader-circle"></div>
                <div className="loader-circle"></div>
                <div className="loader-circle"></div>
                <div className="loader-shadow"></div>
                <div className="loader-shadow"></div>
                <div className="loader-shadow"></div>
                <span>Loading</span>
            </div>
        </div>
    )
}

export default Loader
