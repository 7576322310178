import React from 'react'

function Section_4({ isEng }) {
    return (
        <section className="mini" id="work-process">
            <div className="mini-content">
                <div className="container">
                    <div className="row">
                        <div className="offset-lg-3 col-lg-6">
                            <div className="info">
                                <h1>{isEng ? 'workflow' : 'آلية عمل البرنامج'}</h1>
                                <p></p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 pointer">
                            <a className="mini-box">
                                <div className="row">
                                    <div className="col-10 pb-3" >
                                        {isEng ?
                                            <p>Interested and eligible students shall register with the respective University/College placement office.</p>
                                            :
                                            <p className='arabic'>يجب على الطلاب المهتمين والمؤهلين التسجيل في مكتب الالتحاق بالجامعة / الكلية .
                                            </p>
                                        }
                                    </div>
                                    <div className="col-2">
                                        <i className="material-icons" >1</i>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="col-lg-6 pointer">
                            <a className="mini-box">
                                <div className="row">
                                    <div className="col-10 pb-3" >
                                        {isEng ?
                                            <p>All application will undergo a thorough screening, considering the set criteria of the College/University and host Industry/Company</p>
                                            :
                                            <p className='arabic'>
                                                سيخضع جميع الطلبات لإختبار، مع مراعاة المعايير المحددة للكلية / الجامعة والشركة المضيفة
                                            </p>
                                        }
                                    </div>
                                    <div className="col-2">
                                        <i className="material-icons" >2</i>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-6 pointer">
                            <a className="mini-box">
                                <div className="row">
                                    <div className="col-10 pb-3" >
                                        {isEng ?
                                            <p>Successful applicant will be notified to sit for the online assessment test.</p>
                                            :
                                            <p className='arabic'>
                                                سيتم إخطار المتقدم الناجح بالجلوس لاختبار التقييم عبر الإنترنت.
                                            </p>
                                        }
                                    </div>
                                    <div className="col-2">
                                        <i className="material-icons" >3</i>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="col-lg-6 pointer">
                            <a className="mini-box">
                                <div className="row">
                                    <div className="col-10 pb-3" >
                                        {isEng ?
                                            <p>Applicants who PASS the assessment will be selected and notified shortly.</p>
                                            :
                                            <p className='arabic'>
                                                سيتم اختيار المتقدمين الذين اجتازوا التقييم وإخطارهم .
                                            </p>
                                        }
                                    </div>
                                    <div className="col-2">
                                        <i className="material-icons" >4</i>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="col-lg-6 pointer">
                            <a className="mini-box">
                                <div className="row">
                                    <div className="col-10 pb-3" >
                                        {isEng ?
                                            <p>Students will be initially allocated to the industry and shall be notified to complete industry entry procedures.
                                                Please note that some companies may request applicants to undergo an interview and based on the results, FINAL Allocation will take place.</p>
                                            :
                                            <p className='arabic'>
                                                سيتم تخصيص الطلاب في البداية للمكان الذي تأهل له وسيتم إخطارهم لاستكمال إجراءات.
                                                يرجى ملاحظة أن بعض الشركات قد تطلب من المتقدمين الخضوع لمقابلة وبناءً على النتائج ، سيتم إجراء التخصيص النهائي.

                                            </p>
                                        }
                                    </div>
                                    <div className="col-2">
                                        <i className="material-icons" >5</i>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="col-lg-6 pointer">
                            <a className="mini-box">
                                <div className="row">
                                    <div className="col-10 pb-3" >
                                        {isEng ?
                                            <p>FINAL Allocation and starting date will be announced to all students.</p>
                                            :
                                            <p className='arabic'>
                                                سيتم الإعلان عن التخصيص النهائي وتاريخ البدء لجميع الطلاب.
                                            </p>
                                        }
                                    </div>
                                    <div className="col-2">
                                        <i className="material-icons" >6</i>
                                    </div>
                                </div>
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Section_4