import React, { useState } from 'react'
import { path } from '../../util/constant'
function Nav({ isEng, handleLanguage }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    return (
        <header className="header-area header-sticky">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <nav className="main-nav position-relative">
                            <a href="#" className="logo">
                                <img src="assets/images/logo.png" alt="true" style={{ height: 40 }} />
                            </a>
                            <ul className="nav">
                                <MenuContent isEng={isEng} handleLanguage={handleLanguage} />
                            </ul>
                            <a className="menu-trigger " onClick={() => setIsMenuOpen(prev => !prev)} >
                                <span>Menu</span>
                            </a>
                        </nav>
                    </div>
                </div>
                {isMenuOpen ?
                    <MobileMenu isEng={isEng} handleLanguage={handleLanguage} closeMenue={() => { setIsMenuOpen(false) }} />
                    : null}
            </div>
        </header>
    )
}

const MobileMenu = ({ isEng, handleLanguage, closeMenu }) => {
    return (
        <ul className='position-absulote mt-1 rounded' onClick={closeMenu} style={{ backgroundColor: 'white' }}>
            <MenuContent isEng={isEng} handleLanguage={handleLanguage} />
        </ul>
    )
}

const MenuContent = ({ isEng, handleLanguage }) => {
    return (
        <>
            <li><a href="#welcome">{isEng ? 'Home' : 'الرئيسية'}</a></li>
            <li><a href="#features">{isEng ? 'About' : 'عن البرنامج'}</a></li>
            <li><a href="#timeline">{isEng ? 'Timeline' : 'الجدول الزمني'}</a></li>
            <li><a href="#work-process">{isEng ? 'Work Process' : 'الخطوات'}</a></li>
            <li><a href="#testimonials">{isEng ? 'News' : 'الاخبار'}</a></li>
            <li><a href="#blog">{isEng ? 'Vision' : 'الرؤية'}</a></li>
            <li><a href="#contact-us">{isEng ? 'Contact Us' : 'تواصل معنا'}</a></li>
            <li><a href={`${path}/login`}>{isEng ? 'login' : 'تسجيل دخول'}</a></li>
            <li><a href="#" className="active" onClick={handleLanguage}>{isEng ? 'عربي' : 'English'}</a></li>

        </>
    )

}

export default Nav