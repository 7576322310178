import React, { useState, useEffect, useContext } from 'react'
import { isArr, identifyRole } from '../../../../util/functions'
import JobtaskModal from './jobtaskModal'
import { IoCheckmarkCircleOutline, IoTimeOutline, IoPencil } from "react-icons/io5";
import { Cxt } from '../../../../App';

function Competencies(props) {
    const { role } = useContext(Cxt);
    const { isStudent, isSV } = identifyRole(role);

    const [modal, setModal] = useState(false)
    const [competancyDetails, setCompetancyDetails] = useState({})
    const [jobtaskInfo, setJobtaskInfo] = useState({})
    const [jobtaskSubmissionInfo, setJobtaskSubmissionInfo] = useState({})
    const openJobtaskModal = (jobtaskDetails, jobtaskSubmission) => { setJobtaskSubmissionInfo(jobtaskSubmission); setJobtaskInfo(jobtaskDetails); setModal(true) }
    useEffect(() => { if (!modal) setJobtaskInfo({}) }, [modal])
    const checkIsCompetencyComplete = (id) => {
        const isCompetencyComplete = isArr(props?.submission?.competencies)?.filter(item => item.competency_id == id && item.is_complete == true).length != 0 ? true : false
        return isCompetencyComplete
    }

    const checkIfCompetancyWaitingFromSupervisor = (id) => {
        const competancy = isArr(props?.submission?.competencies)?.filter(item => item.competency_id == id)
        if (competancy.length == 0 || !isSV) return false
        const jobtasksThatHavePendingActionFromSv = isArr(competancy[0].job_tasks).filter(jobtask => jobtask.comment_by_student.is_allow_edit == false && !jobtask.is_complete)
        if (jobtasksThatHavePendingActionFromSv.length == 0) return false
        else return true
    }

    const checkIfCompetancyWaitingActionStudent = (id) => {
        const competancy = isArr(props?.submission?.competencies)?.filter(item => item.competency_id == id)
        if (competancy.length == 0 || !isStudent) return false
        const jobtasksThatHavePendingActionFromSv = isArr(competancy[0].job_tasks).filter(jobtask => jobtask.comment_by_student.is_allow_edit == true && !jobtask.is_complete)
        if (jobtasksThatHavePendingActionFromSv.length == 0) return false
        else return true
    }

    return (<>
        <JobtaskModal
            fdf_id={props?.fdf_id}
            loader={status => props.loader(status)}
            refresh={() => props.refresh()}
            setModal={status => setModal(status)}
            modal={modal}
            submission={jobtaskSubmissionInfo}
            info={jobtaskInfo}
            submission_id={props?.submission?._id}
            isFdfSubmissionCompleted={props?.submission?.is_complete == true}
        />
        <div className="col-sm-12 mt-3 fw-bold">Competencies:</div>
        {
            isArr(props.details).length == 0 ? <div className="ps-3 pe-3 mt-2"><div className="alert alert-success mb-0">there is no Competencies.</div></div> :
                isArr(props.details)?.map((item, index) => {
                    const isCompetencyComplete = checkIsCompetencyComplete(item?._id)
                    const isThisCompetencySelected = competancyDetails?._id == item?._id
                    const isCompetancyHasSubmissionForSupervisor = checkIfCompetancyWaitingFromSupervisor(item?._id)
                    const isCompetancyHasSubmissionForStudent = checkIfCompetancyWaitingActionStudent(item?._id)
                    return (
                        <div className={`col-md-${isThisCompetencySelected ? '12' : '3'}`} key={`competency-${index}`} onClick={() => console.log({ item, submission: props.submission })}>
                            <div className={`p-2  ${isThisCompetencySelected ? '' : 'w-100'} btn`}  >
                                <div className={`card-info smooth-box-shadow border rounded bg-white60 p-3 ${isThisCompetencySelected ? 'bg-warning text-light' : isCompetencyComplete ? 'bg-success' : 'bg-white60'}`} onClick={() => setCompetancyDetails(prev => prev?._id === item?._id ? {} : item)}>
                                    <p className="h6 text-start">
                                        <div className="p-1 text-center fw-bold mb-1 position-relative" >
                                            {isCompetancyHasSubmissionForSupervisor || isCompetancyHasSubmissionForStudent ?
                                                <div className="position-absolute bg-success" style={{ borderRadius: '50%', width: '15px', height: '15px', top: '-20px', left: '-20px' }}>

                                                </div> : null}
                                            Competancy {index + 1} ({item?.title})</div>
                                        <div className="m-1">{isCompetencyComplete ? <><IoCheckmarkCircleOutline /> Complete</> : <><IoTimeOutline /> In Progress</>} </div>
                                    </p>
                                </div>
                            </div>
                            {isThisCompetencySelected ? <CompetancyChildDetailsComponent submission_id={props?.submission?._id} loader={status => props.loader(status)} role={props.role} submission={props.submission} details={item} openModal={(jobtaskDetails, jobtaskSubmission) => openJobtaskModal(jobtaskDetails, jobtaskSubmission)} refresh={() => props.refresh()} /> : null}
                        </div>
                    )
                })
        }
    </>
    )
}


const CompetancyChildDetailsComponent = (props) => {

    const details = props.details
    const submission = props.submission

    const checkIfJobTaskSubmitted = (competencyId, jobTaskId) => {
        //check if student has submitted anything in this competency => if not exit with {obj:false}
        let submitiedCompetency = submission?.competencies?.filter(item => item.competency_id == competencyId)
        if (isArr(submitiedCompetency)?.length == 0) return { isJobTaskSubmitted: false, isAllowStudentUpdateComment: true, isStudentSubmitted: false, isSupervisorSubmitted: false, submitiedJobTask: {} }

        //check if student has submitted this jobtask => if not exit with {obj:false}
        let submitiedJobTask = submitiedCompetency[0]?.job_tasks?.filter(item => item.job_task_id == jobTaskId)
        if (isArr(submitiedJobTask)?.length == 0) return { isJobTaskSubmitted: false, isAllowStudentUpdateComment: true, isStudentSubmitted: false, isSupervisorSubmitted: false, submitiedJobTask: {} }

        //jobtask is submitted => get this job task status
        submitiedJobTask = isArr(submitiedJobTask).length != 0 ? submitiedJobTask[0] : {};
        const isAllowStudentUpdateComment = submitiedJobTask?.comment_by_student?.is_allow_edit ? true : false;
        const isStudentSubmitted = Object.keys(submitiedJobTask)?.length === 0 ? false : true
        const isSupervisorSubmitted = submitiedJobTask?.comment_by_supervisor?.text ? true : false
        const isJobTaskSubmitted = submitiedJobTask?.is_complete ? true : false

        return { isJobTaskSubmitted, isAllowStudentUpdateComment, isStudentSubmitted, isSupervisorSubmitted, submitiedJobTask }
    }


    return (<>
        <div className="col-ms-12 mt-2 card-info  smooth-box-shadow bg-white60">
            <div className="row p-3">
                <div className="col-lg-12 mb-1"><b>Title :  </b>{details?.title}</div>
                <div className="col-lg-12 mb-1"><b>Description :  </b>{details?.description}</div>
                <div className="col-lg-12 mb-1"><b>Level :  </b>{details?.level}</div>
                <div className="col-lg-12 mb-1"><b>Job Tasks : </b></div>
                {details?.job_tasks?.map((item, index) => {
                    const { isJobTaskSubmitted, isAllowStudentUpdateComment, isStudentSubmitted, isSupervisorSubmitted, submitiedJobTask } = checkIfJobTaskSubmitted(details._id, item?.job_task_id)
                    const jobTaskStatus = { isJobTaskSubmitted, isAllowStudentUpdateComment, isStudentSubmitted, isSupervisorSubmitted, submitiedJobTask, competencyId: details._id }
                    return (
                        <div className="col-md-3 p-2 btn " key={`jobtask-${index}`}>
                            <div className={`card-info smooth-box-shadow rounded p-3 border ${isJobTaskSubmitted ? 'bg-success' : 'btn-light '}`} onClick={() => props.openModal(item, jobTaskStatus)} >
                                <p className="h6 w-100 text-start">
                                    <div className="p-1 text-center fw-bold mb-1 ">Task {index + 1} ({item?.title})</div>
                                    <div className="m-1"><p className={isStudentSubmitted ? `bg-${isAllowStudentUpdateComment ? 'warning' : 'success'} text-light p-1 rounded border` : ''}>{isStudentSubmitted ? isAllowStudentUpdateComment ? <IoPencil /> : <IoCheckmarkCircleOutline /> : <IoTimeOutline />} student </p></div>
                                    <div className="m-1"><p className={isSupervisorSubmitted ? 'bg-success p-1 rounded border' : ''}>{isSupervisorSubmitted ? <IoCheckmarkCircleOutline /> : <IoTimeOutline />} supervisor </p></div>
                                </p>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    </>)
}


export default Competencies