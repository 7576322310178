import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { api } from '../../../../util/constant'
import { isArr } from '../../../../util/functions'
import FdfModal from './fdfModal'
import CoursesModal from '../disciplines/coursesModal'
import { IoFileTrayFullOutline } from "react-icons/io5";
import HistoryModal from '../historyModal'
function Fdfs(props) {

    const [fdfs, setFdfs] = useState([])
    //fdf modal states
    const [fdfModal, setFdfModal] = useState(false)
    const [title, setTitle] = useState('')
    const [technicalAuths, setTechnicalAuths] = useState([])
    const [selectedTechnicalAuths, setSelectedTechnicalAuths] = useState([])
    const [superVisors, setSuperVisors] = useState([])
    const [selectedSuperVisors, setSelectedSuperVisors] = useState([])
    const [learningNeeds, setLearningNeeds] = useState([])
    const [competencies, setCompetencies] = useState([])
    const [fdfId, setFdfId] = useState('')
    //courses modal states
    const [coursesModal, setCoursesModal] = useState(false)
    const [courses, setCourses] = useState([])

    //Competencies
    const handleAddNewCompetencies = () => {
        const obj = { title: '', level: '', description: '', job_tasks: [] }
        let arr = [...competencies, obj];
        setCompetencies(arr);
    }
    const handleRemoveCompetencies = (index) => {
        let arr = [...competencies];
        arr.splice(index, 1);
        setCompetencies(arr);
    }
    const handleEditCompetencies = (e, index) => {
        let arr = [...competencies];
        arr[index][e.target.name] = e.target.value;
        setCompetencies(arr);
    }
    const handleChangeCompetencySequence = (competency_index, toIndex) => {
        if (toIndex >= 0) {
            let arrCompetencies = [...competencies]
            const element = arrCompetencies.splice(competency_index, 1)[0];
            arrCompetencies.splice(toIndex, 0, element);
            setCompetencies(arrCompetencies)
        }
    }

    //job task
    const handleAddNewJobTask = (competencies_index) => {
        const jobTask = { job_task_id: '', title: '', description: '', activities: [] }
        let arrCompetencies = [...competencies]
        arrCompetencies[competencies_index].job_tasks = [...competencies[competencies_index].job_tasks, jobTask]
        setCompetencies(arrCompetencies)
    }

    const handleEditJobTask = (e, jobTask_Index, competency_index) => {
        let arrCompetencies = [...competencies]
        arrCompetencies[competency_index].job_tasks[jobTask_Index][e.target.name] = e.target.value;
        setCompetencies(arrCompetencies);
    }

    const handleRemoveJobTask = (jobTask_Index, competency_index) => {
        let arrCompetencies = [...competencies]
        arrCompetencies[competency_index].job_tasks.splice(jobTask_Index, 1);
        setCompetencies(arrCompetencies);
    }

    //activities
    const handleAddActivity = (jobTask_Index, competency_index) => {
        let arrCompetencies = [...competencies]
        arrCompetencies[competency_index].job_tasks[jobTask_Index].activities = [...competencies[competency_index].job_tasks[jobTask_Index].activities, '']
        setCompetencies(arrCompetencies)
    }
    const handleEditActivitiy = (e, jobTask_Index, competency_index, activitiy_index) => {
        let arrCompetencies = [...competencies]
        arrCompetencies[competency_index].job_tasks[jobTask_Index].activities[activitiy_index] = e.target.value
        setCompetencies(arrCompetencies)
    }
    const handleRemoveActivitiy = (jobTask_Index, competency_index, activitiy_index) => {
        let arrCompetencies = [...competencies]
        arrCompetencies[competency_index].job_tasks[jobTask_Index].activities.splice(activitiy_index, 1);
        setCompetencies(arrCompetencies);
    }

    const loader = (status) => { props.loader(status) }
    const getFdfs = () => {
        loader(true)
        axios.get(`${api}/fdf`, { headers: { disciplineId: props?.selectedDiscipline?.id, functionsId: props?.selectedFunction?.id, noDetails: true }, withCredentials: true })
            .then((res) => { loader(false); setFdfs(res?.data); })
            .catch((e) => { loader(false); console.log(e) })
    }

    const getFdf = (id) => {
        loader(true)
        axios.get(`${api}/fdf`, { headers: { fdfId: id }, withCredentials: true })
            .then((res) => {
                loader(false);
                const { title, technicalAuths, supervisors, learning_need, competencies, _id } = res?.data
                setFdfId(_id)
                setTitle(title)
                setSelectedTechnicalAuths(isArr(technicalAuths))
                setSelectedSuperVisors(isArr(supervisors))
                setLearningNeeds(isArr(learning_need))
                setCompetencies(isArr(competencies))
                console.log(res.data);
                openFdfModal()
            })
            .catch((e) => { loader(false); console.log(e) })
    }

    const openFdfModal = () => {
        loader(true)
        axios.get(`${api}/fdf/reviewers`, { headers: { functionId: props?.selectedFunction?.id, disciplineId: props?.selectedDiscipline?.id }, withCredentials: true })
            .then((res) => {
                loader(false);
                const { supervisors, technicalAuths, disciplineCourses } = res?.data;
                setTechnicalAuths(isArr(technicalAuths));
                setSuperVisors(isArr(supervisors));
                setCourses(isArr(disciplineCourses));
                setFdfModal(true);
            })
            .catch((e) => { loader(false); console.log(e) })
    }

    const openCoursesModal = () => {
        loader(true);
        axios.get(`${api}/fdf/courses`, { headers: { discipline: props?.selectedDiscipline?.id, functions: props?.selectedFunction?.id }, withCredentials: true })
            .then((res) => {
                loader(false);
                setCourses(isArr(res?.data));
                setCoursesModal(true);
            })
            .catch((e) => { loader(false); console.log(e) })
    }



    const reset = () => { setFdfId(''); setCourses([]); setTitle(''); setTechnicalAuths([]); setSelectedTechnicalAuths([]); setSuperVisors([]); setSelectedSuperVisors([]); setLearningNeeds([]); setCompetencies([]); }
    useEffect(() => { getFdfs(); }, [])
    useEffect(() => { if (!fdfModal) { reset() } }, [fdfModal])

    return (
        <>

            <CoursesModal
                discipline={props.selectedDiscipline}
                modal={coursesModal}
                setModal={modal => setCoursesModal(modal)}
                loader={loader => props.loader(loader)}
                refresh={() => { openCoursesModal() }}
                courses={courses}
                functions={props.selectedFunction}
            />

            <FdfModal
                fdfId={fdfId}
                modal={fdfModal}
                setModal={modal => setFdfModal(modal)}
                loader={loader => props.loader(loader)}
                refresh={() => { getFdfs() }}
                title={title}
                setTitle={title => setTitle(title)}
                technicalAuths={technicalAuths}
                selectedTechnicalAuths={selectedTechnicalAuths}
                setSelectedTechnicalAuths={selectedTechnicalAuths => setSelectedTechnicalAuths(selectedTechnicalAuths)}
                superVisors={superVisors}
                selectedSuperVisors={selectedSuperVisors}
                setSelectedSuperVisors={selectedSuperVisors => setSelectedSuperVisors(selectedSuperVisors)}
                learningNeeds={learningNeeds}
                setLearningNeeds={learningNeeds => setLearningNeeds(learningNeeds)}
                competencies={competencies}
                courses={courses}
                setCompetencies={competencies => setCompetencies(competencies)}
                discipline={props.selectedDiscipline}
                functions={props.selectedFunction}
                handleAddNewCompetencies={() => handleAddNewCompetencies()}
                handleRemoveCompetencies={index => handleRemoveCompetencies(index)}
                handleChangeCompetencySequence={(competency_index, toIndex) => handleChangeCompetencySequence(competency_index, toIndex)}
                handleEditCompetencies={(name, index) => handleEditCompetencies(name, index)}
                handleAddNewJobTask={competencies_index => handleAddNewJobTask(competencies_index)}
                handleEditJobTask={(e, jobTask_Index, competency_index) => handleEditJobTask(e, jobTask_Index, competency_index)}
                handleRemoveJobTask={(jobTask_Index, competency_index) => handleRemoveJobTask(jobTask_Index, competency_index)}
                handleAddActivity={(jobTask_Index, competency_index) => handleAddActivity(jobTask_Index, competency_index)}
                handleEditActivitiy={(e, jobTask_Index, competency_index, activitiy_index) => handleEditActivitiy(e, jobTask_Index, competency_index, activitiy_index)}
                handleRemoveActivitiy={(jobTask_Index, competency_index, activitiy_index) => handleRemoveActivitiy(jobTask_Index, competency_index, activitiy_index)}

            />
            <div className='p-2'>
                {props.AllowToAddNew ? <><button className="btn btn-success float-end me-3" onClick={() => { openFdfModal() }}>+</button><button className="btn btn-success float-end me-3" onClick={() => { openCoursesModal() }}>Courses</button></> : null}
            </div>
            <div className='p-2'>{props.isBack ? <>
                <button className="btn btn-light border" style={{ fontSize: '10px' }} onClick={() => { props.setSelectedDiscipline({ name: '', id: '' }) }}>&larr; back | <span className="text-success">{`${props.selectedFunction.name}`}</span> <b>&#xab;</b> <span className="text-success"> {`${props.selectedDiscipline.name}`}</span></button></> : null}
            </div>
            <p className="h6  text-center p-2 shadow bg-white60"><span className='fw-bold'>Frameworks </span>{props?.selectedDiscipline?.name ? <>({props?.selectedDiscipline?.name})</> : null}</p>
            {!fdfs.length ?
                <div className="div-center-container" ><div className=" p-3 h6 div-center alert alert-warning text-center">{props.noSubmissionsMessege ? props.noSubmissionsMessege : 'there is no fundimantal devlopment frameworks.'}</div></div>
                : <>
                    <div className="fdf-main row p-4">
                        {fdfs.map((item, index) => {
                            return (
                                <div className="col-md-12 mb-3 position-relative" key={index}>
                                    {props?.AllowToEdit ? <button className="btn btn-light position-absolute p-1 more-index end-0 top-0 shadow" onClick={() => { getFdf(item._id) }}><img src="/images/edit.svg" style={{ width: '20px' }} /></button> : null}
                                    <div className="shadow pointer bg-white60 border p-3 " onClick={() => { props.setSelectedFdf({ name: item.title, id: item._id }) }}>
                                        <p className="h6 fw-bold pe-3">
                                            {index + 1}. {item?.title} ({item?.students} Student)
                                        </p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                </>}
        </>
    )
}

export default Fdfs