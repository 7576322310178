import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';
import { api } from '../../util/constant'
import { isArr } from '../../util/functions';
import muiTable from '../../util/mui-table'
import { IoExpandOutline, IoReloadOutline } from "react-icons/io5";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { IoSearch } from "react-icons/io5";
import { TableFooter, TableCell, TableRow } from "@material-ui/core";
import MUIDataTable from "mui-datatables";

function CloseOpenRegModal(props) {

    const toggle = () => (props.changePerentToggle(!props.modal));
    const loaderToggle = (loader) => (props.changeLoader(loader));

    const [mailLogs, setMailLogs] = useState([])
    const [theme, setTheme] = useState(false)
    const [isSearch, setIsSearch] = useState(false)
    const [tablePageNumber, setTablePageNumber] = useState(1)
    const [tableRowsLimit, setTableRowsLimit] = useState(10)
    const [tableTotalPages, setTotalPages] = useState(0)
    const [tableTotalRows, setTableTotalRows] = useState(0)
    const [emailSearch, setEmailSearch] = useState('')
    const [IsSuccessStatus, setIsSuccessStatus] = useState(true)
    const [isIgnoreStatus, setisIgnoreStatus] = useState(true)


    const getMailLogs = () => {
        loaderToggle(true)
        let search = {}
        if (isSearch) {
            if (emailSearch.trim() != '') search = { ...search, email: emailSearch }
            if (!isIgnoreStatus) search = { ...search, status: IsSuccessStatus }
        }
        axios.get(`${api}/setting/mail/logs`, { headers: { search: JSON.stringify(search), page: tablePageNumber, limit: tableRowsLimit }, withCredentials: true })
            .then((res) => {
                const { totalMailLogs, mailLogs, totalPages } = res?.data
                setTotalPages(totalPages);
                setTableTotalRows(totalMailLogs)
                setMailLogs(isArr(mailLogs)); loaderToggle(false)
            }).catch((e) => { console.log(e); loaderToggle(false) })
    }

    const reSendEmail = (log_id) => {
        loaderToggle(true)
        axios.post(`${api}/setting/mail/logs`, { log_id }, { withCredentials: true })
            .then((res) => { getMailLogs() })
            .catch((e) => { console.log(e); loaderToggle(false) })
    }

    const columns = [{
        label: "Time",
        options: {
            filter: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                return (new Date(tableMeta.rowData[tableMeta.columnIndex]).toLocaleString())
            }
        }
    }, "Email", "Subject", {
        label: "status",
        options: {
            filter: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                const isSuccess = tableMeta.rowData[tableMeta.columnIndex]
                return (
                    <button className={`btn ${isSuccess ? 'btn-success' : 'btn-danger'}`} onClick={e => reSendEmail(tableMeta.rowData[5])} disabled={isSuccess}>{isSuccess ? 'Success' : <><IoReloadOutline />Failed</>}</button>
                )
            }
        }
    }, "Response", {
        label: "",
        options: {
            display: false,
        }
    }];

    let options = muiTable.muiOptions(theme)

    options = {
        ...options,
        rowsPerPage: 100,
        search: false,
        serverSide: true,
        customFooter: () => {
            return (
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={6}>
                            page {tablePageNumber} of {tableTotalPages} - total emails {tableTotalRows}
                            <button className="btn btn-light" disabled={tablePageNumber == 1 ? true : false} onClick={() => setTablePageNumber(prev => prev - 1)}>{`<`}</button>
                            <button className="btn btn-light" disabled={tablePageNumber == tableTotalPages || tableTotalPages == 0 ? true : false} onClick={() => setTablePageNumber(prev => prev + 1)}>{`>`}</button>
                            jump to <select onChange={e => setTablePageNumber(+e.target.value)}>{[...Array(tableTotalPages)].map((item, i) => <option value={i + 1} key={i}>{i + 1}</option>)}</select>
                            per page <select onChange={e => setTableRowsLimit(+e.target.value)}>{[...Array(10)].map((item, i) => <option value={(i + 1) * 10} key={i}>{(i + 1) * 10}</option>)}</select>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            );
        }
    }

    useEffect(() => { if (props.modal) getMailLogs() }, [tablePageNumber, tableRowsLimit, isSearch])
    useEffect(() => { setTablePageNumber(1) }, [tableRowsLimit])
    useEffect(() => { if (props.modal) getMailLogs() }, [])

    return (
        <Modal size="xl" style={{ width: '100%' }} isOpen={props.modal} toggle={toggle}>
            <ModalHeader>Email Logs</ModalHeader>
            <ModalBody>
                <div className="p-4">
                    {!isSearch ? <button className="btn btn-warning mb-1" onClick={() => setIsSearch(prev => !prev)}>search <IoSearch /></button>
                        :
                        <div className="row">
                            <div className="col-sm-6"><input className='form-control' placeholder='search' onChange={e => setEmailSearch(e.target.value)} /></div>
                            <div className="col-sm-4">
                                <input type="radio" onClick={() => setisIgnoreStatus(true)} checked={isIgnoreStatus == true} /> all <br />
                                <input type="radio" onClick={() => { setIsSuccessStatus(true); setisIgnoreStatus(false) }} checked={isIgnoreStatus == false && IsSuccessStatus == true} /> success<br />
                                <input type="radio" onClick={() => { setIsSuccessStatus(false); setisIgnoreStatus(false) }} checked={isIgnoreStatus == false && IsSuccessStatus == false} /> failed
                            </div>
                            <div className="col-sm-1"><button className="btn btn-warning mb-1" onClick={() => { getMailLogs(); setTablePageNumber(1) }}><IoSearch /></button></div>
                            <div className="col-sm-1"><button className="btn btn-danger mb-1" onClick={() => setIsSearch(prev => !prev)}>X</button></div>
                        </div>
                    }
                    <MuiThemeProvider theme={theme ? muiTable.getMuiTheme() : muiTable.noTheme()}>
                        <MUIDataTable
                            title={<>
                                <button className="btn btn-light shadow" disabled >Email Logs</button>
                                <button onClick={() => { setTheme(!theme); }} className="btn btn-light shadow"><IoExpandOutline /></button>
                            </>}
                            data={mailLogs.map(item => { return [item?.createdAt, item?.email, item?.subject, item?.status, item?.response, item._id] })}
                            columns={columns}
                            options={options}
                        />
                    </MuiThemeProvider>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="row">
                    <div className="col-sm-12"><Button color="warning float-start" onClick={toggle}>Cancel</Button></div>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default CloseOpenRegModal