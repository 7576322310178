import React, { useState, useContext } from 'react'
import { Cxt } from '../App'
import { useLocation } from 'react-router-dom';
import './components_style/location.css'
import { IoReturnDownForwardOutline } from "react-icons/io5";

function Location(props) {
    const location = useLocation();
    const { pageWidth } = useContext(Cxt);
    return (
        <>
            <div className="path">
                <span className={`text-nowrap ${pageWidth >= 700 ? 'h5' : 'h6'}`}>
                    <IoReturnDownForwardOutline />{` `}
                    {props?.name ? props?.name.toUpperCase() : location.pathname.replace('/', '').toUpperCase().replace('PLATFORM/', '')}
                </span>
            </div>
        </>
    )
}

export default Location
