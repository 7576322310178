import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios'
import Loader from '../loader'
import '../components_style/resetpass.css'
import { api, path } from '../../util/constant'
import { Notify } from 'notiflix/build/notiflix-notify-aio';

function Resetpass(props) {
    const [pass, setPass] = useState('')
    const [rePass, setRePass] = useState('')
    const [messege, setMessege] = useState([])
    const [success, setSuccess] = useState(false)
    const [loader, setLoader] = useState(false)
    const submitPass = async () => {

        if (pass != rePass) return Notify.warning('passwords does not match')
        if (pass.trim() == '') return Notify.warning('passwords cannot be empty')
        if (pass.trim().length < 8) return Notify.warning('password must be 8 characters')

        setLoader(true)
        await axios.patch(`${api}/login`, { token: props.token, password: pass })
            .then((res) => { setLoader(false); setSuccess(true); setMessege(['alert alert-success', 'Password has been reset successfuly !']) })
            .catch((e) => { setLoader(false); setMessege(['alert alert-warning', 'link has expired ! only valid for 30 days']) })

    }

    return (
        <>
            {loader ? < Loader /> : null}
            <div className="resetpass-main">
                <div className="center-div text-center">
                    {success ? <div className={messege[0]} role="alert">{messege[1]}<br /><Link to={`${path}/`}>Login</Link></div> :
                        <>
                            Enter New Password
                            <input type="password" onChange={(e) => { setPass(e.target.value) }} className="form-control" />
                            Re-write password
                            <input type="password" onChange={(e) => { setRePass(e.target.value) }} className="form-control" />
                            <button className="btn btn-light m-3" onClick={submitPass}>Submit</button>
                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default Resetpass
