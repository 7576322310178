import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FileDownload, handleEditObject, isArr } from '../../util/functions'
import { api } from '../../util/constant';
import { Notify } from 'notiflix';
import { confirmAlert } from 'react-confirm-alert';
import Cookies from 'js-cookie';
import { IoCloudDownloadOutline } from 'react-icons/io5';

function ProjectSubmissionModal({ selectedProject, modalToggle, modal, setLoader, projectSubmission, setProjectSubmission, refresh }) {

    const [nextWrokFlow, setNextWrokFlow] = useState([])

    const [isAllowedToEditSubmission, setIsAllowedToEditSubmission] = useState(false)
    const [isWorkflowActionForThisUser, setIsWorkflowActionForThisUser] = useState(false)

    const [proposalFile, setProposalFile] = useState('')
    const [otherFile, setOtherFile] = useState('')

    const getProjectSubmission = () => {
        setLoader(true)
        axios.get(`${api}/projects/submission/${projectSubmission?._id}`, { headers: { projectid: selectedProject?._id }, withCredentials: true })
            .then(res => {
                const { projectSubmission, isWorkflowActionForThisUser, nextWrokFlow, isAllowedToEditSubmission } = res.data
                setNextWrokFlow(isArr(nextWrokFlow))
                setProjectSubmission(projectSubmission)
                setIsAllowedToEditSubmission(isAllowedToEditSubmission)
                setIsWorkflowActionForThisUser(isWorkflowActionForThisUser)
                setLoader(false)
            })
            .catch(e => { console.error({ e }); setLoader(false); })
    }

    const save = ({ nextworkflow }) => {

        let obj = { ...projectSubmission }
        obj = { ...obj, status: nextworkflow }
        if (!projectSubmission?.project) obj = { ...obj, project: selectedProject._id }

        const data = new FormData();
        if (proposalFile != '') data.append('proposalFile', proposalFile);
        if (otherFile != '') data.append('otherFile', otherFile);

        data.append('data', JSON.stringify(obj));

        setLoader(true)
        axios.post(`${api}/projects/submission`, data, { withCredentials: true })
            .then(res => {
                setLoader(false);
                if (res.status == 203) Notify.warning(res.data)
                else {
                    refresh();
                    modalToggle();
                    Notify.success('Saved Successfully')
                }
            })
            .catch(e => { console.error({ e }); setLoader(false) })
    }


    useEffect(() => { if (modal) getProjectSubmission() }, [modal])

    return (<>
        <Modal size="lg" style={{ maxWidth: '700px', width: '100%' }} isOpen={modal} toggle={modalToggle}>
            <ModalHeader onClick={() => console.log()}>Project Submission ({selectedProject?.title})</ModalHeader>
            <ModalBody>
                <p className='mb-4' ><b>Status :</b>{projectSubmission?.status} </p>
                <b>Executive Summary </b>
                <textarea type='text' disabled={!isAllowedToEditSubmission} rows={4} value={projectSubmission?.summary} onChange={e => setProjectSubmission(handleEditObject(e?.target?.value, 'summary', projectSubmission))} className='form-control mb-2' > </textarea>
                <b>Detailed Description of Proposed Solution </b>
                <textarea type='text' disabled={!isAllowedToEditSubmission} rows={4} value={projectSubmission?.description} onChange={e => setProjectSubmission(handleEditObject(e?.target?.value, 'description', projectSubmission))} className='form-control mb-2' > </textarea>
                <b>Detailed Work Description & Methodology. </b>
                <textarea type='text' disabled={!isAllowedToEditSubmission} rows={4} value={projectSubmission?.methodology} onChange={e => setProjectSubmission(handleEditObject(e?.target?.value, 'methodology', projectSubmission))} className='form-control mb-2' > </textarea>
                <b> Attachment (Upload the research proposal document): </b><br />
                {projectSubmission?.proposalFileUrl ? <><button className="btn btn-light shadow mt-3" onClick={() => { FileDownload(`${api}/download/?token=${Cookies.get('token')}&file_name=${projectSubmission?.proposalFileUrl}&type=projects`) }}><IoCloudDownloadOutline />  Download</button><br /></> : null}
                <input type="file" disabled={!isAllowedToEditSubmission} className="mt-4 mb-4" onChange={(e) => setProposalFile(e.target.files[0])} />
                <br />
                <b> Attachment (Other Document): </b><br />
                {projectSubmission?.extraFileUrl ? <><button className="btn btn-light shadow mt-3" onClick={() => { FileDownload(`${api}/download/?token=${Cookies.get('token')}&file_name=${projectSubmission?.extraFileUrl}&type=projects`) }}><IoCloudDownloadOutline />  Download</button><br /></> : null}
                <input type="file" disabled={!isAllowedToEditSubmission} className="mt-4 mb-4" onChange={(e) => setOtherFile(e.target.files[0])} />
            </ModalBody>
            <ModalFooter>

                <div className="w-100">
                    {isWorkflowActionForThisUser ? nextWrokFlow.map((item, index) => <button key={index} className={`btn btn-${item?.color} me-2`} onClick={() => save({ nextworkflow: item?.name })}>{item?.name}</button>) : null}
                    <Button color="warning float-end" onClick={modalToggle}>Cancel</Button>
                </div>
            </ModalFooter>
        </Modal>
    </>
    )
}

export default ProjectSubmissionModal