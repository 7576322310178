import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import axios from 'axios';
import { api } from '../../../../util/constant';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

function CoursesModal(props) {

    const toggle = () => (props.setModal(!props.modal));
    const loader = (status) => { props.loader(status) };
    const refresh = () => { props.refresh() };
    const [isNewCourse, setIsNewCourse] = useState(false);
    const [newCourseName, setNewCourseName] = useState('');
    const [newCourseId, setNewCourseId] = useState('');
    const [editCourseName, setEditCourseName] = useState('');
    const [editCourseId, setEditCourseId] = useState(''); //this is id -not _id-
    const [editId, setEditId] = useState(''); // this is _id

    const addNewCourse = () => {
        if (newCourseName == '' || newCourseName == '') return Notify.failure('please fill all fields.')
        loader(true)
        axios.post(`${api}/fdf/courses`, { name: newCourseName, id: newCourseId, discipline: props.discipline.id, functions: props.functions.id }, { withCredentials: true })
            .then((res) => { refresh(); reset(); Notify.success('Course has been added successfully.'); loader(false) })
            .catch((e) => {
                loader(false);
                Notify.failure(e.response.status == 409 ? e.response.data : 'somthing went wrong!');
            })
    }

    const updateCourse = (_id) => {
        loader(true)
        axios.put(`${api}/fdf/courses`, { _id, id: editCourseId, name: editCourseName }, { withCredentials: true })
            .then((res) => { setEditId(''); refresh(); reset(); Notify.success('Course has been updated successfully.'); loader(false) })
            .catch((e) => { console.log(e); loader(false); Notify.failure(e.response.status == 409 ? e.response.data : 'somthing went wrong!'); })
    }
    const confirmDeleteCourseAlert = (id) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to delete this course ?',
            buttons: [{ label: 'Delete', onClick: () => { deleteCourse(id); console.log(id); } }, { label: 'cancel', onClick: () => { } }]
        });
    }

    const deleteCourse = (id) => {
        loader(true);
        axios.delete(`${api}/fdf/courses`, { headers: { id }, withCredentials: true })
            .then((res) => { refresh(); reset(); Notify.success('Course has been deleted successfully.'); loader(false) })
            .catch((e) => { loader(false); Notify.failure(e.response.status == 409 ? e.response.data : 'somthing went wrong!'); })
    }

    const reset = () => { setNewCourseName(''); setIsNewCourse(false); setNewCourseId(''); setEditId('') }

    useEffect(() => { reset(); }, [props.modal])

    return (
        <Modal size="lg" style={{ maxWidth: '700px', width: '100%' }} isOpen={props.modal} toggle={() => { toggle(props) }}>
            <ModalHeader toggle={() => { toggle(props) }}>Courses ({props?.discipline?.name})</ModalHeader>
            <ModalBody>

                <div><button className="btn btn-success" onClick={() => { setIsNewCourse(prev => !prev) }}>+</button></div>
                {isNewCourse ?
                    <div className="row">
                        <div className="col-sm-5"><input type="text" value={newCourseId} onChange={e => setNewCourseId(e.target.value)} placeholder='Course ID' className="form-control" /></div>
                        <div className="col-sm-5"><input type="text" value={newCourseName} onChange={e => setNewCourseName(e.target.value)} placeholder='Course Name' className="form-control" /></div>
                        <div className="col-sm-2"><button className="btn btn-success" onClick={() => { addNewCourse() }}>Add</button></div>
                    </div>
                    : null}
                <div className="alert alert-warning p-2 m-2">* any edit on existing course will reflect on learning needs submissions. <br />* cannot remove course that has submission.</div>
                {props?.courses?.length != 0 ? <>
                    {props?.courses?.map((item, index) => {
                        return (
                            <div className="row mt-1 mb-1 border rounded text-center bg-white-1" key={index}>
                                <div className="col-sm-1"><button onClick={() => { confirmDeleteCourseAlert(item._id); }} className="btn btn-danger">-</button></div>
                                <div className="col-sm-3">{editId != item?._id ? item?.id : <input type="text" value={editCourseId} onChange={e => setEditCourseId(e.target.value)} placeholder='Course Id' className="form-control" />}</div>
                                <div className="col-sm-6">{editId != item?._id ? item?.name : <input type="text" value={editCourseName} onChange={e => setEditCourseName(e.target.value)} placeholder='Course Name' className="form-control" />}</div>
                                <div className="col-sm-1">{editId != item?._id ? <button onClick={() => { setEditId(item?._id); setEditCourseId(item?.id); setEditCourseName(item?.name) }} className="btn btn-warning">edit</button> : <button onClick={() => updateCourse(item._id)} className="btn btn-success">save</button>}</div>
                            </div>
                        )
                    })}
                </> : <div className="alert alert-warning text-center">there is no courses</div>}

            </ModalBody>

        </Modal>
    )
}

export default CoursesModal