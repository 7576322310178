import React, { useState, useEffect, useContext } from 'react';
import ModalNewApp from './NewAppModal';
import { IoPlayOutline } from "react-icons/io5";
import { Step } from 'semantic-ui-react'
import axios from 'axios';
import { Cxt } from '../../App';
import { FaPenSquare } from "react-icons/fa";
import { isArr } from '../../util/functions';
import { api } from '../../util/constant';
import { CenterFocusStrong } from '@material-ui/icons';

function Student(props) {

    const [info, setInfo] = useState([]);
    const [modal, setModal] = useState(false);
    const [hasApp, setHasApp] = useState(false);
    const [loader, setLoader] = useState(false)
    const [isModify, setIsModify] = useState(false)
    const [isHistory, setIsHistory] = useState(false)
    const [isProfileUpdated, setIsProfileUpdated] = useState(false)
    const [isResponseArrive, setIsResponseArrive] = useState(false)
    const [hasBeenAddedToFdf, setHasBeenAddedToFdf] = useState(false)
    const [page, setPage] = useState(0)
    const [step, setStep] = useState(0)
    const { role } = useContext(Cxt);

    const getApps = async () => {
        setLoader(true);
        const response = await axios.get(`${api}/app`, { withCredentials: true });
        console.log("🚀 ~ response", response)
        setIsResponseArrive(true);
        setHasApp(response?.data?.app != null)
        setHasBeenAddedToFdf(response?.data?.hasBeenAddedToFdf == true)
        setInfo(response?.data);
        setIsProfileUpdated(response?.data?.user?.profileupdate);
        setStep(response?.data?.app?.workflow_id?.step);
        setIsModify(response.data?.app?.workflow_id?.role == role);
        setLoader(false);
    }

    useEffect(() => { getApps(); }, [])
    useEffect(() => { props.loaderToggle(loader); }, [loader])

    return (
        hasBeenAddedToFdf ? <HasFdfAlertMessage /> : <>
            <div className="row">
                <div className="col-lg-12 mb-2 p-0 shadow">
                    <button className={`sub-nav w50 left ${page == 0 ? 'active' : ''}`} onClick={() => { setPage(0) }}>Application</button>
                    <button className={`sub-nav w50 mid ${page == 1 ? 'active' : ''}`} onClick={() => { setPage(1); setIsHistory(true) }}>History</button>
                </div>
            </div>
            {isResponseArrive ? <>
                {!hasApp && isProfileUpdated ? <button className="btn btn-light shadow m-3" onClick={() => { setModal(!modal) }}>New</button> : null}
                {!isProfileUpdated ? <div className="text-center h3">you cant submit new application, please update your profile.</div> : null}</>
                : null}


            <ModalNewApp
                modal={modal}
                info={info}
                hasApp={hasApp}
                modify={isModify}
                step={step}
                refreshApp={() => { getApps() }}
                loaderToggle={loader => setLoader(loader)}
                modalToggle={modal => setModal(modal)}
            />
            {console.log(info?.app)}
            {page == 0 ?
                <div className="application-container">
                    <div className="row">
                        {hasApp ? <>
                            <div className="col-lg-12">
                                <App newApp={hasApp} isModify={isModify} modal={modal} changeToggle={modal => setModal(modal)} info={info} />
                            </div>
                            <div className="col-lg-12 text-center">
                                <Workflow step={step} />
                            </div>
                        </> : <div className="text-center h3">you still dont have any submition.</div>}
                    </div>
                </div> :
                <History
                    loader={status => setLoader(status)}
                    app_id={info?.app?._id}
                    hasApp={hasApp}
                    isHistory={isHistory}
                />
            }
        </>
    )
}

const App = (props) => {

    const active = "active";
    const [list, setList] = useState({})
    const modalToggle = () => { props.changeToggle(!props.modal) }
    useEffect(() => {
        try {
            const { fullname = '', gpa = 0, } = props?.info?.user
            const { allocation_call = '', options = [], discipline_status = false, assessment_score = 'not assigned' } = props?.info?.app
            const { name = '', step = 0 } = props?.info?.app?.workflow_id
            let percent = 0;
            setList({ fullname, gpa, assessment_score, allocation_call, options, name, percent, step, discipline_status })
        } catch (e) { console.log(e); }
    }, [props.info])
    return (
        <>
            <div className="col-lg-12">
                <div className="item" style={{ backgroundColor: 'white' }}>
                    {props.isModify ? <div className="icon text-success" onClick={modalToggle} ><FaPenSquare /></div> : null}
                    <div className="row">
                        <div className="col-lg-4">
                            <p className='mt-4 text-center fw-bold'>My Details</p>
                            <p className='mt-4'><IoPlayOutline />Name : <b>{list?.fullname}</b></p>
                            <p className='mt-4'><IoPlayOutline />GPA : <b>{list?.gpa}</b></p>
                            <p className='mt-4'><IoPlayOutline />Assessment : <b>{list?.assessment_score}</b></p>
                            <p className='mt-4'><IoPlayOutline />Call Year : <b>{list?.allocation_call?.year}</b></p>
                        </div>
                        <div className="col-lg-4">
                            <p className='mt-4 text-center fw-bold'>Selection</p>
                            {list.hasOwnProperty('options') ? list?.options?.map((item, i) => {
                                return (<p key={`${i}prop`} className={`mt-4 option ${item?.discipline_status ? active : 'border'}`} >
                                    {item?.discipline_id?.name}
                                </p>)
                            }) : null}
                        </div>
                        <div className="col-lg-4">
                            <p className='mt-4 text-center fw-bold'>Status</p>
                            <div className="status border rounded">{list?.name}</div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}


const Workflow = (props) => {
    const CurrentAppStep = props.step
    const isTrue = (type, workflow) => {
        if (type == 1) return CurrentAppStep == workflow ? true : false
        else return CurrentAppStep > workflow ? true : false
    }
    const steps = [
        {
            key: Math.random(),
            icon: 'stopwatch',
            active: isTrue(1, 2),
            title: 'For Review By AFP',
            completed: isTrue(2, 2),
            description: 'Academic focal point will review the submission.',
        },
        {
            key: Math.random(),
            active: isTrue(1, 5),
            icon: 'stopwatch',
            title: 'For Review By Eidaad Team',
            completed: isTrue(2, 5),
            description: 'Accepted by AFP, Eidaad team will review the submission.',
        },
        {
            key: Math.random(),
            active: isTrue(1, 7),
            icon: 'stopwatch',
            title: 'Ready for Online Test',
            completed: isTrue(2, 7),
            description: 'Accepted by Eidaad Team,you are Ready to take online test.',
        }, {
            key: Math.random(),
            active: isTrue(1, 8),
            icon: 'stopwatch',
            title: 'Submitted For Allocation',
            completed: isTrue(2, 8),
            description: 'assessment score has been assign, Ready for allocation.',
        }, {
            key: Math.random(),
            active: isTrue(1, 9),
            icon: 'stopwatch',
            title: 'Allocation is done, For review by Ediaad Team',
            completed: isTrue(2, 9),
            description: 'your allocation is done, you only need Eidaad team approval.',
        }, {
            key: Math.random(),
            active: isTrue(1, 10),
            icon: 'stopwatch',
            title: 'Allocated, For acceptance by Student',
            completed: isTrue(2, 10),
            description: 'Congratulation you have been allocated, student acceptence is requerd as a final step',
        }
    ]

    return (<div style={{ overflowX: 'scroll' }}> <Step.Group items={steps} /></div>)
}

const History = ({ app_id, loader, isHistory, hasApp }) => {
    const [history, setHistory] = useState([])
    const getHistory = () => {
        if (!hasApp) return
        loader(true)
        axios.get(`${api}/app/history`, { headers: { appid: app_id }, withCredentials: true })
            .then((res) => { setHistory(isArr(res?.data?.app_history)); loader(false); })
            .catch((e) => { loader(false); })
    }
    useEffect(() => { if (isHistory) getHistory() }, [isHistory])
    return (
        <div className='row mt-2'>
            {History?.length == 0 ?
                <div className="col-lg-12 alert alert-success text-center ">there is no history.</div>
                :
                history.map((item, index) => <div className='col-lg-12 pe-4 ps-4' key={`apphistory-${index}`}>
                    <span className="bg-light p-2 border rounded">{new Date(item?.createdAt).toLocaleString()}</span>
                    <div className="alert alert-warning">
                        <p>{item?.user_id?.fullname} ({item?.user_id?.role})</p>
                        <p>{item?.workflow_id?.name}</p>
                        <p>Comment: {item?.comment == '' ? 'No Comment' : item?.comment}</p>
                    </div>
                </div>)
            }
        </div>
    )
}


const HasFdfAlertMessage = () => {
    return (<div className="alert alert-success text-center h4">
        you have been added to a framework, nothing to do here. <br />
        <b><i> please visit FDF tap.</i></b>
    </div>)
}

export default Student
