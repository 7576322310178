import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import News from '../components/news'
import ProfileCard from '../components/profileCard'
import DashboardMessege from '../components/dashboardMessege'
import { Progress } from 'reactstrap'
import axios from 'axios';
import { Cxt } from '../../../App'
import { api, path } from '../../../util/constant'
import { isArr } from '../../../util/functions'

function Student(props) {

    const [profile, setProfile] = useState(true)
    const [newsList, setNewsList] = useState([])
    const [Application, setApplication] = useState(null)
    const [allUserInfo, setAllUserInfo] = useState({})
    const [dashboard_messege, setDashboard_messege] = useState({ is_show: false, text: '', color: 'success' })
    const { role } = useContext(Cxt);
    const loader = (status) => { props.loderToggle(status) }
    const getDashboard = () => {
        loader(true)
        axios.get(`${api}/dashboard`, { withCredentials: true })
            .then((res) => {
                const { user, news = [], app, dashboard_messege } = res.data;
                setProfile(user?.profileupdate);
                setNewsList(isArr(news));
                setApplication(app);
                setAllUserInfo(user)
                setDashboard_messege(dashboard_messege)
                loader(false);
            })
            .catch((e) => { console.log(e); loader(false) })
    }
    useEffect(() => { getDashboard(); }, [])

    return (
        <div className='p-4 mt-2'>
            <div className="row">
                {!profile ? <div className="alert alert-success pt-1 pb-1">Please Update your <Link to={`${path}/profile`}><button className="btn btn-success ">profile</button></Link></div> : null}
                <DashboardMessege dashboard_messege={dashboard_messege} />
                <div className="col-sm-6">
                    <ProfileCard info={allUserInfo} />
                </div>
                <div className="col-sm-6">
                    <div className='text-center' ><Workflow userRole={role} workflowRole={Application?.workflow_id?.role} name={Application?.workflow_id?.name} step={Application?.workflow_id?.step} /></div>
                </div>
                <div className="col-md-3"></div>
                <div className="col-md-6"><News news={newsList} /></div>
                <div className="col-md-3"></div>
            </div>
        </div>
    )
}

const Workflow = ({ name = '', step = 0, userRole = '', workflowRole = '' }) => {

    return (
        <div className="card-info smooth-box-shadow mb-5" style={{ position: 'relative' }}>
            <div className="title h3">
                My Application
            </div>
            <div className='p-2 pb-3'>
                {name != '' ? <>
                    <Progress animated value={step} max={11} color='info'  >{parseInt(((step / 11)) * 100)} %</Progress>
                    {workflowRole == userRole ? <Link to={`${path}/application`}><button className="btn btn-outline-success mt-3">{name}</button></Link>
                        : <h4 >{name}</h4>}
                </> :
                    <p className='text-center mt-4 mb-4'>Application progress appear after applying</p>
                }
            </div>
        </div>
    )
}



export default Student
