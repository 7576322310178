import React from 'react'

function Section_2({ isEng }) {
    return (
        <div className="row">
            <div className="col-lg-6 col-sm-12 align-self-center mobile-bottom-fix">
                <div className="left-heading">
                    <h2 className="section-title">
                        {isEng ? <>Founders and custodian of Eidaad </> : <>مأسسين البرنامج</>}

                    </h2>

                </div>
                <div className="left-text">
                    {isEng ? <>
                        <p>Eidaad was first found by External Learning & Development Manager - Hamad Al Hadhrami, from Petroleum Development Oman Company,
                            as a result of the 2017 Oman Energy Forum. However, the ownership and sponsorship of the program is with the Ministry of Higher
                            Education, Research and Innovation (MOHERI). The MOHERI ensures appropriate nurturing of the program, upscaling,
                            accreditation and that the students’ credits are in line with Oman’s education schemes.

                        </p>
                    </> : <>
                        <p className="arabic"> تأسس البرنامج لأول مرة من قبل مدير التعلم والتطوير الخارجي - حمد الحضرمي ، من شركة تنمية نفط عمان ، في منتدى عمان للطاقة 2017. ومع ذلك ، فإن ملكية البرنامج ورعايته تعود الى وزارة التعليم العالي والبحث والابتكار (MOHERI). الوزارة تطمح الى تقديم البرنامج بما يتماشى مع احتياجات الطلبة للرقي بهذا المجتمع الطموح.</p>
                    </>}

                </div>
            </div>
            <div className="col-lg-1" />
            <div className="col-lg-5 col-md-12 col-sm-12 align-self-center mobile-bottom-fix-big" data-scroll-reveal="enter right move 30px over 0.6s after 0.4s">
                <img src="assets/images/right-image.png" className="rounded img-fluid d-block mx-auto" alt="true" />
            </div>
        </div>
    )
}

export default Section_2