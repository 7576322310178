import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Dropdown } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import axios from 'axios';
import { api } from '../../../../util/constant';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { isArr } from '../../../../util/functions';

function StudentsModal(props) {

  const toggle = () => (props.setModal(!props.modal));
  const loader = (status) => { props.loader(status) };

  const [isAddNewStudent, setIsAddNewStudent] = useState(false)
  const [newStudent, setNewStudent] = useState('')
  const [searchResult, setSearchResult] = useState([])
  const [searchType, setSearchType] = useState('fullname')

  const handleUpdateFdfStudents = (isAdd, remove_id) => {
    if (isAdd && newStudent.trim() == '') return Notify.failure('please select student')
    loader(true);
    const obj = isAdd ? { fdf_Id: props.selectedFdfId, add_id: newStudent } : { fdf_Id: props.selectedFdfId, remove_id }
    axios.post(`${api}/fdf/students`, obj, { withCredentials: true })
      .then((res) => { loader(false); props.refresh(); reset() })
      .catch((e) => { loader(false); Notify.failure(e.response.data); })
  }
  const searchStudent = async (search) => {
    await axios.get(`${api}/org/allocated_students`, { headers: { search: JSON.stringify({ [searchType]: { $regex: search, $options: "i" }, role_id: 3 }), org: props?.selectedOrg?.id, limit: 100 }, withCredentials: true })
      .then((res) => {
        const { users } = res?.data
        setSearchResult(isArr(users));
      })
      .catch((e) => { console.log(false) })
  }

  const confirmDeleteAlert = (id) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to delete this student ?',
      buttons: [{ label: 'Delete', onClick: () => { handleUpdateFdfStudents(false, id); } }, { label: 'cancel', onClick: () => { } }]
    });
  }

  const reset = () => { setNewStudent(''); setSearchResult([]); setIsAddNewStudent(false) }

  useEffect(() => { if (!props.modal) { reset() }; }, [props.modal])

  return (
    <Modal size="lg" style={{ maxWidth: '700px', width: '100%' }} isOpen={props.modal} toggle={() => { toggle(props) }}>
      <ModalHeader toggle={() => { toggle(props) }}>Students</ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-sm-2 mb-2"><button className="btn btn-success" onClick={() => { setIsAddNewStudent(prev => !prev); setNewStudent('') }}>+</button></div>
          {isAddNewStudent ? <>
            <div className="col-sm-12">
              <input type="radio" checked={searchType == 'fullname'} name="type" onClick={() => setSearchType('fullname')} /> Name{`  `}
              <input type="radio" checked={searchType == 'phone'} name="type" onClick={() => setSearchType('phone')} /> Phone{`  `}
              <input type="radio" checked={searchType == 'email'} name="type" onClick={() => setSearchType('email')} /> Email
            </div>
            <div className="col-sm-8">
              <Dropdown placeholder='<Search>'
                fluid
                search
                selection
                clearable
                options={searchResult ? searchResult.map(item => { return { key: item.key, text: item?.[searchType], value: item?._id } }) : []}
                onChange={(e, d) => { setNewStudent(d.value); }}
                onSearchChange={e => { searchStudent(e.target.value) }}
                value={newStudent}
              />
            </div>

            <div className="col-sm-2 text-center"><button className="btn btn-success" onClick={() => { handleUpdateFdfStudents(true) }}>Add</button></div>
          </> : null}
        </div>
        {props?.selectedStudents?.length != 0 ? <div className='bg-white-1 border rounded'>
          {props?.selectedStudents?.map((item, index) => <p className='p-3' key={index}> <button className="btn btn-danger" onClick={() => { confirmDeleteAlert(item._id) }}>-</button> <b>{item?.fullname}</b> ({item?.email})</p>)}
        </div>
          : <div className="alert alert-warning text-center">there is no students in this FDF</div>}

      </ModalBody>

    </Modal>
  )
}

export default StudentsModal