import React, { useState, useContext } from 'react'
import '../../components_style/dashboardCard.css'
import NewNewsModal from './newNewsModal'
import axios from 'axios'
import { Cxt } from '../../../App'
import { api } from '../../../util/constant'
function News(props) {
    const [modal, setModal] = useState(false)
    const loaderToggle = (status) => { props.loaderToggle(status) }
    const { role } = useContext(Cxt);
    const refresh = () => { props.refresh() }

    const deleteNews = (id) => {
        loaderToggle(true)
        axios.delete(`${api}/news`, { headers: { id }, withCredentials: "true" })
            .then(() => { loaderToggle(false); refresh() })
            .catch((e) => { console.log(e); loaderToggle(false) })
    }
    return (
        <>
            <NewNewsModal loaderToggle={loader => { loaderToggle(loader) }} modal={modal} modalToggle={modal => setModal(modal)} refresh={() => { refresh() }} />
            <div className="dashboard-card-container smooth-box-shadow mb-3">
                <div className="dashboard-card-title">
                    <img src="/images/news.svg" />
                    <p> {role <= 2 ? <button className="btn btn-success" onClick={() => { setModal(true); }}>Announcement and News</button> : <>Announcement and News</>}</p>

                </div>
                <div className="dashboard-card-body">
                    {props?.news?.length > 0 ?
                        <table >
                            <tbody>
                                {props?.news?.map((item, i) => {
                                    return (<tr key={`news-${i}`} >
                                        <td ></td>
                                        <td className='fw-bold'> </td>
                                        <td className=" position-relative"><u>{`${item.date}`}  <span className='fw-bold'> {item.title}</span></u><br />{item.body}
                                            {role <= 2 ? <button onClick={() => { deleteNews(item._id) }} className="btn btn-light danger-on-hover top-right">X</button> : null}
                                        </td>

                                    </tr>)
                                })}
                            </tbody>
                        </table> : <div className="row"><div className="col-md-12 text-center">no news at the moment.</div></div>}

                </div>
            </div>
        </>
    )
}


export default News
