import React from 'react'
import {
    IoLogoFacebook,
    IoLogoTwitter,
    IoLogoLinkedin
} from "react-icons/io5";
function Footer({ isEng }) {
    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 social-contact">
                        <div className="center-text text-light">
                            Contact us
                        </div>
                        <ul className='h6'>
                            <li>mail : soon</li>
                            <li>call : soon</li>
                        </ul>
                    </div>
                    <div className="col-lg-6 map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3656.5784132426074!2d58.32166851492994!3d23.583580284671925!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e8e0089860dfe67%3A0xf200632b48603ed6!2sMinistry%20of%20Higher%20Education%2C%20Research%20and%20Innovation!5e0!3m2!1sen!2som!4v1621923708543!5m2!1sen!2som" style={{ width: "100%", height: 350, border: 0 }}></iframe>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <ul className="social">
                            <li><a href="#"><IoLogoFacebook /></a></li>
                            <li><a href="#"><IoLogoTwitter /></a></li>
                            <li><a href="#"><IoLogoLinkedin /></a></li>
                        </ul>
                        <p className="copyright">Copyright © {new Date().getFullYear()} Eidaad</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer