import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { api } from '../../../util/constant'
import { isNumeric, isEmpty, isEmail } from 'validator';
import axios from 'axios';
import FunctionModal from './functionModal'
import { Notify } from 'notiflix/build/notiflix-notify-aio';

function EditOrgModal(props) {

    const [orgInfo, setOrgInfo] = useState(props.defualtOrgObject);
    const [functionModal, setFunctionModal] = useState(false);
    const [funcName, setFuncName] = useState('');
    const [funcDesc, setFuncDesc] = useState('');
    const [icon, setIcon] = useState('0');
    const [isModifyFunction, setIsModifyFunction] = useState(false);

    const toggle = () => { props.modalToggle() }
    const loader = (status) => { props.loaderToggle(status) }

    const saveNewOrUpdateOrg = () => {
        const { id, name, type, phone, url, postal, email } = orgInfo
        const pass = validate({ name, type, phone, url, postal, email })
        if (pass) {
            loader(true);
            const data = props.isModify ? { id, name, type, phone, url, postal, email } : { name, type, phone, url, postal, email };
            axios.post(`${api}/org`, data, { withCredentials: true })
                .then((res) => { loader(false); toggle(); props.refresh() })
                .catch((e) => { loader(false); })
        }
    }

    const validate = ({ name, type, phone, email }) => {
        if (isEmpty(name)) { Notify.failure('name cannot be empty'); return false; }
        if (isEmpty((type).toString())) { Notify.failure('please select Type'); return false; }
        if (!isEmpty((phone).toString()) && !isNumeric((phone).toString())) { Notify.failure('phone accept numbers only'); return false; }
        if (!isEmpty(email) && !isEmail(email)) { Notify.failure('Email not valid'); return false; }
        return true
    }

    const getFunction = (id) => {
        loader(true);
        axios.get(`${api}/org/function`, { headers: { id }, withCredentials: true })
            .then((response) => {
                const { name = '', description = '', icon = '0' } = response?.data;
                setFuncName(name);
                setFuncDesc(description);
                setIcon(icon)
                setFunctionModal(true);
                loader(false);
            })
            .catch((e) => { console.log(e); loader(false); })
    }

    useEffect(() => { if (props.info) setOrgInfo(props.info) }, [props.info])
    useEffect(() => { if (!functionModal) { setIcon('0'); setFuncDesc(''); setFuncName(''); } }, [functionModal])

    return (
        <Modal isOpen={props.modal} toggle={() => { toggle(props) }}>
            <ModalHeader toggle={() => { toggle(props) }}>Orgnization </ModalHeader>
            <ModalBody>
                <FunctionModal isDisableFileds={false} orgnization={props.info?.id} setIcon={icon => setIcon(icon)} icon={icon} isModify={isModifyFunction} modal={functionModal} refresh={() => { props?.refreshOrg(props.info?.id) }} setFuncDesc={desc => setFuncDesc(desc)} funcDesc={funcDesc} funcName={funcName} setFuncName={name => setFuncName(name)} setModal={modal => setFunctionModal(modal)} loader={loader => props.loaderToggle(loader)} />
                <div className="row">
                    <div className="col-md-12"><input type="text" value={orgInfo?.name} onChange={(e) => { setOrgInfo({ ...orgInfo, name: e.target.value }) }} className='form-control' placeholder='name' /></div>
                    <div className="col-md-12">
                        <select className='form-control' onChange={(e) => { setOrgInfo({ ...orgInfo, type: e.target.value }) }} value={orgInfo?.type}>
                            <option value="">--select type--</option>
                            <option value="1">acadmic</option>
                            <option value="2">indastirial</option>
                        </select>
                    </div>
                    <br />
                </div>
                <hr />

                <p className='text-center'><button className="btn btn-light" disabled>Contact</button></p>
                <div className="row">
                    <div className="col-md-12"><input type="email" value={orgInfo?.email} onChange={(e) => { setOrgInfo({ ...orgInfo, email: e.target.value }) }} className='form-control' placeholder='email' /></div>
                    <div className="col-md-12"><input type="text" value={orgInfo?.phone} onChange={(e) => { setOrgInfo({ ...orgInfo, phone: e.target.value }) }} className='form-control' placeholder='phone' /></div>
                    <div className="col-md-12"><input type="text" value={orgInfo?.postal} onChange={(e) => { setOrgInfo({ ...orgInfo, postal: e.target.value }) }} className='form-control' placeholder='postal' /></div>
                    <div className="col-md-12"><input type="text" value={orgInfo?.url} onChange={(e) => { setOrgInfo({ ...orgInfo, url: e.target.value }) }} className='form-control' placeholder='url' /></div>

                    <div className="col-sm-12"><hr /><p className="text-center"><button className="btn btn-light" disabled>Functions</button></p><button className="btn btn-success ms-2" onClick={() => { setFunctionModal(true); setIsModifyFunction(false) }}>+</button></div>
                    {props?.functions?.length == 0 ? <div className="alert alert-success">there is no functions.</div> :
                        <div>{props?.functions.map((item, index) => { return <button key={index} onClick={() => { getFunction(item._id); setIsModifyFunction(item._id) }} className='btn btn-success m-2'>{item.name}</button> })}</div>
                    }

                </div>

            </ModalBody>
            <ModalFooter>
                <div className="row">
                    <div className="col-sm-12 mb-2">
                        <button className="btn btn-success float-start" onClick={saveNewOrUpdateOrg}>save</button>
                        <Button color="warning" className='float-end' onClick={toggle}>Cancel</Button>
                    </div>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default EditOrgModal
