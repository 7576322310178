import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { api } from '../../util/constant'
import axios from 'axios';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { isArr } from '../../util/functions';

const RegistrationModal = (props) => {

    const toggle = () => (props.changePerentToggle(!props.modal));
    const loaderToggle = (status) => (props.changeLoader(status));
    const [list, setList] = useState([])
    const [selectedFunction, setSelectedFunction] = useState('')
    const [isOFFP, setIsOFFP] = useState(false)
    const [isResendVerificationIsDone, setIsResendVerificationIsDone] = useState(false)
    const acceptOrReject = async (id, status) => {
        if (isOFFP && selectedFunction == '') { Notify.warning('Must select function.', { timeout: 2500 }); return }
        loaderToggle(true);
        axios.put(`${api}/reg`, { id: id, status, func: selectedFunction }, { withCredentials: true })
            .then((res) => { toggle(props); loaderToggle(false); props.refreshTable(); })
            .catch((e) => { console.log(e); toggle(props); loaderToggle(false); props.refreshTable(); })
    }

    const resendVerificationEmailToStudent = (email) => {
        loaderToggle(true);
        axios.post(`${api}/reg/send_email_verify_student`, { email }, { withCredentials: true })
            .then((res) => { loaderToggle(false); setIsResendVerificationIsDone(true) })
            .catch((e) => { loaderToggle(false); })
    }
    const deleteReg = (email) => {
        loaderToggle(true);
        axios.delete(`${api}/reg`, { headers: { email }, withCredentials: true })
            .then((res) => { toggle(props); props.refreshTable(); loaderToggle(false); })
            .catch((e) => { loaderToggle(false); })
    }

    useEffect(() => {
        try {
            setSelectedFunction('')
            const { date = '', fullname = '', email = '', gender = '', qualification = '', role = '', department = '', _id = '', functions, otherRolesForThisUser } = props.modalInfo
            const { name = '' } = props?.modalInfo?.orgnization_id
            setIsResendVerificationIsDone(false);
            setList({ date, fullname, email, gender, qualification, role, department, name, _id, functions, otherRolesForThisUser });
            setIsOFFP(role == 'Organization Functional Focal Point' ? true : false);
        } catch (e) { console.log(e); }
    }, [props.modalInfo])

    return (
        <div>
            <Modal size="lg" style={{ maxWidth: '700px', width: '100%' }} isOpen={props.modal} toggle={() => { toggle(props) }}>
                <ModalHeader toggle={() => { toggle(props) }}>Registration</ModalHeader>
                <ModalBody>
                    <div className="row h6">
                        <div className="col-md-12"><b>Submitted on : </b>{list?.date}</div>
                        <div className="col-md-12"><b>Name : </b>{list?.fullname}</div>
                        <div className="col-md-12"><b>Email : </b>{list?.email}</div>
                        <div className="col-md-12"><b>Gender : </b>{list?.gender}</div>
                        <div className="col-md-12"><b>Orgnization : </b>{list?.name}</div>
                        <div className="col-md-12"><b>Qualification : </b>{list?.qualification}</div>
                        <div className="col-md-12"><b>Role : </b>{list?.role}</div>
                        <div className="col-md-12 ">{isOFFP ? <b>Function enterd by user</b> : <b>Department</b>}  : {list?.department}</div>
                        {isOFFP ? <><div className="col-md-6 fw-bold pt-4">Function to give :</div><div className="col-md-6">
                            <select className="form-control m-2" onChange={(e) => { setSelectedFunction(e.target.value) }}>
                                <option value="">{`<select function>`}</option>
                                {list?.functions.map(item => <option value={item._id}>{item.name}</option>)}
                            </select>
                            {list?.functions.length == 0 ? <div className="alert alert-warning">orgnization does not have any functions, you can add functions from orgnizations tap.</div> : null}
                        </div></> : null}
                        {isArr(list?.otherRolesForThisUser).length > 0 ?
                            <div className='alert alert-warning p-2'><span className="fw-bold">user roles :</span> <br /><ul>
                                {isArr(list?.otherRolesForThisUser).map((item, index) => <li key={index}>{`${index + 1}- ${item.fullname} (${item?.role}) (${item?.orgnization_id?.name})`}</li>)}</ul></div> : null}

                        {props.regStatus == 4 ? <div className="col-md-12">
                            <button className="btn btn-success float-start" onClick={() => resendVerificationEmailToStudent(list?.email)} disabled={isResendVerificationIsDone}>
                                {isResendVerificationIsDone ? 'email has been sent' : 'Re-send verification email'}
                            </button>
                            <bnt className="btn btn-danger float-end" onClick={() => {
                                confirmAlert({
                                    title: 'Confirm to delete',
                                    message: 'Are you sure to delete this Registration ?',
                                    buttons: [{ label: 'Delete', onClick: () => { deleteReg(list?.email) } }, { label: 'cancel', onClick: () => { } }]
                                });
                            }}>Delete</bnt>
                        </div> : null}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="row w-100" >
                        <div className="col-sm-12  text-center">
                            {props.regStatus == 1 ? <><Button color="success float-start" onClick={() => { acceptOrReject(list._id, 2) }}>Accept</Button>
                                <Button color="danger" onClick={() => { acceptOrReject(list._id, 3) }}>Reject</Button></> : null}
                            <Button color="warning float-end " onClick={() => { toggle(props) }}>Cancel</Button>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default RegistrationModal