import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Dropdown } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { isArr } from '../../util/functions'
import { isEmpty as _isEmpty } from 'validator'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import axios from 'axios';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { api } from '../../util/constant';

function MajorModal(props) {
    const [selectedMajor, setSelectedMajor] = useState('')
    const [majorName, setMajorName] = useState('')
    const [isViewDeleteSection, setIsViewDeleteSection] = useState(false)
    const toggle = () => (props.setModal(!props.modal));
    const loaderToggle = loader => (props.loaderToggle(loader));

    const deleteMajor = () => {
        if (selectedMajor == '') return Notify.failure('please select the major students will shift into');
        loaderToggle(true)
        axios.delete(`${api}/major`, { headers: { majorid: props?.info?.major?._id, shiftStudentsTo: selectedMajor }, withCredentials: true })
            .then((res) => { loaderToggle(false); toggle(); props.refresh() })
            .catch((e) => { loaderToggle(false); })
    }

    const updateMajor = () => {
        if (majorName.trim() == '') return Notify.failure('major name cannot be empty');
        loaderToggle(true)
        axios.put(`${api}/major`, { majorId: props?.info?.major?._id, name: majorName }, { withCredentials: true })
            .then((res) => { loaderToggle(false); toggle(); props.refresh() })
            .catch((e) => { loaderToggle(false); })
    }

    useEffect(() => { if (props?.info?.major?.name) setMajorName(props?.info?.major?.name) }, [props.info])
    useEffect(() => {
        if (!props.modal) {
            props.setMajorInfo({});
            setMajorName('');
            setIsViewDeleteSection(false);
            setSelectedMajor('')
        }
    }, [props.modal])
    return (
        <Modal size="lg" style={{ maxWidth: '700px', width: '100%' }} isOpen={props.modal} toggle={() => { toggle(props) }}>
            <ModalHeader toggle={() => { toggle(props) }}>Major</ModalHeader>
            <ModalBody>
                <input type="text" className="form-control" value={majorName} placeholder="Major Name" onChange={e => setMajorName(e.target.value)} />
                {
                    isViewDeleteSection ? <div className="m-2 alert alert-danger p-2">
                        <span className="fw-bold">Warning !</span><br />
                        <p>Deleting this major will reflect on disciplines and users : <br />
                            <ol>
                                <li>users who has this major will be shifted to other major automatically.</li>
                                <li>this major will removrd from disciplines.</li>
                            </ol>
                        </p>
                        <div>
                            <u>This major will be removed from the following disciplines :</u>
                            <br />
                            <ol>
                                {isArr(props?.info?.major?.disciplines).map(item => <li>{item?.discipline?.name}</li>)}
                            </ol>
                        </div>
                        <div >
                            <u>Number of Students who has this Major:</u> {props.info?.studentsHasThisMajorCount} <br />
                            shift students from this major to :
                            <Dropdown placeholder='<Select Major>' fluid clearable search selection
                                options={isArr(props.majors).map((item, index) => { return { key: index, text: item?.name, value: item?._id, disabled: props?.info?.major?._id == item?._id } })}
                                onChange={(e, d) => { setSelectedMajor(d.value) }}
                                value={selectedMajor}
                            />
                        </div>
                        <button className="btn btn-danger mt-2" onClick={() => confirmAlert({
                            title: 'Confirm to delete',
                            message: 'Are you sure to delete this Major ?',
                            buttons: [{ label: 'Delete', onClick: () => { deleteMajor(); } }, { label: 'cancel', onClick: () => { } }]
                        })}>DELETE</button>
                    </div> : <button onClick={() => { setIsViewDeleteSection(true) }} className="btn btn-warning mt-2">Delete this Major?</button>}

            </ModalBody>
            <ModalFooter>
                <div className="w-100">
                    <button onClick={() => updateMajor()} className="btn btn-success float-end">Update</button>
                    <Button color="warning float-start" onClick={toggle}>Cancel</Button>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default MajorModal