import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';
import '../components_style/yearModal.css'
import { api } from '../../util/constant'
import { isArr } from '../../util/functions';

function YearModal(props) {

    const [timelineList, setTimelineList] = useState([])
    const toggle = () => (props.changePerentToggle(!props.modal));
    const loaderToggle = (loader) => (props.changeLoader(loader));


    const handleAddTimeline = () => { setTimelineList([...timelineList, { title: '', title_ar: '', text: '', text_ar: '', color: '', date: '' }]) }
    const handleRemoveTimeline = (i) => { let newArr = [...timelineList]; newArr.splice(i, 1); setTimelineList(newArr) }
    const handleOnChangeTimeline = (name, value, i) => { let newArr = [...timelineList]; newArr[i][name] = value; setTimelineList(newArr) }


    const getTimeline = async () => {
        loaderToggle(true)
        axios.get(`${api}/setting/timeline`)
            .then((res) => {
                setTimelineList(isArr(res?.data?.content));
                loaderToggle(false)
            })
            .catch((e) => { console.log(e); loaderToggle(false) })
    }

    const save = async () => {
        loaderToggle(true)
        await axios.post(`${api}/setting/timeline`, { content: timelineList }, { withCredentials: true })
            .then(() => { loaderToggle(false); toggle() })
            .catch((e) => { loaderToggle(false); console.log(e); })

    }
    useEffect(() => {
        if (props.modal) getTimeline()
    }, [props.modal])

    return (
        <Modal size="lg" style={{ maxWidth: '700px', width: '100%' }} isOpen={props.modal} toggle={() => { toggle(props) }}>
            <ModalHeader toggle={() => { toggle(props) }}>Timeline</ModalHeader>
            <ModalBody>
                <button className="btn btn-success " onClick={handleAddTimeline}>+</button>
                {timelineList.map((item, i) => {
                    return (<div className="row text-center">
                        <div className="col-md-1"><p className="shadow rounded ">{i + 1}</p></div>
                        <div className="col-md-5">color
                            <select name="color" value={item?.color} className='form-control mt-2' onChange={e => handleOnChangeTimeline(e.target.name, e.target.value, i)}>
                                <option value="">{`<select color>`}</option>
                                <option value="green">green</option>
                                <option value="blue">blue</option>
                                <option value="purple">purple</option>
                                <option value="orange">orange</option>
                            </select>
                        </div>
                        <div className="col-md-5">date
                            <input
                                type="text"
                                className="form-control mt-2"
                                name='date'
                                value={item?.date}
                                onChange={e => handleOnChangeTimeline(e.target.name, e.target.value, i)} />
                        </div>
                        <div className="col-md-1"><button className="btn btn-danger" onClick={() => handleRemoveTimeline(i)}>-</button></div>
                        <div className="col-md-6">title
                            <input
                                type="text"
                                className="form-control mt-2"
                                name='title'
                                value={item?.title}
                                onChange={e => handleOnChangeTimeline(e.target.name, e.target.value, i)} />
                        </div>
                        <div className="col-md-6">text
                            <input
                                type="text"
                                className="form-control mt-2"
                                name='text'
                                value={item?.text}
                                onChange={e => handleOnChangeTimeline(e.target.name, e.target.value, i)} />
                        </div>
                        <div className="col-md-6">title arabic
                            <input
                                type="text"
                                className="form-control mt-2"
                                name='title_ar'
                                value={item?.title_ar}
                                onChange={e => handleOnChangeTimeline(e.target.name, e.target.value, i)} />
                        </div>
                        <div className="col-md-6">text arabic
                            <input
                                type="text"
                                className="form-control mt-2"
                                name='text_ar'
                                value={item?.text_ar}
                                onChange={e => handleOnChangeTimeline(e.target.name, e.target.value, i)} />
                        </div>
                        <div className="col-md-12"><hr /></div>
                    </div>)
                })}
            </ModalBody>
            <ModalFooter>
                <div className="row">
                    <div className="col-sm-4 mb-2"><Button color="success" onClick={save}>Save</Button></div>
                    <div className="col-sm-4 mb-2"></div>
                    <div className="col-sm-4 mb-2"><Button color="warning" onClick={toggle}>Cancel</Button></div>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default YearModal
