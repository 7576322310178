import React from 'react'
import '../../components_style/dashboardCard.css'

function ProfileCard(props) {
    return (
        <div className='card-info smooth-box-shadow mb-5 text-center'>
            <img src="/images/male.png" className='profile-icon' /><br />
            <span className='fw-bold'>{props?.info?.fullname} {props?.info?.role ? `(${props?.info?.role})` : null}</span> <br />
            {props?.info?.email}<br />
            {props?.info?.phone}<br />
            {props?.info?.orgnization_id?.name}
        </div>
    )
}

export default ProfileCard