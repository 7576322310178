import React, { useState, useEffect, useContext } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';
import { Dropdown } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { api } from '../../util/constant'
import { Cxt } from '../../App'
import { validMail } from '../../util/validateMail';
import { identifyRole, isArr } from '../../util/functions';

function NewUserModal(props) {

    const [FullName, setFullName] = useState('');
    const [Email, setEmail] = useState('');
    const [Phone, setPhone] = useState('');
    const [Department, setDepartment] = useState('');
    const [Role, setRole] = useState('');
    const [Gender, setGender] = useState('');
    const [Organization, setOrganization] = useState('');
    const [Qualification, setQualification] = useState('');
    const [Error, setError] = useState(false);
    const [Messege, setMessege] = useState('');
    const [colleges, setColleges] = useState([]);
    const [hasPass, setHasPass] = useState(false);
    const [pass, setPass] = useState('');
    const [rePass, setRePass] = useState('');
    const [isStudent, setIsStudent] = useState(false)
    const [functions, setFunctions] = useState([])
    const [selectedFunction, setSelectedFunction] = useState('')
    const { role } = useContext(Cxt);

    const toggle = () => (props.modalToggle(!props.modal));
    const loader = (status) => { props.loaderToggle(status) }
    const refresh = () => { props.refreshApp() }
    const resetAllStates = () => { setIsStudent(false); setError(false); setHasPass(false); setFullName(''); setEmail(''); setDepartment(''); setRole(''); setGender(''); setOrganization(''); setQualification(''); setMessege(''); setPass(''); setRePass('') }
    const getOrgs = async () => { loader(true); const orgs = await axios.get(`${api}/org`); setColleges(isArr(orgs?.data)); loader(false); }
    const handleSubmit = () => {
        loader(true);
        setError(false);
        let Fields = [Email, Gender, Role, Organization, FullName, Phone];
        if (isStudent) Fields = [...Fields, Department, Qualification];
        validation(Fields);
    }

    const validation = (Fields) => {
        let status = true;
        const { isOFFP } = identifyRole(Role)
        if (hasPass) { Fields.push(pass, rePass) }
        status = validMail(Fields[0]) ? true : false;
        Fields.map(item => { if (item.trim() == '') { status = false; return } })
        if (!status) { setError(true); setMessege('Missing Field or Email is not valid'); loader(false); return }
        if (pass !== rePass) { setError(true); setMessege('password does not match !'); loader(false); return }
        if (isOFFP && selectedFunction == '') { setError(true); setMessege('must select a function.'); loader(false); return }
        handleSave(Fields)
    }



    const handleSave = async () => {
        const { isOFFP } = identifyRole(Role)

        let data = {
            fullname: FullName,
            phone: Phone,
            role: Role,
            orgnization_id: Organization,
            email: Email,
            gender: Gender
        }

        if (isStudent) data = { ...data, qualification: Qualification, department: Department, }
        if (isOFFP) data = { ...data, func: selectedFunction }
        if (hasPass) data.pass = pass
        await axios.put(`${api}/users`, data, { withCredentials: true })
            .then(() => { setError(false); loader(false); toggle(); refresh() })
            .catch((e) => { loader(false); setError(true); setMessege("Email already exisit.") })
    }

    const getFunctionsOfOrgIfRoleIsOFFP = async () => {
        const { isOFFP } = identifyRole(Role)
        if (!isOFFP || Organization == '') {
            setFunctions([])
            setSelectedFunction('')
            return
        }
        loader(true);
        await axios.get(`${api}/org/function`, { headers: { orgnizationid: Organization }, withCredentials: true })
            .then((res) => { loader(false); setFunctions(isArr(res?.data)) })
            .catch((e) => { loader(false); })
    }

    useEffect(() => { getOrgs(); }, []);
    useEffect(() => { getFunctionsOfOrgIfRoleIsOFFP(); }, [Organization, Role]);
    useEffect(() => { resetAllStates(); }, [props.modal])
    useEffect(() => { setIsStudent(Role == 3 ? true : false); }, [Role])

    return (
        <div>
            <div>
                <Modal size="lg" style={{ maxWidth: '700px', width: '100%' }} isOpen={props.modal} toggle={() => { toggle(props) }}>
                    <ModalHeader toggle={() => { toggle(props) }}>New user</ModalHeader>
                    <ModalBody>

                        <div className="row">

                            <div className="col-md-6">
                                <input type="text" onChange={(e) => { setFullName(e.target.value) }} placeholder="Full Name" className="form-control m-2" />
                            </div>
                            <div className="col-md-6">
                                <input type="email" onChange={(e) => { setEmail(e.target.value) }} placeholder="Email" className="form-control m-2" />
                            </div>
                            <div className="col-md-6">
                                <input type="text" onChange={(e) => { setPhone(e.target.value) }} placeholder="Mobile Number" className="form-control m-2" />
                            </div>

                            <div className="col-md-6">
                                <select className="form-control m-2" onChange={(e) => { setGender(e.target.value) }} >
                                    <option value="0">{`<Select Gender>`}</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                            </div>

                            <div className="col-md-6">
                                <select className="form-control m-2" onChange={(e) => { setRole(e.target.value) }}>
                                    <option value="0">{`<Select Role>`}</option>
                                    {role == 1 ? <option value="2">Eidaad</option> : null}
                                    <option value="3">Student</option>
                                    <option value="4">Accademia Focal Point</option>
                                    <option value="5">Organization Focal Point</option>
                                    <option value="6">Organization Functional Focal Point</option>
                                    <option value="7">Technical Authority</option>
                                    <option value="8">Supervisor</option>
                                    {/* <option value="9">Acadamic Advisor</option> */}
                                </select>
                            </div>
                            <div className="col-md-6">
                                <Dropdown className="m-2"
                                    placeholder='<Select institution>'
                                    fluid
                                    clearable
                                    search
                                    selection
                                    options={colleges ? colleges.map(item => { return { key: item.key, text: item.name, value: item._id } }) : []}
                                    onChange={(e, d) => { setOrganization(d.value); }}
                                />
                            </div>
                            {Role == 6 ? <div className="col-md-6">
                                <Dropdown className="m-2"
                                    placeholder='<Select function>'
                                    fluid
                                    clearable
                                    search
                                    selection
                                    options={functions ? functions.map((item, index) => { return { key: index, text: item.name, value: item._id } }) : []}
                                    onChange={(e, d) => { setSelectedFunction(d.value) }}
                                />
                                {isArr(functions).length == 0 && Organization != '' ? <div className="alert alert-warning">this orgnization doesn't have any function, first you need to add functions in order to add offp user</div> : null}

                            </div> : null}
                            {isStudent ? <><div className="col-md-6">
                                <select className="form-control m-2" onChange={(e) => { setQualification(e.target.value) }}>
                                    <option value="1">--Select Qualification--</option>
                                    <option value="PHD">Phd</option>
                                    <option value="MASTER">Master</option>
                                    <option value="BACHELOR">Bachelor</option>
                                    <option value="DIPLOMA">Diploma</option>
                                </select>
                            </div>
                                <div className="col-md-6">
                                    <input type="text" onChange={(e) => { setDepartment(e.target.value) }} placeholder="Department" className="form-control m-2" />
                                </div></> : null}
                            <div className="col-md-12 pt-0 pb-0 pr-3 pl-3"><input type="checkbox" checked={hasPass} onChange={() => { setHasPass(!hasPass) }} />
                                {'  '}Set Password for this user? if not he/she will be recive an email to set it.
                            </div>
                            {hasPass ?
                                <>
                                    <div className="col-md-6">
                                        <input type="password" onChange={(e) => { setPass(e.target.value) }} placeholder="New Password" className="form-control m-2" />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="password" onChange={(e) => { setRePass(e.target.value) }} placeholder="verify password" className="form-control m-2" />
                                    </div></>
                                : null}
                            {Error ? <div className="col-md-12"><div className="alert alert-warning" role="alert">{Messege}</div></div> : null}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="row">
                            <div className="col-md-12"><Button className="btn btn-success" onClick={handleSubmit}>Add</Button></div>

                        </div>
                    </ModalFooter>
                </Modal>
            </div>
        </div>
    )
}

export default NewUserModal
