module.exports = {
    FileDownload: (url) => {
        window.open(url);
    },
    identifyRole: (role) => {
        const isAdminOrEidaad = role <= 2 ? true : false;
        const isStudent = role == 3 ? true : false;
        const isAFP = role == 4 ? true : false;
        const isOFP = role == 5 ? true : false;
        const isOFFP = role == 6 ? true : false;
        const isTA = role == 7 ? true : false;
        const isSV = role == 8 ? true : false;
        const isAdvisor = role == 9 ? true : false;
        return { isAFP, isOFP, isStudent, isAdminOrEidaad, isOFFP, isTA, isSV, isAdvisor }
    },
    isArr: (str) => {
        if (!Array.isArray(str)) console.log(`isArr => ${JSON.stringify(str)} is not an array`);
        return Array.isArray(str) ? str : []
    },
    isStr: (str) => {
        return typeof str === 'string' ? true : false
    },
    isEmp: (str) => {
        return typeof str === 'string' && !str.trim() === '' ? true : false
    },
    getRandomColor: () => {
        var letters = '0123456789ABCDEF'.split('');
        var color = '#';
        for (var i = 0; i < 6; i++) color += letters[Math.floor(Math.random() * 16)];
        return color;
    },
    handleEditObject: (value, keyName, obj) => {
        let result = { ...obj }
        result[keyName] = value
        return result
    },
    handleEditObjectInArray: (value, keyName, array, index) => {
        let arr = [...array]
        let obj = { ...arr[index] }
        obj[keyName] = value
        arr[index] = obj
        return arr
    },

    handleAddItemToArray: (defaultObject, Array) => [...module.exports.isArr(Array), defaultObject]
    ,
    handleRemoveItemFromArray: (index, array) => {
        let arr = [...module.exports.isArr(array)]
        arr.splice(index, 1)
        return arr

    },
    isItemInArray: (array, value) => {
        const indexOfVariable = array.findIndex(item => item == value)
        const isVariableExistsInArray = indexOfVariable != -1
        return (isVariableExistsInArray)
    },
    findIndexInArrayOfObjects: (array, keyName, valueToFind) => {
        let arr = module.exports.isArr(array)
        if (arr.length == 0) console.log('findIndexInArray => array is empty');
        return arr.findIndex(x => x[keyName] === valueToFind)
    },
    calculateFormDataSizeInMb: (formData) => {
        let totalSize = 0;

        for (let [key, value] of formData.entries()) {
            totalSize += new TextEncoder().encode(key).length;
            totalSize += (typeof value === 'string')
                ? new TextEncoder().encode(value).length
                : value.size;
        }

        return totalSize / (1024 * 1024);
    }
}