import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { api } from '../../util/constant';
import { identifyRole, isArr } from '../../util/functions';
import { Dropdown } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

const SupervisorsAndTaAndAdvisorForStudent = props => {
    const { isOFFP, isOFP, isAdminOrEidaad } = identifyRole(props.role)
    const [selectedStudents, setSelectedStudents] = useState([])
    const [students, setStudents] = useState([])
    const [studentsForView, setStudentsForView] = useState([])
    const isAllowedToEditStudents = isOFFP || isOFP || isAdminOrEidaad
    const loader = status => props.loader(status)

    const getStudentsUnderSvOrTa = async () => {
        loader(true)
        await axios.get(`${api}/users`, { headers: { studentsundersvorta: true, id: props.userInfo._id }, withCredentials: true })
            .then(res => {
                const { students, selectedStudents } = res.data
                setStudents(isArr(students))
                setStudentsForView(isArr(selectedStudents))
                setSelectedStudents(isArr(selectedStudents).map(item => item._id))
                loader(false)
            })
            .catch(e => { console.log(e); loader(false) })
    }

    const updateStudents = async () => {
        loader(true)
        await axios.put(`${api}/users`, { id: props.userInfo._id, selectedStudents }, { headers: { supervisorandta: true }, withCredentials: true })
            .then(res => {
                props.toggle()
                props.refresh()
                loader(false)
            })
            .catch(e => { console.log(e); loader(false) })
    }

    useEffect(() => {
        if (!props.modal) {
            setStudents([])
            setSelectedStudents([])
        }
    }, [props.modal])

    useEffect(() => {
        getStudentsUnderSvOrTa()
    }, [])

    return <>
        <div className="alert alert-success mb-3 p-2" >
            <h4 > Students:[{isArr(studentsForView).length}]</h4>
            {isAllowedToEditStudents ?
                <Dropdown className="m-2"
                    placeholder='<Select Students>'
                    fluid
                    clearable
                    search
                    selection
                    multiple
                    options={isArr(students).map((item, index) => { return { key: index, text: item?.fullname, value: item?._id } })}
                    onChange={(e, d) => { setSelectedStudents(d.value); }}
                    value={selectedStudents}
                /> :
                isArr(studentsForView).map((item, index) => <div key={index}>{index + 1} - {item.fullname}</div>)}
        </div>
        {isAllowedToEditStudents ? <div className="text-center"><button className="btn btn-success" onClick={updateStudents}>update</button></div> : null}
    </>
}

export default SupervisorsAndTaAndAdvisorForStudent