import React from 'react'
import '../../style/dashboard.css'
function Card({ title, number, imgUrl }) {
    return (
        <div className='dashboard-main'>

            <div className="card-info smooth-box-shadow">
                <div className="title shadow">
                    {title}
                </div>
                {imgUrl ? <div className="icon"><img src={imgUrl} /></div> : null}
                <div className="val">{number}</div>
            </div>
        </div>
    )
}

export default Card