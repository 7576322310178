import React from 'react'
import '../../components_style/dashboardCard.css'

function ForMyAcion(props) {
    const getDate = (updatedAt) => { return new Date(updatedAt).toLocaleDateString() }
    return (
        <div className="dashboard-card-container smooth-box-shadow">
            <div className="dashboard-card-title">
                <img src="/images/action.svg" />
                For My Action
            </div>
            <div className="dashboard-card-body">
                <table className='w-100'>
                    <tbody>
                        {props?.apps?.length > 0 ? props?.apps?.map((item, i) => {
                            return (<tr key={`news-${i}`} >
                                <td> </td>
                                <td><u>{`${getDate(item?.updatedAt)}`}</u> <span className=''> <b> {item?.user_id?.fullname}</b></span><br />{item?.workflow_id?.name}
                                </td>
                            </tr>)
                        }) : <div className="col-md-12 text-center">There is nothing for my acion .</div>}
                    </tbody>
                </table>
            </div>
        </div >
    )
}

export default ForMyAcion
