import React, { useState, useEffect } from 'react'
import Nav from '../components/nav'
import Location from '../components/location'
import Loader from '../components/loader'
import { api } from '../util/constant'
import axios from 'axios'
import FdfSubmission from '../components/fdf/components/fdfSubmission/fdfSubmission'
import { useLocation } from 'react-router-dom'

function FdfSubmissionView() {
    const [loader, setLoader] = useState(false)
    const [fdfSubmission, setFdfSubmission] = useState({})
    const [fdfDetails, setFdfDetails] = useState({})
    const [selectedFdf, setSelectedFdf] = useState({ id: '', name: '' })
    const [selectedSubmission, setSelectedSubmission] = useState('')

    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    const fdfId = searchParams.get("id");
    const fdfName = searchParams.get("name");
    const student = searchParams.get("student");

    useEffect(() => {
        if (fdfId && fdfName && student) {
            setSelectedFdf({ id: fdfId, name: fdfName })
            setSelectedSubmission(student)
        }
    }, [])

    useEffect(() => {
        getSubmission()
    }, [selectedFdf])


    const getSubmission = () => {
        setLoader(true)
        axios.get(`${api}/fdf/sub`, { headers: { studentId: selectedSubmission, fdf: selectedFdf.id }, withCredentials: true })
            .then((res) => {
                const { fdfDetails = {}, fdfSubmission = {} } = res?.data;
                setFdfDetails(fdfDetails);
                setFdfSubmission(fdfSubmission);
                setLoader(false);
            })
            .catch((e) => { console.log(e); setLoader(false) })
    }
    return (
        <>
            <Nav />
            <Location />
            {loader ? <Loader /> : null}
            <FdfSubmission
                isBack={false}
                details={fdfDetails}
                setSelectedSubmission={student_id => setSelectedSubmission(student_id)}
                refresh={() => getSubmission()}
                selectedFdf={selectedFdf}
                submission={fdfSubmission}
                loader={status => setLoader(status)}

            />
        </>
    )
}

export default FdfSubmissionView