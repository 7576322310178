import React, { useState, useEffect, useContext } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import axios from 'axios';
import { Cxt } from '../../../../App'
import { api } from '../../../../util/constant';
import { IoCloudDownloadOutline } from 'react-icons/io5';
import { FileDownload, calculateFormDataSizeInMb, identifyRole } from '../../../../util/functions'
import Cookies from 'js-cookie';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

function ExtraWorkModal(props) {
    const { role } = useContext(Cxt);
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [attachments, setAttachments] = useState([]);
    const [supervisorComment, setSupervisorComment] = useState('')
    const { isSV, isStudent } = identifyRole(role)

    const toggle = () => (props.setModal(!props.modal));
    const loader = (status) => { props.loader(status) };
    const handleAddAttachment = () => setAttachments(prev => prev.length >= 3 ? [...prev] : [...prev, ''])
    const handleEditAttachment = (file, index) => { const _attachmetns = [...attachments]; _attachmetns[index] = file; setAttachments(_attachmetns) }
    const handleRemoveAttachment = (index) => { const _attachmetns = [...attachments]; _attachmetns.splice(index, 1); setAttachments(_attachmetns); }

    const submittionByStudent = (type) => {
        if (title.trim() == '') { Notify.warning('title cannot be empty.'); return };
        if (description.trim() == '') { Notify.warning('description cannot be empty.'); return };
        const data = new FormData();
        attachments.map(item => { if (item != '') data.append(`files`, item) })
        const obj = { title, description, extra_work_id: props?.extraWorkSubmission?._id, type, submission_id: props.submission_id, fdf_id: props?.fdf_id }
        data.append('data', JSON.stringify(obj))
        const filesSizeInMb = calculateFormDataSizeInMb(data)
        if (filesSizeInMb > 25) return Notify.warning(`max size of all files is 25MB, your files size is ${parseInt(filesSizeInMb)}MB.`);
        loader(true)
        axios.post(`${api}/fdf/sub/extrawork`, data, { withCredentials: true })
            .then((res) => {
                toggle();
                Notify.success('submitted successfully');
                loader(false);
                props.refresh()
            })
            .catch((e) => { console.log(e); loader(false); Notify.failure('somthing went wrong'); })
    }

    const submissionBySupervisor = ({ isSendBackToStudent, modificationComment = '' }) => {
        if (supervisorComment.trim() == '' && !isSendBackToStudent) { Notify.warning('comment cannot be empty.'); return false };
        if (isSendBackToStudent && modificationComment.trim() == '') { Notify.warning('modification comment cannot be empty.'); return false };

        loader(true)
        const obj = { submission_id: props.submission_id, comment: supervisorComment, extra_work_id: props?.extraWorkSubmission?._id, isSendBackToStudent, modificationComment }

        axios.post(`${api}/fdf/sub/extrawork`, obj, { withCredentials: true })
            .then((res) => {
                toggle();
                Notify.success(isSendBackToStudent ? 'sent back to student for modification' : 'submitted successfully');
                loader(false);
                props.refresh();
            })
            .catch((e) => { console.log(e); loader(false); Notify.failure('somthing went wrong'); })
        return true
    }

    const deleteExtraWork = () => {
        axios.delete(`${api}/fdf/sub/extrawork`, { headers: { submissionid: props?.submission_id, extraworkid: props?.extraWorkSubmission?._id }, withCredentials: true })
            .then((res) => {
                toggle();
                Notify.success('deleted successfully');
                loader(false);
                props.refresh()
            })
            .catch((e) => { console.log(e); loader(false); Notify.failure('somthing went wrong'); })
    }

    const modificationAlert = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='react-confirm-alert-body'>
                        <h1>modification request</h1>
                        <p>please type comment for student</p>
                        <input type="text" className="form-control" id='modificationComment' />
                        <div className="react-confirm-alert-button-group">
                            <button onClick={onClose}>No</button>
                            <button onClick={async () => {
                                const modificationComment = document.getElementById('modificationComment').value
                                const isDone = submissionBySupervisor({ isSendBackToStudent: true, modificationComment })
                                if (isDone) onClose()
                            }} >Submit </button>
                        </div>
                    </div >
                );
            }
        });
    }

    const deleteAlert = () => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to delete this extra work ?',
            buttons: [
                { label: 'Yes', onClick: () => deleteExtraWork() },
                { label: 'No', onClick: () => { } }
            ]
        });

    }

    useEffect(() => {
        if (props.isStudentSubmittedExtraWork) {
            const { title = '', description = '', attachments = [] } = props?.extraWorkSubmission
            setTitle(title)
            setDescription(description);
            setAttachments(attachments);
        }

        if (props.isSupervisorSubmitted) setSupervisorComment(props?.extraWorkSubmission?.comment_by_supervisor?.text)

        if (!props.modal) {
            setTitle('');
            setDescription('');
            setAttachments([]);
            setSupervisorComment('');
        }
    }, [props.modal])

    return (
        <Modal size="lg" style={{ maxWidth: '700px', width: '100%' }} isOpen={props.modal} toggle={() => { toggle(props) }}>
            <ModalHeader toggle={() => { toggle(props) }}>Extra Work</ModalHeader>
            <ModalBody>
                <div className="row">
                    <div className="col-sm-12">
                        {props?.isStudentAlloedToEditExtraWork && props?.isStudentSubmittedExtraWork ?
                            <div className="alert alert-warning"> <b>modification comment by supervisor :</b> <br /> {props?.extraWorkSubmission?.comment_by_supervisor?.modification_comment}</div>
                            : null}
                        <p className='mb-3 fw-bold'>title :</p>
                        <textarea rows="1" placeholder='title' value={title} onChange={e => setTitle(e.target.value)} disabled={!props?.isStudentSubmittedExtraWork && isStudent || props?.isStudentAlloedToEditExtraWork && isStudent ? false : true} className='form-control'></textarea>
                        <p className='mb-3 fw-bold'>description :</p>
                        <textarea rows="4" placeholder='description' value={description} onChange={e => setDescription(e.target.value)} disabled={!props?.isStudentSubmittedExtraWork && isStudent || props?.isStudentAlloedToEditExtraWork && isStudent ? false : true} className='form-control'></textarea>
                    </div>
                    <div className="col-sm-12">
                        Attachments (size of all files should be less than 25MB) : {props?.isStudentAlloedToEditExtraWork && isStudent || !props?.isStudentSubmittedExtraWork && isStudent ? <button className="btn btn-success" onClick={handleAddAttachment}>+</button> : null}<br />
                        {attachments?.map((item, index) => <div className="mt-2">
                            {props?.isStudentAlloedToEditExtraWork && isStudent || !props.isStudentSubmittedExtraWork && isStudent ? <button className="btn btn-danger" onClick={() => handleRemoveAttachment(index)}>-</button> : null}
                            {item?.name && isStudent || props?.isStudentAlloedToEditExtraWork && isStudent || !props.isStudentSubmittedExtraWork && isStudent ? <label className="btn border"> choose file
                                <input type="file" className="mt-4 mb-4" onChange={(e) => { handleEditAttachment(e.target.files[0], index) }} />
                            </label> : null}
                            {!item?.name && item != '' ? <button className="btn btn-light shadow" onClick={() => { FileDownload(`${api}/download/?token=${Cookies.get('token')}&file_name=${item}&type=extrawork`) }}><IoCloudDownloadOutline /> file {index + 1}</button> : item?.name}
                        </div>)}
                        {attachments?.length == 0 ? <div className="alert alert-success p-2 text-center m-3">there is no attachments</div> : null}
                        <hr />
                    </div>
                    <div className="col-sm-12">
                        <p className='mb-3 fw-bold'>Supervisor :</p>
                        <textarea className='form-control' disabled={isSV && !props?.isSupervisorSubmitted && props?.isStudentSubmittedExtraWork && !props?.isStudentAlloedToEditExtraWork ? false : true} value={supervisorComment} onChange={e => setSupervisorComment(e.target.value)} placeholder='supervisor comment' rows="4"></textarea>
                    </div>

                </div>
            </ModalBody>
            <ModalFooter>
                <div className="row w-100">
                    <div className="col-md-12 text-center" >
                        {!props?.isStudentSubmittedExtraWork && isStudent || props?.isStudentAlloedToEditExtraWork && isStudent || isSV && props.isStudentSubmittedExtraWork && !props.isExtraWorkComplete && !props?.isStudentAlloedToEditExtraWork ?
                            <button className="btn btn-success float-end mb-2" onClick={() => {
                                if (isStudent) props.isStudentSubmittedExtraWork ? submittionByStudent('update') : submittionByStudent('new')
                                else if (isSV) submissionBySupervisor({ isSendBackToStudent: false })
                            }}>submit</button>
                            : null}
                        {isStudent && props?.isStudentSubmittedExtraWork && props?.isStudentAlloedToEditExtraWork ? <button className="btn btn-danger" onClick={deleteAlert}>Delete</button> : null}
                        {isSV && props?.isStudentSubmittedExtraWork && !props?.isStudentAlloedToEditExtraWork && !props.isFdfSubmissionCompleted /*&& !props.isExtraWorkComplete */ ? <button className='btn btn-warning mb-2' onClick={() => modificationAlert()}>send back to student for modification</button> : null}
                        <button className="btn btn-warning float-start mb-2" onClick={toggle}>close</button>

                    </div>
                </div>
            </ModalFooter>
        </Modal >
    )
}

export default ExtraWorkModal