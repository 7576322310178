import { createTheme } from "@material-ui/core/styles";

export const muiTable = {
    muiOptions: (theme) => {
        return { filterType: "dropdown", jumpToPage: true, responsive: theme ? 'stacked' : 'scroll', selectableRows: false, rowsPerPage: 10, rowsPerPageOptions: [10, 20, 30, 100] };
    },
    noTheme: () => {
        return createTheme({})
    },
    getMuiTheme: () => {
        return createTheme({
            overrides: {
                MUIDataTableBodyCell: {
                    root: {
                        paddingTop: '2px',
                        paddingBottom: '2px',
                        paddingLeft: '5px',
                        paddingRight: '5px'
                    },
                }
            },
        })
    }
}
export default muiTable