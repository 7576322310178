import React from 'react'
import { Link } from 'react-router-dom';

function PageNotFound() {
    return (
        <div className='position-relative' style={{ width: '100vw', height: '100vh' }}>
            <div className='text-center div-center p-4 shadow bg-light'>
                <h1 className='fw-bold'>404</h1>
                <h3 className='mb-3'>page not found</h3>
                You may have mistyped the address, or the page has been moved to another URL.
                <br />
                <Link to='/'><button className="btn btn-light shadow border mt-2">Back</button></Link>
            </div>
        </div>
    )
}

export default PageNotFound