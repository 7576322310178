import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { isArr } from '../../../../util/functions'
import { api } from '../../../../util/constant'
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { IoSearch } from "react-icons/io5";
import { IoEllipsisVerticalCircle, IoExpandOutline } from "react-icons/io5";
import { TableFooter, TableCell, TableRow } from "@material-ui/core";
import muiTable from '../../../../util/mui-table'
import ViewUserModal from '../../../users/viewUserModal'
import AllocateStudentToOrgModal from './allocateStudentToOrgModal'
import '../../../../style/users.css'
function Students(props) {

  const [userInfo, setUserInfo] = useState({})
  const [isViewUserModal, setIsViewUserModal] = useState(false)
  const [isAllocateStudentToOrgModal, setIsAllocateStudentToOrgModal] = useState(false)

  const [students, setStudents] = useState([])
  const [theme, setTheme] = useState(false)
  const [isSearch, setIsSearch] = useState(false)
  const [tablePageNumber, setTablePageNumber] = useState(1)
  const [tableRowsLimit, setTableRowsLimit] = useState(10)
  const [tableTotalPages, setTotalPages] = useState(0)
  const [tableTotalRows, setTableTotalRows] = useState(0)
  const [tableSearch, setTableSearch] = useState('')
  const [tableSearchType, setTableSearchType] = useState('')

  const loader = status => props.loader(status)

  const getStudents = () => {
    loader(true)
    const search = handleSearch()

    axios.get(`${api}/org/allocated_students`, { headers: { search: JSON.stringify(search), org: props?.orgnizationId, page: tablePageNumber, limit: tableRowsLimit }, withCredentials: true })
      .then((res) => {
        const { users, totalPages, totalUsers } = res?.data
        setStudents(isArr(users));
        setTotalPages(totalPages);
        setTableTotalRows(totalUsers)
        loader(false)
      })
      .catch((e) => { loader(false) })
  }

  const handleSearch = () => {
    if (!isSearch) return {}
    else if (tableSearchType == 'name') return { 'fullname': { $regex: tableSearch, $options: "i" } }
    else if (tableSearchType == 'email') return { 'email': { $regex: tableSearch, $options: "i" } }
    else if (tableSearchType == 'phone') return { 'phone': { $regex: tableSearch, $options: "i" } }
    else return {}
  }

  const getStudent = async (id) => {
    loader(true)
    await axios.get(`${api}/users`, { headers: { id }, withCredentials: true })
      .then((res) => { setUserInfo(res.data); setIsViewUserModal(true); loader(false); })
      .catch((e) => { loader(false); console.log(e); })
  }

  const columns = [{ label: "Full Name", options: { filter: false } }, "Phone", "Orgnization", "Email", {
    label: "Supervisors",
    options: {
      filter: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (<>{isArr(tableMeta.rowData[tableMeta.columnIndex]).map((item, index) => <p key={index}>{index + 1}. {item.fullname}</p>)}</>)
      }
    }
  }, {
    label: "Technical Authorities",
    options: {
      filter: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (<>{isArr(tableMeta.rowData[tableMeta.columnIndex]).map((item, index) => <p key={index}>{index + 1}. {item.fullname}</p>)}</>)
      }
    }
  }, {
    label: "",
    options: {
      filter: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (<span style={{ fontSize: "20px" }} className="btn p-0" onClick={() => getStudent(tableMeta.rowData[tableMeta.columnIndex])}><IoEllipsisVerticalCircle /></span>)
      }
    }
  }];

  let options = muiTable.muiOptions(theme)
  options = {
    ...options,
    rowsPerPage: 100,
    search: false,
    serverSide: true,
    customFooter: () => {
      return (
        <TableFooter>
          <TableRow>
            <TableCell colSpan={6}>
              page {tablePageNumber} of {tableTotalPages} - total users {tableTotalRows}
              <button className="btn btn-light" disabled={tablePageNumber == 1 ? true : false} onClick={() => setTablePageNumber(prev => prev - 1)}>{`<`}</button>
              <button className="btn btn-light" disabled={tablePageNumber == tableTotalPages || tableTotalPages == 0 ? true : false} onClick={() => setTablePageNumber(prev => prev + 1)}>{`>`}</button>
              jump to <select onChange={e => setTablePageNumber(+e.target.value)}>{[...Array(tableTotalPages)].map((item, i) => <option value={i + 1} key={i}>{i + 1}</option>)}</select>
              per page <select onChange={e => setTableRowsLimit(+e.target.value)}>{[...Array(10)].map((item, i) => <option value={(i + 1) * 10} key={i}>{(i + 1) * 10}</option>)}</select>
            </TableCell>
          </TableRow>
        </TableFooter>
      );
    }
  }

  useEffect(() => { getStudents() }, [tablePageNumber, tableRowsLimit, isSearch])
  useEffect(() => { setTablePageNumber(1) }, [tableRowsLimit])
  return (
    <>
      <ViewUserModal modal={isViewUserModal} isAdminOrEidaad={false} refreshApp={() => getStudents()} info={userInfo} loaderToggle={status => props.loader(status)} modalToggle={modal => setIsViewUserModal(modal)} />
      <AllocateStudentToOrgModal refresh={() => getStudents()} orgnizationId={props?.orgnizationId} modal={isAllocateStudentToOrgModal} setModal={status => setIsAllocateStudentToOrgModal(status)} loader={status => props.loader(status)} />
      {props?.isAllowAddStudents == true ? <div className='p-2'>
        <button className="btn btn-success shadow border float-end mb-1" onClick={() => setIsAllocateStudentToOrgModal(prev => !prev)}>+</button>
      </div>
        : null}
      <div className="p-4">
        {!isSearch ? <button className="btn btn-warning mb-1" onClick={() => setIsSearch(prev => !prev)}>search <IoSearch /></button>
          :
          <div className="row">
            <div className="col-sm-6"><input className='form-control' placeholder='search' onChange={e => setTableSearch(e.target.value)} /></div>
            <div className="col-sm-3 "><select value={tableSearchType} className='w-100' onChange={e => setTableSearchType(e.target.value)}><option value="">{`<select>`}</option><option value="name">name</option><option value="email">email</option><option value="phone">phone</option></select></div>
            <div className="col-sm-1"><button className="btn btn-warning mb-1" onClick={() => { getStudents(); setTablePageNumber(1) }}><IoSearch /></button></div>
            <div className="col-sm-1"><button className="btn btn-danger mb-1" onClick={() => setIsSearch(prev => !prev)}>X</button></div>
          </div>
        }
        <MuiThemeProvider theme={theme ? muiTable.getMuiTheme() : muiTable.noTheme()}>
          <MUIDataTable
            title={<>
              <button className="btn btn-light shadow" disabled >All Users</button>
              <button onClick={() => { setTheme(!theme); }} className="btn btn-light shadow"><IoExpandOutline /></button>
            </>}
            data={students.map(item => [item?.fullname, item?.phone, item?.orgnization_id?.name, item?.email, item?.supervisors, item?.technical_authorities, item?._id])}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </div>
    </>
  )
}




export default Students