import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Dropdown } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import axios from 'axios';
import { api } from '../../../../util/constant';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { isArr } from '../../../../util/functions';

function AllocateStudentToOrgModal(props) {

    const toggle = () => props.setModal(!props.modal)
    const loader = (status) => props.loader(status)

    const [newStudent, setNewStudent] = useState('')
    const [searchResult, setSearchResult] = useState([])
    const [searchType, setSearchType] = useState('fullname')

    const handleUpdateAllocatedStudents = () => {
        if (newStudent == '') return Notify.failure('please select student');
        loader(true);
        axios.post(`${api}/org/allocated_students`, { id: newStudent, org: props?.orgnizationId }, { withCredentials: true })
            .then((res) => { loader(false); props.refresh(); toggle(); reset() })
            .catch((e) => { loader(false); Notify.failure(e.response.data); })
    }

    const searchStudent = async (search) => {
        await axios.get(`${api}/users`, { headers: { search: JSON.stringify({ [searchType]: { $regex: search, $options: "i" }, role_id: 3 }), limit: 100 }, withCredentials: true })
            .then((res) => {
                const { users } = res?.data
                setSearchResult(isArr(users));
            })
            .catch((e) => { console.log(false) })
    }

    const reset = () => { setNewStudent(''); setSearchResult([]); }

    useEffect(() => { if (!props.modal) { reset() }; }, [props.modal])

    return (
        <Modal size="lg" style={{ maxWidth: '700px', width: '100%' }} isOpen={props.modal} toggle={() => { toggle(props) }}>
            <ModalHeader toggle={() => { toggle(props) }}>Students</ModalHeader>
            <ModalBody>
                <div className="row">
                    <div className="col-sm-12">
                        <input type="radio" checked={searchType == 'fullname'} name="type" onClick={() => setSearchType('fullname')} /> Name{`  `}
                        <input type="radio" checked={searchType == 'phone'} name="type" onClick={() => setSearchType('phone')} /> Phone{`  `}
                        <input type="radio" checked={searchType == 'email'} name="type" onClick={() => setSearchType('email')} /> Email
                    </div>
                    <div className="col-sm-8">
                        <Dropdown placeholder='<Search>'
                            fluid
                            search
                            selection
                            clearable
                            options={searchResult ? searchResult.map(item => { return { key: item.key, text: item?.[searchType], value: item?._id } }) : []}
                            onChange={(e, d) => { setNewStudent(d.value); }}
                            onSearchChange={e => { searchStudent(e.target.value) }}
                            value={newStudent}
                        />
                    </div>
                    <div className="col-sm-2 text-center"><button className="btn btn-success" onClick={() => { handleUpdateAllocatedStudents() }}>Add</button></div>
                </div>

            </ModalBody>

        </Modal>
    )
}

export default AllocateStudentToOrgModal