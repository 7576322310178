import React from 'react'
import Card from './card'

function FdfSub({ numberOfCompletedCompetencies, numberOfCompletedCourses, numberOfCompletedFdfSubmissions, numberOfCompletedJobtasks, numberOfFdfSubmissions }) {
    return (
        <>
            <div className="row m-2 p-2 shadow bg-white60">
                <h1 className='text-center mb-3'>Frame Works Submissions</h1>
                <div className="p-2 col-md-4"><Card title={'Completed Competencies'} number={numberOfCompletedCompetencies} /></div>
                <div className="p-2 col-md-4"><Card title={'Completed Courses (Learning needs)'} number={numberOfCompletedCourses} /></div>
                <div className="p-2 col-md-4"><Card title={'Completed Jobtasks'} number={numberOfCompletedJobtasks} /></div>
                <div className="p-2 col-md-4"><Card title={'Total of Completed Submissions'} number={numberOfCompletedFdfSubmissions} /></div>
                <div className="p-2 col-md-4"><Card title={'Total Submissions'} number={numberOfFdfSubmissions} /></div>

            </div>
        </>
    )
}

export default FdfSub