import axios from 'axios'
import React, { useState, useEffect, useContext } from 'react'
import { api } from '../../../../util/constant'
import { isArr, identifyRole } from '../../../../util/functions'
import StudentsModal from './studentsModal'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Cxt } from '../../../../App'

function Fdf(props) {

    const [title, setTitle] = useState('')
    const [technicalAuths, setTechnicalAuths] = useState([])
    const [superVisors, setSuperVisors] = useState([])
    const [learningNeeds, setLearningNeeds] = useState([])
    const [competencies, setCompetencies] = useState([])
    const [submissions, setSubmissions] = useState([])
    const [page, setPage] = useState(0)
    const [isArchived, setIsArchived] = useState(false)

    //student modal
    const [modal, setModal] = useState(false)
    const [selectedStudents, setSelectedStudents] = useState([])
    const [studentsCanJoin, setStudentsCanJoin] = useState([])

    const { role } = useContext(Cxt);

    const openStudentModal = () => {
        loader(true);
        axios.get(`${api}/fdf/students`, { headers: { disciplineId: props?.selectedDiscipline?.id, fdfId: props?.selectedFdf?.id }, withCredentials: true })
            .then((res) => {
                let { selectedStudentsInFdf = [] } = res?.data
                setSelectedStudents(isArr(selectedStudentsInFdf))
                loader(false);
                setModal(true);
            })
            .catch((e) => { loader(false); console.log(e) })
    }

    const loader = (status) => { props.loader(status) }
    const getFdf = () => {
        loader(true)
        axios.get(`${api}/fdf`, { headers: { fdfId: props?.selectedFdf?.id, populate: true, archived: isArchived }, withCredentials: true })
            .then((res) => {
                loader(false);
                setTitle(res?.data?.title);
                setTechnicalAuths(isArr(res?.data?.technicalAuths));
                setSuperVisors(isArr(res?.data?.supervisors));
                setLearningNeeds(isArr(res?.data?.learning_need));
                setCompetencies(isArr(res?.data?.competencies));
                setSubmissions(isArr(res?.data?.submissions));
            })
            .catch((e) => { loader(false); console.log(e) })
    }

    const handleViewJobTasks = (competency_index) => {
        let _competencies = [...competencies]
        _competencies[competency_index].isViewJobtasks = _competencies[competency_index]?.isViewJobtasks == true ? false : true
        setCompetencies(_competencies)
    }

    const confirmDelete = () => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete this FDF ?',
            buttons: [{ label: 'Delete', onClick: () => { handleDeleteFdf(); } }, { label: 'cancel', onClick: () => { } }]
        });
    }

    const handleDeleteFdf = () => {
        axios.delete(`${api}/fdf`, { headers: { fdfId: props?.selectedFdf?.id }, withCredentials: true })
            .then((res) => {
                loader(false);
                props?.setSelectedFdf({ name: '', id: '' })
            })
            .catch((e) => { loader(false); console.log(e) })
    }

    useEffect(() => { getFdf(); }, [])
    useEffect(() => { if (!modal) { setSelectedStudents([]); setStudentsCanJoin([]); } }, [modal])
    useEffect(() => { getFdf() }, [isArchived])

    const isThereFuncionAndDiscipine = props?.selectedFunction?.name && props?.selectedDiscipline?.name ? true : false
    const { isOFFP, isOFP, isAdminOrEidaad } = identifyRole(role)

    return (
        <>
            <StudentsModal
                AllowToAddAnyStudent={props?.AllowToAddAnyStudent}
                selectedOrg={props?.selectedOrg}
                selectedFdfId={props?.selectedFdf?.id}
                refresh={() => openStudentModal()}
                loader={loader => props.loader(loader)}
                modal={modal}
                setModal={modal => setModal(modal)}
                selectedStudents={selectedStudents}
                studentsCanJoin={studentsCanJoin}
            />

            {props.AllowToAddNew ? <div className='p-2'>
                <button className="btn btn-success float-end me-3" onClick={() => { openStudentModal() }}>Students</button>
                <div className="float-end p-1 me-2 shadow bg-white60"><input type="checkbox" value={isArchived} onClick={() => setIsArchived(prev => !prev)} className='me-2' />Archived</div>
            </div> : null}
            <div className='p-2'>
                {props.isBack ? <button className="btn btn-light border" style={{ fontSize: '10px' }} onClick={() => { props?.setSelectedFdf({ name: '', id: '' }) }}>&larr; back | {isThereFuncionAndDiscipine ? <><span className='text-success'> {`${props?.selectedFunction?.name}`}</span> <b>&#xab;</b> <span className='text-success'>{`${props.selectedDiscipline.name}`} </span><b>&#xab;</b></> : null} <span className='text-success'>{`${props.selectedFdf.name}`}</span></button> : null}
            </div>
            <p className="h6  text-center p-2 shadow bg-white60 mb-4"><span className='fw-bold'> </span>{props?.selectedFdf?.name}<span className='fw-bold'></span></p>
            <div className="col-md-12 mb-2 p-0 shadow">
                <button className={`sub-nav w50 left ${page == 0 ? 'active' : ''}`} onClick={() => { setPage(0) }}>Details</button>
                <button className={`sub-nav w50 mid ${page == 1 ? 'active' : ''}`} onClick={() => { setPage(1) }}>Submissions</button>
            </div>
            {page == 0 ? <div className="row shadow blur bg-white60 p-2 me-2 mb-4">
                <div className="col-md-12 pb-3">
                    <p className='fw-bold'>Title :</p>
                    {title}
                </div>
                <div className="col-md-12 ">
                    <p className='fw-bold'>Technical Authority :</p>
                    <ul>{technicalAuths.map((item, index) => <li key={`technicalAuths-${index}`}>{item.fullname}</li>)}</ul>
                </div>

                <div className="col-md-12 ">
                    <p className='fw-bold'>Supervisors :</p>
                    <ul>{superVisors.map((item, index) => <li key={`superVisors-${index}`}>{item.fullname}</li>)}</ul>
                </div>
                <div className="col-md-12">
                    <p className='fw-bold'>Learning Needs : ({learningNeeds?.length})</p>
                    <ul>{learningNeeds.map((item, index) => <li key={`learningNeeds-${index}`}>{item.name}({item.id})</li>)}</ul>
                </div>
                <div className="col-md-12 mt-1">
                    <p className='fw-bold'>competencies : ({competencies?.length})</p>
                    {competencies.map((competency_item, competency_index) => {
                        return (<div className="row border-start border-success p-2 mb-3 light-onhover" key={`competency-${competency_index}`}>
                            <div><b>Competency {competency_index + 1} : </b>{competency_item.title} <br /></div>
                            <div><b>Level: </b>{competency_item.level} <br /></div>
                            <div><b>Description: </b>{competency_item.description}</div> <br />
                            <div className='col-sm-12 p-2'>
                                <div><button className="btn btn-success" onClick={() => handleViewJobTasks(competency_index)}>Job Tasks: ({competency_item?.job_tasks?.length})</button> </div>
                                {competency_item?.isViewJobtasks == true ?
                                    <div className="p-2 rounded">
                                        {competency_item?.job_tasks.map((jobTask_item, jobTask_Index) => {
                                            return (
                                                <div className='p-2 bg-white-1 rounded mb-2 border-start border-warning position-relative mt-2' key={`jobTask-${jobTask_Index}`}>
                                                    <div className="rounded-circle bg-success position-absolute text-center border " style={{ width: '22px', height: '22px', top: '-10px', left: '-12px' }}>{jobTask_Index + 1}</div>
                                                    <div><b>Jobtask {jobTask_Index + 1} :</b> {jobTask_item.title}</div>
                                                    <div><b>ID :</b> {jobTask_item.job_task_id}</div>
                                                    <div><b>description :</b> <br />{jobTask_item.description}</div>
                                                    <div><b>activities : ({jobTask_item?.activities?.length}) </b> <br /></div>
                                                    <ul>{jobTask_item?.activities?.map((activity_item, activity_index) => <li key={`activity-${activity_index}`}>{activity_item}</li>)}</ul>
                                                </div>
                                            )
                                        })}
                                    </div> : null}
                            </div>
                        </div>)
                    })}
                </div>
                {isOFFP || isOFP || isAdminOrEidaad ? <div className="p2"><button className="btn btn-danger float-end" onClick={confirmDelete}>delete</button></div> : null}
            </div> : <Submissions submissions={submissions} setSelectedSubmission={student_id => props.setSelectedSubmission(student_id)} />}
        </>
    )
}


const Submissions = (props) => {
    return (
        <div className="row m-2" >
            <div className="col-lg-12 p-2 shadow bg-white60 ">
                {
                    props?.submissions?.length != 0 ?
                        props?.submissions?.map((item, index) =>
                            <div key={`submissions-${index}`} className={`w-100 pointer border p-2 rounded mb-3 mb-1 position-relative h6 ${item?.is_complete ? 'btn-success' : ''}`} onClick={() => props.setSelectedSubmission(item?.student?._id)} >
                                <div className="position-absolute bg-light p-0 pe-1 ps-1 borderd rounded text-dark" style={{ top: '-10px', left: 0, fontSize: '10px' }}>{new Date(item?.updatedAt).toLocaleString()}</div>
                                {index + 1}. {item?.student?.fullname} {item?.is_complete ? <><button className='btn btn-light p-0' disabled>Completed </button> <button className="btn btn-light p-0" disabled >{item?.score}%</button></> : ''}
                            </div>)
                        :
                        <div className="alert alert-success mb-0 text-center">there is no submissions in this framework</div>
                }
            </div>
        </div>
    )
}


export default Fdf