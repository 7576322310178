import React, { useContext, useState } from 'react';
import '../style/dashboard.css';
import Nav from '../components/nav';
import { Cxt } from '../App'
import AdminAndEidaad from '../components/dashboard/users/adminAndEidaad';
import Student from '../components/dashboard/users/student';
import AfpAndAdvisor from '../components/dashboard/users/afpAndAdvisor';
import Ofp from '../components/dashboard/users/ofp';
import Offp from '../components/dashboard/users/offp';
import SupervisorAndTa from '../components/dashboard/users/supervisorAndTa';
import Loader from '../components/loader'
import { identifyRole } from '../util/functions'

const Dashboard = () => {
    const { role } = useContext(Cxt);
    const [loader, setLoader] = useState(false);
    const { isAFP, isOFP, isOFFP, isStudent, isSV, isTA, isAdminOrEidaad, isAdvisor } = identifyRole(role);
    return (
        <>
            <Nav />
            {loader ? <Loader /> : null}
            {isAdminOrEidaad ? <AdminAndEidaad loderToggle={loader => setLoader(loader)} /> : null}
            {isStudent ? <Student loderToggle={loader => setLoader(loader)} /> : null}
            {isAFP || isAdvisor ? <AfpAndAdvisor loderToggle={loader => setLoader(loader)} /> : null}
            {isOFP ? <Ofp loderToggle={loader => setLoader(loader)} /> : null}
            {isOFFP ? <Offp loderToggle={loader => setLoader(loader)} /> : null}
            {isSV || isTA ? <SupervisorAndTa loderToggle={loader => setLoader(loader)} /> : null}

        </>
    );
}

export default Dashboard;
