import React, { useState } from 'react'
import Nav from '../components/nav';
import Location from '../components/location';
import Major from '../components/disciplinesAndMajors/major';
import Loader from '../components/loader';
import Discipline_Component from '../components/disciplinesAndMajors/discipline';
import Mapping from '../components/disciplinesAndMajors/mapping';
function Discipline() {
    const [component, setComponent] = useState('1');
    const [loader, setLoader] = useState(false);
    return (
        <>
            <Nav />
            <Location />
            {loader ? <Loader /> : null}
            <div className="row">
                <div className="col-md-12 mb-2 p-0 shadow">
                    <button className={`sub-nav w33 left ${component == 1 ? 'active' : ''}`} onClick={() => { setComponent(1) }}>Demand</button>
                    <button className={`sub-nav w33 mid ${component == 2 ? 'active' : ''}`} onClick={() => { setComponent(2) }}>Major</button>
                    <button className={`sub-nav w33 right ${component == 3 ? 'active' : ''}`} onClick={() => { setComponent(3) }}>Mapping</button>
                </div>
            </div>

            {component == 1 ? <Discipline_Component loaderToggle={loader => setLoader(loader)} /> : null}
            {component == 2 ? <Major loaderToggle={loader => setLoader(loader)} /> : null}
            {component == 3 ? <Mapping loaderToggle={loader => setLoader(loader)} /> : null}
        </>
    )
}


export default Discipline
