import React, { useState } from 'react'
import axios from 'axios'
import Nav from '../components/nav'
import Location from '../components/location'
import Loader from '../components/loader'
import { api } from '../util/constant'
import Users from '../components/reports/users'
import Orgs from '../components/reports/orgs'
import Fdf from '../components/reports/fdf'
import FdfSub from '../components/reports/fdfSub'
import { Notify } from 'notiflix/build/notiflix-notify-aio';

function Reports(props) {
    const [loader, setLoader] = useState(false)
    const [startDate, setStartDate] = useState(undefined)
    const [endDate, setEndDate] = useState(undefined)
    const [fdf, setFdf] = useState({})
    const [fdfSub, setFdfSub] = useState({})
    const [orgs, setOrgs] = useState({})
    const [users, setUsers] = useState({})
    const [isReportGenrated, setIsReportGenrated] = useState(false)

    const getReport = () => {
        setLoader(true)
        axios.post(`${api}/org/reports`, {}, { withCredentials: true })
            .then(res => {
                setLoader(false)
                setFdf(res?.data?.fdf)
                setFdfSub(res?.data?.fdfSub)
                setOrgs(res?.data?.org)
                setUsers(res?.data?.users)
                Notify.success('Report Genrated Successfully')
                setIsReportGenrated(true)

            }).catch(e => {
                setLoader(false)
                Notify.failure('somthing went wrong')
            })
    }
    return (
        <>
            <Nav />
            <Location />
            {loader ? <Loader /> : null}
            <div className="row shadow mb-2 mt-2 bg-white60 p-2">

                <div className="col-md-4">
                    Orgnization
                    <select className='form-control' disabled>
                        <option value="All">All</option>
                    </select>
                </div>
                <div className="col-md-4 ">
                    From
                    <input type="date" className='form-control' disabled onChange={e => setStartDate(e.target.value)} />
                </div>
                <div className="col-md-4 ">
                    To
                    <input type="date" className='form-control' disabled onChange={e => setEndDate(e.target.value)} />
                </div>
                <div className="col-md-12 ">
                    <div className='alert alert-success h6'>search by date and selecting specific orgnization are coming soon.</div>

                </div>
                <div className="col-md-12 text-center">
                    <button className="btn btn-success" onClick={getReport}>Generate Report</button>
                </div>
            </div>
            {isReportGenrated ? <>
                <Users
                    numberOfOffp={users?.numberOfOffp}
                    numberOfOfp={users?.numberOfOfp}
                    numberOfStudents={users?.numberOfStudents}
                    numberOfSupervisors={users?.numberOfSupervisors}
                    numberOfTa={users?.numberOfTa}
                    numberOfAFP={users?.numberOfAFP} />

                <Fdf numberOfActivities={fdf?.numberOfActivities}
                    numberOfCompetencies={fdf?.numberOfCompetencies}
                    numberOfFdfs={fdf?.numberOfFdfs}
                    numberOfJobtasks={fdf?.numberOfJobtasks} />

                <FdfSub numberOfCompletedCompetencies={fdfSub?.numberOfCompletedCompetencies}
                    numberOfCompletedCourses={fdfSub?.numberOfCompletedCourses}
                    numberOfCompletedFdfSubmissions={fdfSub?.numberOfCompletedFdfSubmissions}
                    numberOfCompletedJobtasks={fdfSub?.numberOfCompletedJobtasks}
                    numberOfFdfSubmissions={fdfSub?.numberOfFdfSubmissions} />
                <Orgs
                    numberOfMajors={orgs?.numberOfMajors}
                    numberOfAcadamicInstitutions={orgs?.numberOfAcadamicInstitutions}
                    numberOfIndustrialInstitutions={orgs?.numberOfIndustrialInstitutions}
                    numberOfCourses={orgs?.numberOfCourses}
                    numberOfDisciplines={orgs?.numberOfDisciplines}
                    numberOfFunctions={orgs?.numberOfFunctions}
                    allocatedStudentsInIndustrialOrgs={orgs?.allocatedStudentsInIndustrialOrgs}
                    studentsInAcadamicOrgs={orgs?.studentsInAcadamicOrgs} />

            </> : null}

        </>
    )
}




export default Reports