import React from 'react'
import { Bar, PolarArea } from 'react-chartjs-2';

function Chart(props) {
    const data = {
        labels: ['acadamic Organizations', 'industry Organizations',],
        datasets: [
            {
                label: 'Orgnizations',
                data: [props.data?.acadamicalOrgsCount, props.data?.industrialOrgsCount],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.5)',
                    'rgba(54, 162, 235, 0.5)'
                ],
                borderWidth: 1,
            },
        ],
    };
    const data_bar = {
        labels: ['today', 'week', 'two weeks', 'month'],
        height: 50,
        datasets: [
            {
                label: 'Login',
                data: [props?.data?.loginCount?.today,
                props?.data?.loginCount?.lastWeek,
                props?.data?.loginCount?.twoWeeks,
                props?.data?.loginCount?.lastMonth],
                backgroundColor: 'rgb(75, 192, 192)',
            },
            {
                label: 'Regestration',
                data: [props?.data?.regCount?.today,
                props?.data?.regCount?.lastWeek,
                props?.data?.regCount?.twoWeeks,
                props?.data?.regCount?.lastMonth],
                backgroundColor: '	rgb(216,191,216)',
            },
        ],
    };

    const options_bar = {
        scales: {
            yAxes: [
                {
                    stacked: true,
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
            xAxes: [
                {
                    stacked: true,
                },
            ],
        },
    };
    return (
        <div className="row " style={{ width: "100%" }}>

            <div className="col-md-6 col-sm-12" >
                <div className="smooth-box-shadow bg-white60">
                    <PolarArea data={data} height={330} options={{ maintainAspectRatio: false }} />
                </div>
            </div>
            <div className="col-md-6 col-sm-12"  >
                <div className="smooth-box-shadow bg-white60">
                    <Bar data={data_bar} options={options_bar} />
                </div>
            </div>
        </div>
    )
}

export default Chart