import React, { useState, useEffect, useContext } from 'react'
import { identifyRole, isArr } from '../../../../util/functions'
import LearningNeedsComponent from './learningNeeds'
import Competencies from './competencies'
import CompetenciesForCompletedFdfSubmission from './CompetenciesForCompletedFdfSubmission'
import LearningNeedsComponentForCompletedFdfSubmission from './LearningNeedsComponentForCompletedFdfSubmission'
import ExtraWork from './extrawork'
import { Cxt } from '../../../../App'
import axios from 'axios'
import { Notify } from 'notiflix'
import { api } from '../../../../util/constant'
import { IoFileTrayFullOutline, IoReloadOutline } from "react-icons/io5";
import HistoryModal from '../historyModal'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

function FdfSubmission(props) {
  const loader = (status) => { props.loader(status) }

  const [details, setDetails] = useState({})
  const [submission, setSubmission] = useState({})

  const [modalHistory, setModalHistory] = useState(false)
  const [historyInfo, setHistoryInfo] = useState([])

  const { role } = useContext(Cxt);
  const { isStudent, isTA, isOFP, isOFFP, isAdminOrEidaad, isSV } = identifyRole(role);
  useEffect(() => { setDetails(props?.details); setSubmission(props?.submission); }, [props?.details, props?.submission])
  const isThereFuncionAndDiscipine = props?.selectedFunction?.name && props?.selectedDiscipline?.name ? true : false

  const getSubmissionHistory = () => {
    loader(true)
    axios.get(`${api}/fdf/history`, { headers: { fdfSubmission: submission?._id }, withCredentials: true })
      .then((res) => { loader(false); setModalHistory(true); setHistoryInfo(res?.data) })
      .catch((e) => { loader(false); console.log(e) })
  }

  const changeSubmissionStatus = () => {
    loader(true)
    axios.post(`${api}/fdf/sub/status`, { submission_id: submission?._id, is_complete: !submission?.is_complete }, { withCredentials: true })
      .then((res) => { loader(false); props.refresh() })
      .catch((e) => { loader(false); console.log(e) })
  }
  return (<>
    <HistoryModal
      modal={modalHistory}
      setModal={modal => setModalHistory(modal)}
      info={historyInfo}
    />
    <div className='p-2'>
      {props.isBack ? <div className='p-2'>
        <button className="btn btn-light border" style={{ fontSize: '10px' }} onClick={() => { props.setSelectedSubmission('') }}>&larr; back | {isThereFuncionAndDiscipine ? <><span className='text-success'> {`${props?.selectedFunction?.name}`}</span> <b>&#xab;</b> <span className='text-success'>{`${props.selectedDiscipline.name}`} </span><b>&#xab;</b></> : null} <span className='text-success'>{`${props?.selectedFdf?.name}`}</span> <b>&#xab;</b> <span className="text-success">{submission?.student?.fullname}</span></button>
      </div> : null}
      <div className='p-2 pb-5'>
        <button className="btn btn-warning float-end me-1" onClick={getSubmissionHistory}><IoFileTrayFullOutline size={17} /> History</button>
        <button className="btn btn-success float-end me-1" onClick={() => props.refresh()}><IoReloadOutline size={17} /> </button>

      </div>
      <p className="h6 text-center p-2 shadow bg-white60 rounded"><span className='fw-bold'>(</span>{submission?.is_complete ? submission?.title_when_completed : details?.title}<span className='fw-bold'>)</span></p>
      <div className="row">
        <div className="col-sm-12 p-2">
          {submission ?
            <div className='bg-white60 p-3 shadow rounded'>
              <b>Technical Authority: </b><ol className='pb-0 mb-0'>{isArr(submission?.is_complete ? submission?.technicalAuths_when_completed : submission?.student?.technical_authorities)?.map((item, index) => <li className='mb-0 pb-0' key={`TA-${index}`}>{item.fullname}</li>)}</ol>
              <b>Supervisors:</b><br /><ol className='pb-0 mb-0'>{isArr(submission?.is_complete ? submission.supervisors_when_completed : submission?.student?.supervisors)?.map((item, index) => <li className='mb-0 pb-0' key={`SV-${index}`}>{item.fullname}</li>)}</ol>
              <b>Student : </b> <br /><ul className='pb-0 mb-0'><li className='mb-0 pb-0'>{submission?.student?.fullname}</li></ul>
              <TechnicalAuthComponent comment_by_ta={submission?.comment_by_ta} isStudent={isStudent} score={submission?.score} submission_id={submission?._id} isTA={isTA} is_complete={submission?.is_complete} loader={status => props.loader(status)} refresh={() => props.refresh()} />
              <b>Status : </b>{submission?.is_complete ? <span className='text-success'>Completed</span> : 'In Progress'}
              {isOFP || isOFFP || isAdminOrEidaad || isSV ? <button className="btn btn-success float-end me-1"
                onClick={() => confirmAlert({
                  title: 'Change Submisssion Status',
                  message: `Are you sure you want to change status to ${submission?.is_complete ? '"In Progress"' : '"Completed"'}  ?`,
                  buttons: [{ label: 'Confirm', onClick: () => { changeSubmissionStatus(); } }, { label: 'cancel', onClick: () => { } }]
                })}
              >{submission?.is_complete ? 'Change Status To "In Progress"' : 'Change Status To "Completed"'}</button> : null}
              <br />
            </div> : null}
        </div>
        {
          submission?.is_complete ?
            <CompetenciesForCompletedFdfSubmission
              loader={status => props.loader(status)}
              refresh={() => props.refresh()}
              submission={submission}
              details={isArr(submission?.competencies)}
              fdf_id={details?._id}
              role={role}
            />
            :
            <Competencies
              loader={status => props.loader(status)}
              refresh={() => props.refresh()}
              submission={submission}
              details={isArr(details?.competencies)}
              fdf_id={details?._id}
              role={role}
            />
        }
        <hr className='m-3' />
        {
          submission?.is_complete ?
            <LearningNeedsComponentForCompletedFdfSubmission
              loader={status => props.loader(status)}
              refresh={() => props.refresh()}
              submission={submission}
              details={details}
              fdf_id={details?._id}
            />
            :
            <LearningNeedsComponent
              loader={status => props.loader(status)}
              refresh={() => props.refresh()}
              submission={submission}
              details={details}
              fdf_id={details?._id}
            />
        }
        <hr className='m-3' />
        <ExtraWork
          loader={status => props.loader(status)}
          refresh={() => props.refresh()}
          submission={submission}
          details={details}
          fdf_id={details?._id}
          isStudent={isStudent}
          isSubmissionCompleted={submission?.is_complete}
        />
      </div>
    </div>
  </>
  )
}


const TechnicalAuthComponent = ({ isStudent, is_complete, score, isTA, loader, refresh, submission_id, comment_by_ta: _comment_by_ta }) => {
  const [newScore, setNewScore] = useState('')
  const [comment_by_ta, setComment_by_ta] = useState(_comment_by_ta || '')
  useEffect(() => { setComment_by_ta(_comment_by_ta || '') }, [_comment_by_ta])
  const handleScoreChange = (e) => {
    const value = e.target.value.trim()
    setNewScore(prev => value.length <= 3 && !isNaN(+value) && +value <= 100 ? value : prev)
  }
  const saveScore = ({ isScore }) => {
    if (newScore == '' && isScore || !isScore && comment_by_ta == '') return Notify.failure(`${isScore ? 'score' : 'comment'} cannot be empty`);

    loader(true)
    const obj = isScore ? { score: newScore } : { comment_by_ta }
    axios.post(`${api}/fdf/sub`, { ...obj, submission_id }, { withCredentials: true })
      .then((res) => { Notify.success('submitted successfully'); loader(false); refresh() })
      .catch((e) => { console.log(e); loader(false); Notify.failure('somthing went wrong'); })
  }
  return (
    <div class="row mt-2">
      <div className='col-md-12'>
        {comment_by_ta && !isTA ? <><b>Comment by Technical Authority:</b> {comment_by_ta}</> : null}
        {isTA ? <>Comment by Technical Authority :<textarea className='form-control' value={comment_by_ta} onChange={e => setComment_by_ta(e.target.value)} rows="4"></textarea>
          <div className="text-center"><button className="btn btn-success mt-2" onClick={() => saveScore({ isScore: false })}>Save Comment</button></div></> : null}
      </div>
      {!isStudent ? <>
        <div class="col-md-4 fw-bold">Score : {score ? score : 'Not assigned'}</div>
        {isTA && is_complete && !score ?
          <>
            <div class="col-md-4">
              <input type="text" placeholder='score /100' value={newScore} onChange={e => handleScoreChange(e)} className='form-control' />
            </div>
            <div class="col-md-4">
              <button className="btn btn-success" onClick={() => saveScore({ isScore: true })}> Submit</button>
            </div>
          </> :
          null}
      </> : null}

    </div>
  )
}




export default FdfSubmission