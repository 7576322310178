import React, { useState, useEffect, useContext } from 'react';
import MUIDataTable from "mui-datatables";
import Nav from '../components/nav';
import Location from '../components/location';
import { IoEllipsisVerticalCircle, IoExpandOutline } from "react-icons/io5";
import '../style/users.css'
import { TableFooter, TableCell, TableRow } from "@material-ui/core";
import NewUserModal from '../components/users/newUserModal'
import ViewUserModal from '../components/users/viewUserModal'
import { MuiThemeProvider } from "@material-ui/core/styles";
import Loader from '../components/loader'
import axios from 'axios';
import { Cxt } from '../App'
import { api } from '../util/constant'
import muiTable from '../util/mui-table'
import { isArr, identifyRole } from '../util/functions'
import { IoSearch } from "react-icons/io5";


const Users = () => {

    const [list, setList] = useState([])
    const [modal, setModal] = useState(false) // for new user modal
    const [viewModal, setViewModal] = useState(false) // for view user modal
    const [loader, setLoader] = useState(false)
    const { role } = useContext(Cxt);
    const [userInfo, setUserInfo] = useState({})// for single user information - used in view user modal

    const [theme, setTheme] = useState(false)
    const [isSearch, setIsSearch] = useState(false)
    const [tablePageNumber, setTablePageNumber] = useState(1)
    const [tableRowsLimit, setTableRowsLimit] = useState(10)
    const [tableTotalPages, setTotalPages] = useState(0)
    const [tableTotalRows, setTableTotalRows] = useState(0)
    const [tableSearch, setTableSearch] = useState('')
    const [tableSearchType, setTableSearchType] = useState('')

    const getUsers = () => {
        setLoader(true)
        const search = handleSearch()

        axios.get(`${api}/users`, { headers: { search: JSON.stringify(search), page: tablePageNumber, limit: tableRowsLimit }, withCredentials: true })
            .then((res) => {
                const { users, totalPages, totalUsers } = res?.data
                setList(isArr(users));
                setTotalPages(totalPages);
                setTableTotalRows(totalUsers)
                setLoader(false)
            })
            .catch((e) => { setLoader(false) })
    }

    const getSingleUser = (id) => {
        setLoader(true)
        axios.get(`${api}/users`, { headers: { id }, withCredentials: true })
            .then((res) => { setUserInfo(res.data); setViewModal(true); setLoader(false); })
            .catch((e) => { setLoader(false); console.log(e); })
    }

    useEffect(() => { getUsers(); }, [])
    useEffect(() => { if (!viewModal) setUserInfo({}); }, [viewModal])
    const columns = [{ label: "Full Name", options: { filter: false } }, "Role", "Orgnization", "email", {
        label: "",
        options: {
            filter: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                return (<span style={{ fontSize: "20px" }} className="btn p-0" onClick={() => getSingleUser(tableMeta.rowData[4])}><IoEllipsisVerticalCircle /></span>)
            }
        }
    }];

    const handleSearch = () => {
        if (!isSearch) return {}
        else if (tableSearchType == 'name') return { 'fullname': { $regex: tableSearch, $options: "i" } }
        else if (tableSearchType == 'email') return { 'email': { $regex: tableSearch, $options: "i" } }
        else if (tableSearchType == 'phone') return { 'phone': { $regex: tableSearch, $options: "i" } }
        else return {}
    }

    let options = muiTable.muiOptions(theme)

    options = {
        ...options,
        rowsPerPage: 100,
        search: false,
        serverSide: true,
        customFooter: () => {
            return (
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={6}>
                            page {tablePageNumber} of {tableTotalPages} - total users {tableTotalRows}
                            <button className="btn btn-light" disabled={tablePageNumber == 1 ? true : false} onClick={() => setTablePageNumber(prev => prev - 1)}>{`<`}</button>
                            <button className="btn btn-light" disabled={tablePageNumber == tableTotalPages || tableTotalPages == 0 ? true : false} onClick={() => setTablePageNumber(prev => prev + 1)}>{`>`}</button>
                            jump to <select onChange={e => setTablePageNumber(+e.target.value)}>{[...Array(tableTotalPages)].map((item, i) => <option value={i + 1} key={i}>{i + 1}</option>)}</select>
                            per page <select onChange={e => setTableRowsLimit(+e.target.value)}>{[...Array(10)].map((item, i) => <option value={(i + 1) * 10} key={i}>{(i + 1) * 10}</option>)}</select>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            );
        }
    }

    const { isAdminOrEidaad } = identifyRole(role)

    useEffect(() => { getUsers() }, [tablePageNumber, tableRowsLimit, isSearch])
    useEffect(() => { setTablePageNumber(1) }, [tableRowsLimit])

    return (
        <>
            <Nav />
            <Location />
            {loader ? <Loader /> : null}
            {isAdminOrEidaad ? <button className="btn btn-light shadow m-4" onClick={() => { setModal(true) }}>New</button> : null}
            <ViewUserModal modal={viewModal} isAdminOrEidaad={isAdminOrEidaad} refreshApp={() => { getUsers() }} info={userInfo} loaderToggle={loader => setLoader(loader)} modalToggle={modal => setViewModal(modal)} />
            <NewUserModal modal={modal} refreshApp={() => { getUsers() }} loaderToggle={loader => setLoader(loader)} modalToggle={modal => setModal(modal)} />
            <div className="p-4">
                {!isSearch ? <button className="btn btn-warning mb-1" onClick={() => setIsSearch(prev => !prev)}>search <IoSearch /></button>
                    :
                    <div className="row">
                        <div className="col-sm-6"><input className='form-control' placeholder='search' onChange={e => setTableSearch(e.target.value)} /></div>
                        <div className="col-sm-3 "><select value={tableSearchType} className='w-100' onChange={e => setTableSearchType(e.target.value)}><option value="">{`<select>`}</option><option value="name">name</option><option value="email">email</option><option value="phone">phone</option></select></div>
                        <div className="col-sm-1"><button className="btn btn-warning mb-1" onClick={() => { getUsers(); setTablePageNumber(1) }}><IoSearch /></button></div>
                        <div className="col-sm-1"><button className="btn btn-danger mb-1" onClick={() => setIsSearch(prev => !prev)}>X</button></div>
                    </div>
                }
                <MuiThemeProvider theme={theme ? muiTable.getMuiTheme() : muiTable.noTheme()}>
                    <MUIDataTable
                        title={<>
                            <button className="btn btn-light shadow" disabled >All Users</button>
                            <button onClick={() => { setTheme(!theme); }} className="btn btn-light shadow"><IoExpandOutline /></button>
                        </>}
                        data={list.map(item => { return [item?.fullname, item?.role, item?.orgnization_id?.name, item?.email, item?._id] })}
                        columns={columns}
                        options={options}
                    />
                </MuiThemeProvider>
            </div>
        </>
    );
}

export default Users;
