import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { api } from '../../util/constant';
import axios from 'axios';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
function EndYearModal(props) {
    const toggle = () => (props.changePerentToggle(!props.modal));
    const loaderToggle = (loader) => (props.changeLoader(loader));

    const endTheYear = () => {
        loaderToggle(true)
        axios.post(`${api}/setting/end_year`, {}, { withCredentials: true })
            .then(() => { loaderToggle(false); toggle(); Notify.success('Success'); })
            .catch((e) => { loaderToggle(false); console.log(e); Notify.failure('somthing went  wrong!'); })
    }
    return (
        <Modal size="lg" style={{ maxWidth: '700px', width: '100%' }} isOpen={props.modal} toggle={() => { toggle(props) }}>
            <ModalHeader toggle={() => { toggle(props) }}>Major</ModalHeader>
            <ModalBody>
                <div className="alert alert-warning p-3">
                    by ending the year, you are changing all active  framworks submissions to completed and archived. <br />
                    the all users can still view submissions after archived.
                </div>
                <button className="btn btn-success" onClick={() => confirmAlert({
                    title: 'Confirm',
                    message: 'Are you sure you want to change all active  framworks submissions to completed and archived ?',
                    buttons: [{ label: 'Yes', onClick: () => { endTheYear(); } }, { label: 'cancel', onClick: () => { } }]
                })}>End the Year</button>
            </ModalBody>
            <ModalFooter>
                <div className="row">
                    <div className="col-sm-4 mb-2"></div>
                    <div className="col-sm-4 mb-2"></div>
                    <div className="col-sm-4 mb-2"><Button color="warning" onClick={toggle}>Cancel</Button></div>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default EndYearModal