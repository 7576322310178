import React from 'react'
import Card from './card'
import { Bar } from 'react-chartjs-2';
import { getRandomColor, isArr } from '../../util/functions';

function Orgs({ numberOfMajors
    , numberOfAcadamicInstitutions
    , numberOfIndustrialInstitutions
    , numberOfCourses
    , numberOfDisciplines
    , numberOfFunctions
    , allocatedStudentsInIndustrialOrgs
    , studentsInAcadamicOrgs }) {




    const options_bar = {
        scales: { yAxes: [{ stacked: true, ticks: { beginAtZero: true } }], xAxes: [{ stacked: true }] }, plugins: { legend: { display: false } }
    };



    return (
        <div className="row m-2 p-2 shadow bg-white60">
            <h1 className='text-center mb-3'>Organizations</h1>
            <div className="p-2 col-md-4"><Card title={'Majors'} number={numberOfMajors} /></div>
            <div className="p-2 col-md-4"><Card title={'Acadamic Institutions'} number={numberOfAcadamicInstitutions} /></div>
            <div className="p-2 col-md-4"><Card title={'Industrial Institutions'} number={numberOfIndustrialInstitutions} /></div>
            <div className="p-2 col-md-4"><Card title={'Courses'} number={numberOfCourses} /></div>
            <div className="p-2 col-md-4"><Card title={'Disciplines'} number={numberOfDisciplines} /></div>
            <div className="p-2 col-md-4"><Card title={'Functions'} number={numberOfFunctions} /></div>
            <div className="p-2 col-md-12">
                <h3 className='text-center'>Allocated Students in Indastirial  Orgnizations</h3>
                <Bar options={options_bar} data={{
                    labels: isArr(allocatedStudentsInIndustrialOrgs).map(item => item?.name),
                    height: 50,
                    datasets: [{ label: '', data: isArr(allocatedStudentsInIndustrialOrgs).map(item => item?.allocated_student || 0), backgroundColor: isArr(allocatedStudentsInIndustrialOrgs).map(() => getRandomColor()) }]
                }} />
            </div>
            <div className='p-2 col-md-12'>
                <table className='table table-light table-bordered table-hover'>
                    <thead><tr><th>Organization</th><th>Students</th></tr></thead>
                    <tbody>
                        {isArr(allocatedStudentsInIndustrialOrgs).map((item, index) => <tr key={index}><td>{item?.name}</td><td>{item?.allocated_student || 0}</td></tr>)}
                    </tbody>
                </table>
            </div>
            <div className="p-2 col-md-12">
                <h3 className='text-center'>Students based on Acadamic Orgnizations</h3>
                <Bar options={options_bar} data={{
                    labels: isArr(studentsInAcadamicOrgs).map(item => item?.name),
                    height: 50,
                    datasets: [{ label: '', data: isArr(studentsInAcadamicOrgs).map(item => item?.student || 0), backgroundColor: isArr(studentsInAcadamicOrgs).map(() => getRandomColor()) }]
                }} />
            </div>
            <div className='p-2 col-md-12'>
                <table className='table table-light table-bordered table-hover'>
                    <thead><tr><th>Organization</th><th>Students</th></tr></thead><tbody>
                        {isArr(studentsInAcadamicOrgs).map((item, index) => <tr key={index}><td>{item?.name}</td><td>{item?.student || 0}</td></tr>)}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Orgs