import React, { useState, useEffect } from 'react'
import News from '../components/news'
import ForMyAcion from '../components/AppForMyAcion'
import axios from 'axios';
import { api } from '../../../util/constant'
import Chart from '../components/chart'
import { isArr } from '../../../util/functions'
function AdminAndEidaad(props) {

    const [info, setInfo] = useState({})
    const [newsList, setNewsList] = useState([])
    const [appsList, setAppsList] = useState([])
    const [statistics, setStatistics] = useState({})
    const loaderToggle = (status) => { props.loderToggle(status) }
    const getDashboardInformation = () => {
        loaderToggle(true)
        axios.get(`${api}/dashboard`, { withCredentials: true })
            .then((res) => {
                const { news = [], AppForMyAction = [], studentsCount, PendingReg, ApplicationsCount, loginCount, regCount, disciplinesCount, acadamicalOrgsCount, industrialOrgsCount } = res.data
                setInfo({ studentsCount, PendingReg, ApplicationsCount, disciplinesCount });
                setNewsList(isArr(news));
                setAppsList(isArr(AppForMyAction));
                setStatistics({ loginCount, regCount, acadamicalOrgsCount, industrialOrgsCount })
                loaderToggle(false)
            })
            .catch((e) => { console.log(e); })
    }



    useEffect(() => { getDashboardInformation(); }, [])
    return (
        <>
            <div className="dashboard-main row p-4 ">
                <div className='p-2 col-md-3'>
                    <div className=" card-info smooth-box-shadow">
                        <div className="title shadow">
                            Students
                        </div>
                        <div className="icon"><img src="/images/employee.svg" /></div>
                        <div className="val">{info?.studentsCount}</div>
                    </div>
                </div>
                <div className='p-2 col-md-3'>
                    <div className=" card-info smooth-box-shadow">
                        <div className="title shadow">
                            Pending Registration
                        </div>
                        <div className="icon"><img src="/images/notes.svg" /></div>
                        <div className="val">{info?.PendingReg}</div>
                    </div>
                </div>
                <div className='p-2 col-md-3'>
                    <div className="card-info smooth-box-shadow">
                        <div className="title shadow">
                            Applications
                        </div>
                        <div className="icon"><img src="/images/folder.svg" /></div>
                        <div className="val">{info?.ApplicationsCount}</div>
                    </div>
                </div>
                <div className='p-2 col-md-3'>
                    <div className="card-info smooth-box-shadow">
                        <div className="title shadow">
                            Disciplines
                        </div>
                        <div className="icon"><img src="/images/discipline.svg" /></div>
                        <div className="val">{info?.disciplinesCount}</div>
                    </div>
                </div>

                <div className="col-md-6 p-2">
                    <News news={newsList} loaderToggle={loader => { loaderToggle(loader) }} refresh={() => { getDashboardInformation() }} />
                </div>
                <div className="col-md-6 p-2">
                    <ForMyAcion apps={appsList} />
                </div>

            </div>
            <Chart data={statistics} />
        </>
    )
}

export default AdminAndEidaad
