import React, { useState, useContext } from 'react';
import Nav from '../components/nav';
import Location from '../components/location';
import Loader from '../components/loader';
import '../style/application.css';
import Student from '../components/application/student'
import OtherRoles from '../components/application/otherRoles'

import { Cxt } from '../App'

function Application() {
    const [loader, setLoader] = useState(false);
    const { role } = useContext(Cxt);
    return (
        <>
            <Nav />
            <Location />
            {loader ? <Loader /> : null}
            {role == 3 ? <Student loaderToggle={loader => setLoader(loader)} /> : <OtherRoles loaderToggle={loader => setLoader(loader)} />}
        </>
    )
}




export default Application
