import axios from 'axios'
import React, { useState, useEffect } from 'react'
import FdfSubmission from '../components/fdfSubmission/fdfSubmission'
import { api } from '../../../util/constant'
import { isArr } from '../../../util/functions'
function Student(props) {

    const [fdfSubmission, setFdfSubmission] = useState({})
    const [fdfDetails, setFdfDetails] = useState({})
    const [student, setStudent] = useState({})
    const [hasBeenAddedToFdf, setHasBeenAddedToFdf] = useState(false)
    const [fdfs, setFdfs] = useState([])
    const [selectedFdf, setSelectedFdf] = useState('')
    const loader = (status) => props.loader(status)

    const getfdf = () => {
        loader(true)
        axios.get(`${api}/fdf/sub`, { headers: { fdf: selectedFdf }, withCredentials: true })
            .then((res) => {
                const { student, fdfs, fdfDetails = {}, fdfSubmission = {}, hasBeenAddedToFdf = false } = res?.data;
                if (fdfs) setFdfs(fdfs)
                setHasBeenAddedToFdf(hasBeenAddedToFdf)
                setFdfDetails(fdfDetails);
                setFdfSubmission(fdfSubmission);
                setStudent(student)
                loader(false);
            })
            .catch((e) => { console.log(e); loader(false) })
    }

    useEffect(() => { getfdf() }, [])

    useEffect(() => { getfdf() }, [selectedFdf])

    return (
        <>
            {hasBeenAddedToFdf ? <>
                <div className="alert alert-light border shadow p-3 m-2 me-3"> My Frameworks : <br /> {isArr(fdfs).map((item, index) => <button key={index} onClick={() => setSelectedFdf(item._id)} className={`btn shadow me-1 mt-1 ${selectedFdf == item._id ? 'btn-success' : 'btn-light'}`}>{item?.title}</button>)}</div>
                {selectedFdf != '' ? <FdfSubmission isBack={false} details={fdfDetails} refresh={() => getfdf()} submission={fdfSubmission} student={student} loader={status => { props.loader(status) }} /> : <div className='text-center pt-4'> <span className="alert alert-warning">please select framework</span> </div>}
            </> :
                <div className='text-center p-3 fw-bold '>
                    <p className='alert alert-success'>
                        <span >you still dont have a fundamantal development framework<br />
                            As soon as you are added to the framework, it will appear</span>
                    </p>
                </div>}
        </>
    )
}



export default Student