import React from 'react'
import { path } from '../../../util/constant';
import { isArr } from '../../../util/functions'

import '../../components_style/dashboardCard.css'
function ForMyAcion(props) {
    const goTO = (submission) => {
        window.open(`${path}/fdfsubmissionview?id=${submission?.fdf?._id}&name=${submission?.fdf?.title}&student=${submission?.student?._id}`);
    }

    return (
        < div className="dashboard-card-container smooth-box-shadow" >
            <div className="dashboard-card-title">
                <img src="/images/action.svg" />
                For My Action [{isArr(props?.fdf).length}]
            </div>
            <div className="dashboard-card-body">
                <table className='w-100'>
                    <tbody  >
                        {isArr(props?.fdf)?.length > 0 ? props?.fdf?.map((item, i) => {
                            return (<tr key={`news-${i}`} onClick={() => goTO(item)}>
                                <td className=" position-relative">
                                    <span className='fw-bold'> {item?.fdf?.title}</span><br />
                                    {item?.student?.fullname}
                                </td>
                            </tr>)
                        }) : <tr className="text-center"><td>There is nothing for my acion .</td></tr>}
                    </tbody>
                </table>
            </div>
        </div >
    )
}

export default ForMyAcion
