import React, { useState, useEffect, useContext } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { IoCloudDownloadOutline } from 'react-icons/io5';
import axios from 'axios';
import { api } from '../../util/constant';
import Cookies from 'js-cookie';
import { FileDownload, identifyRole, isArr } from '../../util/functions';
import { Dropdown } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { Cxt } from '../../App';
import SupervisorsAndTaAndAdvisorForStudent from './supervisorsAndTaAndAdvisorForStudent'
import StudentsUnderSupervisorsAndTa from './studentsUnderSupervisorsAndTa'
import { ChangePasswordComponent } from '../../view/profile';

function ViewUserModal(props) {
    const { role } = useContext(Cxt);
    const [isLocked, setIsLocked] = useState(false);
    const [orgs, setOrgs] = useState([])
    const [selectedOrg, setSelectedOrg] = useState('')
    const [page, setPage] = useState(props?.defualtPage || 0)

    const toggle = () => props.modalToggle(!props.modal)
    const loader = status => props.loaderToggle(status)
    const refresh = () => props.refreshApp()

    const getOrgs = async () => {
        if (!props.isAdminOrEidaad) return
        loader(true);
        const orgs = await axios.get(`${api}/org`);
        setOrgs(isArr(orgs?.data));
        loader(false);
    }

    //update user 
    const updateUser = (id) => {
        let obj = {}
        if (props.isAdminOrEidaad && selectedOrg == '') return Notify.failure('Orgnization cannot be empty')
        if (props.isAdminOrEidaad && selectedOrg != orgnization_id._id) obj = { orgnization_id: selectedOrg }
        loader(true)
        axios.post(`${api}/users`, { id, isLocked, ...obj }, { withCredentials: true })
            .then(() => { loader(false); toggle(); refresh() })
            .catch((e) => { console.log(e); loader(false) })
    }

    const isNotEmpty = str => str ? true : false

    //if account locked or profile updated == undifine or false Change it to (false)
    useEffect(() => {
        const locked = props?.info?.locked
        locked == undefined || locked == false ? setIsLocked(false) : setIsLocked(true)
        getOrgs();
        setSelectedOrg(orgnization_id?._id ? orgnization_id?._id : '')
    }, [props.info])

    useEffect(() => { if (!props.modal && !props.defualtPage) setPage(0) }, [props.modal])

    const { fullname, department, email, phone, gender, orgnization_id, address, gpa, createdAt, lastLogin, major, nationality,
        qualification, profileupdate, cv_url, tr_url, role_id, supervisors, technical_authorities, advisors, _id } = props.info
    const { isStudent: isStudentProfile, isSV: isSupervisorProfile, isTA: isTechnicalAuthProfile } = identifyRole(role_id)
    return (
        <>
            <Modal size="lg" style={{ maxWidth: '700px', width: '100%' }} isOpen={props.modal} toggle={() => { toggle(props) }}>
                <ModalHeader toggle={() => { toggle(props) }}>User Profile</ModalHeader>
                <ModalBody>
                    <div className="col-md-12 mb-2 p-0 shadow">
                        <button className={`sub-nav w50  ${page == 0 ? 'active' : ''}`} onClick={() => { setPage(0) }}>key Information</button>
                        {isStudentProfile ? <button className={`sub-nav w50  ${page == 1 ? 'active' : ''}`} onClick={() => { setPage(1) }}>(Supervisors, TA, Advisors)</button> : null}
                        {isSupervisorProfile || isTechnicalAuthProfile ? <button className={`sub-nav w50  ${page == 2 ? 'active' : ''}`} onClick={() => { setPage(2) }}>Students</button> : null}
                    </div>
                    {
                        page == 0 ? <div className="row h6">
                            {isNotEmpty(fullname) ? <div className='col-md-6 border'><b>Name : </b>{fullname}</div> : null}
                            {isNotEmpty(department) ? <div className='col-md-6 border'><b>Department : </b>{department}</div> : null}
                            {isNotEmpty(email) ? <div className='col-md-6 border'><b>Email : </b>{email}</div> : null}
                            {isNotEmpty(phone) ? <div className='col-md-6 border'><b>Mobile Number : </b>{phone}</div> : null}
                            {isNotEmpty(gender) ? <div className='col-md-6 border'><b>Gender : </b>{gender}</div> : null}
                            {isNotEmpty(props.info?.role) ? <div className='col-md-6 border'><b>Role : </b>{props.info?.role}</div> : null}
                            {isNotEmpty(orgnization_id?.name) ? <div className='col-md-6 border'>
                                <b>Orgnization : </b>{props.isAdminOrEidaad ? <Dropdown className="m-2"
                                    placeholder='<Select institution>'
                                    fluid
                                    clearable
                                    search
                                    selection
                                    options={orgs.map((item, index) => { return { key: index, text: item?.name, value: item?._id } })}
                                    onChange={(e, d) => { setSelectedOrg(d.value); }}
                                    value={selectedOrg}
                                /> : orgnization_id?.name}

                            </div> : null}
                            {isNotEmpty(address) ? <div className='col-md-6 border'><b>Address : </b>{address}</div> : null}
                            {isNotEmpty(gpa) ? <div className='col-md-6 border'><b>GPA : </b>{gpa}</div> : null}
                            <div className='col-md-6 border'><b>Rigisterd : </b>{new Date(createdAt).toLocaleDateString()}</div>
                            <div className='col-md-6 border'><b>Last Login : </b>{lastLogin}</div>
                            {isNotEmpty(major) ? <div className='col-md-6 border'><b>Major : </b>{major.name}</div> : null}
                            {isNotEmpty(nationality) ? <div className='col-md-6 border'><b>Nationality : </b>{nationality}</div> : null}
                            {isNotEmpty(qualification) ? <div className='col-md-6 border'><b>Qualification : </b>{qualification}</div> : null}
                            {isNotEmpty(profileupdate) ? <div className='col-md-6 border'><b>Profile Update : </b>{profileupdate ? 'yes' : 'no'}</div> : null}
                            {cv_url ? <div className='col-md-6 border text-center'> <button className='btn btn-light' onClick={() => { FileDownload(`${api}/download/?token=${Cookies.get('token')}&file_name=${cv_url}&type=cv`) /*download('cv', cv_url)*/ }}><IoCloudDownloadOutline /> CV</button> </div> : null}
                            {tr_url ? <div className='col-md-6 border text-center'> <button className='btn btn-light' onClick={() => { FileDownload(`${api}/download/?token=${Cookies.get('token')}&file_name=${tr_url}&type=transcript`) /*download('transcript', tr_url)*/ }}><IoCloudDownloadOutline /> Transcript</button>  </div> : null}
                            {props.isAdminOrEidaad ? <div className='col-md-6 border'><b>Account locked : </b><input type="checkbox" checked={isLocked} onChange={() => { setIsLocked(!isLocked) }} /></div> : null}
                            {props.isAdminOrEidaad ? <ChangePasswordComponent loader={loader} setLoader={status => loader(status)} userid={_id} /> : null}
                        </div> : null
                    }
                    {isStudentProfile && page == 1 ? <SupervisorsAndTaAndAdvisorForStudent modal={props.modal} studentInfo={props.info} toggle={toggle} refresh={() => refresh()} role={role} supervisors={supervisors} technical_authorities={technical_authorities} advisors={advisors} loader={status => props.loaderToggle(status)} /> : null}
                    {isSupervisorProfile && page == 2 || isTechnicalAuthProfile && page == 2 ? <StudentsUnderSupervisorsAndTa modal={props.modal} userInfo={props.info} toggle={toggle} refresh={() => refresh()} role={role} loader={status => props.loaderToggle(status)} /> : null}
                </ModalBody>
                <ModalFooter>
                    <div className="w-100">
                        {props.isAdminOrEidaad && page == 0 ? <button className="btn btn-success float-end" onClick={() => { updateUser(props?.info._id) }}>update</button> : null}
                        <button onClick={toggle} className="btn btn-warning float-start">Close</button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    )
}





export default ViewUserModal
