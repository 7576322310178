import React, { useState, useEffect, useContext } from 'react'
import MUIDataTable from "mui-datatables";
import { IoEllipsisVerticalCircle, IoExpandOutline } from "react-icons/io5";
import axios from 'axios';
import DisciplineModal from './disciplineModal'
import { api } from '../../util/constant'
import muiTable from '../../util/mui-table'
import { MuiThemeProvider } from "@material-ui/core/styles";
import { identifyRole, isArr } from '../../util/functions'
import { IoSearch } from "react-icons/io5";
import { TableFooter, TableCell, TableRow } from "@material-ui/core";
import { Dropdown } from 'semantic-ui-react';
import { Cxt } from '../../App';

function Discipline_Component(props) {
    const { role } = useContext(Cxt);
    //states
    const [list, setList] = useState([]);
    const [disciInfo, setDisciInfo] = useState('')
    const [orgs, setOrgs] = useState([])
    const [modelType, setModelType] = useState(1) //1-new //2-modify
    const [yearInfo, setYearInfo] = useState([])
    const [disciplinModal, setDisciplineModal] = useState(false)
    const [majors, setMajors] = useState([])
    const [majorsForSingleDiscipline, setMajorsForSingleDiscipline] = useState([])

    const [theme, setTheme] = useState(false)
    const [isSearch, setIsSearch] = useState(false)
    const [tablePageNumber, setTablePageNumber] = useState(1)
    const [tableRowsLimit, setTableRowsLimit] = useState(10)
    const [tableTotalPages, setTotalPages] = useState(0)
    const [tableTotalRows, setTableTotalRows] = useState(0)
    const [tableSearch, setTableSearch] = useState('')
    const [tableSearchType, setTableSearchType] = useState('')
    const [tableSearchOrg, setTableSearchOrg] = useState('')

    const LoaderToggle = (status) => (props.loaderToggle(status))
    const { isAdminOrEidaad } = identifyRole(role)

    const getDisciplinesAndOrgsAndMajors = async () => {
        LoaderToggle(true);
        const search = handleSearch()
        const discipline = await axios.get(`${api}/discipline`, { headers: { search: JSON.stringify(search), page: tablePageNumber, limit: tableRowsLimit }, withCredentials: true });
        const orgnizations = await axios.get(`${api}/org`);
        const _majors = await axios.get(`${api}/major`, { headers: { disciplines: false }, withCredentials: true });
        const industryOrgs = orgnizations?.data?.filter(item => item?.type == 2);
        setMajors(isArr(_majors.data));
        setOrgs(isArr(industryOrgs));

        const { disciplines, totalPages, totalDisciplines } = discipline.data
        setTotalPages(totalPages);
        setTableTotalRows(totalDisciplines)
        setList(isArr(disciplines));

        LoaderToggle(false);
    }

    const handleSearch = () => {
        let obj = {}
        if (!isSearch) return {}
        if (tableSearchOrg != '' && isAdminOrEidaad) obj = { orgnization: tableSearchOrg }
        if (tableSearchType == 'name') return { ...obj, 'name': { $regex: tableSearch, $options: "i" } }
        return obj
    }

    const getSingleDiscipline = async (id) => {
        try {
            LoaderToggle(true);
            const res = await axios.get(`${api}/discipline`, { headers: { id: id }, withCredentials: true });
            const { discipline, year, majorsHasThisDiscipline } = res?.data;
            const majorsIdOnly = []
            if (Array.isArray(majorsHasThisDiscipline)) { majorsHasThisDiscipline.map(item => majorsIdOnly.push(item._id)) }
            setMajorsForSingleDiscipline(majorsIdOnly);
            setDisciInfo(discipline);
            setYearInfo(isArr(year));
            setModelType(2);
            setDisciplineModal(true);
            LoaderToggle(false);
        } catch (error) { LoaderToggle(false); }
    }

    const getYearsAndOrgs = async () => {//this run only when create new Discipline
        LoaderToggle(true);
        const years = await axios.get(`${api}/years`, { withCredentials: true });
        const orgnizations = await axios.get(`${api}/org`);
        const industryOrgs = orgnizations?.data?.filter(item => item?.type == 2);
        setOrgs(isArr(industryOrgs));
        setYearInfo(isArr(years?.data))
        setDisciplineModal(true)
        LoaderToggle(false);
    }

    useEffect(() => { getDisciplinesAndOrgsAndMajors(); }, []);

    const columns = ["Name", "Location", {
        label: "year / demand",
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                return (<ul className='list-unstyled mt-1' key={`${tableMeta?.rowIndex + 1}-demands`}>
                    {tableMeta?.rowData[2] ? tableMeta?.rowData[2].map((item, index) => {
                        return <li className='mb-2' key={`${tableMeta?.rowIndex + 1}-${index}-demand`}>
                            <span className='bg-info rounded-2 text-light shadow p-1'>{item?.year?.year}</span>
                            {item?.is_all ? <span className='bg-warning rounded-2 shadow text-light p-1'>{item.all.seats}</span>
                                : <>
                                    <span className='bg-success shadow rounded-2 text-light p-1'>{item.male.seats}</span>
                                    <span className='bg-danger shadow rounded-2 text-light p-1'>{item.female.seats}</span>
                                </>
                            }
                        </li>
                    }) : null}
                </ul>)
            }
        }
    }, {
            label: "",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (<span style={{ fontSize: "20px", padding: '0px' }} className="btn" onClick={() => { getSingleDiscipline(tableMeta?.rowData[3]); }}><IoEllipsisVerticalCircle /></span>)
                }
            }
        }];

    let options = muiTable.muiOptions(theme)

    options = {
        ...options,
        rowsPerPage: 100,
        search: false,
        serverSide: true,
        customFooter: () => {
            return (
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={6}>
                            page {tablePageNumber} of {tableTotalPages} - total users {tableTotalRows}
                            <button className="btn btn-light" disabled={tablePageNumber == 1 ? true : false} onClick={() => setTablePageNumber(prev => prev - 1)}>{`<`}</button>
                            <button className="btn btn-light" disabled={tablePageNumber == tableTotalPages || tableTotalPages == 0 ? true : false} onClick={() => setTablePageNumber(prev => prev + 1)}>{`>`}</button>
                            jump to <select onChange={e => setTablePageNumber(+e.target.value)}>{[...Array(tableTotalPages)].map((item, i) => <option value={i + 1} key={i}>{i + 1}</option>)}</select>
                            per page <select onChange={e => setTableRowsLimit(+e.target.value)}>{[...Array(10)].map((item, i) => <option value={(i + 1) * 10} key={i}>{(i + 1) * 10}</option>)}</select>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            );
        }
    }


    useEffect(() => { getDisciplinesAndOrgsAndMajors() }, [tablePageNumber, tableRowsLimit, isSearch])
    useEffect(() => { if (!isSearch) { setTableSearchOrg(''); } }, [isSearch])
    useEffect(() => { setTablePageNumber(1); setTableSearchType('') }, [tableRowsLimit])

    return (
        <>

            <div className="row">
                <div className="col-md-4"><button className="btn btn-light shadow m-3" onClick={() => { setModelType(1); getYearsAndOrgs(); }}>New</button></div>
                <div className="col-md-4"></div>
            </div>

            <DisciplineModal
                majorsForSingleDiscipline={majorsForSingleDiscipline}
                majors={majors}
                years={yearInfo}
                orgs={orgs}
                modal={disciplinModal}
                info={disciInfo}
                modelType={modelType}
                refreshTable={() => { getDisciplinesAndOrgsAndMajors() }}
                changeLoader={loader => props.loaderToggle(loader)} changePerentToggle={modal => setDisciplineModal(modal)}
            />


            <div className="Discpline-container p-4">
                {!isSearch ? <button className="btn btn-warning mb-1" onClick={() => setIsSearch(prev => !prev)}>search <IoSearch /></button>
                    :
                    <div className="row">
                        <div className="col-sm-4"><input className='form-control' placeholder='search' onChange={e => setTableSearch(e.target.value)} /></div>
                        <div className="col-sm-3 "><select value={tableSearchType} className='w-100' onChange={e => setTableSearchType(e.target.value)}><option value="">{`<select>`}</option><option value="name">name</option></select></div>
                        {isAdminOrEidaad ? <div className="col-sm-3">
                            <Dropdown
                                placeholder='<Select institution>'
                                fluid
                                search
                                clearable
                                selection
                                options={orgs.map((item, index) => { return { key: index, text: item.name, value: item._id } })}
                                onChange={(e, d) => { setTableSearchOrg(d.value) }}
                                value={tableSearchOrg}
                                style={{ fontSize: '13px', zIndex: '101' }}
                            />
                        </div> : null}
                        <div className="col-sm-1"><button className="btn btn-warning mb-1" onClick={() => { getDisciplinesAndOrgsAndMajors(); setTablePageNumber(1) }}><IoSearch /></button></div>
                        <div className="col-sm-1"><button className="btn btn-danger mb-1" onClick={() => setIsSearch(prev => !prev)}>X</button></div>
                    </div>
                }
                <MuiThemeProvider theme={theme ? muiTable.getMuiTheme() : muiTable.noTheme()}>
                    <MUIDataTable title={<>
                        <button className="btn btn-light shadow" disabled >Discipline List</button>
                        <button onClick={() => { setTheme(!theme); }} className="btn btn-light shadow"><IoExpandOutline /></button>
                    </>}
                        data={list.length ? list.map(item => { return [item?.name, item?.location, item?.demand, item?._id] }) : []}
                        columns={columns} options={options} />
                </MuiThemeProvider>
            </div>
        </>)
}


export default Discipline_Component
