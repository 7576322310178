import React, { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import { IoEllipsisVerticalCircle, IoExpandOutline } from "react-icons/io5";
import Loader from '../loader';
import axios from 'axios';
import EditOrgModal from './components/editOrgModal'
import { api } from '../../util/constant'
import muiTable from '../../util/mui-table'
import { MuiThemeProvider } from "@material-ui/core/styles";
import { isArr } from '../../util/functions'
function OrgsEidaadAndAdmin() {

    const defualtOrgObject = { id: '', name: '', type: '', email: '', phone: '', postal: '', url: '' }
    const [list, setList] = useState([])
    const [loader, setLoader] = useState(false);
    const [modal, setModal] = useState(false)
    const [orgInfo, setOrgInfo] = useState(defualtOrgObject)
    const [isModify, setIsModify] = useState(false);
    const [functions, setFunctions] = useState([])
    const [theme, setTheme] = useState(false)

    const getSingleOrg = (id) => {
        setLoader(true);
        setIsModify(true);
        axios.get(`${api}/org`, { headers: { id }, withCredentials: true })
            .then((response) => {
                const { name = '', type = '', phone = '', email = '', postal = '', url = '' } = response?.data?.org;
                setFunctions(isArr(response?.data?.functions))
                setIsModify(true)
                setOrgInfo({ id, name, type, phone, email, postal, url });
                setModal(true);
                setLoader(false);

            })
            .catch((e) => { console.log(e); setLoader(false) })
    }


    const getOrgs = async () => {
        setLoader(true);
        const orgs = await axios.get(`${api}/org`);
        setList(isArr(orgs.data));
        setLoader(false);
    }

    useEffect(() => { getOrgs(); }, [])
    useEffect(() => { if (!modal) { setOrgInfo(defualtOrgObject); setFunctions([]) } }, [modal])

    const columns = [{ label: "Name" }, "type", {
        label: "", options: {
            filter: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                return (<button className='btn p-0' onClick={() => getSingleOrg(tableMeta.rowData[2])}><IoEllipsisVerticalCircle size={20} /></button>)
            }
        }
    }];

    const options = muiTable.muiOptions(theme)

    return (
        <>
            <div className="row m-3 ">
                <div className="col-md-12 p-3">
                    <button className="btn btn-light float-start shadow" onClick={() => { setIsModify(false); setModal(true) }}>New</button>
                </div>
            </div>
            {loader ? <Loader /> : null}
            <EditOrgModal refreshOrg={id => getSingleOrg(id)} functions={functions} info={orgInfo} isModify={isModify} refresh={() => { getOrgs() }} defualtOrgObject={defualtOrgObject} loaderToggle={loader => setLoader(loader)} modal={modal} modalToggle={modal => setModal(modal)} />
            <div className="p-4">
                <MuiThemeProvider theme={theme ? muiTable.getMuiTheme() : muiTable.noTheme()}>
                    <MUIDataTable
                        title={<>
                            <button className="btn btn-light shadow" disabled >Orgnizations</button>
                            <button onClick={() => { setTheme(!theme); }} className="btn btn-light shadow"><IoExpandOutline /></button>
                        </>}
                        data={list ? list.map(item => { return [item?.name, item?.type == 1 ? 'academic' : 'industrial', item?._id] }) : []}
                        columns={columns}
                        options={options}
                    />
                </MuiThemeProvider>
            </div>
        </>
    )
}

export default OrgsEidaadAndAdmin