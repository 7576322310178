import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function RequirementModal({ isEng, modal, setModal }) {

  const toggle = () => (setModal(!modal));

  return (
    <Modal size="lg" style={{ maxWidth: '700px', width: '100%' }} isOpen={modal} toggle={() => { toggle() }}>
      <ModalHeader toggle={() => { toggle() }}>Requirements</ModalHeader>
      <ModalBody>
        <h2>{isEng ? <>Eligibility Criteria</> : <>متطلبات الالتحاق بالبرنامج</>}</h2>

        {isEng ? <p> This program is mainly inviting Omani students currently enrolled in university/college and expected to complete their pre-final year studies on or before June/ July to apply for this program.</p>
          :
          <p className='arabic'>يدعو هذا البرنامج بشكل أساسي الطلاب العمانيين المسجلين حاليًا في الجامعة / الكلية ومن المتوقع أن يكملوا دراساتهم في السنة قبل النهائية في أو قبل يونيو / يوليو للتقدم لهذا البرنامج.</p>
        }

        <h2>{isEng ? <>Applying Requirements</> : <>متطلبات التقديم</>}</h2>
        {isEng ? <p>Every interested applicant should be sure and should have clear understanding of the qualifications for the internship. Each applicant is expected to provide the following documents, when Applying to Eidaad:</p>
          : <p className='arabic'>يجب أن يكون كل متقدم مهتمًا و على يقين ويجب أن يكون لديه فهم واضح لمؤهلات التدريب. يُتوقع من كل متقدم تقديم المستندات التالية :</p>}
        <br />
        {isEng ? <p>
          An up-to-date Transcript (from the respective university / college, testifying completion or in the verge of completion of level 3 studies, with CGPA) <br />
          An up-to-date Transcript (from the respective university / college, testifying completion or in the verge of completion of level 3 studies, with CGPA) <br />
          An approval letter from the respective university / college, with the stamp and/or signature of academic advisor. <br />
          Updated Resume / CV <br />
          Valid copy of ID card, front and back (expiry date must not be three months or less) <br />
          Valid copy of Passport, front and back (expiry date must not be three months or less) <br />
          A photo with blue background. <br />
        </p> : <p className='arabic'>
          نسخة محدثة (من الجامعة / الكلية المعنية ، شهادة إكمال أو على وشك الانتهاء من دراسات المستوى 3 ، مع CGPA) <br />
          نسخة محدثة (من الجامعة / الكلية المعنية ، شهادة إكمال أو على وشك الانتهاء من دراسات المستوى 3 ، مع CGPA) <br />
          خطاب موافقة من الجامعة / الكلية المعنية ، مع ختم و / أو توقيع المرشد الأكاديمي. <br />
          السيرة الذاتية المحدثة / السيرة الذاتية <br />
          نسخة صالحة من بطاقة الهوية ، من الأمام والخلف (يجب ألا يكون تاريخ انتهاء الصلاحية ثلاثة أشهر أو أقل) <br />
          نسخة صالحة من جواز السفر ، من الأمام والخلف (يجب ألا يكون تاريخ انتهاء الصلاحية ثلاثة أشهر أو أقل) <br />
          صورة بخلفية زرقاء. <br />

        </p>}
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-warning" onClick={toggle}>close</button>
      </ModalFooter>
    </Modal>
  )
}

export default RequirementModal
