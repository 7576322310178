import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Dropdown } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import axios from 'axios';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { api } from '../../../../util/constant';

function DisciplineModal(props) {
    const toggle = () => (props.setModal(!props.modal));
    const loader = (status) => { props.loader(status) }
    const refresh = () => { props.refresh() }

    const updateFunction = () => {
        loader(true)
        axios.post(`${api}/fdf/discipline`, { selectedDisciplines: props.selectedDisciplines, functions: props?.function?.id }, { withCredentials: true })
            .then((res) => {
                toggle();
                refresh();
                loader(false);
                Notify.success('Disciplines updated successfully');
            })
            .catch((e) => { loader(false); console.log(e) })
    }

    return (
        <Modal size="lg" style={{ maxWidth: '700px', width: '100%' }} isOpen={props.modal} toggle={() => { toggle(props) }}>
            <ModalHeader toggle={() => { toggle(props) }}>Disciplines for {props.function.name} function</ModalHeader>
            <ModalBody>
                <div className="row">
                    <div className="col-md-12 ">
                        <Dropdown placeholder='<select Disciplines>' fluid multiple search selection
                            options={props.disciplines ? props.disciplines.map(item => { return { key: item.key, text: item.name, value: item._id } }) : []}
                            onChange={(e, d) => { props.setSelectedDisciplines(d.value); }}
                            value={props.selectedDisciplines}
                        />
                        {!props.disciplines.length ? <div className="alert alert-warning mt-3">there is no disciplines.</div> : null}
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <button className='btn btn-success' onClick={updateFunction}>Save</button>
                    </div>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default DisciplineModal
