import React from 'react'

function Welcome() {
    return (
        <div className="welcome-area" id="welcome">
            <div className="header-text">
                <div className="container">
                    <div className="row">
                        <div className="offset-xl-3 col-xl-6 offset-lg-2 col-lg-8 col-md-12 col-sm-12">
                            <img src="/assets/images/eidaadheadlogo.png" alt="true" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Welcome