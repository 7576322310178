import React from 'react'
import { IoLogIn, IoNewspaperOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';
import { path } from '../../util/constant'
function Featured({ isEng, setModal }) {
    return (
        <section className="section home-feature">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-2 "></div>
                            <div className="col-lg-4 " data-scroll-reveal="enter bottom move 50px over 0.6s after 0.2s">
                                <Link to={`${path}/login`}>
                                    <div className="features-small-item">
                                        <div className="icon">
                                            <IoLogIn size={40} color='white' />
                                        </div>
                                        <h5 className="features-title">{isEng ? <>Registration / Login</> : <>تسجيل الدخول/مستخدم جديد</>}</h5>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-4" data-scroll-reveal="enter bottom move 50px over 0.6s after 0.4s">
                                <div className="features-small-item" onClick={() => setModal()}>
                                    <div className="icon">
                                        <IoNewspaperOutline size={40} color='white' />
                                    </div>
                                    <h5 className="features-title">{isEng ? <>Eligibility and Applying Requirements </> : <>متطلبات الالتحاق بالبرنامج</>}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Featured