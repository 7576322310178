import React from 'react'

function Section_1({ isEng }) {
    return (
        <div className="row">
            <div className="col-lg-5 col-md-12 col-sm-12 align-self-center" data-scroll-reveal="enter left move 30px over 0.6s after 0.4s">
                <img src="assets/images/left-image.png" className="rounded img-fluid d-block mx-auto" alt="true" />
            </div>
            <div className="col-lg-1" />
            <div className="col-lg-6 col-sm-12 align-self-center mobile-top-fix">
                <div className="left-heading">
                    <h2 className="section-title">
                        {isEng ? <>What is Eidaad </> : <>عن البرنامج</>}
                    </h2>
                </div>
                <div className="left-text">
                    {isEng ?
                        <>
                            <p>Eidaad is an educational initiative that aims to narrow the gap between Industry & Academia by establishing an Internship program that lasts for one academic year which will enable students to engage in a longer period of applied learning aligned with industry future needs.
                                Eidaad also provides a platform that facilitates and promotes partnership and intellectual exchange between academia and industry.
                                This program provides an opportunity for university/college students with a year of valuable experience, which will give the graduate an edge when they enter the real world of work after their studies.
                            </p>
                        </>
                        :
                        <>
                            <p className="arabic">إعداد هي مبادرة تعليمية تهدف إلى تضييق الفجوة بين الصناعة والأوساط الأكاديمية من خلال إنشاء برنامج تدريب يستمر لمدة عام دراسي واحد والذي سيمكن الطلاب من الانخراط في فترة أطول من التعلم التطبيقي بما يتماشى مع احتياجات الصناعة المستقبلية.
                                كما توفر إعداد منصة تسهل وتعزز الشراكة والتبادل الفكري بين الأوساط الأكاديمية والصناعة.
                                يوفر هذا البرنامج فرصة لطلاب الجامعات / الكليات الذين يتمتعون بسنة من الخبرة القيمة ، والتي ستمنح الخريجين ميزة عندما يدخلون عالم العمل الحقيقي بعد دراستهم.</p>
                        </>}
                </div>
            </div>
        </div>
    )
}

export default Section_1