import React, { useState, useEffect } from 'react';
import '../style/website.css'
import Nav from '../components/website/nav';
import Welcome from '../components/website/welcome';
import Featured from '../components/website/featured';
import Section_1 from '../components/website/section_1';
import Section_2 from '../components/website/section_2';
import Section_3 from '../components/website/section_3';
import Section_4 from '../components/website/section_4';
import Section_5 from '../components/website/section_5';
import Timeline from '../components/website/timeline';
import News from '../components/website/news';
import Statistics from '../components/website/statistics';
import Contact from '../components/website/contact';
import RequirementModal from '../components/website/requirementModal';
import Footer from '../components/website/footer';

function Website() {
    const [isEng, setIsEng] = useState(true)
    const [modal, setModal] = useState(false)

    const handleLanguage = () => {
        localStorage.setItem('language', isEng ? 'ar' : 'eng')
        setIsEng(prev => !prev)
    }

    useEffect(() => {
        if (localStorage.getItem('language')) {
            const lang = localStorage.getItem('language')
            setIsEng(lang == 'eng' ? true : false)
        }
    }, [])

    return (
        <div style={{ backgroundColor: 'white' }}>
            <Nav isEng={isEng} handleLanguage={() => handleLanguage()} />
            <Welcome />
            <Featured isEng={isEng} setModal={() => setModal(true)} />
            <RequirementModal isEng={isEng} modal={modal} setModal={() => setModal(prev => !prev)} />

            <section className="section padding-top-70 padding-bottom-0" id="features">
                <div className="container">
                    <Section_1 isEng={isEng} /><br />
                    <Section_2 isEng={isEng} /><br />
                    <Section_3 isEng={isEng} />
                </div>
            </section>

            <Statistics isEng={isEng} />
            <Timeline isEng={isEng} />
            <Section_4 isEng={isEng} />
            {/* <News isEng={isEng} /> */}
            <Section_5 isEng={isEng} />
            <Contact isEng={isEng} />
            <Footer isEng={isEng} />
        </div>
    )
}

export default Website