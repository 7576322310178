import React, { useState, useEffect, useContext } from 'react';
import MUIDataTable from "mui-datatables";
import axios from 'axios';
import { api } from '../../util/constant'
import { Cxt } from '../../App'
import muiTable from '../../util/mui-table'
import { IoEllipsisVerticalCircle, IoExpandOutline } from "react-icons/io5";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { isArr } from '../../util/functions'
import MajorModal from './majorModal'
function Major(props) {

    const { role } = useContext(Cxt);

    const [newMajor, setNewMajor] = useState(false);
    const [newMajorName, setNewMajorName] = useState('');
    const [warning, setWarning] = useState(false);
    const [messege, setMessege] = useState('');
    const [list, setList] = useState([])
    const [theme, setTheme] = useState(false)
    const [modal, setModal] = useState(false)
    const [majorInfo, setMajorInfo] = useState({})

    const LoaderToggle = (status) => (props.loaderToggle(status))


    const getMajors = async () => {
        LoaderToggle(true);
        const majors = await axios.get(`${api}/major`, { withCredentials: true });
        setList(isArr(majors?.data));
        LoaderToggle(false);
    }

    //save new major
    const SaveMajor = () => {
        LoaderToggle(true);
        if (newMajorName == '') { LoaderToggle(false); setWarning(true); setMessege("Major Name Cannot be empty !"); return }
        axios.post(`${api}/major`, { name: newMajorName }, { withCredentials: true })
            .then((res) => { LoaderToggle(false); resetNewMajor(); getMajors() })
            .catch((e) => { LoaderToggle(false); setWarning(true); setMessege('this major already exists !') })
    }

    const getMajor = (id) => {
        LoaderToggle(true);
        axios.get(`${api}/major`, { headers: { id, sendStudentsCounts: true }, withCredentials: true })
            .then((res) => { LoaderToggle(false); setMajorInfo(res.data); setModal(true) })
            .catch((e) => { LoaderToggle(false); })
    }

    //reset all status to defualt
    const resetNewMajor = () => { setWarning(false); setMessege(''); setNewMajorName(''); setNewMajor(!newMajor) }

    useEffect(() => { getMajors(); }, [])

    //table attributes
    const columns = ["Name", {
        label: "",
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                return (role <= 2 ? <span style={{ fontSize: "20px", padding: '0px' }} className="btn" onClick={() => { getMajor(tableMeta.rowData[tableMeta.columnIndex]) }}><IoEllipsisVerticalCircle /></span> : '')
            }
        }
    }];
    const options = muiTable.muiOptions(theme)

    return (
        <>
            {role <= 2 ? <MajorModal refresh={() => getMajors()} majors={list} setMajorInfo={info => setMajorInfo(info)} info={majorInfo} modal={modal} setModal={status => setModal(status)} loaderToggle={status => props.loaderToggle(status)} /> : null}
            <div className="row m-1">
                {role <= 2 ? <div className="col-md-1 p-3"><button className="btn btn-light shadow" onClick={resetNewMajor}>New</button></div> : null}
                {newMajor ?
                    <><div className="col-md-5 p-3"><input type="text" placeholder="Name" onChange={(e) => { setNewMajorName(e.target.value) }} className="form-control" /></div>
                        <div className="col-md-1 p-3"><button className="btn btn-success" onClick={SaveMajor}>Save</button></div>
                        {warning ? <div className="col-md-5"><div className="alert alert-warning">{messege}</div></div> : null}
                    </> : null}
            </div>
            <div className="p-4">
                <MuiThemeProvider theme={theme ? muiTable.getMuiTheme() : muiTable.noTheme()}>
                    <MUIDataTable title={<>
                        <button className="btn btn-light shadow" disabled >Majors List</button>
                        <button onClick={() => { setTheme(!theme); }} className="btn btn-light shadow"><IoExpandOutline /></button>
                    </>} data={list ? list.map(item => { return [item?.name, item?._id] }) : []}
                        columns={columns} options={options} />
                </MuiThemeProvider>
            </div>
        </>)
}

export default Major
