import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';
import { api } from '../../util/constant'
import { isArr } from '../../util/functions';
function DashboardMessegeModal(props) {

    const [dashboardMessege, setDashboardMessege] = useState([])
    const toggle = () => (props.changePerentToggle(!props.modal));
    const loaderToggle = (loader) => (props.changeLoader(loader));

    const getDashboardMessege = async () => {
        loaderToggle(true)
        axios.get(`${api}/setting/dashboard_messege`, { withCredentials: true })
            .then((res) => { setDashboardMessege(isArr(res.data)); loaderToggle(false) })
            .catch((e) => { console.log(e); loaderToggle(false) })
    }

    const handleValueChange = (value, type, index) => {
        let arr = [...dashboardMessege]
        arr[index][type] = value
        setDashboardMessege(arr)
    }

    const Save = async () => {
        loaderToggle(true)
        await axios.post(`${api}/setting/dashboard_messege`, { dashboard_messege: dashboardMessege }, { withCredentials: true })
            .then(() => { loaderToggle(false); toggle() })
            .catch((e) => { loaderToggle(false); console.log(e); })
    }
    useEffect(() => { if (props.modal) getDashboardMessege() }, [props.modal])

    return (
        <Modal size="lg" style={{ maxWidth: '700px', width: '100%' }} isOpen={props.modal} toggle={() => { toggle(props) }}>
            <ModalHeader toggle={() => { toggle(props) }}>Dashboard Messege</ModalHeader>
            <ModalBody>

                {isArr(dashboardMessege).map((item, index) =>
                    <div className='row'>
                        <div className="col-md-2">{item?.role_name}</div>
                        <div className="col-md-6"><input type="text" className="form-control" placeholder='Messege' value={item?.text} onChange={e => handleValueChange(e.target.value, 'text', index)} /></div>
                        <div className="col-md-2 text-center"> <input type="checkbox" checked={item?.is_show} onClick={e => handleValueChange(e.target.checked, 'is_show', index)} id="" /> {item?.is_show ? 'Visible' : 'Hidden'} </div>
                        <div className="col-md-2">
                            <select defaultValue={item?.color} onClick={e => handleValueChange(e.target.value, 'color', index)} className='form-control'>
                                <option value="success">green</option>
                                <option value="warning">yellow</option>
                                <option value="danger">red</option>
                            </select>
                        </div>
                    </div>
                )}

            </ModalBody>
            <ModalFooter>
                <div className="row">
                    <div className="col-sm-4 mb-2"><Button color="success" onClick={Save}>Save</Button></div>
                    <div className="col-sm-4 mb-2"></div>
                    <div className="col-sm-4 mb-2"><Button color="warning" onClick={toggle}>Cancel</Button></div>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default DashboardMessegeModal
