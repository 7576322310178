import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { isLogin } from './login'
import { Cxt } from '../App'
import { path } from './constant'
function PrivateRoute({ component: Component, authenticated, ...rest }) {
    const { role, setRole } = useContext(Cxt)

    const Role = (allowedRole, userRole) => {
        if (allowedRole.includes(parseInt(userRole))) return true
        return false
    }

    return (
        <Route {...rest} render={props => (isLogin() && Role(authenticated, role) ?
            <Component {...props} />
            :
            <Redirect to={`${path}/login`} />)} />
    )
}

export default PrivateRoute
