import React, { useState, useEffect } from 'react';
import News from '../components/news';
import axios from 'axios';
import ForMyAction from '../components/AppForMyAcion'
import { api } from '../../../util/constant'
import DashboardMessege from '../components/dashboardMessege'
import { isArr } from '../../../util/functions'
import ProfileCard from '../components/profileCard'
function AfpAndAdvisor(props) {

    const [newsList, setNewsList] = useState([])
    const [appsList, setAppsList] = useState([])
    const [allUserInfo, setAllUserInfo] = useState({})
    const [dashboard_messege, setDashboard_messege] = useState({ is_show: false, text: '', color: 'success' })
    const loader = (status) => { props.loderToggle(status) }
    const getDashboard = () => {
        loader(true)
        axios.get(`${api}/dashboard`, { withCredentials: true })
            .then((res) => {
                const { user, news, AppForMyAction, dashboard_messege } = res.data;
                setNewsList(isArr(news)); setAppsList(isArr(AppForMyAction));
                setAllUserInfo(user)
                setDashboard_messege(dashboard_messege)
                loader(false);
            })
            .catch((e) => { console.log(e); loader(false) })
    }
    useEffect(() => { getDashboard(); }, [])
    return (
        <>
            <div className='row mt-3 p-3'>
                <DashboardMessege dashboard_messege={dashboard_messege} />
                <div className="col-md-4 p-2"></div>
                <div className="col-md-4 p-2"><ProfileCard info={allUserInfo} /></div>
                <div className="col-md-4 p-2"></div>
                <div className="col-md-6 p-2"><News news={newsList} /></div>
                <div className="col-md-6 p-2"><ForMyAction apps={appsList} /></div>
            </div>

        </>
    )
}

export default AfpAndAdvisor
