import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { api } from '../../../util/constant'
import News from '../components/news'
import DashboardMessege from '../components/dashboardMessege'
import { isArr } from '../../../util/functions'
import ProfileCard from '../components/profileCard'
function Ofp(props) {

    const [newsList, setNewsList] = useState([])
    const [disciplines, setDisciplines] = useState('')
    const [pending, setPending] = useState('')
    const [allUserInfo, setAllUserInfo] = useState({})
    const [dashboard_messege, setDashboard_messege] = useState({ is_show: false, text: '', color: 'success' })
    const loader = (status) => { props.loderToggle(status) }
    const getDashboard = () => {
        loader(true)
        axios.get(`${api}/dashboard`, { withCredentials: true })
            .then((res) => {
                const { news = [], disciplines = '', pending = '', user = {}, dashboard_messege } = res?.data;
                setNewsList(isArr(news));
                setDisciplines(disciplines);
                setPending(pending);
                setAllUserInfo(user)
                setDashboard_messege(dashboard_messege)
                loader(false);
            })
            .catch((e) => { console.log(e); loader(false) })
    }
    useEffect(() => { getDashboard(); }, [])

    return <>
        <div className="dashboard-main row p-4">
            <DashboardMessege dashboard_messege={dashboard_messege} />
            <div className="col-md-4 p-2">
                <div className=" card-info smooth-box-shadow">
                    <div className="title">
                        Disciplines
                    </div>
                    <div className="icon"><img src="/images/paper.svg" /></div>
                    <div className="val">{disciplines}</div>
                </div>
            </div>
            <div className="col-md-4 p-2"><ProfileCard info={allUserInfo} /></div>
            <div className="col-md-4 p-2">
                <div className=" card-info smooth-box-shadow">
                    <div className="title">
                        Pending Registration
                    </div>
                    <div className="icon"><img src="/images/demand.svg" /></div>
                    <div className="val">{pending}</div>
                </div>
            </div>
            <div className="col-md-12 p-2"><News news={newsList} /></div>
        </div>

    </>;
}

export default Ofp;
