import React, { useState, useEffect } from 'react'
import { Dropdown, List } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css';
import axios from 'axios';
import '../components_style/mapping.css'
import { api } from '../../util/constant'
import { isArr } from '../../util/functions'
import Graph from 'react-json-graph';
import { IoSearchOutline } from "react-icons/io5";
function Mapping(props) {

    const [majors, setMajors] = useState([])
    const [mappingType, setMappingType] = useState('list')

    const loader = (status) => { props.loaderToggle(status) }

    const getMajors = async () => {
        loader(true);
        axios.get(`${api}/major`, { withCredentials: true })
            .then((res) => { console.log(res?.data); setMajors(isArr(res?.data)); loader(false); })
            .catch((e) => { console.log(e); loader(false); })
    }

    const displayDisciplinesOfMajor = async (id, i) => {
        const major = await axios.get(`${api}/major`, { headers: { id }, withCredentials: true });
        const temp = [...majors]
        temp[i].displainsWithDetails = Array.isArray(major?.data?.disciplines) ? major?.data?.disciplines : [];
        setMajors(temp)
    }

    useEffect(() => { getMajors(); }, [])

    return (
        <div className="p-3">
            <div className="row">
                <div className="col-md-12 mb-2 p-0 shadow">
                    <button className={`sub-nav w50 left ${mappingType == 'list' ? 'active' : ''}`} onClick={() => { setMappingType('list') }}>List</button>
                    <button className={`sub-nav w50 mid ${mappingType == 'graph' ? 'active' : ''}`} onClick={() => { setMappingType('graph') }}>Graph</button>
                </div>
            </div>
            {/* <div>
                <input type="radio" name="mappingType" checked={mappingType == 'list'} onClick={() => setMappingType('list')} />List <br />
                <input type="radio" name="mappingType" checked={mappingType == 'graph'} onClick={() => setMappingType('graph')} />Graph
            </div> */}
            {mappingType == 'graph' ? <GraphList loader={status => loader(status)} majors={majors} /> : null}
            {mappingType == 'list' ? <>
                <List>
                    {majors.map((item, i) => {
                        return (
                            <OuterList
                                index={i}
                                displayDisciplinesOfMajor={(id, index) => { displayDisciplinesOfMajor(id, index) }}
                                item={item}
                            />)
                    })}
                </List>
            </> : null}
        </div>
    )
}


const OuterList = props => {

    return (
        <List.Item className="m-2">
            <List.Icon name='caret add square text-success' className='header-list' onClick={() => props.displayDisciplinesOfMajor(props?.item?._id, props.index)} />
            <List.Content>
                <List.Header >
                    <span className='header-list' onClick={() => { props.displayDisciplinesOfMajor(props?.item?._id, props.index) }}>
                        {props?.item?.name}
                        <span className='text-dark'>
                            ({props?.item?.disciplines?.length})
                        </span>
                    </span>
                </List.Header>
                <List.Description></List.Description>
                {props?.item?.displainsWithDetails && props?.item?.displainsWithDetails?.length != 0 ?
                    props?.item?.displainsWithDetails.map(item => <InnerList item={item} />) : null}
            </List.Content>
        </List.Item>
    )
}

const InnerList = props => {
    return (
        <List.Item>
            <List.Content>
                <List.Header className="header-list-inner"><List.Icon name='caret angle right' />{props?.item?.discipline?.name}</List.Header>
            </List.Content>
        </List.Item>
    )
}

const GraphList = ({ loader, majors }) => {

    const [graphJson, setGraphJson] = useState({
        nodes: [{
            id: '0',
            label: 'Alice',
            position: { x: 150, y: 150 },
        },
        {
            id: '1',
            label: 'Bob',
            position: { x: 250, y: 250 },
        },
        {
            id: '2',
            label: 'Daived',
            position: { x: 350, y: 350 },
        }],
        edges: [{
            source: '0',
            target: '1'
        },
        {
            source: '2',
            target: '1'
        },
        {
            source: '2',
            target: '0'
        }
        ]
    })
    const [selectedMajors, setSelectedMajors] = useState([])
    const [reload, setReload] = useState(false)
    const [graphScale, setGraphScale] = useState(1)
    const getMajorsForGraph = async () => {
        loader(true);
        setReload(true)
        axios.post(`${api}/major/graph`, { selectedMajors }, { withCredentials: true })
            .then((res) => {
                setGraphJson(res?.data);
                setReload(false)
                loader(false);
            })
            .catch((e) => { console.log(e); loader(false); })
    }

    useEffect(() => { getMajorsForGraph() }, [selectedMajors])

    return <>
        <div className="row">
            <div className="col-md-6">
                <Dropdown placeholder='<select majors>' fluid multiple search selection
                    options={majors ? majors.map(item => { return { key: item.key, text: item.name, value: item._id } }) : []}
                    onChange={(e, d) => { setSelectedMajors(d.value); }}
                    defaultValue={selectedMajors}
                />
            </div>
            <div className="col-md-6 text-center">
                <button className='btn btn-light shadow border' onClick={() => {
                    setGraphScale(prev => prev > 0.5 ? prev - 0.1 : prev)
                }}>-<IoSearchOutline /></button>
                <button className='btn btn-light shadow border' onClick={() => {
                    setGraphScale(prev => prev < 1 ? prev + 0.1 : prev)
                }}>+<IoSearchOutline /></button>
            </div>
        </div>
        <div className='graph-scaled-wrapper'>
            <div className='graph-scaled-content' style={{ transform: `scale(${graphScale})` }}>
                {reload ? null : <Graph
                    width={1000}
                    height={typeof graphJson?.nodes[graphJson?.nodes?.length - 1]?.position?.y == 'number' ? graphJson.nodes[graphJson.nodes.length - 1].position.y + 20000 : 1000}
                    json={{
                        nodes: isArr(graphJson?.nodes),
                        edges: isArr(graphJson?.edges),
                    }}
                />}
            </div>
        </div>

    </>
}

export default Mapping
