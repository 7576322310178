import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { isArr } from '../../../util/functions'

const HistoryModal = (props) => {
    const toggle = () => (props.setModal(!props.modal));

    return (
        <Modal size="lg" style={{ maxWidth: '700px', width: '100%' }} isOpen={props.modal} toggle={() => { toggle(props) }}>
            <ModalHeader toggle={() => { toggle() }}>History</ModalHeader>
            <ModalBody>
                <div className='row mt-2'>
                    {isArr(props?.info)?.length == 0 ?
                        <div className="col-md-12 alert alert-success text-center ">there is no history.</div>
                        :
                        isArr(props?.info)?.map((item, index) => {
                            return (<div className='col-md-12 pe-4 ps-4' key={`apphistory-${index}`}>
                                <span className="bg-light p-2 border rounded">{new Date(item?.createdAt).toLocaleString()}</span>
                                <div className="alert alert-warning">
                                    <p><pre style={{ whiteSpace: 'pre-wrap' }}>{item?.text}</pre></p>
                                </div>
                            </div>)
                        })
                    }
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="row">
                    <div className="col-md-12">
                        <button className='btn btn-warning float-end' onClick={() => { toggle() }}>Close</button>
                    </div>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default HistoryModal