import React, { useState, useContext } from 'react'
import Nav from '../components/nav';
import Location from '../components/location';
import { Cxt } from '../App'
import Ofp from '../components/fdf/users/Ofp'
import Student from '../components/fdf/users/student'
import EidaadAndAdmin from '../components/fdf/users/eidaadAndAdmin'
import SupervisorAndTechAuth from '../components/fdf/users/supervisorAndTechAuth'
import Afp from '../components/fdf/users/afp'
import { identifyRole } from '../util/functions'
import Loader from '../components/loader'
import '../style/fdf.css';
import FdfFullSubmissionList from '../components/fdf/components/fdfFullSubmissionList';

function FDF() {
    const [loader, setLoader] = useState(false)
    const [page, setPage] = useState(0)
    const { role } = useContext(Cxt);
    const { isOFP, isOFFP, isAdminOrEidaad } = identifyRole(role)
    return (
        <>
            <Nav />
            {loader ? <Loader /> : null}
            <Location name="Fundimantal devlopment Framework" />
            {isAdminOrEidaad || isOFP || isOFFP ? <div className="col-md-12 mb-2 p-0 shadow">
                <button className={`sub-nav w50 left ${page == 0 ? 'active' : ''}`} onClick={() => { setPage(0) }}>FDF</button>
                <button className={`sub-nav w50 mid ${page == 1 ? 'active' : ''}`} onClick={() => { setPage(1) }}>Submissions List</button>
            </div> : null}
            {page == 0 ? <StructureFdf setLoader={loader => setLoader(loader)} /> : null}
            {page == 1 ? <FdfFullSubmissionList loader={loader => setLoader(loader)} /> : null}
        </>
    )
}


const StructureFdf = ({ setLoader }) => {
    const { role } = useContext(Cxt);
    const { isOFP, isStudent, isOFFP, isAFP, isTA, isSV, isAdminOrEidaad, isAdvisor } = identifyRole(role)
    return (<>
        {isAdminOrEidaad ? <EidaadAndAdmin loader={loader => setLoader(loader)} /> : null}
        {isOFP || isOFFP ? <Ofp loader={loader => setLoader(loader)} /> : null}
        {isStudent ? <Student loader={loader => setLoader(loader)} /> : null}
        {isSV || isTA ? <SupervisorAndTechAuth loader={loader => setLoader(loader)} /> : null}
        {isAFP || isAdvisor ? <Afp loader={loader => setLoader(loader)} /> : null}
    </>)
}
export default FDF