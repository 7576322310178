import React, { useState, useEffect, useContext } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';
import { Dropdown } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { api } from '../../util/constant'
import { Cxt } from '../../App'
import { isArr } from '../../util/functions'
import { isEmpty as _isEmpty } from 'validator'
const DisciplineModal = (props) => {

    const { role } = useContext(Cxt);
    //states
    const [name, setName] = useState('');
    const [location, setLocation] = useState('');
    const [selectedOrg, setSelectedOrg] = useState('');
    const [warning, setWarning] = useState([false, '']);
    const [ConfirmDelete, setConfirmDelete] = useState(false);
    const [Demand, setDemand] = useState([]);
    const [Description, setDescription] = useState('');
    const [DisabledYears, setDisabledYears] = useState([])
    const [selectedMajors, setSelectedMajors] = useState([])

    const toggle = () => (props.changePerentToggle(!props.modal));
    const loaderToggle = (loader) => (props.changeLoader(loader));

    const reset = () => { setSelectedMajors([]); setName(''); setDescription(''); setLocation(''); setSelectedOrg(''); setWarning([false, '']); setDemand([]) }

    const validation = (Fields) => {
        let isEmpty = false
        Fields.map(item => { if (item == undefined || item == null || _isEmpty(item)) { isEmpty = true } });
        if (isEmpty) { loaderToggle(false); setWarning([true, 'please fill all Fields']); } else { setWarning([false, '']); };
        if (selectedMajors.length == 0) { loaderToggle(false); setWarning([true, 'please select majors']); isEmpty = true; }
        if (Demand.length > 0) {
            Demand.map(item => {
                const year = parseInt(item.year);
                if (item.is_all == true) {
                    if (item?.all?.seats <= 0 || isNaN(item?.all?.seats)) { setWarning([true, "demand must be greater than 0."]); isEmpty = true; return true }
                } else {
                    const { male, female } = item
                    if (male?.seats <= 0 && female?.seats <= 0 || isNaN(male?.seats) && isNaN(female?.seats)) { setWarning([true, "demand must be greater than 0."]); isEmpty = true; return true }
                }
                const isYearEmpty = item.year == ''
                if (isYearEmpty) { setWarning([true, "year cannot be empty"]); isEmpty = true; return true }
                const isYearRepeatedForThisDiscipline = Demand.filter(ele => ele.year == `${item.year}`).length > 1
                if (isYearRepeatedForThisDiscipline) { setWarning([true, "call year can't be repeated."]); isEmpty = true; return true }
            })
        }
        return isEmpty
    }

    const checkIfYearDisable = (selectedYear) => {
        const year = props?.years.filter(item => item._id == selectedYear)
        if (year.length == 0) return true
        else return year[0]?.enable
    }

    const updateDiscipline = async (id) => {
        loaderToggle(true);
        const FieldsWithoutOrg = [name, Description, location];
        const isEmpty = validation(role == 5 ? FieldsWithoutOrg : [...FieldsWithoutOrg, selectedOrg]);
        if (isEmpty) { loaderToggle(false); return }
        axios.put(`${api}/discipline`, { id, name, demand: Demand, Description, selectedMajors, location, orgnization: selectedOrg }, { withCredentials: true })
            .then((res) => { reset(); toggle(); props.refreshTable(); loaderToggle(false); })
            .catch((e) => { console.log(e); loaderToggle(false); })

    }


    const createNewDiscipline = async () => {
        loaderToggle(true);
        const FieldsWithoutOrg = [name, Description, location];
        const isEmpty = validation(role == 5 ? FieldsWithoutOrg : [...FieldsWithoutOrg, selectedOrg])
        if (isEmpty) { loaderToggle(false); return }
        await axios.post(`${api}/discipline`, { name, location, demand: Demand, Description, orgnization: selectedOrg, selectedMajors }, { withCredentials: true })
            .then((res) => { reset(); toggle(); props.refreshTable(); })
            .catch((e) => { console.log(e); })
        loaderToggle(false);
    }

    const handleAddDemand = () => { const obj = { seats: 0 }; setDemand([...Demand, { year: '', is_all: false, all: obj, male: obj, female: obj }]) }
    const handleRemoveDemand = (i) => { let newArr = [...Demand]; newArr.splice(i, 1); setDemand(newArr) }
    const handleOnChangeDemand = (e, i) => {
        let newArr = [...Demand];
        const is_all = Boolean(e.target.checked);
        const isChangeValueOfInput = e.target.name == 'all' || e.target.name == 'male' || e.target.name == 'female' ? true : false;
        let isResetAllElement = e.target.name == 'male' || e.target.name == 'female' || !isChangeValueOfInput ? true : false;
        let isResetMaleAndFemaleElement = e.target.name == 'all' || !isChangeValueOfInput ? true : false;

        if (isChangeValueOfInput) { newArr[i][e.target.name] = { seats: e.target.value } }
        else { newArr[i][e.target.name] = e.target.name == 'is_all' ? is_all : newArr[i][e.target.name] = e.target.value; }

        if (isResetMaleAndFemaleElement) { newArr[i]['male'] = { seats: 0 }; newArr[i]['female'] = { seats: 0 } }
        if (isResetAllElement) { newArr[i]['all'] = { seats: 0 } }

        setDemand(newArr);
    }


    useEffect(() => {
        if (props.modelType == 1) { reset() }
        else {
            setSelectedMajors(props.majorsHasThisDiscipline)
            setWarning([false, '']);
            setConfirmDelete(false);
            setDemand(isArr(props.info?.demand))
            setName(props.info?.name);
            setDescription(props.info?.Description);
            setLocation(props.info?.location);
            setSelectedMajors(props.majorsForSingleDiscipline)
            setSelectedOrg(props.info?.orgnization?._id ? props.info.orgnization._id : '')
        }

    }, [props.modal])

    return (
        <div>
            <Modal size="lg" style={{ maxWidth: '700px', width: '100%' }} isOpen={props.modal} toggle={() => { toggle(props) }}>
                <ModalHeader toggle={() => { toggle(props) }}>{props.modelType == 1 ? <>New Discipline</> : <>Modify Discipline</>}</ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-md-3" onClick={() => { console.log({ name, location, Description, selectedOrg }) }}>Name:</div>
                        <div className="col-md-9 "><input type="text" value={name} onChange={(e) => { setName(e.target.value) }} className="form-control" /></div>
                        <div className="col-md-3">Location :</div>
                        <div className="col-md-9"><input type="text" value={location} onChange={(e) => { setLocation(e.target.value) }} className="form-control" /></div>
                        <div className="col-md-3">Description  :</div>
                        <div className="col-md-9"><textarea value={Description} onChange={(e) => { setDescription(e.target.value) }} className="form-control" /></div>

                        {role != 5 ? <><div className="col-md-3">Orgnization :</div>
                            <div className="col-md-9">
                                <Dropdown placeholder='--Select institution--' fluid clearable search selection disabled={role <= 2 ? false : true}
                                    options={props.orgs ? props.orgs.map(item => { return { key: item.key, text: item.name, value: item._id } }) : []}
                                    onChange={(e, d) => { setSelectedOrg(d.value) }}
                                    value={selectedOrg} /></div> </> : null}
                        <div className="col-md-3">Majors :</div>
                        <div className="col-md-9">
                            <Dropdown placeholder='<select majors>' fluid multiple search selection
                                options={props.majors ? props.majors.map(item => { return { key: item.key, text: item.name, value: item._id } }) : []}
                                onChange={(e, d) => { setSelectedMajors(d.value); }}
                                defaultValue={selectedMajors}
                            />
                        </div>
                    </div>
                    Demand<button onClick={handleAddDemand} className="btn btn-success m-3">+</button>
                    <div className='border' style={{ maxHeight: '150px', overflow: 'scroll' }}>
                        {Demand.length == 0 ? <div className="alert alert-success mt-1 ms-1 mb-0">No Demand</div> : Demand.map((item, i) => {
                            return (
                                <div className="row m-1 text-center" key={i}>
                                    <div className="col-sm-12 p-1">

                                        <select className="form-control" value={item.year || ''} name='year' disabled={!checkIfYearDisable(item.year)} onChange={(e) => { handleOnChangeDemand(e, i) }}>
                                            <option value="">{`<call year>`}</option>
                                            {props.years?.map((item) => {
                                                return <option value={item._id} style={{ color: item.enable ? 'green' : 'red' }} disabled={item.enable ? false : true} >{item.year}</option>
                                            })}
                                        </select>

                                    </div>
                                    <div className="col-sm-12 p-1">
                                        All <input checked={item.is_all} type="checkbox" disabled={!checkIfYearDisable(item.year)} name='is_all' onChange={(e) => { handleOnChangeDemand(e, i) }} />{` `}
                                        {item.is_all ? <>
                                            <input type="text" name='all' disabled={!checkIfYearDisable(item.year)} style={{ width: '50px' }} value={item?.all?.seats || ''} onChange={(e) => { handleOnChangeDemand(e, i) }} className="form-control  d-inline-block" />
                                            <span className={`border border-1 border-${item?.all?.taken ? item?.all?.taken < item?.all?.seats ? 'info' : 'warning' : 'info'} rounded p-2`}>/{item?.taken ? item?.taken : 0}</span>
                                        </> :
                                            <>
                                                male<input type="text" name='male' disabled={!checkIfYearDisable(item.year)} style={{ width: '45px' }} value={item?.male?.seats || ''} onChange={(e) => { handleOnChangeDemand(e, i) }} className="form-control  d-inline-block" />
                                                <span className={`border border-1 border-${item?.male?.taken ? item?.male?.taken < item?.male?.seats ? 'info' : 'warning' : 'info'} rounded p-2`}>/{item?.taken ? item?.taken : 0}</span>
                                                female<input type="text" name='female' disabled={!checkIfYearDisable(item.year)} style={{ width: '45px' }} value={item?.female?.seats || ''} onChange={(e) => { handleOnChangeDemand(e, i) }} className="form-control d-inline-block" />
                                                <span className={`border border-1 border-${item?.female?.taken ? item?.female?.taken < item?.male?.seats ? 'info' : 'warning' : 'info'} rounded p-2`}>/{item?.taken ? item?.taken : 0}</span>
                                            </>
                                        }

                                    </div>
                                    {checkIfYearDisable(item.year) ? <div className="col-sm-2 p-1"><button onClick={() => { handleRemoveDemand(i) }} className="btn btn-danger">-</button></div> : null}
                                </div>)
                        })}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="row">
                        <div className="col-sm-4 mb-2"><button onClick={() => { if (props.modelType == 1) { createNewDiscipline() } else { updateDiscipline(props.info._id) } }} className="btn btn-success">Save</button></div>
                        <div className="col-sm-4 mb-2">{warning[0] ? <div className="alert alert-warning">{warning[1]}</div> : null}</div>
                        <div className="col-sm-4 mb-2"><Button color="warning" onClick={toggle}>Cancel</Button></div>
                        {/* {props.modelType == 2 ?
                            <div className="col-sm-12 text-center p-2">
                                {ConfirmDelete ? <button className="btn btn-danger" onClick={DeleteDiscipline}>Are you sure you want to delete ?</button> :
                                    <button className="btn btn-danger" onClick={() => { setConfirmDelete(true) }}>Delete</button>}
                            </div> : null} */}
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default DisciplineModal
