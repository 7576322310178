import React from 'react'

function Section_5({ isEng }) {
    return (
        <section className="section" id="blog">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="center-heading">
                            <h2 className="section-title">{isEng ? 'Vision' : 'الرؤية'}</h2>
                        </div>
                    </div>
                    <div className="offset-lg-3 col-lg-6">
                        <div className="center-text">
                            <p>{isEng ? 'improve Graduate Employability Index and enhance Graduate Capabilities to bridge Industry/Academia gap and attain Global Recognition with the excellence of our People.' : 'تحسين مؤشر توظيف وتعزيز قدرات الخريجين لسد فجوة الصناعة / الأوساط الأكاديمية وتحقيق الاعتراف العالمي بتميز موظفينا هي من اهم اهداف البرنامج.'}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="blog-post-thumb">
                            <div className="img">
                                <img src="assets/images/blog-item-01.png" alt="true" />
                            </div>
                            <div className="blog-content">
                                <h3 className='pe-4 ps-4'>
                                    <a href="">
                                        {isEng ? 'Advantages of Eidaad to Industries' : 'فوائد البرنامج للمشركات'}
                                    </a>
                                </h3>
                                <div className="text pe-4 ps-4">
                                    {isEng ? 'Build high-level transferable skills and the ability to develop and demonstrate professional competencies in the workplace and Enhance Industry based research' : 'بناء مهارات عالية المستوى قابلة للتحويل والقدرة على تطوير وإثبات الكفاءات المهنية في مكان العمل وتعزيز البحث القائم على الصناعة'}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="blog-post-thumb">
                            <div className="img">
                                <img src="assets/images/blog-item-02.png" alt="true" />
                            </div>
                            <div className="blog-content">
                                <h3 className='pe-4 ps-4'>
                                    <a href="#">{isEng ? 'Benefits of Eidaad to Students' : 'فوائد البنامج للطلاب'}</a>
                                </h3>
                                <div className="text pe-4 ps-4">
                                    {isEng ? 'enhance students technical fundamentals and critical thinking skills and to expose them to open-ended real-life situations.' : 'تعزيز الأساسيات التقنية للطلاب ومهارات التفكير النقدي وتعريضهم لمواقف الحياة الواقعية المفتوحة.'}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="blog-post-thumb">
                            <div className="img">
                                <img src="assets/images/blog-item-03.png" alt="true" />
                            </div>
                            <div className="blog-content">
                                <h3 className='pe-4 ps-4'>
                                    <a href="#" >{isEng ? 'Program mission' : 'مهمة البرنامج'}</a>
                                </h3>
                                <div className="text pe-4 ps-4">
                                    {isEng ? 'Complement and Integrate Industry and Academia capabilities through genuine partnership to bridge the gap between Industry and Academia.' : 'استكمال ودمج قدرات الصناعة والأكاديمية من خلال شراكة حقيقية لسد الفجوة بين الصناعة والأوساط الأكاديمية.'}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Section_5