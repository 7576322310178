import React, { useContext } from 'react'
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { Confirm } from 'notiflix/build/notiflix-confirm-aio';
import { isEmpty } from 'validator'
import { api } from '../../../util/constant'
import { Cxt } from '../../../App';
import { identifyRole } from '../../../util/functions';

const FunctionModal = (props) => {
    const { role } = useContext(Cxt);
    const { isAdminOrEidaad } = identifyRole(role)
    const modalToggle = () => { props.setModal(!props.modal) }
    const loader = (status) => { props.loader(status) }
    const reset = () => { props.setFuncName(''); props.setFuncDesc(''); props.setIcon('0') }

    const save = (isActive) => {
        if (isEmpty(props.funcName.trim())) { Notify.warning('please enter function name.', { timeout: 2500 }); return }
        loader(true)
        let obj = { name: props.funcName, description: props.funcDesc, icon: props.icon }
        if (props.isModify) obj = { ...obj, id: props.isModify }
        if (props?.orgnization) obj = { ...obj, orgnization: props?.orgnization }
        if (isActive != undefined) obj = { ...obj, isActive }
        axios.post(`${api}/org/function`, obj, { withCredentials: true })
            .then((res) => { modalToggle(); loader(false); reset(); props.refresh(); Notify.success('Function has been added successfully.'); })
            .catch((e) => { Notify.failure('somthing went wrong.'); loader(false); console.log(e); })
    }

    const ConfermDelete = () => {
        Confirm.show(
            'Confirm Delete',
            'are you sure you want to delete this function ?',
            'Yes',
            'No',
            () => { save(false) },
            () => { },
            {
            },
        );
    }
    return (
        <Modal size="lg" style={{ maxWidth: '700px', width: '100%' }} isOpen={props.modal} toggle={() => { modalToggle() }}>
            <ModalHeader >Function</ModalHeader>
            <ModalBody>
                <div className="shadow p-3 m-2">
                    <div className="h3 text-center">{props.isModify ? 'Modify' : 'New'} Function</div>
                    Name:
                    <input type="text" className="form-control m-2" disabled={props.isDisableFileds} value={props.funcName} onChange={(e) => { props.setFuncName(e.target.value) }} placeholder='(Required)' />
                    Description:
                    <textarea className="form-control m-2" value={props.funcDesc} disabled={props.isDisableFileds} onChange={(e) => { props.setFuncDesc(e.target.value) }} rows="3"></textarea>
                    icons:
                    <div className="border rounded p-2">{Array.from({ length: 36 }, (_, i) => {
                        return <img src={`/icons/${i}.png`} onClick={() => { if (!props.isDisableFileds) props.setIcon(i) }} className={`btn m-1 border ${props.icon == i ? 'bg-success' : ''}`} style={{ width: '60px' }} />
                    })}
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="w-100 text-center">
                    {props.isDisableFileds ? null : <Button color="success float-start" onClick={() => { save() }}>Save</Button>}
                    {props.isDisableFileds || !isAdminOrEidaad ? null : <Button color="danger" onClick={() => { ConfermDelete() }}>Delete</Button>}
                    <Button color="warning float-end" onClick={() => { modalToggle() }}>Cancel</Button>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default FunctionModal