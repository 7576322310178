import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { api } from '../../util/constant';
import { isArr, identifyRole } from '../../util/functions';
import Loader from '../loader';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { isEmpty, isEmail, isNumeric } from 'validator';
import FunctionModal from './components/functionModal';

function OrgOfp({ role }) {

    const [loader, setLoader] = useState(false);
    const [orgInfo, setOrgInfo] = useState()
    const [functions, setFunctions] = useState([]);
    const [modal, setModal] = useState(false)
    const [funcName, setFuncName] = useState('')
    const [funcDesc, setFuncDesc] = useState('')
    const [icon, setIcon] = useState('0')
    const [isModifyFunction, setIsModifyFunction] = useState(false)

    const getOrg = (id) => {
        setLoader(true);
        axios.get(`${api}/org`, { headers: { id }, withCredentials: true })
            .then((response) => {
                const { name = '', type = '', phone = '', email = '', postal = '', url = '' } = response.data.org;
                setOrgInfo({ id, name, type, phone, email, postal, url });
                setFunctions(isArr(response.data.functions))
                setLoader(false);
            })
            .catch((e) => { console.log(e); setLoader(false); })
    }

    const updateOrg = () => {
        if (!isEmail(orgInfo.email)) { Notify.warning('email is not valid.', { timeout: 2500 }); return }
        if (!isNumeric(`${orgInfo.phone}`)) { Notify.warning('phone accept numbers only', { timeout: 2500 }); return }
        if (isEmpty(orgInfo.url) || isEmpty(orgInfo.postal)) { Notify.warning('please fill all fields', { timeout: 2500 }); return }
        setLoader(true);
        axios.post(`${api}/org`, { ...orgInfo }, { withCredentials: true })
            .then(() => { setLoader(false); Notify.success('update successfully.', { timeout: 2500 }); })
            .catch((e) => { console.log(e); setLoader(false); })
    }

    const getFunction = (id) => {
        setLoader(true);
        axios.get(`${api}/org/function`, { headers: { id }, withCredentials: true })
            .then((response) => {
                const { name = '', description = '', icon = '0' } = response?.data;
                setFuncName(name);
                setFuncDesc(description);
                setIcon(icon)
                setModal(true);
                setLoader(false);
            })
            .catch((e) => { console.log(e); setLoader(false); })
    }

    useEffect(() => { getOrg(); }, [])

    const { isOFP } = identifyRole(role)
    const isDisableFileds = isOFP ? false : true
    return (
        <>
            {loader ? <Loader /> : null}
            <FunctionModal isDisableFileds={isDisableFileds} setIcon={icon => setIcon(icon)} icon={icon} isModify={isModifyFunction} modal={modal} refresh={() => { getOrg() }} setFuncDesc={desc => setFuncDesc(desc)} funcDesc={funcDesc} funcName={funcName} setFuncName={name => setFuncName(name)} setModal={modal => setModal(modal)} loader={loader => setLoader(loader)} />
            <div className="container mt-5" style={{ maxWidth: '700px' }}>
                <div className="smooth-box-shadow bg-white60 p-3">
                    <div className="h3 text-center"></div>
                    <div className="row">
                        <div className="col-md-12 p-2 h3 text-center">{`${orgInfo?.name} (${orgInfo?.type == 1 ? 'Acadmic' : 'Indastirial'})`}</div>
                        <br />
                    </div>
                    <div className="h5 p-2">contact information :</div>
                    <div className="row">
                        <div className="col-md-12"><input type="email" disabled={isDisableFileds} value={orgInfo?.email} onChange={(e) => { setOrgInfo({ ...orgInfo, email: e.target.value }) }} className='form-control mb-3' placeholder='email' /></div>
                        <div className="col-md-12"><input type="text" disabled={isDisableFileds} value={orgInfo?.phone} onChange={(e) => { setOrgInfo({ ...orgInfo, phone: e.target.value }) }} className='form-control mb-3' placeholder='phone' /></div>
                        <div className="col-md-12"><input type="text" disabled={isDisableFileds} value={orgInfo?.postal} onChange={(e) => { setOrgInfo({ ...orgInfo, postal: e.target.value }) }} className='form-control mb-3' placeholder='postal' /></div>
                        <div className="col-md-12"><input type="text" disabled={isDisableFileds} value={orgInfo?.url} onChange={(e) => { setOrgInfo({ ...orgInfo, url: e.target.value }) }} className='form-control mb-3' placeholder='url' /></div>
                        {isDisableFileds ? null : <div className="col-md-12 text-center"><button className="btn btn-success" disabled={isDisableFileds} onClick={updateOrg}>update</button></div>}
                    </div>
                    <hr />
                    <div className="h5 p-2">Functions :{isDisableFileds ? null : <button className="btn btn-success" onClick={() => { setIsModifyFunction(false); setModal(true); setFuncName(''); setFuncDesc(''); setIcon('0') }}>+</button>} </div>
                    {functions.length == 0 ? <div className="alert alert-success">there is no functions.</div> :
                        <div>{functions.map((item, index) => { return <button key={index} onClick={() => { setIsModifyFunction(item._id); getFunction(item._id) }} className='btn btn-success m-2'>{item.name}</button> })}</div>
                    }
                </div>
            </div>
        </>
    )
}




export default OrgOfp