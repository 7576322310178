import React, { useState, useEffect } from 'react'
import Functions from '../components/functions/functions'
import Orgs from '../components/orgs'
import Fdfs from '../components/fdfs/fdfs'
import Fdf from '../components/fdf/fdf'
import Disciplines from '../components/disciplines/disciplines'
import axios from 'axios'
import { api } from '../../../util/constant'
import FdfSubmission from '../components/fdfSubmission/fdfSubmission'

function EidaadAndAdmin(props) {

    const setLoader = (status) => { props.loader(status) }
    const [selectedOrg, setSelectedOrg] = useState({ id: '', name: '' })
    const [selectedFunction, setSelectedFunction] = useState({ id: '', name: '' })
    const [selectedDiscipline, setSelectedDiscipline] = useState({ id: '', name: '' })
    const [selectedFdf, setSelectedFdf] = useState({ id: '', name: '' })
    const [selectedSubmission, setSelectedSubmission] = useState('')
    const [fdfSubmission, setFdfSubmission] = useState({})
    const [fdfDetails, setFdfDetails] = useState({})

    //page monitor
    const [isOrgsPage, setIsOrgsPage] = useState(false)
    const [isFunctionsPage, setIsFunctionsPage] = useState(false)
    const [isDisciplinesPage, setIsDisciplinesPage] = useState(false)
    const [isFdfsPage, setIsFdfsPage] = useState(false)
    const [isFdfPage, setIsFdfPage] = useState(false)
    const [isSubmissionPage, setIsSubmissionPage] = useState(false)

    const getSubmission = () => {
        props.loader(true)
        axios.get(`${api}/fdf/sub`, { headers: { studentId: selectedSubmission, fdf: selectedFdf.id }, withCredentials: true })
            .then((res) => {
                const { fdfDetails = {}, fdfSubmission = {} } = res?.data;
                setFdfDetails(fdfDetails);
                setFdfSubmission(fdfSubmission);
                props.loader(false);
            })
            .catch((e) => { console.log(e); props.loader(false) })
    }

    useEffect(() => {
        setIsOrgsPage(selectedOrg?.id == '' && selectedFunction.id == '' && selectedDiscipline.id == '' && selectedFdf.id == '' && selectedSubmission == '' ? true : false)
        setIsFunctionsPage(selectedOrg?.id != '' && selectedFunction.id == '' && selectedDiscipline.id == '' && selectedFdf.id == '' && selectedSubmission == '' ? true : false)
        setIsDisciplinesPage(selectedOrg?.id != '' && selectedFunction.id != '' && selectedDiscipline.id == '' && selectedFdf.id == '' && selectedSubmission == '' ? true : false)
        setIsFdfsPage(selectedOrg?.id != '' && selectedFunction.id != '' && selectedDiscipline.id != '' && selectedFdf.id == '' && selectedSubmission == '' ? true : false)
        setIsFdfPage(selectedOrg?.id != '' && selectedFunction.id != '' && selectedDiscipline.id != '' && selectedFdf.id != '' && selectedSubmission == '' ? true : false)
        setIsSubmissionPage(selectedOrg?.id != '' && selectedFunction.id != '' && selectedDiscipline.id != '' && selectedFdf.id != '' && selectedSubmission != '' ? true : false)
        if (selectedSubmission != '') getSubmission()
    }, [selectedOrg, selectedFunction, selectedDiscipline, selectedFdf, selectedSubmission])

    return (
        <>
            {!isOrgsPage ?
                <p className='bg-white60 rounded-pill'><img src="/images/org.svg" style={{ width: '50px' }} /> {selectedOrg.name}</p>
                : null}

            {isOrgsPage ?
                <Orgs
                    AllowToAddNew={true}
                    setSelectedOrg={selectedOrg => setSelectedOrg(selectedOrg)}
                    loader={loader => setLoader(loader)}
                />
                : null}


            {isFunctionsPage ? <Functions
                isBack={true}
                isAllowAddStudents={true}
                selectedOrg={selectedOrg}
                setSelectedOrg={selectedOrg => setSelectedOrg(selectedOrg)}
                setSelectedFunction={selectedFunction => setSelectedFunction(selectedFunction)}
                loader={loader => setLoader(loader)}
            />
                : null}



            {isDisciplinesPage ?
                <Disciplines
                    isBack={true}
                    AllowToAddNew={true}
                    selectedOrg={selectedOrg}
                    selectedFunction={selectedFunction}
                    setSelectedOrg={selectedOrg => setSelectedOrg(selectedOrg)}
                    setSelectedFunction={selectedFunction => setSelectedFunction(selectedFunction)}
                    setSelectedDiscipline={selectedDiscipline => setSelectedDiscipline(selectedDiscipline)}
                    loader={loader => setLoader(loader)}
                />
                : null}

            {isFdfsPage ?
                <Fdfs
                    isBack={true}
                    AllowToAddNew={true}
                    AllowToEdit={true}
                    selectedOrg={selectedOrg}
                    selectedFunction={selectedFunction}
                    selectedDiscipline={selectedDiscipline}
                    setSelectedDiscipline={selectedDiscipline => setSelectedDiscipline(selectedDiscipline)}
                    setSelectedFdf={selectedFdf => setSelectedFdf(selectedFdf)}
                    loader={loader => setLoader(loader)}
                /> : null}

            {isFdfPage ?
                <Fdf
                    isBack={true}
                    AllowToAddNew={true}
                    AllowToAddAnyStudent={true}
                    selectedOrg={selectedOrg}
                    selectedFunction={selectedFunction}
                    selectedDiscipline={selectedDiscipline}
                    selectedFdf={selectedFdf}
                    setSelectedFdf={selectedFdf => setSelectedFdf(selectedFdf)}
                    setSelectedSubmission={student_id => setSelectedSubmission(student_id)}
                    loader={loader => setLoader(loader)}
                /> : null}

            {isSubmissionPage ?
                <FdfSubmission
                    isBack={true}
                    details={fdfDetails}
                    selectedFunction={selectedFunction}
                    selectedDiscipline={selectedDiscipline}
                    setSelectedSubmission={student_id => setSelectedSubmission(student_id)}
                    refresh={() => getSubmission()}
                    selectedFdf={selectedFdf}
                    submission={fdfSubmission}
                    loader={status => { props.loader(status) }}

                />
                : null}


        </>
    )
}
export default EidaadAndAdmin