import React from 'react'

function Section_3({ isEng }) {
    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <div className="center-heading">
                        <h2 className="section-title">{isEng ? <>Benifits </> : <>فوائد البرنامج للمنتسبين </>}</h2>
                    </div>
                    <div className="center-text">
                        {isEng ? <>
                            <p>The most important benefit of Eidaad is that it provides students with real-work experience before their graduation. No doubt, a good CGPA is important for graduates to apply for a job, however employers want to see entry-level job candidates with some level of exposure to open-ended real-life situations that are outside of the classroom engagement.</p>
                        </> : <>
                            <p className="arabic">من أهم مزايا البرنامج أنه يوفر للطلاب خبرة عملية حقيقية قبل تخرجهم. لا شك أن المعدل التراكمي الجيد و مهم للخريجين للتقدم لوظيفة ، ومع ذلك يرغب أصحاب العمل في رؤية المرشحين للوظائف على مستوى المبتدئين ان يمتلك على الاقل على خبرة عملية في مجال العمل و التعرض لمواقف العمل الواقعية خارج الفصل الدراسي.</p>
                        </>}
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12"
                    data-scroll-reveal="enter bottom move 50px over 0.6s after 0.2s">
                    <div className="pricing-item">
                        <div className="pricing-body">
                            <div className="price-wrapper">
                                <span className="period">{isEng ? 'Fundamentals' : 'الأساسيات'}</span>
                            </div>
                            <ul className="list">
                                <li className="active">{isEng ? 'Learn applied fundamentals of future job discipline.' : 'تعلم الأساسيات التطبيقية للخبرة الوظيفي في المستقبل.'} </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12"
                    data-scroll-reveal="enter bottom move 50px over 0.6s after 0.2s">
                    <div className="pricing-item">
                        <div className="pricing-body">
                            <div className="price-wrapper">
                                <span className="period">{isEng ? 'Solutions' : 'حلول'}</span>
                            </div>
                            <ul className="list">
                                <li className="active">{isEng ? 'Link individual project with industry problems.' : 'ربط المشروع الفردي بالتحديات الصناعة'}</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12"
                    data-scroll-reveal="enter bottom move 50px over 0.6s after 0.2s">
                    <div className="pricing-item">
                        <div className="pricing-body">
                            <div className="price-wrapper">
                                <span className="period">{isEng ? 'Skills' : 'خبرة'}</span>
                            </div>
                            <ul className="list">
                                <li className="active">{isEng ? 'Improved employability and career prospects.' : 'تحسين فرص العمل وآفاق التطور الوظيفي'}</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12"
                    data-scroll-reveal="enter bottom move 50px over 0.6s after 0.2s">
                    <div className="pricing-item">
                        <div className="pricing-body">
                            <div className="price-wrapper">
                                <span className="period">{isEng ? 'Competition' : 'منافسة'}</span>
                            </div>
                            <ul className="list">
                                <li className="active">{isEng ? 'Stand out in the graduate marketplace when competing for jobs' : 'تميز في سوق الخريجين عند التنافس على الوظائف'}</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12"
                    data-scroll-reveal="enter bottom move 50px over 0.6s after 0.2s">
                    <div className="pricing-item">
                        <div className="pricing-body">
                            <div className="price-wrapper">
                                <span className="period">{isEng ? 'Decisions Maker' : 'صانع القرار'}</span>
                            </div>
                            <ul className="list">
                                <li className="active">{isEng ? 'Make better-informed decisions on a future career.' : 'اتخذ قرارات مستنيرة بشأن مستقبل مهني.'} </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12"
                    data-scroll-reveal="enter bottom move 50px over 0.6s after 0.2s">
                    <div className="pricing-item">
                        <div className="pricing-body">
                            <div className="price-wrapper">
                                <span className="period">{isEng ? 'Jobs' : 'وظائف'}</span>
                            </div>
                            <ul className="list">
                                <li className="active">{isEng ? 'It is possible that placement may lead to a permanent role after graduation.' : 'من الممكن أن يؤدي التدريب إلى عمل دائم بعد التخرج.'}</li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Section_3