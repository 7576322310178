import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { api } from '../../util/constant'
import { isArr } from '../../util/functions'
import '../components_style/timeline.css'
function Timeline({ isEng }) {

    const [timelineList, setTimelineList] = useState([])

    const getTimeline = async () => {
        axios.get(`${api}/setting/timeline`)
            .then((res) => { setTimelineList(isArr(res?.data?.content)); })
            .catch((e) => { console.log(e); })
    }

    useEffect(() => { getTimeline() }, [])

    const color = (type) => {
        if (type == 'purple') return 1
        if (type == 'blue') return 2
        if (type == 'green') return 3
        if (type == 'orange') return 4
    }
    return (
        timelineList.length == 0 ? <></> : <section className="section padding-bottom-100 colored" id="timeline">
            <div className="container">
                <div className="timeline">
                    <div className="center-heading">
                        <h2 className="section-title mb-5 ">Program TimeLine</h2>

                    </div>
                    {timelineList.map((item, index) =>
                        <div className={`timeline__event timeline__event--type${color(item.color)}`}>
                            <div className="timeline__event__icon ">
                                <i>{index + 1}</i>
                            </div>
                            <div className="timeline__event__date">
                                {item?.date}
                            </div>
                            <div className="timeline__event__content ">
                                <div className={`timeline__event__title ${isEng ? '' : 'arabic'}`}>
                                    {isEng ? item?.title : item?.title_ar}
                                </div>
                                <div className={`timeline__event__description ${isEng ? '' : 'arabic'}`}>
                                    <p>{isEng ? item?.text : item?.text_ar}</p>
                                </div>
                            </div>
                        </div>)}
                </div>
            </div>
        </section>
    )
}

export default Timeline