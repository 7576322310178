import axios from 'axios';
import { Notify } from 'notiflix';
import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { isArr } from '../../util/functions';
import { api } from '../../util/constant';
import { IoExpand } from "react-icons/io5";
import ProjectSubmissionModal from './projectSubmissionModal'

function ProjectSubmissionsListModal({ modal, modalToggle, selectedProject, setLoader, projectSubmission, setProjectSubmission }) {

    const [isProjectSubmissionModal, setIsProjectSubmissionModal] = useState(false);
    const [submissions, setSubmissions] = useState([]);

    const getProjectSubmissions = () => {
        setLoader(true)
        axios.get(`${api}/projects/submission/list/${selectedProject?._id}`, { withCredentials: true })
            .then(res => {
                if (res.status == 203) {
                    Notify.warning(res.data);
                } else {
                    const { submissions } = res.data;
                    setSubmissions(isArr(submissions));

                }
                setLoader(false)
            })
            .catch(e => { console.error({ e }); setLoader(false); })
    }

    useEffect(() => {
        if (modal) getProjectSubmissions()
        else setSubmissions([])
    }, [modal])



    return (
        <>
            <ProjectSubmissionModal
                projectSubmission={projectSubmission}
                setProjectSubmission={projectSubmission => setProjectSubmission(projectSubmission)}
                modal={isProjectSubmissionModal}
                modalToggle={modal => setIsProjectSubmissionModal(prev => !prev)}
                setLoader={loader => setLoader(loader)}
                selectedProject={selectedProject}
                refresh={() => getProjectSubmissions()}
            />
            <Modal size="lg" style={{ maxWidth: '700px', width: '100%' }} isOpen={modal} toggle={modalToggle}>
                <ModalHeader onClick={() => console.log()}>Submissions List ({selectedProject?.title})</ModalHeader>
                <ModalBody>
                    <table className="table" style={{ fontSize: '12px' }}>
                        <thead>
                            <td>#</td>
                            <td>Name</td>
                            <td>Status</td>
                            <td>Update</td>
                            <td>View</td>
                        </thead>
                        <tbody>
                            {submissions.map((item, index) => <tr key={index}>
                                <td>{index}</td>
                                <td>{item?.student?.fullname}</td>
                                <td>{item?.status}</td>
                                <td >{new Date(item?.updatedAt).toLocaleDateString()} {new Date(item?.updatedAt).toLocaleTimeString()}</td>
                                <td><button className='btn btn-light pt-0 p-1 shadow border' onClick={() => {
                                    setProjectSubmission({ _id: item?._id })
                                    setIsProjectSubmissionModal(true)
                                }}><IoExpand /></button></td>
                            </tr>)}
                        </tbody>
                    </table>
                </ModalBody>
                <ModalFooter>
                    <div className="w-100">
                        <Button color="warning float-end" onClick={modalToggle}>Cancel</Button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default ProjectSubmissionsListModal