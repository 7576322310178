import React, { useState, useEffect } from 'react'
import { isArr } from '../../../../util/functions'
import { IoCheckmarkCircleOutline, IoTimeOutline, IoPencil } from "react-icons/io5";
import LearningNeedModal from './learningNeedModal'
function LearningNeeds(props) {

    const [learningNeedModal, setLearningNeedModal] = useState(false)
    const [learningNeedSubmission, setLearningNeedSubmission] = useState({})
    const [learningNeedDetails, setLearningNeedDetails] = useState({})
    const [IsStudentAlloedToEditLearningNeed, setIsStudentAlloedToEditLearningNeed] = useState(false)
    const [isStudentSubmittedLearningNeed, setIsStudentSubmittedLearningNeed] = useState(false)
    const [isSupervisorSubmitted, setIsSupervisorSubmitted] = useState(false)
    const [isLearningNeedComplete, setIsLearningNeedComplete] = useState(false)
    const openLearningNeedModal = ({ isLearningNeedComplete, learningNeedsIndex, isAllowStudentUpdateComment, isStudentSubmitted, learningNeedSubmitied, isSupervisorSubmitted }) => {
        const learningNeedsDetails = props?.details?.learning_need[learningNeedsIndex];
        setLearningNeedDetails(learningNeedsDetails)
        setLearningNeedSubmission(learningNeedSubmitied)
        setIsStudentSubmittedLearningNeed(isStudentSubmitted)
        setIsStudentAlloedToEditLearningNeed(isAllowStudentUpdateComment)
        setIsSupervisorSubmitted(isSupervisorSubmitted)
        setIsLearningNeedComplete(isLearningNeedComplete)
        setLearningNeedModal(true)
    }

    useEffect(() => {
        if (!learningNeedModal) { setIsLearningNeedComplete(false); setLearningNeedSubmission({}); setLearningNeedDetails({}); setIsStudentAlloedToEditLearningNeed(false); setIsStudentSubmittedLearningNeed(false) }
    }, [learningNeedModal])

    const checkIfLearningNeedsSubmitted = course_id => {
        let learningNeedSubmitied = props.submission?.learning_need?.filter(item => item.learning_need_id == course_id)
        learningNeedSubmitied = isArr(learningNeedSubmitied).length != 0 ? learningNeedSubmitied[0] : {};
        const isSupervisorSubmitted = true
        const isStudentSubmitted = true
        const isLearningNeedComplete = true
        const isAllowStudentUpdateComment = false
        return { isLearningNeedComplete, isAllowStudentUpdateComment, isStudentSubmitted, isSupervisorSubmitted, learningNeedSubmitied }
    }

    return (<>
        <LearningNeedModal
            refresh={() => props.refresh()}
            loader={status => props.loader(status)}
            details={learningNeedDetails}
            submition={learningNeedSubmission}
            setModal={modal => setLearningNeedModal(modal)}
            modal={learningNeedModal}
            isStudentSubmittedLearningNeed={isStudentSubmittedLearningNeed}
            IsStudentAlloedToEditLearningNeed={IsStudentAlloedToEditLearningNeed}
            isSupervisorSubmitted={isSupervisorSubmitted}
            isLearningNeedComplete={isLearningNeedComplete}
            fdf_id={props.fdf_id}
            submission_id={props?.submission?._id}
            isFdfSubmissionCompleted={props?.submission?.is_complete == true}

        />

        <div className="col-sm-12 mt-3 fw-bold">Learning needs:</div>
        {isArr(props.submission?.learning_need).length == 0 ? <div className="ps-3 pe-3 mt-2"><div className="alert alert-success mb-0">there is no learning needs.</div></div>
            :
            isArr(props.submission?.learning_need).map((item, learningNeedsIndex) => {
                const { isLearningNeedComplete, isAllowStudentUpdateComment, isStudentSubmitted, isSupervisorSubmitted, learningNeedSubmitied } = checkIfLearningNeedsSubmitted(item.learning_need_id, learningNeedsIndex)
                return (<div className="col-md-3 p-2 btn " onClick={() => { openLearningNeedModal({ isLearningNeedComplete, learningNeedsIndex, isAllowStudentUpdateComment, isStudentSubmitted, learningNeedSubmitied, isSupervisorSubmitted }) }}>
                    <div className={`card-info smooth-box-shadow bg-white60 rounded p-3 ${isLearningNeedComplete ? 'bg-success' : ''}`}>
                        <p className="h6 w-100 text-start ">
                            <div className="p-1 text-center fw-bold mb-1">Course {learningNeedsIndex + 1} ({item?.title_when_completed})</div>
                            <div className='m-1'><p className={isStudentSubmitted ? `bg-${isAllowStudentUpdateComment ? 'warning' : 'success'} text-light p-1 rounded border` : ''}> {isStudentSubmitted ? isAllowStudentUpdateComment ? <IoPencil /> : <IoCheckmarkCircleOutline /> : <IoTimeOutline />} student</p></div>
                            <div className="m-1"><p className={`bg-${isSupervisorSubmitted ? 'success' : 'warning'} p-1 rounded border`}>{isSupervisorSubmitted ? <IoCheckmarkCircleOutline /> : <IoTimeOutline />} supervisor </p></div>
                        </p>
                    </div>
                </div>)
            })

        }
    </>
    )
}

export default LearningNeeds