import React, { useState, useEffect } from 'react'
import { isArr, identifyRole } from '../../../../util/functions'
import JobtaskModal from './jobtaskModal'
import { IoCheckmarkCircleOutline, IoTimeOutline, IoPencil } from "react-icons/io5";
import { Notify } from 'notiflix';
import axios from 'axios';
import { api } from '../../../../util/constant';

function CompetenciesForCompletedFdfSubmission(props) {
    const [modal, setModal] = useState(false)
    const [competancyDetails, setCompetancyDetails] = useState({})
    const [jobtaskInfo, setJobtaskInfo] = useState({})
    const [jobtaskSubmissionInfo, setJobtaskSubmissionInfo] = useState({})
    const openJobtaskModal = (jobtaskDetails, jobtaskSubmission) => { setJobtaskSubmissionInfo(jobtaskSubmission); setJobtaskInfo(jobtaskDetails); setModal(true) }
    useEffect(() => { if (!modal) setJobtaskInfo({}) }, [modal])

    const checkIsCompetencyComplete = (id) => {
        const isCompetencyComplete = isArr(props?.submission?.competencies)?.filter(item => item.competency_id == id && item.is_complete == true).length != 0 ? true : false
        return isCompetencyComplete
    }

    return (<>
        <JobtaskModal
            fdf_id={props?.fdf_id}
            loader={status => props.loader(status)}
            refresh={() => props.refresh()}
            setModal={status => setModal(status)}
            modal={modal}
            submission={jobtaskSubmissionInfo}
            info={jobtaskInfo}
            submission_id={props?.submission?._id}
            isFdfSubmissionCompleted={props?.submission?.is_complete == true}

        />
        <div className="col-sm-12 mt-3 fw-bold">Competencies:</div>
        {
            isArr(props.details).length == 0 ? <div className="ps-3 pe-3 mt-2"><div className="alert alert-success mb-0">there is no Competencies.</div></div> :
                isArr(props.details)?.map((item, index) => {
                    const isCompetencyComplete = item?.is_complete == true
                    return (
                        <div className={`col-md-${competancyDetails._id === item._id ? '12' : '3'}`} key={`competency-${index}`} >
                            <div className={`p-2  ${competancyDetails._id === item._id ? '' : 'w-100'} btn`}  >
                                <div className={`card-info smooth-box-shadow border rounded bg-white60 p-3 ${competancyDetails._id === item._id ? 'bg-warning text-light' : isCompetencyComplete ? 'bg-success' : 'bg-white60'}`} onClick={() => setCompetancyDetails(prev => prev?._id === item?._id ? {} : item)}>
                                    <p className="h6 text-start">
                                        <div className="p-1 text-center fw-bold mb-1 three-dots" >Competancy {index + 1} ({item?.title_when_completed})</div>
                                        <div className="m-1">{isCompetencyComplete ? <><IoCheckmarkCircleOutline /> Complete</> : <><IoTimeOutline /> In Progress</>} </div>
                                    </p>
                                </div>
                            </div>
                            {competancyDetails?._id === item?._id ? <CompetancyChildDetailsComponent submission_id={props?.submission?._id} loader={status => props.loader(status)} role={props.role} submission={props.details} details={competancyDetails} openModal={(jobtaskDetails, jobtaskSubmission) => openJobtaskModal(jobtaskDetails, jobtaskSubmission)} refresh={() => props.refresh()} /> : null}
                        </div>
                    )
                })
        }
    </>
    )
}


const CompetancyChildDetailsComponent = (props) => {

    const details = props.details

    return (<>
        <div className="col-ms-12 mt-2 card-info  smooth-box-shadow bg-white60">
            <div className="row p-3">
                <div className="col-lg-12 mb-1"><b>Title :  </b>{details?.title_when_completed}</div>
                <div className="col-lg-12 mb-1"><b>Description :  </b>{details?.description_when_completed}</div>
                <div className="col-lg-12 mb-1"><b>Level :  </b>{details?.level_when_completed}</div>
                <div className="col-lg-12 mb-1"><b>Job Tasks : </b></div>
                {details?.job_tasks?.map((item, index) => {
                    const isJobTaskSubmitted = true
                    const isStudentSubmitted = isJobTaskSubmitted
                    const isSupervisorSubmitted = item.comment_by_supervisor
                    const isAllowStudentUpdateComment = false
                    const jobTaskStatus = {
                        isJobTaskSubmitted,
                        isAllowStudentUpdateComment,
                        isStudentSubmitted,
                        isSupervisorSubmitted,
                        submitiedJobTask: {
                            comment_by_supervisor: item?.comment_by_supervisor,
                            comment_by_student: item?.comment_by_student,
                            attachments: item?.attachments
                        },
                        competencyId: details._id
                    }

                    return (
                        <div className="col-md-3 p-2 btn " key={`jobtask-${index}`}>
                            <div className={`card-info smooth-box-shadow rounded p-3 border ${isJobTaskSubmitted ? 'bg-success' : 'btn-light '}`} onClick={() => {
                                props.openModal({
                                    title: item?.title_when_completed,
                                    activities: item?.activities_when_completed,
                                    description: item?.description_when_completed,
                                    job_task_id: item?.job_task_id
                                }, jobTaskStatus);
                            }} >
                                <p className="h6 w-100 text-start">
                                    <div className="p-1 text-center fw-bold mb-1 three-dots">Task {index + 1} ({item?.title_when_completed})</div>
                                    <div className="m-1"><p className={isStudentSubmitted ? `bg-${isAllowStudentUpdateComment ? 'warning' : 'success'} text-light p-1 rounded border` : ''}>{isStudentSubmitted ? isAllowStudentUpdateComment ? <IoPencil /> : <IoCheckmarkCircleOutline /> : <IoTimeOutline />} student </p></div>
                                    <div className="m-1"><p className={`bg-${isSupervisorSubmitted ? 'success' : 'warning'} p-1 rounded border`}>{isSupervisorSubmitted ? <IoCheckmarkCircleOutline /> : <IoTimeOutline />} supervisor </p></div>
                                </p>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    </>)
}


export default CompetenciesForCompletedFdfSubmission