import React, { useState, useEffect } from 'react'
import MUIDataTable from "mui-datatables";
import axios from 'axios'
import { IoSearch, IoExpandOutline } from "react-icons/io5";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { api } from '../../util/constant';
import muiTable from '../../util/mui-table';
import { MuiThemeProvider } from "@material-ui/core/styles";
import { Notify } from 'notiflix/build/notiflix-notify-aio';
function Allocation(props) {
    const [years, setYears] = useState([])
    const [year, setYear] = useState('')
    const [allocationType, setAllocationType] = useState(0)
    const [allocationPassList, setAllocationPassList] = useState([])
    const [allocationFailList, setAllocationFailList] = useState([])
    const [modal, setModal] = useState(false)
    const [isShowAppsThatPass, setIsShowAppsThatPass] = useState(false)
    const [previewList, setPreviewList] = useState([])
    const [theme, setTheme] = useState(false)

    const loaderToggle = (status) => { props.loaderToggle(status) }


    const getYears = () => {
        axios.get(`${api}/years`, { withCredentials: true })
            .then((res) => { setYears(res.data); })
            .catch((e) => { console.log(e); })
    }

    const search = async (type) => {
        const pass = validate()
        if (pass) {
            loaderToggle(true)
            axios.get(`${api}/allocation`, { withCredentials: true, headers: { year, type: +allocationType } })
                .then((res) => {
                    const { applications = [], applicationsNoOffer = [] } = res?.data ? res?.data : []
                    const isApplicationsNotEmpty = applications == null || applications == undefined ? false : true
                    const isApplicationsNoOfferNotEmpty = applicationsNoOffer == null || applicationsNoOffer == undefined ? false : true
                    isApplicationsNotEmpty ? setAllocationPassList(applications) : setAllocationPassList([])
                    isApplicationsNoOfferNotEmpty ? setAllocationFailList(applicationsNoOffer) : setAllocationFailList([])
                    loaderToggle(false)
                })
                .catch((e) => { console.log(e); loaderToggle(false) })
        }
    }

    const handleYearChange = (year) => {
        setYear(year)
        if (year != '' && allocationType != 0) {
            //change table content
            axios.get(`${api}/allocation`, { withCredentials: true })
        }
    }

    const validate = () => {
        const status = year != '' && allocationType >= 1 && allocationType <= 2 ? true : false
        if (!status) Notify.warning('plaese select year and allocation type');
        return status
    }

    const allocation = () => {
        const pass = validate()
        if (pass) {
            loaderToggle(true)
            axios.post(`${api}/allocation`, { allocationType, year, isPreview: false }, { withCredentials: true })
                .then((res) => { Notify.success('allocation done with success'); loaderToggle(false) })
                .catch((e) => { Notify.failure(e.response.data); loaderToggle(false) })
        } else { Notify.failure('please select year and allocation type'); }
    }

    const preview = () => {
        loaderToggle(true)
        axios.post(`${api}/allocation`, { allocationType, year, isPreview: true }, { withCredentials: true })
            .then((res) => { setPreviewList(res?.data); console.log(res?.data); loaderToggle(false) })
            .catch((e) => { Notify.failure(e?.response?.data); loaderToggle(false); })
    }

    const handleModalView = () => {
        const pass = validate();
        if (pass) setModal(true);
    }



    useEffect(() => { getYears(); }, [])
    useEffect(() => { setPreviewList([]); }, [modal])

    //table attributes
    const options = muiTable.muiOptions(theme)
    const columns = [{ label: "Student Name", options: { filter: false } }, {
        label: "Discipline",
        options: {
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                return (
                    !isShowAppsThatPass ?
                        <span className='text-success' >{tableMeta.rowData[1]?.filter(option => option.discipline_status == true)[0]?.discipline_id?.name} </span>
                        :
                        <ul key={`${tableMeta.rowIndex + 1}-disciName`}>
                            {tableMeta?.rowData[1]?.map((item, index) => { return (<li key={`${tableMeta.rowIndex + 1}-${index}-li`}>{item.discipline_id.name}</li>) })}
                        </ul>
                )
            }
        }
    }, "Status"];

    return (
        <>
            <AllocationModal previewList={previewList} preview={() => { preview() }} allocation={() => { allocation() }} modal={modal} year={year} type={setAllocationType} modalToggle={modal => setModal(modal)} />
            <div className='row p-2 border-top'>
                <div className="col-md-3 p-2">
                    <select className='form-control' value={year} onChange={(e) => { handleYearChange(e.target.value) }}>
                        <option value="">select Year</option>
                        {years?.map((item, index) => <option key={`${index}-callYear`} value={item?._id}>{item?.year}</option>)}
                    </select>
                </div>
                <div className="col-md-3 p-2">
                    <select className='form-control' value={allocationType} onChange={(e) => { setAllocationType(e.target.value) }}>
                        <option value={0}>select Allocation</option>
                        <option value={1}>First</option>
                        <option value={2}>Second</option>
                    </select>
                </div>
                <div className="col-md-2 p-2 ">
                    <label className="switch">
                        <input type="checkbox" checked={isShowAppsThatPass} onChange={(e) => { setIsShowAppsThatPass(e.target.checked) }} />
                        <span className="slider"></span>
                    </label>
                    <span className={!isShowAppsThatPass ? 'text-success' : 'text-danger'}>{!isShowAppsThatPass ? <>  Allocated</> : <>  Not-Allocated</>}</span>
                </div>
                <div className="col-md-4 p-2">
                    <button className="btn btn-warning float-start" onClick={search}><IoSearch /> search</button>
                    <button className="btn btn-warning float-end  mr-2" onClick={handleModalView}>Allocation</button>
                </div>

            </div>
            <div className="p-4">
                <MuiThemeProvider theme={theme ? muiTable.getMuiTheme() : muiTable.noTheme()}>
                    <MUIDataTable
                        title={<>
                            <button className="btn btn-light shadow" disabled >Allocation</button>
                            <button onClick={() => { setTheme(!theme); }} className="btn btn-light shadow"><IoExpandOutline /></button>
                        </>}
                        columns={columns} options={options} data={!isShowAppsThatPass ? allocationPassList.map(item => [item?.user_id?.fullname, item?.options, item?.workflow_id?.name]) : allocationFailList.map(item => [item?.user_id?.fullname, item?.options, item?.workflow_id?.name])} />
                </MuiThemeProvider>
            </div>
        </>
    )
}

const AllocationModal = (props) => {
    const toggle = () => (props.modalToggle(!props.modal));
    const allocation = () => { toggle(); props.allocation(); }
    return (<>

        <Modal size="lg" style={{ maxWidth: '700px', width: '100%' }} isOpen={props.modal} toggle={() => { toggle(props) }}>

            <ModalHeader toggle={() => { toggle(props) }}>Allocation</ModalHeader>
            <ModalBody>
                <div className="row">
                    <div className="col-md-12 fw-bold">
                        are you sure ?
                    </div>
                    <div className="col-sm-12">
                        <div className="alert alert-warning"> <b>Note :</b> <br />
                            * allocation can't run more than one time for the same year and period . <br />
                            * you can't go back after allocation response with success.
                        </div>
                        <table className='table table-bordered'>
                            {props?.previewList?.map((item, index) => {
                                return (
                                    <tr className={item?.has_offer ? 'bg-info text-light' : ''}>
                                        <td className='p-2'>{index}</td>
                                        <td className='p-2'>{item?.fullname}</td>
                                        <td className='p-2'>{item?.options?.map(option => { return (<>{option?.discipline_status == true ? option?.discipline_id?.name : ''}</>) })}</td>
                                    </tr>
                                )
                            })}
                        </table>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <button className="btn btn-success float-start" onClick={allocation}>Allocate</button>
                        <button className="btn btn-info" onClick={() => { props.preview() }}>Preview</button>
                        <button className="btn btn-warning float-end" onClick={toggle}>Close</button>
                    </div>
                </div>
            </ModalFooter>
        </Modal>
    </>)
}

export default Allocation
