import React, { useState, useEffect, useContext } from 'react';
import MUIDataTable from "mui-datatables";
import Nav from '../components/nav';
import { IoEllipsisVerticalCircle, IoExpandOutline } from "react-icons/io5";
import Location from '../components/location';
import Loader from '../components/loader';
import axios from 'axios';
import { api } from '../util/constant'
import muiTable from '../util/mui-table'
import { MuiThemeProvider } from "@material-ui/core/styles";
import { identifyRole, isArr } from '../util/functions'
import RegistrationModal from '../components/registration/registrationModal'
import { TableFooter, TableCell, TableRow } from "@material-ui/core";
import { IoSearch } from "react-icons/io5";
import { Cxt } from '../App'

function RegUsers() {

    const { role } = useContext(Cxt);
    const [list, setList] = useState([])
    const [loader, setLoader] = useState(false)
    const [modal, setModal] = useState(false);
    const [modalInfo, setModalInfo] = useState([])
    const [regStatus, setRegStatus] = useState(1)

    const [theme, setTheme] = useState(false)
    const [isSearch, setIsSearch] = useState(false)
    const [tablePageNumber, setTablePageNumber] = useState(1)
    const [tableRowsLimit, setTableRowsLimit] = useState(10)
    const [tableTotalPages, setTotalPages] = useState(0)
    const [tableTotalRows, setTableTotalRows] = useState(0)
    const [tableSearch, setTableSearch] = useState('')
    const [tableSearchType, setTableSearchType] = useState('')

    const getRegList = async () => {
        const search = handleSearch()
        setLoader(true)
        axios.get(`${api}/reg`, { headers: { search: JSON.stringify(search), page: tablePageNumber, limit: tableRowsLimit, status: regStatus }, withCredentials: true })
            .then((res) => {
                const { users, totalPages, totalUsers } = res?.data
                setList(isArr(users));
                setTotalPages(totalPages);
                setTableTotalRows(totalUsers)
                setLoader(false)
            })
            .catch((e) => { setLoader(false) })
    }

    const handleSearch = () => {
        if (!isSearch) return {}
        else if (tableSearchType == 'name') return { 'fullname': { $regex: tableSearch, $options: "i" } }
        else if (tableSearchType == 'email') return { 'email': { $regex: tableSearch, $options: "i" } }
        else if (tableSearchType == 'phone') return { 'phone': { $regex: tableSearch, $options: "i" } }
        else return {}
    }

    const getSingle = (data) => {
        setLoader(true);
        axios.get(`${api}/reg`, { headers: { id: data.rowData[5] }, withCredentials: true })
            .then((res) => {
                const { fullname = '', email = '', createdAt = '', orgnization_id = '', gender = '', qualification = '', role = '', department = '', _id } = res?.data?.list;
                const date = new Date(createdAt).toLocaleDateString() + '  ' + new Date(createdAt).toLocaleTimeString();
                setModalInfo({ date, fullname, email, gender, orgnization_id, qualification, role, department, _id, otherRolesForThisUser: isArr(res?.data?.otherRolesForThisUser), functions: isArr(res?.data?.functions) });
                setModal(true);
                setLoader(false);
            })
            .catch((e) => { console.log(e); setLoader(false); })


    }

    useEffect(() => { getRegList(); }, [])

    const columns = [{ label: "FullName", options: { filter: false } }, { label: "email", options: { filter: false } }, "Orgnization", "degree", 'role', {
        label: "",
        options: {
            filter: false, customBodyRender: (value, tableMeta, updateValue) => {
                return (<span className="btn" style={{ fontSize: "20px" }} onClick={() => getSingle(tableMeta)}><IoEllipsisVerticalCircle /></span>)
            }
        }
    }];



    let options = muiTable.muiOptions(theme)
    options = {
        ...options,
        rowsPerPage: 100,
        search: false,
        serverSide: true,
        customFooter: () => {
            return (
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={6}>
                            page {tablePageNumber} of {tableTotalPages} - total users {tableTotalRows}
                            <button className="btn btn-light" disabled={tablePageNumber == 1 ? true : false} onClick={() => setTablePageNumber(prev => prev - 1)}>{`<`}</button>
                            <button className="btn btn-light" disabled={tablePageNumber == tableTotalPages || tableTotalPages == 0 ? true : false} onClick={() => setTablePageNumber(prev => prev + 1)}>{`>`}</button>
                            jump to <select onChange={e => setTablePageNumber(+e.target.value)}>{[...Array(tableTotalPages)].map((item, i) => <option value={i + 1} key={i}>{i + 1}</option>)}</select>
                            per page <select onChange={e => setTableRowsLimit(+e.target.value)}>{[...Array(10)].map((item, i) => <option value={(i + 1) * 10} key={i}>{(i + 1) * 10}</option>)}</select>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            );
        }

    }

    useEffect(() => { getRegList() }, [tablePageNumber, tableRowsLimit, isSearch, regStatus])
    useEffect(() => { setTablePageNumber(1) }, [tableRowsLimit])

    const { isAdminOrEidaad } = identifyRole(role)
    return (
        <>
            <Nav />
            {loader ? <Loader /> : null}
            <Location />
            <RegistrationModal regStatus={regStatus} modal={modal} modalInfo={modalInfo} refreshTable={() => { getRegList() }} changeLoader={loader => setLoader(loader)} changePerentToggle={modal => setModal(modal)} />
            <div className="p-3 ">
                <button className={`btn me-2 border btn-${regStatus == 1 ? 'success' : 'light'}`} onClick={() => setRegStatus(1)}>Pending</button>
                <button className={`btn me-2 border btn-${regStatus == 2 ? 'success' : 'light'}`} onClick={() => setRegStatus(2)}>Accepted</button>
                <button className={`btn me-2 border btn-${regStatus == 3 ? 'success' : 'light'}`} onClick={() => setRegStatus(3)}>Rejected</button>
                {isAdminOrEidaad ?
                    <>
                        <button className={`btn me-2 border btn-${regStatus == 4 ? 'success' : 'light'}`} onClick={() => setRegStatus(4)}>Students not Verified</button>
                        <button className={`btn me-2 border btn-${regStatus == 5 ? 'success' : 'light'}`} onClick={() => setRegStatus(5)}>Verified Students</button>
                    </>
                    : null}
            </div>
            <div className="p-3">
                {!isSearch ? <button className="btn btn-warning mb-1" onClick={() => setIsSearch(prev => !prev)}>search <IoSearch /></button>
                    :
                    <div className="row">
                        <div className="col-sm-6"><input className='form-control' placeholder='search' onChange={e => setTableSearch(e.target.value)} /></div>
                        <div className="col-sm-3 "><select value={tableSearchType} className='w-100' onChange={e => setTableSearchType(e.target.value)}><option value="">{`<select>`}</option><option value="name">name</option><option value="email">email</option><option value="phone">phone</option></select></div>
                        <div className="col-sm-1"><button className="btn btn-warning mb-1" onClick={() => { getRegList(); setTablePageNumber(1) }}><IoSearch /></button></div>
                        <div className="col-sm-1"><button className="btn btn-danger mb-1" onClick={() => setIsSearch(prev => !prev)}>X</button></div>
                    </div>
                }
                <MuiThemeProvider theme={theme ? muiTable.getMuiTheme() : muiTable.noTheme()}>
                    <MUIDataTable
                        title={<>
                            <button className="btn btn-light shadow" disabled >Pending Registration</button>
                            <button onClick={() => { setTheme(!theme); }} className="btn btn-light shadow"><IoExpandOutline /></button>
                        </>}
                        data={list ? list.map(item => { return [item?.fullname, item?.email, item?.orgnization_id?.name, item?.qualification, item?.role, item?._id] }) : []}
                        columns={columns}
                        options={options}
                    />
                </MuiThemeProvider>
            </div>

        </>
    );
}





export default RegUsers
