import React, { useState, useEffect } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { isEmpty } from 'validator';
import axios from 'axios'
import { api } from '../../../util/constant'
function NewNewsModal(props) {

    const [title, setTitle] = useState('')
    const [body, setBody] = useState('')
    const [Warning, setWarning] = useState(false)

    const toggle = () => (props.modalToggle(!props.modal));
    const loaderToggle = (status) => { props.loaderToggle(status) }
    const refresh = () => { props.refresh() }
    const saveNews = () => {
        const IsEmpty = isEmpty(title) || isEmpty(body) ? true : false;
        //if all fields not empty => save
        if (!IsEmpty) {
            loaderToggle(true);
            axios.post(`${api}/news`, { title, body }, { withCredentials: true })
                .then((res) => { loaderToggle(false); toggle(false); refresh() })
                .catch((e) => { console.log(e); loaderToggle(false); })
        }
    }

    useEffect(() => { setTitle(''); setBody(''); setWarning(false); }, [props.modal])

    return (
        <Modal size="lg" style={{ maxWidth: '700px', width: '100%' }} isOpen={props.modal} toggle={() => { toggle(props) }}>
            <ModalHeader toggle={() => { toggle(props) }}>News</ModalHeader>
            <ModalBody>
                <div className="row">
                    <div className="col-md-12"><input onChange={(e) => { setTitle(e.target.value); }} value={title} type="text" placeholder="title" className="form-control" /></div>
                    <div className="col-md-12"><textarea onChange={(e) => { setBody(e.target.value) }} value={body} className="form-control" placeholder="body" rows="10"></textarea></div>
                    {Warning ? <div className="col-md-12">
                        <div className="alert alert-warning">please fill all fields.</div>
                    </div> : null}
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="row">
                    <div className="col-md-12 text-center"><button className="btn btn-success" onClick={() => { saveNews() }}>Save</button></div>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default NewNewsModal
