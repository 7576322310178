import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { IoLockClosedOutline, IoLockOpenOutline } from "react-icons/io5";
import axios from 'axios';
import { api } from '../../util/constant'
import { isArr } from '../../util/functions';

function CloseOpenRegModal(props) {

    const toggle = () => (props.changePerentToggle(!props.modal));
    const loaderToggle = (loader) => (props.changeLoader(loader));
    const [registrationSettings, setRegistrationSettings] = useState([])
    const getRegSetting = () => {
        loaderToggle(true)
        axios.get(`${api}/setting/reg`, { withCredentials: true })
            .then((res) => {
                setRegistrationSettings(isArr(res?.data?.registration)); loaderToggle(false)
            })
            .catch((e) => { console.log(e); loaderToggle(false) })
    }

    const handleValueChange = (value, type, index) => {
        let arr = [...registrationSettings]
        arr[index][type] = value
        setRegistrationSettings(arr)
    }

    const Save = () => {
        loaderToggle(true)
        axios.post(`${api}/setting/reg`, { registration: registrationSettings }, { withCredentials: true })
            .then(() => { loaderToggle(false); toggle() })
            .catch((e) => { loaderToggle(false); console.log(e); })
    }

    useEffect(() => { if (props.modal) getRegSetting() }, [props.modal])

    return (
        <Modal size="lg" style={{ maxWidth: '700px', width: '100%' }} isOpen={props.modal} toggle={toggle}>
            <ModalHeader>Registration</ModalHeader>
            <ModalBody>
                {isArr(registrationSettings).map((item, index) =>
                    <div className='row'>
                        <div className="col-md-2">{item?.role_name}</div>
                        <div className="col-md-6"><input type="text" className="form-control" placeholder='Messege' value={item?.text} onChange={e => handleValueChange(e.target.value, 'text', index)} /></div>
                        <div className="col-md-2 text-center"> <input type="checkbox" checked={item?.is_close} onClick={e => handleValueChange(e.target.checked, 'is_close', index)} id="" /> {item?.is_close ? 'Closed' : 'Open'} </div>
                    </div>
                )}
            </ModalBody>
            <ModalFooter>
                <div className="row">
                    <div className="col-sm-4 mb-2"><Button color="success" onClick={Save}>Save</Button></div>
                    <div className="col-sm-4 mb-2"></div>
                    <div className="col-sm-4 mb-2"><Button color="warning" onClick={toggle}>Cancel</Button></div>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default CloseOpenRegModal