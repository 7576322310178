import React, { useState, useEffect } from 'react'
import FdfSubmission from '../components/fdfSubmission/fdfSubmission'
import { api } from '../../../util/constant'
import axios from 'axios'
import { isArr } from '../../../util/functions'

function Afp(props) {
    const [isSubmissionPage, setIsSubmissionPage] = useState(false)
    const [selectedSubmission, setSelectedSubmission] = useState({})
    const [submissions, setSubmissions] = useState([])
    //for submission component
    const [fdfSubmission, setFdfSubmission] = useState({})
    const [fdfDetails, setFdfDetails] = useState({})

    const getListOfFdfSubmissions = () => {
        props.loader(true)
        axios.get(`${api}/fdf`, { withCredentials: true })
            .then((res) => {
                const { fdfsubs } = res?.data;
                setSubmissions(isArr(fdfsubs))
                props.loader(false);
            })
            .catch((e) => { console.log(e); props.loader(false) })
    }

    const getSubmission = () => {
        props.loader(true)
        axios.get(`${api}/fdf/sub`, { headers: { studentId: selectedSubmission?.student, fdf: selectedSubmission?.fdf }, withCredentials: true })
            .then((res) => {
                const { fdfDetails = {}, fdfSubmission = {} } = res?.data;
                setFdfDetails(fdfDetails);
                setFdfSubmission(fdfSubmission);
                props.loader(false);
            })
            .catch((e) => { console.log(e); props.loader(false) })
    }

    useEffect(() => {
        const isThereSubmission = selectedSubmission?.student ? true : false
        setIsSubmissionPage(isThereSubmission);
        if (isThereSubmission) getSubmission()
    }, [selectedSubmission])

    useEffect(() => { getListOfFdfSubmissions() }, [])

    return (
        <>
            {isSubmissionPage ?
                <FdfSubmission
                    isBack={true}
                    details={fdfDetails}
                    submission={fdfSubmission}
                    setSelectedSubmission={submission => setSelectedSubmission(submission)}
                    refresh={() => getSubmission()}
                    loader={status => { props.loader(status) }}
                    selectedFdf={{ name: 'Submissions' }}
                />
                :
                <StudentsList submissions={submissions} setSelectedSubmission={obj => setSelectedSubmission(obj)} />
            }
        </>
    )
}

const StudentsList = (props) => {

    return (<>
        <p className="h6 text-center p-2 shadow bg-white60 rounded mb-4"><span className='fw-bold'></span>Students Submissions<span className='fw-bold'></span></p>
        {props.submissions.length == 0 ? <div className="text-center pt-4"><span className="alert alert-warning">Sorry, there is no submissions.</span></div> :
            <>
                {props.submissions.map((item, index) => <div className="col-md-12 mb-4" key={index} onClick={() => props.setSelectedSubmission({ student: item?.student?._id, fdf: item?.fdf?._id })}>
                    <div className="shadow pointer bg-white60 border p-2 h6 position-relative">
                        {index + 1}.
                        <b>{item?.student?.fullname}</b>{` `}
                        ({item?.fdf?.title}){` `}
                        <p className="position-absolute start-0 bg-light" style={{ top: '-15px' }}>{new Date(item?.updatedAt).toLocaleString()}</p>
                    </div>
                </div>)}
            </>
        }
    </>)
}

export default Afp