import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { isArr } from '../../../../util/functions'
import { api } from '../../../../util/constant'
import Students from './students'
function Functions(props) {

    const [functions, setFunctions] = useState([])
    const [page, setPage] = useState(0)
    const loader = status => props.loader(status)
    const options = props.isBack ? { headers: { orgnizationId: props?.selectedOrg?.id }, withCredentials: true } : { withCredentials: true }

    const getFunctions = () => {
        loader(true);
        axios.get(`${api}/org/function`, options)
            .then((response) => { loader(false); setFunctions(isArr(response.data)) })
            .catch((e) => { console.log(e); loader(false); })
    }

    useEffect(() => { getFunctions(); }, [])

    return (
        <>
            <div className='p-2'>
                {props.isBack ?
                    <button className="btn btn-light border" style={{ fontSize: '10px' }} onClick={() => { props?.setSelectedOrg({ name: '', id: '' }) }}>&larr; back |
                        <span className="text-success"> Orgnizations</span>
                    </button> : null}
            </div>
            <div className="col-md-12 mb-2 p-0 shadow">
                <button className={`sub-nav w50 left ${page == 0 ? 'active' : ''}`} onClick={() => { setPage(0) }}>Functions</button>
                <button className={`sub-nav w50 mid ${page == 1 ? 'active' : ''}`} onClick={() => { setPage(1) }}>Students</button>
            </div>
            {page == 0 ? <>
                <p className="h4 text-center p-2 mt-5"><span className='shadow bg-white60 p-2 rounded'>Functions</span></p>
                {!functions.length ?
                    <div className="div-center-container" ><div className="p-3 h3 div-center alert alert-warning text-center">there is no Functions for this orgnization</div></div>
                    : <>
                        <div className="fdf-main row p-4">
                            {functions.map((item, index) => {
                                return (
                                    <div className="col-md-12 mb-3" key={index} onClick={() => { props.setSelectedFunction({ id: item?._id, name: item?.name }) }}>
                                        <div className="shadow pointer bg-white60 border p-3 position-relative">
                                            <span className="h6 fw-bold">
                                                {index + 1}. ({item?.name})</span> {item?.description != '' ? <span> {item?.description})</span> : null}
                                            <div className='position-absolute' style={{ right: '-15px', top: '-15px' }}><img style={{ width: '35px' }} src={`/icons/${item.icon}.png`} /></div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </>}
            </> : null}
            {page == 1 ? <Students loader={status => loader(status)} orgnizationId={props?.selectedOrg?.id} isAllowAddStudents={props?.isAllowAddStudents} /> : null}
        </>
    )
}

export default Functions