import React, { useState, useEffect, useContext } from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { login } from '../util/login';
import '../style/login.css';
import { Cxt } from '../App';
import Cookies from 'js-cookie';
import Resetpass from '../components/login/resetpass';
import Loader from '../components/loader';
import axios from 'axios';
import { validMail } from '../util/validateMail';
import { api, path } from '../util/constant';
import { isArr } from '../util/functions';
import UserSelectLoginRoleModal from '../components/login/userSelectLoginRoleModal';
import { Notify } from 'notiflix';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [worning, setWarning] = useState(false);
    const [loader, setLoader] = useState(false);
    const [forgetpass, setForgetPass] = useState(false);
    const [messege, setMessege] = useState('')
    const history = useHistory();
    const { setRole, setCurrentPage } = useContext(Cxt);
    const [isUserSelectLoginRoleModal, setIsUserSelectLoginRoleModal] = useState(false)
    const [userRoles, setUserRoles] = useState([])

    const isAuth = async (user_id) => {
        setWarning(false)
        setLoader(true)
        const { status, code, roles } = await login(username, password, user_id);
        if (status == true && code == 200) {
            setRole(Cookies.get('role'));
            history.push(`${path}/`);
        } else if (status == true && code == 207) {
            setUserRoles(isArr(roles))
            setIsUserSelectLoginRoleModal(true)
        } else {
            if (code == 403) setMessege('Your Account is Locked, please contact Eidaad team for more information.');
            else setMessege('Wrong username or password');
            setWarning(true);
        }
        setLoader(false)
    }

    //if there is token open <resetpassword/> component and hide login
    const search = useLocation().search;
    const token = new URLSearchParams(search).get("token");
    const verify = new URLSearchParams(search).get("verify");

    //change currentpage state on App.js
    const location = useLocation().pathname
    useEffect(() => { setCurrentPage(location); }, [useLocation().pathname])
    return (
        <>
            <Link to='/'><button className="btn btn-light top-0 start-0 mt-4 ms-4 position-absolute shadow border" style={{ zIndex: '100' }}>&larr; WEBSITE</button></Link>
            <UserSelectLoginRoleModal modal={isUserSelectLoginRoleModal} login={user_id => isAuth(user_id)} roles={userRoles} setModal={modal => setIsUserSelectLoginRoleModal(modal)} />
            {loader ? <Loader /> : null}
            {verify ? <Verify verify={verify} loader={loader} loaderToggle={loader => setLoader(loader)} /> :
                <>{token ? (<Resetpass token={token} />) :
                    (forgetpass ? <ForgetPassword forgetpassToggle={(status) => { setForgetPass(status) }} loader={loader} loaderToggle={loader => setLoader(loader)} /> :
                        <form className="login-main" onSubmit={e => { e.preventDefault() }}>
                            <div className='center-div'>
                                <img src="/images/logo.png" className='logo' />
                                <p className="title">Login</p>
                                <input type="text" placeholder="username (email)" onChange={(e) => { setUsername(e.target.value.trim()) }} className="form-control mt-3" />
                                <input type="password" placeholder="password" onChange={(e) => { setPassword(e.target.value) }} className="form-control mt-2" />
                                <button type='button' className="p-1 mt-1 btn btn-link" onClick={() => { setForgetPass(true) }}>Forget Password ?</button>
                                <div className="text-center">
                                    <button type="submit" className="btn btn-light border" onClick={() => isAuth()}>Login</button>
                                </div>
                                {worning ? <div className="alert alert-danger" role="alert">{messege}</div> : null}
                                <hr />
                                <Link to={`${path}/reg`}><button type="button" className="btn btn-light border w-100">Registration</button></Link>
                            </div>
                        </form >
                    )
                }
                </>
            }

        </>
    )
}



const ForgetPassword = (props) => {
    const [email, setEmail] = useState('')
    const [warning, setWarning] = useState(false)
    const [messege, setMessege] = useState('')
    const [success, setSuccess] = useState(false)
    const LoaderToggle = (status) => (props.loaderToggle(status))
    const returnToLogin = () => { props.forgetpassToggle(false) }

    const submit = async () => {
        LoaderToggle(true);
        setWarning(false);
        //validate
        if (validMail(email)) { setWarning(false); } else { LoaderToggle(false); setMessege(['alert alert-warning', 'Email is not valid !']); setWarning(true); return }
        //send to app
        await axios.put(`${api}/login`, { email: email })
            .then((res) => { setWarning(true); setSuccess(true); setMessege(['alert alert-success', 'reset password has been sent to your email !']) })
            .catch((e) => { setWarning(true); setMessege(['alert alert-warning', e?.response?.data]) })
        LoaderToggle(false);
    }

    return (<>
        <div className="login-main">
            <div className='center-div p-4 text-center' style={{ maxWidth: '300px' }}>
                {success ? null : <>
                    <div className='position-relative p-4'>
                        <button className="btn btn-light position-absolute top-0 start-0" onClick={returnToLogin}>{`< Back`}</button>
                    </div>
                    <p className='mb-3'>Enter your email</p>
                    <input type="email" onChange={(e) => { setEmail(e.target.value) }} placeholder="email" className="form-control mb-3" />
                    <button className='btn btn-light' onClick={submit}>Reset</button><br />
                </>}
                {warning ? <div className={`${messege[0]} h6`} role="alert">{messege[1]}</div> : null}
            </div>
        </div>
    </>)
}

const Verify = (props) => {
    const history = useHistory();
    const sendVierifyToApp = () => {
        LoaderToggle(true)
        axios.patch(`${api}/reg`, { token: props.verify })
            .then((res) => {
                history.push(`${path}/login?token=${props.verify}`)
                LoaderToggle(false);
            })
            .catch((e) => {
                if (e.response.status == 403) Notify.info(e.response.data);
                console.log(e.data); LoaderToggle(false)
            })
    }

    const LoaderToggle = (status) => (props.loaderToggle(status))
    return (
        <>
            <div className="login-main ">
                <div className='center-div text-center h-10 pt-3'>
                    <>Verify your Email <br />
                        <button className="btn btn-light mt-3 mb-3" onClick={sendVierifyToApp}>Verify</button></>
                </div>
            </div>
        </>
    )
}

export default Login
