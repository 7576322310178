import React from 'react'
import Card from './card'

function Fdf({ numberOfActivities, numberOfCompetencies, numberOfFdfs, numberOfJobtasks }) {
    return (
        <>
            <div className="row m-2 p-2 shadow bg-white60">
                <h1 className='text-center mb-3'>Frame Works</h1>
                <div className="p-2 col-md-4"><Card title={'Activities'} number={numberOfActivities} /></div>
                <div className="p-2 col-md-4"><Card title={'Competencies'} number={numberOfCompetencies} /></div>
                <div className="p-2 col-md-4"><Card title={'Fdfs'} number={numberOfFdfs} /></div>
                <div className="p-2 col-md-4"><Card title={'Job Tasks'} number={numberOfJobtasks} /></div>
            </div>
        </>
    )
}

export default Fdf