import React, { useState } from 'react'
import Nav from '../components/nav'
import YearModal from '../components/setting/yearModal'
import Location from '../components/location'
import Loader from '../components/loader'
import TimelineModal from '../components/setting/timelineModal'
import DashboardMessegeModal from '../components/setting/dashboardMessegeModal'
import CloseOpenRegModal from '../components/setting/closeOpenRegModal'
import MailLogsModal from '../components/setting/mailLogsModal'
import EndYearModal from '../components/setting/endYearModal'

function Setting() {
    const [yearModal, setYearModal] = useState(false)
    const [timeLineModal, setTimelineModal] = useState(false)
    const [loader, setLoader] = useState(false)
    const [dashboardMessegeModal, setDashboardMessegeModal] = useState(false)
    const [closeOpenRegModalModal, setCloseOpenRegModal] = useState(false)
    const [isMailLogsModal, setIsMailLogsModal] = useState(false)
    const [isEndYearModal, setIsEndYearModal] = useState(false)
    return (
        <>
            <Nav />
            <Location />
            {loader ? <Loader /> : null}
            <YearModal changeLoader={loader => setLoader(loader)} modal={yearModal} changePerentToggle={modal => setYearModal(modal)} />
            <TimelineModal changeLoader={loader => setLoader(loader)} modal={timeLineModal} changePerentToggle={modal => setTimelineModal(modal)} />
            <DashboardMessegeModal changeLoader={loader => setLoader(loader)} modal={dashboardMessegeModal} changePerentToggle={modal => setDashboardMessegeModal(modal)} />
            <CloseOpenRegModal changeLoader={loader => setLoader(loader)} modal={closeOpenRegModalModal} changePerentToggle={modal => setCloseOpenRegModal(modal)} />
            <MailLogsModal changeLoader={loader => setLoader(loader)} modal={isMailLogsModal} changePerentToggle={modal => setIsMailLogsModal(modal)} />
            <EndYearModal changeLoader={loader => setLoader(loader)} modal={isEndYearModal} changePerentToggle={modal => setIsEndYearModal(modal)} />
            <div className="p-4">
                <div className="row m-5">
                    <div className="col-12 h1 text-center">
                        <span className="bg-white60 p-2 rounded">Platform</span>
                    </div>
                    <Card
                        modal={dashboardMessegeModal}
                        setModal={modal => setDashboardMessegeModal(modal)}
                        title='Dashboard Messege'
                        iconUrl='/images/browser.svg' />

                    <Card
                        modal={yearModal}
                        setModal={modal => setYearModal(modal)}
                        title='close/open Allocation'
                        iconUrl='/images/close-open-calendar.svg' />

                    <Card
                        modal={false}
                        setModal={modal => setCloseOpenRegModal(modal)}
                        title='close/open Registration'
                        iconUrl='/images/close-open-reg.svg' />

                    <Card
                        modal={isMailLogsModal}
                        setModal={modal => setIsMailLogsModal(modal)}
                        title='Emails Logs'
                        iconUrl='/images/email.svg' />

                    <Card
                        modal={isEndYearModal}
                        setModal={modal => setIsEndYearModal(modal)}
                        title='End Year'
                        iconUrl='/images/notes.svg' />

                    <div className="col-12 h1 text-center">
                        <span className="bg-white60 p-2 rounded"> <hr />Website</span>
                    </div>
                    <Card
                        modal={timeLineModal}
                        setModal={modal => setTimelineModal(modal)}
                        title='TimeLine'
                        iconUrl='/images/discipline.svg' />




                </div>
            </div>
        </>
    )
}

const Card = ({ modal, setModal, iconUrl, title }) => {
    return (
        <div className="col-md-4 setting-container">
            <div className='setting-card shadow' onClick={() => { setModal(!modal) }}>
                <div className="position-relative h-100 w-100">
                    <img src={iconUrl} className='setting-icon' alt="" />
                    <div className="setting-title">{title}</div>
                </div>
            </div>
        </div>
    )
}

export default Setting