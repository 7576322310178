import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Cxt } from '../../App';
import { isNumeric } from 'validator';
import { api } from '../../util/constant';
import { IoCloudDownloadOutline } from 'react-icons/io5';
import Cookies from 'js-cookie';
import { FileDownload, isArr } from '../../util/functions'


function ViewAppModal(props) {

    //states
    const [info, setInfo] = useState({})
    const [Options, setOptions] = useState([])
    const [next, setNext] = useState([])
    const [Comment, setComment] = useState('')
    const [isHistory, setIsHistory] = useState(false)
    const [warning, setWarning] = useState(false)
    const [Messege, setMessege] = useState('')
    const { role } = useContext(Cxt);

    //toggles
    const toggle = () => (props.modalToggle(!props.modal));
    const loader = (status) => { props.loaderToggle(status) }
    const refresh = () => { props.refreshApp() }

    useEffect(() => {
        const { allocation_call = '', updatedAt = '', options = [], user_id = {}, workflow_id = {}, points = '-', assessment_score = '', comment = '', _id } = props.info.app
        const { fullname = '', department = '', gender = '', gpa = '', lastLogin = '', nationality = '', tr_url = '', cv_url = '' } = user_id
        const { name: workflow_name = '', next_workflow = [], step = 0, role: user_role = 0, _id: current_workflow = '' } = workflow_id
        let last_update = '';
        if (updatedAt != '') { last_update = new Date(updatedAt).toLocaleString(); }
        setOptions(options)
        setNext(next_workflow)
        setInfo({
            allocation_call, last_update, fullname, department, gender, gpa, lastLogin, nationality, workflow_name, current_workflow, step,
            user_role, points, assessment_score, comment, _id, tr_url, cv_url
        })


    }, [props.info])

    useEffect(() => { setComment(''); setIsHistory(false) }, [props.modal])

    const updateApplication = async (app_id, next) => {
        const pass = validate()
        if (pass) {
            loader(true)
            let data = { app_id, next, comment: Comment }
            if (info.step == 7) { data = { ...data, assessment_score: info.assessment_score } }
            await axios.put(`${api}/app`, data, { withCredentials: true })
                .then((res) => { setWarning(false); refresh(); toggle(); loader(false); })
                .catch((e) => { loader(false); })
        }
    }

    //validation
    const validate = () => {
        //if we are in workflow 7 must enter score
        if (info.step == 7) {
            const isNumber = isNumeric(info.assessment_score)
            if (isNumber && +info.assessment_score <= 100) { setWarning(false); return true }
            else {
                setWarning(true);
                setMessege('score accept numbers only and it must be less than 100 .');
                return false
            }
        } else {
            return true
        }
    }

    //handle score change
    const handleScoreChange = (e) => {
        let arr = info
        arr.assessment_score = e.target.value
        setInfo(arr);

    }

    const disciplineColor = (item) => {
        const { discipline_status } = item
        if (discipline_status == undefined) return ''
        else if (discipline_status == false) return 'text-danger'
        else if (discipline_status == true) return 'text-success'
    }
    return (
        <div>
            <div>
                <Modal size="lg" style={{ maxWidth: '700px', width: '100%' }} isOpen={props.modal} toggle={() => { toggle(props) }}>
                    <ModalHeader toggle={() => { toggle(props) }}>Application</ModalHeader>
                    <ModalBody>
                        {/* <button className={`btn btn-${isHistory ? 'warning' : 'success'}`} onClick={() => setIsHistory(prev => !prev)}>History</button> */}
                        <div className="row">
                            <div className="col-md-12 mb-2 p-0 shadow">
                                <button className={`sub-nav w50 left ${!isHistory ? 'active' : ''}`} onClick={() => { setIsHistory(false); }}>Application</button>
                                <button className={`sub-nav w50 mid ${isHistory ? 'active' : ''}`} onClick={() => { setIsHistory(true); setIsHistory(true) }}>History</button>
                            </div>
                        </div>
                        {isHistory ?
                            <History
                                loader={(status) => props.loaderToggle(status)}
                                app_id={info._id}
                                isHistory={isHistory}
                            />
                            :
                            <div className="row">
                                <div className="col-md-12 h4 text-center app-title"> <span>{info.workflow_name}</span></div>
                                <table className="table table-borderless">
                                    <tbody>
                                        <tr>
                                            <td className='fw-bold'>Last Update</td>
                                            <td>{info.last_update}</td>
                                        </tr>
                                        <tr>
                                            <td className='fw-bold'>Name</td>
                                            <td>{info.fullname}</td>
                                        </tr>
                                        <tr>
                                            <td className='fw-bold'>Department</td>
                                            <td>{info.department}</td>
                                        </tr>
                                        <tr>
                                            <td className='fw-bold'>Gender</td>
                                            <td>{info.gender}</td>
                                        </tr>
                                        <tr>
                                            <td className='fw-bold'>Nationality</td>
                                            <td>{info.nationality}</td>
                                        </tr>
                                        <tr>
                                            <td className='fw-bold'>Allocation Call</td>
                                            <td>{info.allocation_call?.year}</td>
                                        </tr>
                                        <tr>
                                            <td className='fw-bold'>Points</td>
                                            <td>{info.points == '-' ? 'NOT SET' : info?.points}</td>
                                        </tr>
                                        <tr>
                                            <td className='fw-bold'>Assessment Score</td>
                                            <td>{info.user_role == role && info.step == 7 ? <input placeholder="Assessment Score (out of 100)" type="text" onChange={(e) => { handleScoreChange(e) }} className="form-control" /> : <> {info.assessment_score == '' ? 'NOT SET' : info.assessment_score} </>}</td>
                                        </tr>
                                        <tr>
                                            <td className='fw-bold'>Last Comment</td>
                                            <td>{info.comment == '' ? 'EMPTY' : info.comment}</td>
                                        </tr>
                                        <tr>
                                            <td>{info?.cv_url ? <button className='btn btn-light shadow' onClick={() => { FileDownload(`${api}/download/?token=${Cookies.get('token')}&file_name=${info?.cv_url}&type=cv`) /*download('cv', info?.cv_url)*/ }}><IoCloudDownloadOutline /> CV</button> : null}</td>
                                            <td>{info?.tr_url ? <button className='btn btn-light shadow' onClick={() => { FileDownload(`${api}/download/?token=${Cookies.get('token')}&file_name=${info?.tr_url}&type=transcript`) /*download('transcript', info?.tr_url)*/ }}><IoCloudDownloadOutline /> Transcript</button> : null}</td>
                                        </tr>
                                        <tr>
                                            <td className='fw-bold'>Disciplines</td>
                                            <td><ul>{Options.length > 0 ? Options.map((item, i) => { return <li key={`disci${i}`} className={disciplineColor(item)}>{`(${item?.discipline_seq})${item?.discipline_id?.name}`}</li> }) : null}</ul></td>
                                        </tr>
                                    </tbody>
                                </table>
                                {warning ? <div className="col-md-12"><div className="alert alert-warning">{Messege}</div></div> : null}
                                {info?.user_role == role ? <div className="col-md-12"><textarea onChange={(e) => { setComment(e.target.value) }} value={Comment} className='w-100 form-control' placeholder='comment (optional)' rows="3"></textarea></div> : null}
                            </div>
                        }
                    </ModalBody>
                    {info?.user_role == role ? <ModalFooter>
                        <div className="row">
                            {next.length > 0 ? next.map((item, i) => {
                                return (<div className="col-sm-4  p-2" key={`next-${i}`}>
                                    <Button className={`btn btn-${item.id.color} w-100 h-100`} onClick={() => { updateApplication(info._id, item.id._id) }}>{item.id.name}</Button>
                                </div>)
                            }) : null}
                        </div>

                    </ModalFooter> : null}
                </Modal>
            </div>
        </div >
    )
}

const History = ({ app_id, loader, isHistory }) => {
    const [history, setHistory] = useState([])
    const getHistory = () => {
        loader(true)
        axios.get(`${api}/app/history`, { headers: { app_id }, withCredentials: true })
            .then((res) => { setHistory(isArr(res?.data?.app_history)); loader(false); })
            .catch((e) => { loader(false); })
    }
    useEffect(() => { if (isHistory) getHistory() }, [isHistory])
    return (
        <div className='row'>
            {History?.length == 0 ?
                <div className="col-md-12 alert alert-success text-center">there is no history.</div>
                :
                history.map((item, index) => <div className='col-md-12 pe-4 ps-4' key={`apphistory-${index}`}>
                    <span className="bg-light p-2 border rounded">{new Date(item?.createdAt).toLocaleString()}</span>
                    <div className="alert alert-warning">
                        <p>{item?.user_id?.fullname} ({item?.user_id?.role})</p>
                        <p>{item?.workflow_id?.name}</p>
                        <p>Comment: {item?.comment == '' ? 'No Comment' : item?.comment}</p>
                    </div>
                </div>)
            }
        </div>
    )
}

export default ViewAppModal
