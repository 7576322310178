import React, { useState, useEffect, useContext } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import axios from 'axios';
import { Cxt } from '../../../../App'
import { api } from '../../../../util/constant';
import { IoCloudDownloadOutline } from 'react-icons/io5';
import { FileDownload, isArr, identifyRole, calculateFormDataSizeInMb } from '../../../../util/functions'
import Cookies from 'js-cookie';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

function JobtaskModal(props) {

    const { role } = useContext(Cxt);
    const [comment, setComment] = useState('');
    const [attachments, setAttachments] = useState([]);
    const [supervisorComment, setSupervisorComment] = useState('');

    const toggle = () => (props.setModal(!props.modal));
    const loader = (status) => { props.loader(status) };
    const { isSV, isStudent, isAdminOrEidaad } = identifyRole(role);

    const submitByStudent = () => {
        if (comment.trim() == '') { Notify.warning('comment cannot be empty.'); return };

        const data = new FormData();
        attachments.map(item => { if (item != '') data.append(`files`, item) })
        const obj = { comment, competency_id: props?.submission?.competencyId, jobtask_id: props?.info?.job_task_id, fdf_id: props?.fdf_id }
        data.append('data', JSON.stringify(obj))
        const filesSizeInMb = calculateFormDataSizeInMb(data)
        if (filesSizeInMb > 25) return Notify.warning(`max size of all files is 25MB, your files size is ${parseInt(filesSizeInMb)}MB.`);
        loader(true)
        axios.post(`${api}/fdf/sub/jobtask`, data, { withCredentials: true })
            .then((res) => { Notify.success('submitted successfully'); toggle(); loader(false); props.refresh() })
            .catch((e) => { console.log(e); loader(false); Notify.failure('somthing went wrong'); })
    }

    const submitBySupervisor = ({ isSendBackToStudent, modificationComment = '' }) => {
        if (!isSendBackToStudent && supervisorComment.trim() == '') { Notify.warning('comment cannot be empty.'); return };
        if (isSendBackToStudent && modificationComment.trim() == '') { Notify.warning('modification comment cannot be empty.'); return false };
        loader(true)
        const obj = { comment: supervisorComment, competency_id: props.submission.competencyId, jobtask_id: props?.info?.job_task_id, submission_id: props.submission_id, isSendBackToStudent, modificationComment }
        axios.post(`${api}/fdf/sub/jobtask`, obj, { withCredentials: true })
            .then((res) => { Notify.success(isSendBackToStudent ? 'sent back to student for modification successfully' : 'submitted successfully'); toggle(); loader(false); props.refresh() })
            .catch((e) => { console.log(e); loader(false); Notify.failure('somthing went wrong'); })
        return true
    }


    const deleteJibtaskSubmission = () => {
        const competencyid = props.submission.competencyId
        const jobtaskid = props?.info?.job_task_id
        const submissionid = props.submission_id

        axios.delete(`${api}/fdf/sub/jobtask`, { headers: { submissionid, competencyid, jobtaskid }, withCredentials: true })
            .then((res) => {
                toggle();
                Notify.success('deleted successfully');
                loader(false);
                props.refresh()
            })
            .catch((e) => { console.log(e); loader(false); Notify.failure('somthing went wrong'); })
    }

    const modificationAlert = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='react-confirm-alert-body'>
                        <h1>modification request</h1>
                        <p>please type comment for student</p>
                        <input type="text" className="form-control" id='modificationComment' />
                        <div className="react-confirm-alert-button-group">
                            <button onClick={onClose}>No</button>
                            <button onClick={async () => {
                                const modificationComment = document.getElementById('modificationComment').value
                                const isDone = submitBySupervisor({ isSendBackToStudent: true, modificationComment })
                                if (isDone) onClose()
                            }} >Submit </button>
                        </div>
                    </div >
                );
            }
        });
    }

    const handleAddAttachment = () => setAttachments(prev => prev.length >= 3 ? [...prev] : [...prev, ''])
    const handleEditAttachment = (file, index) => { const _attachmetns = [...attachments]; _attachmetns[index] = file; setAttachments(_attachmetns) }
    const handleRemoveAttachment = (index) => { const _attachmetns = [...attachments]; _attachmetns.splice(index, 1); setAttachments(_attachmetns); }

    useEffect(() => {
        const { submission } = props
        if (submission?.isStudentSubmitted) {
            setComment(submission?.submitiedJobTask?.comment_by_student?.text)
            setAttachments(isArr(submission?.submitiedJobTask?.attachments))
        }
        if (props?.submission?.isSupervisorSubmitted) setSupervisorComment(submission?.submitiedJobTask?.comment_by_supervisor?.text)
    }, [props.submission])
    useEffect(() => { if (!props.modal) { setComment(''); setAttachments([]); setSupervisorComment(''); } }, [props.modal])
    return (
        <Modal size="lg" style={{ maxWidth: '700px', width: '100%' }} isOpen={props.modal} toggle={() => { toggle(props) }}>
            <ModalHeader toggle={() => { toggle(props) }} >JobTask</ModalHeader>
            <ModalBody>
                {props?.submission?.submitiedJobTask?.comment_by_student?.is_allow_edit ?
                    <div className="alert alert-warning"> <b>modification comment by supervisor :</b> <br /> {props?.submission?.submitiedJobTask?.comment_by_student?.modification_comment}</div>
                    : null}
                <p><b>Title : </b>{props?.info?.title}</p>
                <p><b>ID : </b>{props?.info?.job_task_id}</p>
                <p><b>description : </b>{props?.info?.description}</p>
                <p><b>Activities : </b></p>
                <ul>{props?.info?.activities?.map((item, index) => <li key={`activity-${index}`}>{item}</li>)}</ul>
                <div className='mb-3'>
                    <p><b>student :</b></p>
                    <textarea className='form-control mb-2' disabled={isStudent && props?.submission?.isAllowStudentUpdateComment ? false : true} onChange={e => setComment(e.target.value)} value={comment} placeholder='student comment' rows="4"></textarea>
                    Attachments (size of all files should be less than 25MB) : {props?.submission?.isAllowStudentUpdateComment && isStudent ? <button className="btn btn-success" onClick={handleAddAttachment}>+</button> : null}<br />
                    {attachments?.map((item, index) => <div className="mt-2">
                        {props?.submission?.isAllowStudentUpdateComment && isStudent ? <button className="btn btn-danger" onClick={() => handleRemoveAttachment(index)}>-</button> : null}
                        {item?.name && isStudent || props?.submission?.isAllowStudentUpdateComment && isStudent ? <label className="btn border"> choose file
                            <input type="file" className="mt-4 mb-4" onChange={(e) => { handleEditAttachment(e.target.files[0], index) }} />
                        </label> : null}
                        {!item?.name && item != '' ? <button className="btn btn-light shadow" onClick={() => { FileDownload(`${api}/download/?token=${Cookies.get('token')}&file_name=${item}&type=jobtask`) }}><IoCloudDownloadOutline /> file {index + 1}</button> : item?.name}
                    </div>)}
                    {attachments?.length == 0 ? <div className="alert alert-success p-2 text-center m-3">there is no attachments</div> : null}
                </div>
                <hr />
                <div>
                    <p><b>supervisor :</b></p>
                    <textarea className='form-control' disabled={isSV && !props?.submission?.isSupervisorSubmitted && props?.submission?.isStudentSubmitted ? false : true} value={supervisorComment} onChange={e => setSupervisorComment(e.target.value)} placeholder='supervisor comment' rows="4"></textarea>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="row w-100">
                    <div className="col-md-12 text-center">

                        {props?.submission?.isAllowStudentUpdateComment && props?.submission?.isStudentSubmitted && isStudent || props?.submission?.isStudentSubmitted && isSV && !props?.submission?.isSupervisorSubmitted && !props?.submission?.isAllowStudentUpdateComment || isAdminOrEidaad && props?.submission?.isStudentSubmitted && !props.isFdfSubmissionCompleted ?
                            <button className='btn btn-danger start-end mb-2 me-2' onClick={() => confirmAlert({
                                title: 'Confirm to delete',
                                message: 'Are you sure to do delete this submission ?',
                                buttons: [
                                    {
                                        label: 'Yes',
                                        onClick: () => deleteJibtaskSubmission()
                                    },
                                    {
                                        label: 'No',
                                        onClick: () => { }
                                    }
                                ]
                            })}
                            >delete</button> : null}
                        {props?.submission?.isAllowStudentUpdateComment && isStudent
                            || props?.submission?.isStudentSubmitted && isSV && !props?.submission?.isSupervisorSubmitted && !props?.submission?.isAllowStudentUpdateComment ?
                            <button className="btn btn-success float-end mb-2" onClick={() => {
                                if (isStudent) submitByStudent()
                                if (isSV) submitBySupervisor({ isSendBackToStudent: false })
                            }}>submit</button> : null}

                        {props?.submission?.isStudentSubmitted && isSV && !props?.submission?.isAllowStudentUpdateComment && !props.isFdfSubmissionCompleted /* && !props?.submission.isJobTaskSubmitted */ ?
                            <button className='btn btn-warning mb-2' onClick={() => modificationAlert()}>send back to student for modification</button> :
                            null}

                        <button className="btn btn-warning float-start mb-2" onClick={toggle}>close</button>
                    </div>
                </div>
            </ModalFooter>
        </Modal >
    )
}

export default JobtaskModal