import React, { useContext } from 'react'
import Nav from '../components/nav';
import Location from '../components/location';
import { Cxt } from '../App'
import OrgsView from '../components/org/orgs'
import OrgView from '../components/org/org'
import { identifyRole } from '../util/functions'
function Org() {
    const { role } = useContext(Cxt);
    const { isOFP, isOFFP, isAdminOrEidaad } = identifyRole(role)
    return (
        <>
            <Nav />
            <Location />
            {isAdminOrEidaad ? <OrgsView /> : null}
            {isOFP || isOFFP ? <OrgView role={role} /> : null}
        </>
    )
}

export default Org