import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { api, path } from '../../../util/constant'
import { isArr } from '../../../util/functions';
import { useHistory } from 'react-router-dom'

// Muitable Requerments
import { TableFooter, TableCell, TableRow } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import muiTable from '../../../util/mui-table'
import MUIDataTable from "mui-datatables";
import { IoExpandOutline, IoRepeat, IoSearch, IoExpand } from "react-icons/io5";
function FdfFullSubmissionList({ loader }) {


    const [search, setSearch] = useState({})
    const [submissionsList, setSubmissionsList] = useState([])

    const [theme, setTheme] = useState(true)
    const [tablePageNumber, setTablePageNumber] = useState(1)
    const [tableRowsLimit, setTableRowsLimit] = useState(10)
    const [tableTotalPages, setTotalPages] = useState(0)
    const [tableTotalRows, setTableTotalRows] = useState(0)


    let options = muiTable.muiOptions(theme)
    options = {
        ...options,
        rowsPerPage: 100,
        search: false,
        serverSide: true,
        customFooter: () => {
            return (
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={6}>
                            page {tablePageNumber} of {tableTotalPages} - total result {tableTotalRows}
                            <button className="btn btn-light" disabled={tablePageNumber == 1 ? true : false} onClick={() => setTablePageNumber(prev => prev - 1)}>{`<`}</button>
                            <button className="btn btn-light" disabled={tablePageNumber == tableTotalPages || tableTotalPages == 0 ? true : false} onClick={() => setTablePageNumber(prev => prev + 1)}>{`>`}</button>
                            jump to <select onChange={e => setTablePageNumber(+e.target.value)}>{[...Array(tableTotalPages)].map((item, i) => <option value={i + 1} key={i}>{i + 1}</option>)}</select>
                            per page <select onChange={e => setTableRowsLimit(+e.target.value)}>{[...Array(10)].map((item, i) => <option value={(i + 1) * 10} key={i}>{(i + 1) * 10}</option>)}</select>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            );
        }
    }

    const columns = [{
        label: "",
        options: {
            filter: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                return (<>
                    {/* <span onClick={() => ConfirmDelete(tableMeta.rowData[tableMeta.columnIndex])} className={`text-center rounded pt-0 p-1 border pointer text-light bg-danger`}>X</span> */}
                </>)
            }
        }
    }, "Updated", "Name", "Framework", {
        label: "Status",
        options: {
            filter: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                const is_complete = tableMeta.rowData[tableMeta.columnIndex]
                return (<p className={`text-center pt-0 p-1 border w-100 h-100 ${is_complete ? 'bg-success' : 'bg-light'}`}>{is_complete ? 'Complete' : 'In Progress'}</p>)
            }
        }
    }, {
        label: "",
        options: {
            filter: false,
            customBodyRender: (value, tableMeta, updateValue) => {//<button className='btn btn-light' onClick={() => goTO(tableMeta.rowData[tableMeta.columnIndex])}>Open</button>
                return <>
                    <p className={`text-center pt-0 p-1 pointer border w-100 h-100`} onClick={() => goTO(tableMeta.rowData[tableMeta.columnIndex])}>View</p>
                </>
            }
        }
    }];

    const goTO = (submission) => {
        window.open(`${path}/fdfsubmissionview?id=${submission?.fdf?._id}&name=${submission?.fdf?.title}&student=${submission?.student?._id}`);
    }
    const ConfirmDelete = (id) => console.log(id)
    const getAllSubmissionsList = () => {
        loader(true)
        axios.get(`${api}/fdf/sub`, { headers: { search, limit: tableRowsLimit, page: tablePageNumber, isallsubmissions: 'true' }, withCredentials: true })
            .then(res => {
                const { totalRows, totalPages, submissions } = res.data
                setTotalPages(totalPages)
                setTableTotalRows(totalRows)
                setSubmissionsList(submissions)
                loader(false)
            })
            .catch(e => { console.error({ e }); loader(false) })
    }

    useEffect(() => {
        getAllSubmissionsList()
    }, [])

    useEffect(() => { getAllSubmissionsList() }, [tablePageNumber, tableRowsLimit])
    useEffect(() => { setTablePageNumber(1) }, [tableRowsLimit])

    return (
        <div>
            <MuiThemeProvider theme={muiTable.getMuiTheme()}>
                <MUIDataTable
                    title={<>
                        <button className="btn btn-light shadow" disabled >Submissions</button>
                        <button onClick={() => setTheme(!theme)} className="btn btn-light shadow"><IoExpandOutline /></button>
                    </>}
                    data={isArr(submissionsList).map(item => {
                        return [item._id, new Date(item?.updatedAt).toLocaleDateString() + ' ' + new Date(item?.updatedAt).toLocaleTimeString(),
                        item?.student?.fullname, item?.is_complete ? item?.title_when_completed : item?.fdf?.title, item?.is_complete, item]
                    })}
                    columns={columns}
                    options={options}
                />
            </MuiThemeProvider>
        </div>
    )
}

export default FdfFullSubmissionList