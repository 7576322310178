import React, { useState, useEffect } from 'react'
import './App.css';
import Cookies from 'js-cookie';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import PrivateRoute from './util/PrivateRoute';
import { path, roles } from './util/constant';

import Dashboard from './view/dashboard'
import Users from './view/users'
import Projects from './view/projects'
import Reg from './view/userRegistration'
import Login from './view/login'
import Profile from './view/profile'
import Regusers from './view/registration'
import Org from './view/org'
import Fdf from './view/fdf'
import Application from './view/application'
import Discipline from './view/discipline'
import Setting from './view/setting'
import Website from './view/website'
import Reports from './view/reports'
import PageNotFound from './view/404'
import FdfSubmissionView from './view/fdfSubmissionView';

export const Cxt = React.createContext(false);

function App() {
  const cookie = Cookies.get('role')
  // const extra_roles = Cookies.get('extra_roles')
  const [role, setRole] = useState(cookie)
  const [pageWidth, setPageWidth] = useState(window.innerWidth)
  const [currentPage, setCurrentPage] = useState('')
  const [isNotAddPadding, setIsNotAddPadding] = useState(true)
  const { admin, eidaad, student, afp, ofp, offp, ta, supervisor, advisor } = roles

  const handleWhenToAddPadding = () => { setIsNotAddPadding([`${path}/login`, `${path}/reg`, ``].includes(currentPage)) }
  useEffect(() => { handleWhenToAddPadding(); }, [currentPage])

  useEffect(() => {
    window.addEventListener("resize", () => { setPageWidth(window.innerWidth); });
  }, [])


  return (
    <div className={`App ${!isNotAddPadding ? pageWidth >= 700 ? 'app-desktop-view' : 'mt-5' : ''}`}>
      <Cxt.Provider value={{ role, setRole, pageWidth, setCurrentPage }}>
        <Router>
          <Switch>
            <Route path='/' exact component={Website} />
            <PrivateRoute exact path={`${path}/`} authenticated={[admin, eidaad, student, afp, ofp, offp, ta, supervisor, advisor]} component={Dashboard} />
            <PrivateRoute path={`${path}/profile`} authenticated={[admin, eidaad, student, afp, ofp, offp, ta, supervisor, advisor]} component={Profile} />
            <PrivateRoute path={`${path}/users`} authenticated={[admin, eidaad, ofp, offp, afp, advisor]} component={Users} />
            <PrivateRoute path={`${path}/projects`} authenticated={[admin, eidaad, ofp, offp, afp, student, advisor]} component={Projects} />
            <PrivateRoute path={`${path}/registration`} authenticated={[admin, eidaad, ofp]} component={Regusers} />
            <PrivateRoute path={`${path}/orgnizations`} authenticated={[admin, eidaad, ofp, offp]} component={Org} />
            <PrivateRoute path={`${path}/fdf`} authenticated={[admin, eidaad, ofp, afp, offp, student, ta, supervisor, advisor]} component={Fdf} />
            <PrivateRoute path={`${path}/fdfsubmissionview`} authenticated={[admin, eidaad, ofp, supervisor, ta, afp, advisor]} component={FdfSubmissionView} />
            <PrivateRoute path={`${path}/discipline`} authenticated={[admin, eidaad, ofp]} component={Discipline} />
            <PrivateRoute path={`${path}/application`} authenticated={[admin, eidaad, student, afp]} component={Application} />
            <PrivateRoute path={`${path}/setting`} authenticated={[admin, eidaad]} component={Setting} />
            <PrivateRoute path={`${path}/reports`} authenticated={[admin, eidaad]} component={Reports} />
            <Route path={`${path}/reg`} component={Reg} />
            <Route path={`${path}/login`} component={Login} />
            <Route path={`*`} component={PageNotFound} />
          </Switch>
        </Router>
      </Cxt.Provider>
    </div>
  );
}

export default App;
