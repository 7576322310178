import React from 'react'
import { IoAlertCircleOutline } from "react-icons/io5";
function DashboardMessege({ dashboard_messege }) {
    return (
        dashboard_messege?.is_show ?
            <div className={`alert alert-${dashboard_messege?.color}`}>
                <IoAlertCircleOutline size={22} /> {dashboard_messege?.text}
            </div> : <></>
    )
}

export default DashboardMessege